import { randomBytes } from "crypto";
import React from "react";
import { GlobVar } from "./globvar";

let enter_down = false;
let space_down = false;

window.onkeydown = (e) =>
{
	switch(e.key)
	{
		case "Enter":
			enter_down = true;
			break;
		case " ":
			space_down = true;
			break;
	}
}

window.onkeyup = (e) =>
{
	switch(e.key)
	{
		case "Enter":
			enter_down = false;
			break;
		case " ":
			space_down = false;
			break;
	}
}

export const OnKeyUp = (f: () => unknown): React.KeyboardEventHandler =>
{
	return (e) =>
	{
		if(space_down && e.key === " ")
		{
			f();
		}
	}
}

const OnKeyDown = (f: () => unknown): React.KeyboardEventHandler =>
{
	return (e) =>
	{
		if(!enter_down && e.key === "Enter")
		{
			f();
		}

		else if(e.key === " ")
		{
			e.preventDefault();
		}
	}
}

export const OnActive = (f: undefined | (() => unknown), disabled: boolean = false) =>
{
	if(disabled || f === undefined) {
		return {};
	}

	return {
		onKeyDown: OnKeyDown(f),
		onKeyUp: OnKeyUp(f),
		onClick: f,
		tabIndex: 0,
		role: "button",
	};
}

export const GenID = () =>
{
	return randomBytes(32).toString("hex");
}

export const GetSelectable = (e: HTMLElement) =>
{
	return e.querySelectorAll<HTMLElement>(`a[href], button, details, input, select, textarea, [tabindex="0"]`);
}

export class ReactComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS>
{
	private life = 0;
	
	glob_queue: [GlobVar<any>, string][] = [];

	get mounted()
	{
		return this.life > 0;
	}

	onMount?(): void;
	onUnmount?(): void;

	componentDidMount()
	{
		this.life += 1;

		for(let item of this.glob_queue)
		{
			item[0]._regSet(this, item[1]);
		}

		this.glob_queue = [];

		if(this.onMount)
		{
			this.onMount();
		}

		return null;
	}

	componentWillUnmount()
	{
		this.life -= 1;

		if(this.onUnmount)
		{
			this.onUnmount();
		}

		return null;
	}
}
