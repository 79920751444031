import {Staff} from ".";
import { GetType } from "./Api";
import * as CallType from "./CallType";

export interface LogType
{
	id: number;
	created: number;
	content: string;
	user_name: string;
	user_email: string;
}

export interface Hits extends GetType
{
	Res: {
		hits: {
			date: number;
			count: number;
		}[];
	};
};

export interface Logs extends GetType
{
	Query: {
		at?: string;
		before?: string;
	};
	Res: {
		logs: LogType[];
	};
};

export interface Home extends GetType
{
	Body: {
		data: any;
	};
}

export interface GetStaff extends GetType
{
	Res: {
		users: Staff.User.UserBasicType[];
	}
}

export const Call = 
{
	logs: CallType.Get<Logs>("/admin/logs"),
	hits: CallType.Get<Hits>("/admin/hits"),
	home: CallType.Post<Home>("/admin/home"),
	getStaff: CallType.Get<GetStaff>("/admin/getStaff"),
};

