import { Col } from "reactstrap";
import {Component} from "../../..";
import {User} from ".";

export const RenderOrders = (ref: User) =>
{
	let elements: JSX.Element[] = [];
	let it = 0;

	if(!ref.state.user || ref.state.user.orders.length === 0)
	{
		return <></>;
	}

	for(let order of ref.state.user.orders)
	{
		elements.push(
			<Component.OrderCard setHidden={false} order={order} key={it++} />
		);
	}

	return (
		<Col className="spacer">
			<h5>Orders</h5>
			<Component.Skipper>
				<div className="scroll-window">
					{elements}
				</div>
			</Component.Skipper>
		</Col>
	);
}
