
import React from "react";
import { OnActive } from "../../scripts/util";
import "./style.scss";

interface IProps {
	user: {name: string, email?: string};
	onClick?: () => void;
	children?: any;
}

export class UserCard extends React.Component<IProps>
{
	render()
	{
		let classes: string[] = ["component-user-card"];

		if(this.props.onClick)
		{
			classes.push("is-clickable");
		}

		return (
			<div
				className={classes.join(" ")}
				{...OnActive(() => {
					if(this.props.onClick) {
						this.props.onClick();
					}
				}, !this.props.onClick)}
			>
				{this.props.children}
				{this.props.user.email ? <>
					<h5>{this.props.user.name}</h5>
					<p>{this.props.user.email}</p>
				</> : <>
					<p>{this.props.user.name}</p>
				</>}
			</div>
		);
	}
}
