import React, { RefObject, useEffect, useId, useRef, useState } from "react";
import "./style.scss";
import GouldsLogo from "./GouldsLogo.jpg";
import { NavItems, NavItem } from "./NavItems";
import { App } from "../../app/App";
import { StripPathname } from "../../shared/helpers/DataHelpers";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { UserHasCapability, UserCapability } from "../../shared/User";
import { OnActive, ReactComponent } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {

}

interface IState {
	nav_extended: boolean;
	session: Api.Auth.SessionType;
}

export const Navbar = () =>
{
	const [session, setSession] = useState(GlobalVariables.Session.value);
	const [extended, setExtended] = useState(false);
	const focused = useRef(0);

	GlobalVariables.Session.regUse(setSession);
	
	let navItems: JSX.Element = <></>;

	if(session.confirmed)
	{
		// display all the nav items
		let e: JSX.Element[] = [];
		let path = StripPathname(window.location.pathname);
		let nav_items: NavItem[] = [];

		for(let nav_item of NavItems(session))
		{
			if(nav_item.cap && (!session.user || !UserHasCapability(session.user.permission, nav_item.cap))) {
				continue;
			}
			
			nav_items.push(nav_item);
		}

		if(session.user) {
			
			nav_items.push({
				display: "Profile",
				to: "/profile",
			});

		} else {
			
			nav_items.push({
				display: "Login",
				to: "/login",
			});
			
		}

		if(GlobalVariables.IsPhone)
		{
			e.push(
				<li
					key={0}
					className={"phone" + (extended ? " selected" : " last")}
				>
					<p role="button" aria-hidden="true" onClick={() => setExtended(!extended)}>|||</p>
				</li>
			);
		}

		let classes_a: string[] = [];

		if(!extended && GlobalVariables.IsPhone)
		{
			classes_a.push("visually-hidden");
		}

		if(GlobalVariables.IsPhone)
		{
			classes_a.push("phone");
		}

		let classes = classes_a.join(" ");

		for(let i = 0; i < nav_items.length; i++)
		{
			let navitem = nav_items[i];

			const click = () =>
			{
				if(extended)
				{
					setExtended(false);
				}

				App.routeToUrl(navitem.to);
			}

			const focus = () =>
			{
				if(focused.current === 0 && !extended)
				{
					setExtended(true);
				}

				focused.current += 1;
			}

			const blur = () =>
			{
				focused.current -= 1;

				if(focused.current === 0 && extended)
				{
					setExtended(false);
				}
			}

			let selected: boolean;
			let n_path = StripPathname(navitem.visual_to ?? navitem.to);

			if(n_path.length > 0) {
				selected = path.startsWith(n_path);
			} else {
				selected = path.length === 0;
			}

			e.push(
				<li
					key={i + 1}
					className={
						(i === nav_items.length - 1 ? "last " : "") +
						(selected ? "selected " : "") +
						classes
					}
					onFocus={focus}
					onBlur={blur}
					{...OnActive(click)}
				>
					<p>
						{navitem.display}
					</p>
				</li>
			);
		}

		navItems = (
			<Component.Skipper label="Navigation menu">
				<ul className="nav-items">{e}</ul>
			</Component.Skipper>
		);
	}

	let goulds_logo_alt = (
		`Goulds Logo. Goulds Natural Medicine extablished 1881. `+
		`Image description: A stationary mortar and pestle. There are `+
		`leaves in the bowl and some spilled outside. `
	);

	return (
		<div className="component-navbar">
			<img src={GouldsLogo} alt={goulds_logo_alt} className="goulds-logo" />
			{navItems}
		</div>
	)
}
