import { Container, Col, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { BirthdayValidator, EmailValidator, NameValidator, PasswordValidator, PhoneValidator } from "../../shared/Validators";
import { SessionReload } from "../AppHelpers";
import { workerCalculatePassword } from "../../scripts/worker";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { GenID, ReactComponent } from "../../scripts/util";
import {MakeRoute, Route} from "../Routes";
import {Confirm} from "./Confirm";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {

}

interface IState {
	showErrors: boolean;
	name: string;
	email: string;
	phone: string;
	password: string;
	passwordC: string;
	emailError: string;
	buttonDisabled: boolean;
	session: Api.Auth.SessionType;
}

class Register extends ReactComponent<IProps, IState>
{
	emailElement?: HTMLInputElement | HTMLTextAreaElement;
	nameElement?: HTMLInputElement | HTMLTextAreaElement;
	passwordElement?: HTMLInputElement | HTMLTextAreaElement;
	passwordCElement?: HTMLInputElement | HTMLTextAreaElement;
	phoneElement?: HTMLInputElement | HTMLTextAreaElement;

	phoneError() {
		switch(PhoneValidator(this.phoneElement ? this.phoneElement.value : "")) {
			case "InvalidCharacters": return "Invalid characters in phone number";
			case "TooLong": return "Phone number is too long";
			case "Required": return "Required";
			default: return "";
		}
	}

	emailError() {
		if(this.state.emailError !== "") return this.state.emailError;
		switch(EmailValidator(this.emailElement ? this.emailElement.value : "")) {
			case "InvalidCharacters": return "Illegal characters";
			case "InvalidSyntax": return "Invalid email format";
			case "TooLong": return "Email too long";
			case "Required": return "Required";
			default: return "";
		}
	}

	nameError() {
		switch(NameValidator(this.nameElement ? this.nameElement.value : "")) {
			case "IllegalCharacters": return "Illegal characters";
			case "TooBig": return "Your name is too big";
			case "Required": return "Required";
			default: return "";
		}
	}

	passwordError()
	{
		if(!this.passwordElement || !this.passwordCElement) {
			return "Required";
		}

		switch(PasswordValidator(this.passwordElement.value)) {
			case "Required": return "Required";
			case "TooShort": return "Too short";
		}

		if(this.passwordElement.value !== this.passwordCElement.value) {
			return "Passwords do not match";
		}

		return "";
	}

	updateValues() {
		this.setState({
			name: this.nameElement ? this.nameElement.value : "",
			email: this.emailElement ? this.emailElement.value : "",
			password: this.passwordElement ? this.passwordElement.value : "",
			passwordC: this.passwordCElement ? this.passwordCElement.value : "",
			phone: this.phoneElement ? this.phoneElement.value : "",
		});
	}

	async register()
	{
		this.setState({
			showErrors: true,
		});

		// Don't continue if there are any errors
		if(
			this.phoneError() !== "" ||
			this.passwordError() !== "" ||
			this.emailError() !== "" ||
			this.nameError() !== "" ||
			!this.passwordCElement ||
			!this.passwordElement ||
			!this.emailElement ||
			!this.nameElement ||
			!this.phoneElement
		) {
			return;
		}

		this.setState({
			buttonDisabled: true,
		});

		let email = this.emailElement.value;
		let phone = this.phoneElement.value;
		let password = await workerCalculatePassword(this.passwordElement.value);
		let name = this.nameElement.value;

		let response = await Api.Auth.Call.register({
			email: email,
			phone: phone,
			password: password,
			name: name,
		});

		if(response.error) {
			this.setState({
				buttonDisabled: false,
			});
			if(response.error === "user exists") {
				this.setState({
					emailError: "This email has already been used",
				});
				return;
			}
			throw response.error;
		}

		await SessionReload();
	}

	constructor(props: IProps) {
		super(props);

		this.state = {
			showErrors: false,
			email: "",
			phone: "",
			name: "",
			password: "",
			passwordC: "",
			emailError: "",
			buttonDisabled: false,
			session: GlobalVariables.Session.regSet(this, "session"),
		}
	}

	id1 = GenID();
	id2 = GenID();
	id3 = GenID();
	id4 = GenID();
	id5 = GenID();

	render() {

		return (
			<div className="page-register">
				<Container role="form">

					<Col><h4>Register</h4></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id1}>Full Name</Label>
						<Input
							id={this.id1}
							type="text" 
							innerRef={(e) => {if(e) this.nameElement = e}}
							onChange={(e) => this.updateValues()}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.nameError() : ""}
						</FormText>
					</FormGroup></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id2}>Email</Label>
						<Input
							id={this.id2}
							type="text" 
							innerRef={(e) => {if(e) this.emailElement = e}} 
							onChange={() => {
								this.setState({
									emailError: "",
								});
								this.updateValues();
							}}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.emailError() : ""}
						</FormText>
					</FormGroup></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id3}>Phone Number</Label>
						<Input
							id={this.id3}
							type="text" 
							innerRef={(e) => {if(e) this.phoneElement = e}} 
							onChange={() => {
								this.updateValues();
							}}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.phoneError() : ""}
						</FormText>
					</FormGroup></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id4}>Password</Label>
						<Input
							id={this.id4}
							type="password" 
							innerRef={(e) => {if(e) this.passwordElement = e}} 
							onChange={() => this.updateValues()}
						/>
						<FormText color="danger" />
					</FormGroup></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id5}>Confirm Password</Label>
						<Input
							id={this.id5}
							type="password" 
							innerRef={(e) => {if(e) this.passwordCElement = e}} 
							onChange={() => this.updateValues()}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.passwordError() : ""}
						</FormText>
					</FormGroup></Col>

					<Col>
						<Button
							onClick={() => this.register()}
							disabled={this.state.buttonDisabled}
						>Register</Button>
					</Col>

				</Container>
				<Component.Padding type="bottom" />
			</div>
		);
	}
}

export const RegisterRoute = () => MakeRoute(Register, undefined, {
	"confirm": MakeRoute(Confirm),
});

