import {useState} from "react";
import {Api, Helpers} from "../../shared";
import {AddProduct} from "./AddProduct";
import {Products} from "./Products";

export function ProductFinder(props: {
	callback: (product: Api.Practitioner.Product.TemplateType, demo_product: Api.Product.ProductType, id: string) => any;
	overrides?: Partial<Api.Practitioner.Product.TemplateType>;
	onDemoUpdate?: (p: Api.Product.ProductType) => any;
	showStaffOpts: boolean;
	showPublicOpts?: boolean;
}) {

	let [preset, setPreset] = useState<Api.Practitioner.Product.TemplateType>();

	async function click(p: Api.Product.ProductSearchedType) {
		let res = await Api.Product.Call.get({id: p.id});
		if(!res.data) throw res.error;
		setPreset({
			...Helpers.Data.MakeProductTemplate(res.data.product),
			...((props.overrides ?? {}) as Api.Practitioner.Product.TemplateType),
		});
	}

	if(preset) {
		return <AddProduct preset={preset} callback={props.callback} showStaffOpts={props.showStaffOpts} showPublicOpts={props.showPublicOpts} onDemoUpdate={props.onDemoUpdate} />;
	}
	
	return <Products onClick={click} showStaffOpts={props.showStaffOpts} showPublicOpts={props.showPublicOpts} onDemoUpdate={props.onDemoUpdate} />
}
