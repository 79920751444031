import {GetType} from "./Api";
import * as CallType from "./CallType";

export interface ImageType
{
	file_name: string;
	file_id: string;
	is_owner?: boolean | null;
}

export interface Images extends GetType
{
	Res: {
		images: ImageType[];
	}
}

export const Image = (file_id: string, file_name: string) =>
{
	return "/api/files/image/" + file_id + "/" + file_name;
}

export const Call =
{
	image: Image,
	images: CallType.Get<Images>("/files/images"),
};

