import {Button, Col, FormGroup, Input, Label} from "reactstrap";
import {Api, SQLEnum} from "../../../shared";
import {useEffect, useId, useState} from "react";
import {SizingsPicker} from "./SizingsPicker";
import {App} from "../../App";
import {Component} from "../../..";
import {Image} from "../../../shared/api/Files";

interface FormProps {
	template: Api.Staff.Template.Type;
	onChange: (v: Api.Staff.Template.Type) => any;
	onError: (v: string | undefined) => any;
	showErrors: boolean;
}

export function Form(props: FormProps)
{
	const [name, setName] = useState(props.template.name);
	const [sizings, setSizings] = useState(props.template.sizings);
	const [quantity_type, setQuantityType] = useState<SQLEnum.QuantityType>(props.template.quantity_type);
	const [image, setImage] = useState<Api.Files.ImageType>({file_id: props.template.image_id, file_name: props.template.image_name});

	const nameId = useId();
	const quantityTypeId = useId();

	function pickImage()
	{
		App.open(() => <Component.ImageSelector closeCallback={(image) => {
			if(image) {
				setImage(image);
			}
			App.open(null);
		}} />, false);
	}

	useEffect(() =>
	{
		if(name.length === 0) {
			props.onError("Name is required");
			return;
		}
		
		if(!image) {
			props.onError("Image required");
			return;
		}

		let template: Api.Staff.Template.Type = {name, sizings, quantity_type, image_id: image.file_id, image_name: image.file_name};
		let sizings_found: number[] = [];

		for(let item of template.sizings) {
			if(isNaN(item.amount) || isNaN(item.base_cost) || isNaN(item.weight) || item.amount <= 0 || item.base_cost < 0 || item.weight < 0) {
				props.onError("Invalid product sizings");
				return;
			}
			if(sizings_found.includes(item.amount)) {
				props.onError("Duplicate product sizings");
				return;
			}
			sizings_found.push(item.amount);
		}

		props.onChange(template);
		props.onError(undefined);
	
	}, [name, sizings, quantity_type, image]);

	return (<>
		<FormGroup>
			<Label htmlFor={nameId}>Name</Label>
			<Input id={nameId} defaultValue={name} onChange={(e) => setName(e.target.value)} />
		</FormGroup>
		<FormGroup>
			<Label htmlFor={quantityTypeId}>Quantity Type</Label>
			<Input id={quantityTypeId} type="select" defaultValue={quantity_type} onChange={(e) => setQuantityType(e.target.value as SQLEnum.QuantityType)}>
				<option value="G">Grams</option>
				<option value="ML">Milliliters</option>
			</Input>
		</FormGroup>
		<FormGroup>
			<Label>Product Sizings</Label>
		</FormGroup>
		<FormGroup>
			<SizingsPicker showErrors={props.showErrors} value={sizings} quantityType={quantity_type} onChange={setSizings} />
		</FormGroup>
		<FormGroup>
			<Label>Image</Label>
			<div>
				{image ? <>
					<img className="product-image" src={Image(image.file_id, image.file_name)} />
				</> : <></>}
			</div>
			<Button onClick={pickImage}>Pick</Button>
		</FormGroup>
	</>);
}

