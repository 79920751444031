import {convertToRaw} from "draft-js";
import {ErrorsType, FormType, ServerOpts} from ".";
import {Api, Helpers} from "../../shared";
import {RemoveDraftJSWhitespaces} from "../../shared/helpers/DataHelpers";

export type FormProcOpts = {
	server?: ServerOpts;
	form: FormType;
	showPublicOpts: boolean;
	product_base: Api.Practitioner.Product.TemplateType;
};

export type FormProcRet = {
	template_product?: Api.Practitioner.Product.TemplateType;
	errors: ErrorsType;
};

export function FormProc({server, form, showPublicOpts, product_base}: FormProcOpts): FormProcRet
{
	let errors: ErrorsType = {};
	let has_errors = false;

	if(!server || !server.templates || !form.components) {
		return {errors: {main: "Not loaded"}};
	}

	const cart_limit_v = parseInt(form.cart_limit);
	const template = server.templates.find(v => v.name === form.template_name);
	const desc = Helpers.Data.RemoveDraftJSWhitespaces(convertToRaw(form.description.getCurrentContent()));
	const desc_plain = Helpers.Data.TextRawify(desc);

	if(form.name.length === 0)													{errors.name = "Required";				has_errors = true; }
	if(form.template_name.length === 0)											{errors.template_name = "Required";		has_errors = true; }
	else if(!template)															{errors.template_name = "Invalid";		has_errors = true; }
	if(!desc_plain)																{errors.description = "Required"; 		has_errors = true; }
	if(form.base_size.length === 0)												{errors.base_size = "Invalid";			has_errors = true; }
	if(!form.components || form.components.length === 0)						{errors.components = "Required";		has_errors = true; }
	if(form.sizes.length === 0)													{errors.sizes = "Required";				has_errors = true; }
	if(form.cart_limit_enabled && (isNaN(cart_limit_v) || cart_limit_v <= 0))	{errors.cart_limit = "Invalid";			has_errors = true; }
	
	if(showPublicOpts) {
		if(form.brand.length === 0)												{errors.brand = "Required";				has_errors = true; }
		if(form.category.length === 0)											{errors.category = "Required";			has_errors = true; }
	}

	for(let item of form.components) {
		if (item.name.length === 0 || item.value.length === 0) {
			errors.components = "Required";
			has_errors = true;
			break;
		}
		let component = server.components.find((v) => v.name === item.name && (v.templates.includes(form.template_name) || v.group_templates?.includes(form.template_name)));
		let value = parseFloat(item.value);
		if(!component || isNaN(value) || value <= 0) {
			errors.components = "Invalid";
			has_errors = true;
			break;
		}
	}

	if(has_errors) {
		return {errors};
	}

	if(!template) {
		return {errors: {template_name: "Invalid"}};
	}

	let base_size_v = parseFloat(form.base_size);
	let image_v = form.image ?? Helpers.Data.MakeImage(template);
	let brand_v = showPublicOpts ? form.brand : "";
	let category_v = showPublicOpts ? form.category : "";

	let template_product: Api.Practitioner.Product.TemplateType = {
		name: form.name,
		template: form.template_name,
		image_id: image_v.file_id,
		image_name: image_v.file_name,
		brand: brand_v,
		category: category_v,
		description: RemoveDraftJSWhitespaces(convertToRaw(form.description.getCurrentContent())),
		visibility: form.visibility,
		gst_aud: form.gst_aud,
		vat_gbp: form.vat_gbp,
		components: [],
		sizes: form.sizes,
		is_fav: product_base.is_fav,
	}

	for(let item of form.components) {
		let component = server.components.find((v) => v.name === item.name)!;
		template_product.components.push({
			name: component.name,
			amount_per_q: parseFloat(item.value) / base_size_v,
			id: component.id,
		});
	}

	return {errors, template_product};
}

