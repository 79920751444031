import {Api} from ".";

export const ConvertStaffProduct = (p_in: Api.Staff.Product.ItemType) =>
{
	let options: Api.Product.ProductType["options"] = [];

	for(let option of p_in.options) {
		options.push({
			amount: option.amount,
			weight: option.weight,
			name: option.name,
			out_stock: false,
			id: "",
		});
	}

	let p_out: Api.Product.ProductType =
	{
		can_purchase: false,
		cart_max: 0,
		description: p_in.description,
		id: "",
		image_id: p_in.image_id,
		image_name: p_in.image_name,
		name: p_in.name,
		options: options,
		visibility: "PUBLIC",
		gst_aud: p_in.gst_aud,
		vat_gbp: p_in.vat_gbp,
		brand: p_in.brand,
		category: p_in.category,
	}

	return p_out;
}

export interface ProductsUserCanPurchaseOpts
{
	product: Api.Product.ProductType;
	option: Api.Product.ProductOptionType;
}
