import { useId } from "react";
import { Col, Input } from "reactstrap";
import { OnActive } from "../../../scripts/util";
import { GlobalVariables } from "../../../scripts/GlobalVariables";
import { App } from "../../App";
import {Api, Helpers} from "../../../shared";
import {Component} from "../../..";
import {CartRefProps} from "..";

export const CartList = (ref: CartRefProps): JSX.Element =>
{
	let state = ref.state;
	let base_id = useId();

	if(!state.cart) {
		return <></>;
	}

	let itemCountElements: JSX.Element[] = [];
	let it = 0;

	for(let item of state.cart)
	{
		const input_onchange = (v: string) =>
		{
			if(ref.state.progress !== 0) {
				return;
			}

			let n = "";

			for(let c of v) {
				if(c === ".") {
					break;
				}
				if("1234567890e-".includes(c)) {
					n += c;
				}
			}

			let count = parseInt(n);

			if(!isFinite(count) || count < 1) {
				count = 1;
			}

			if(count > 1000) {
				count = 1000;
			}

			if(item.cart_max && count > item.cart_max) {
				count = item.cart_max;
			}

			if(item.uses != null && item.uses_max != null && count + item.uses > item.uses_max) {
				count = item.uses_max - item.uses;
			}

			if(count < 0) {
				count = 0;
			}

			item.count = count;
			ref.setState({
				cart: state.cart,
			});
		}

		const input_onblur = (v: string) =>
		{
			if(ref.state.progress !== 0) {
				return v;
			}

			let n = "";

			for(let c of v) {
				if(c === ".") {
					break;
				}
				if("1234567890e-".includes(c)) {
					n += c;
				}
			}

			item.count = parseInt(n);

			if(!isFinite(item.count)) {
				item.count = 1;
			}

			if(item.count > 1000) {
				item.count = 1000;
			}

			if(item.cart_max && item.count > item.cart_max) {
				item.count = item.cart_max;
			}

			if(item.uses != null && item.uses_max != null && item.count + item.uses > item.uses_max) {
				item.count = item.uses_max - item.uses;
			}

			if(item.count < 0) {
				item.count = 0;
			}

			v = item.count.toString();

			Api.Cart.Call.changeProduct({
				id: item.option_id,
				count: item.count,
			});

			ref.setState({
				cart: ref.state.cart,
			});

			return v;
		}

		const click = async () =>
		{
			item.count = 0;

			ref.setState({
				cart: ref.state.cart,
			});
						
			await Api.Cart.Call.changeProduct({
				id: item.option_id,
				count: item.count,
			});
		}

		let id = `${base_id}_${it++}`;

		itemCountElements.push(
			<div style={{
				width: "100px",
				margin: "0 auto",
			}}>
				<label className="visually-hidden" htmlFor={id}>Item cart count</label>
				<Input
					id={id}
					disabled={ref.state.progress !== 0}
					defaultValue={item.count}
					style={{
						display: "inline-block",
						width: "79px",
					}}
					onChange={(e) => input_onchange(e.target.value)}
					onBlur={(e) => {
						e.target.value = input_onblur(e.target.value);
					}}
					max="1000"
					step="1"
					min="0"
				/>
				<button
					disabled={ref.state.progress !== 0}
					style={{
						borderStyle: "none",
						background: "none",
						margin: 0,
						padding: 0,
						marginLeft: "10px",
						color: "#800",
					}}
					onClick={click}
				>X</button>
			</div>
		);
	}

	let ret: JSX.Element;

	if(!GlobalVariables.IsPhone)
	{
		let elements: JSX.Element[] = [];
		let count = 0;

		for(let i = 0; i < state.cart.length; i++)
		{
			let item = state.cart[i];

			if(item.count < 1) {
				continue;
			}

			count += 1;

			let e: JSX.Element;
			let url = "/store/item?" + Helpers.Data.QueryString({
				id: item.product_id
			});

			let name = item.name;

			if(item.is_custom)
			{
				name += "*";
			}

			if(state.progress === 0)
			{
				e = (
					<span {...OnActive(() => App.routeToUrl(url))} className="link-subtle">{name}</span>
				);
			}

			else
			{
				let url = "/store/item?" + Helpers.Data.QueryString({
					id: item.product_id
				});

				e = (
					<a href={url} target="_blank">{name}</a>
				);
			}

			elements.push(
				<tr key={i}>
					<td className="product-cart-image"><img
						src={Api.Files.Image(item.image_id, item.image_name)}
						className="product-image"
						alt={item.image_name}
					/></td>
					<td><p>
						{e}
					</p></td>
					<td>{itemCountElements[i]}</td>
					<td><p>${(item.amount/100).toFixed(2)} AUD</p></td>
					<td><p>
						${(item.amount/100*item.count).toFixed(2)} AUD
					</p></td>
				</tr>
			);
		}

		if(count === 0) {
			elements.push(
				<tr key={0}>
					<td colSpan={6}><p>
						Your cart is empty
					</p></td>
				</tr>
			)
		}

		ret = (
			<Col>
				<table>
					<thead>
						<tr>
							<th colSpan={2}><p>Item</p></th>
							<th><p>Quantity</p></th>
							<th><p>Price</p></th>
							<th className="total"><p>Total</p></th>
						</tr>
					</thead>
					<tbody>
						{elements}
					</tbody>
				</table>
			</Col>
		);
	}

	else
	{
		let elements: JSX.Element[] = [];
		let count = 0;

		for(let i = 0; i < state.cart.length; i++)
		{
			let item = state.cart[i];

			if(item.count < 1) {
				continue;
			}

			count += 1;

			let e: JSX.Element;
			let url = "/store/item?" + Helpers.Data.QueryString({
				id: item.product_id
			});

			let name = item.name;

			if(item.is_custom)
			{
				name += "*";
			}

			if(state.progress === 0)
			{
				e = (
					<span {...OnActive(() => App.routeToUrl(url))} className="link-subtle">{name}</span>
				);
			}

			else
			{
				e = (
					<a href={url} target="_blank">{name}</a>
				)
			}

			elements.push(
				<div className="cart-item" key={item.option_id}>
					<img
						src={Api.Files.Image(item.image_id, item.image_name)}
						className="product-image"
						aria-hidden="true"
					/>
					<p>{e}</p>
					<div style={{marginBottom: "16px"}}>
						<p aria-hidden="true" style={{marginBottom: "0"}}>Count:</p>
						{itemCountElements[i]}
					</div>
					<p>Price: ${(item.amount/100).toFixed(2)} AUD</p>
					<p>
						Total Price:
						${(item.amount/100*item.count).toFixed(2)} AUD
					</p>
				</div>
			);
		}

		if(count === 0) {
			ret = (
				<Col><p>Your cart is empty</p></Col>
			);
		}

		else {
			ret = (
				<Col>
					<Component.Skipper label="Cart items">
						<div className="cart-items-phone">
							{elements}
						</div>
					</Component.Skipper>
				</Col>
			);
		}
	}

	return ret;
}
