import {Staff} from "..";
import {GetType} from "../Api";
import * as CallType from "../CallType";

export interface Get extends GetType<"component not found"> {
	Query: {
		name: string;
	};
	Res: {
		component: Staff.Component.TypeID;
	};
};

export interface GetAll extends GetType<"template not found"> {
	Query: {
		template?: string;
	};
	Res: {
		components: Staff.Component.TypeID[];
	};
};

export interface GetTemplates extends GetType {
	Res: {
		templates: Staff.Template.Type[];
	};
};

export const Call = {
	get: CallType.Get<Get>("/practitioner/component/get"),
	getAll: CallType.Get<GetAll>("/practitioner/component/getAll"),
	getTemplates: CallType.Get<GetTemplates>("/practitioner/component/getTemplates"),
};

