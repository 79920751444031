import { calculatePassword_int } from "../shared/Security";
import { Buffer } from "buffer";

onmessage = async (ev) =>
{
	if(ev.data.mode === "calc_password")
	{
		let header: Buffer | undefined;

		if(ev.data.header)
		{
			header = Buffer.from(ev.data.header, "hex");
		}

		let hash = calculatePassword_int(ev.data.password, header);
		
		postMessage(hash.toString("hex"));
	}
}

let worker: Worker | undefined;
let worker_queue: ((data: any) => void)[] = [];

export const workerInit = () =>
{
	if(!worker)
	{
		worker = new Worker(new URL("./worker", import.meta.url));
		worker.onmessage = (ev) =>
		{
			let callback = worker_queue.pop();

			if(callback)
			{
				callback(ev.data);
			}
		}
	}

	return worker;
}

const workerProcess = (data: any) =>
{
	return new Promise<any>((res) =>
	{
		workerInit().postMessage(data);
		worker_queue.unshift(res);
	});
}

export const workerCalculatePassword = (password: string, header?: string): Promise<string> =>
{
	return workerProcess({
		mode: "calc_password",
		password: password,
		header: header,
	});
}
