import { useRef, useState } from "react";
import { GetSelectable, OnActive } from "../../scripts/util";


export const Skipper = (props: {children: any, label?: string, enabled?: boolean}) =>
{
	const b1 = useRef<HTMLParagraphElement>(null);
	const b2 = useRef<HTMLParagraphElement>(null);

	if(props.enabled === false)
	{
		return <>{props.children}</>;
	}

	const goto = (b: React.RefObject<HTMLParagraphElement>) =>
	{
		if(b.current)
		{
			b.current.focus();
		}
	}
	
	return (
		<>
			{props.label ? <h4 className="visually-hidden">{props.label}</h4> : <></>}
			<p {...OnActive(() => goto(b2))} className="visually-hidden" aria-hidden="true" ref={b1}>Skip to end</p>
			{props.children}
			<p {...OnActive(() => goto(b1))} className="visually-hidden" aria-hidden="true" ref={b2}>Skip to start</p>
		</>
	);
}
