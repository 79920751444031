import axios, { Axios } from "axios";

export interface EnvironmentType
{
	recaptcha_public: string;
	stripe_public: string;
	disable_purchase?: boolean;
}

export let Environment: EnvironmentType;

export const EnvironmentInit = async () =>
{
	let res = await axios.get("/globals.json");

	Environment = res.data;
}
