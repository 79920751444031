
export namespace SQLEnum
{
	export type Bool = 0 | 1;
	export type OrderStep = "CREATED" | "PAID" | "PROCESSED" | "SHIPPED" | "CANCELLED";
	export type PaymentMethod = "PHONE" | "CARD" | "BANK";
	export type TaxType = "NONE" | "GST_AUD" | "VAT_GBP";
	export type TaxTypeGST = "FULL" | "NONE";
	export type TaxTypeVAT = "FULL" | "SEMI" | "NONE";
	export type ProductVisibility = "PUBLIC" | "VISIBLE" | "INVISIBLE" | "PRIVATE";
	export type AttribType = "BRAND" | "CATEGORY";
	export type UserPermission = "CUSTOMER" | "STAFF" | "ADMIN" | "PRACTITIONER";
	export type Logger = "INFO" | "WARNING" | "ERROR" | "FATAL";
	export type QuantityType = "G" | "ML";

	export namespace A
	{
		export const OrderStep = ["CREATED", "PAID", "PROCESSED", "SHIPPED", "CANCELLED"];
		export const PaymentMethod = ["PHONE", "CARD", "BANK"];
		export const TaxType = ["NONE", "GST_AUD", "VAT_GBP"];
		export const TaxTypeGST = ["FULL", "NONE"];
		export const TaxTypeVAT = ["FULL", "SEMI", "NONE"];
		export const ProductVisibility = ["PUBLIC", "VISIBLE", "INVISIBLE", "PRIVATE"];
		export const AttribType = ["BRAND", "CATEGORY"];
		export const UserPermission = ["CUSTOMER", "STAFF", "ADMIN", "PRACTITIONER"];
		export const Logger = ["INFO", "WARNING", "ERROR", "FATAL"];
		export const QuantityType = ["G", "ML"];
	}
}
