import { createRef, useId, useState } from "react";
import { Button, Col, Container, FormGroup, FormText, Input } from "reactstrap";
import { GlobalVariables } from "../../../scripts/GlobalVariables";
import { UserHasCapability, UserCapability } from "../../../shared/User";
import { App } from "../../App";
import { ReactComponent } from "../../../scripts/util";
import {Api} from "../../../shared";
import {User} from ".";

interface IState
{
	session: Api.Auth.SessionType;
	form_error?: string;
	selected?: number;
}

interface IProps
{
	page: User;
	user: Api.Staff.User.UserType;
}

const Discount = (props: {callback: (v: [string, number, boolean]) => void}) =>
{
	const [name, setName] = useState("");
	const [amount, setAmount] = useState(0);
	
	let valid = true;

	if(name.length === 0 || name.length > 100)
	{
		valid = false;
	}

	else if(isNaN(amount) || amount <= 0 || amount > 1)
	{
		valid = false;
	}

	const id1 = useId();
	const id2 = useId();
	const id3 = useId();

	const click = (v: [string, number, boolean]) =>
	{
		App.open(null);
		props.callback(v);
	}

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<Col id={id1}>
				<h4>Set User Discount</h4>
			</Col>
			<Col>
				<FormGroup>
					<label htmlFor={id2} className="visually-hidden">Discount name</label>
					<Input id={id2} onChange={(e) => setName(e.target.value)} placeholder="Discount Name" />
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<label htmlFor={id3} className="visually-hidden">Discount amount %</label>
					<Input id={id3} onChange={(e) => setAmount(parseFloat(e.target.value) / 100)} placeholder="Amount (%)" />
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Button onClick={() => click([name, amount, true])} disabled={!valid}>Add</Button>
					<Button onClick={() => click(["", 0, false])}>Close</Button>
				</FormGroup>
			</Col>
		</Container>
	)
}

export class Discounts extends ReactComponent<IProps, IState>
{
	in_ref = createRef<HTMLInputElement>();
	expiry_ref = createRef<HTMLInputElement>();

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	async get_custom()
	{
		return new Promise<[string, number, boolean]>((res) =>
		{
			App.open(() => <Discount callback={res} />);
		});
	}

	async click()
	{
		if(!this.in_ref.current)
		{
			return;
		}

		let asked = false;
		let v = parseInt(this.in_ref.current.value);
		let expiry: Date | undefined = this.expiry_ref.current ? new Date(this.expiry_ref.current.value) : undefined;
		let name = "";
		let value = 0;

		if(expiry === undefined || isNaN(expiry.getTime()) || expiry.getTime() - Date.now() > 1577880000000)
		{
			if(v !== 0)
			{
				this.setState({
					form_error: "A valid expiration date must be provided",
				});
	
				return;
			}

			expiry = undefined;
		}
		
		this.setState({
			form_error: undefined,
		});
		
		if(expiry !== undefined)
		{
			expiry.setMinutes(expiry.getMinutes() + expiry.getTimezoneOffset());
		}

		switch(v)
		{
			case 1:
				name = "Low Income";
				value = 0.075;
				break;
			case 2:
				return;
			case 3:
				[name, value, asked] = await this.get_custom();
				if(!asked) return;
				break;
		}

		if(!asked)
		{
			let res = await App.openDialogue({
				title: "Set User Discount",
				content: () => (
					<p>
						Are you sure you want to change {this.props.user.name}'s
						current discount to {name ? (name + " at ") : ""}{value * 100}%?
					</p>
				),
				prompt: true,
			});
	
			if(!res)
			{
				this.setState({
					form_error: "Unknown error occured",
				});

				return;
			}
		}

		await Api.Staff.User.Call.setDiscount({
			name: name,
			value: value,
			email: this.props.user.email,
			expires: expiry?.getTime(),
		});

		this.props.user.discount = {
			amount: value,
			name: name,
		};

		let opt: number;

		if(this.props.user.discount.name === "Low Income" && this.props.user.discount.amount === 0.075)
		{
			opt = 1;
		}

		else if(this.props.user.discount.amount !== 0 && this.props.user.discount.name.length !== 0)
		{
			opt = 2;
		}

		else
		{
			opt = 0;
			
			this.props.user.discount = undefined;
		}

		this.props.page.setState({
			user: this.props.user,
		});

		setImmediate(() =>
		{
			if(this.in_ref.current)
			{
				this.in_ref.current.value = opt.toString();
			}
		});
		
	}

	render()
	{
		let user = this.props.user;
		let staff = this.state.session.user;

		if(!staff)
		{
			return <></>;
		}

		let disabled = (UserHasCapability(user.permission, UserCapability.STAFF));
		let e_current: JSX.Element = <></>;
		let default_opt = 0;
		let default_opt_expiry = "";

		if(user.discount)
		{
			if(user.discount.name === "Low Income" && user.discount.amount === 0.075)
			{
				default_opt = 1;
			}

			else
			{
				default_opt = 2;

				e_current = (
					<option value="2">{user.discount.name} ({user.discount.amount * 100}%)</option>
				);
			}

			if(user.discount.expiry)
			{
				let expiry = new Date(user.discount.expiry);
				expiry.setMinutes(expiry.getMinutes() - expiry.getTimezoneOffset());
				default_opt_expiry = expiry.toJSON().substring(0, 10);
			}
		}

		return (
			<>
				<Col className="spacer">
					<h5>User Discount</h5>
					<FormGroup>
						<Input
							type="select"
							innerRef={this.in_ref}
							disabled={disabled}
							onChange={(e) => {
								this.setState({
									selected: parseInt(e.target.value),
								});
							}}
							{...{[disabled ? "value" : "defaultValue"]: default_opt}}
						>
							<option value="0">None (0%)</option>
							<option value="1">Low Income (7.5%)</option>
							{e_current}
							<option value="3">Custom</option>
						</Input>
					</FormGroup>
					{(this.state.selected ?? default_opt) !== 0 ? <FormGroup>
						<Input
							type="date"
							innerRef={this.expiry_ref}
							disabled={disabled}
							defaultValue={default_opt_expiry}
							value={disabled ? default_opt_expiry : undefined}
						/>
						<FormText color="danger">{this.state.form_error}</FormText>
					</FormGroup> : <></>}
					<FormGroup>
						<Button disabled={disabled} onClick={() => this.click()}>Update</Button>
					</FormGroup>
				</Col>
			</>
		);
	}
}
