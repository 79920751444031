import * as CallType from "../CallType";
import {GetType, PostType} from "../Api";

export interface Type {
	name: string;
	templates: string[];
	components: string[];
};

export interface Set extends PostType<"template not found"> {
	Body: {
		name: string;
		templates: string[];
	};
};

export interface GetAll extends GetType {
	Res: {
		groups: Type[];
	}
}

export const Call = {
	set: CallType.Post<Set>("/staff/group/set"),
	getAll: CallType.Get<GetAll>("/staff/group/getAll"),
};

