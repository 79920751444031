import {Button, Col, Container} from "reactstrap";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";
import {App} from "../../app/App";
import {FormType, ServerOpts} from ".";
import {convertToRaw} from "draft-js";
import {MakeImage} from "../../shared/helpers/DataHelpers";
import {useEffect} from "react";

function DemoWindow({product}: {product: Api.Product.ProductType})
{
	function close() {
		App.open(null);
	}
	
	return (
		<Container>
			<Col><Component.ProductInfo viewOnly={true} product={product} /></Col>
			<Col className="spacer"><Button onClick={close}>Close</Button></Col>
		</Container>
	);
}

export function Demo(p: {
	form: FormType;
	components: Map<string, Api.Staff.Component.Type>;
	template?: Api.Staff.Template.Type;
	server?: ServerOpts;
	onUpdate?: (p: Api.Product.ProductType) => any;
}) {

	function openDemo()
	{
		App.open(() => <DemoWindow product={product} />, false);
	}

	let image = p.form.image ?? (p.template ? MakeImage(p.template) : {file_name: "", file_id: ""});

	let product: Api.Product.ProductType = {
		name: p.form.name,
		brand: p.form.brand,
		category: p.form.category,
		description: convertToRaw(p.form.description.getCurrentContent()),
		visibility: p.form.visibility,
		template: p.form.template_name,
		gst_aud: p.form.gst_aud,
		vat_gbp: p.form.vat_gbp,
		image_id: image.file_id,
		image_name: image.file_name,
		can_purchase: false,
		components: [],
		options: [],
		id: "",
	}

	let base_size = parseFloat(p.form.base_size);

	if(p.template)
	{
		for(let size of p.template.sizings)
		{
			if(!p.form.sizes.includes(size.amount)) {
				continue;
			}

			let name = Helpers.String.FormatAmount(p.template.quantity_type, size.amount);
			let amount = size.base_cost ?? 0;
			let out_stock = false;
			
			for(let item of p.form.components)
			{
				let component = p.components.get(item.name);
				let amount_per_q = parseFloat(item.value) / base_size * size.amount;

				if(!component || isNaN(amount_per_q)) {
					continue;
				}

				out_stock ||= component.out_stock;
				amount += component.surcharge * amount_per_q;
			}

			product.options.push({name, amount, out_stock, id: "", weight: 0, sizing: size.amount});
		}

		for(let item of p.form.components)
		{
			let component = p.components.get(item.name);

			if(!component) {
				continue;
			}

			product.components!.push({
				quantity_type: component.quantity_type,
				name: component.name,
				amount_per_q: parseFloat(item.value) / base_size,
				out_stock: component.out_stock,
				id: component.id!,
			});
		}
	}

	if(product.options.length === 0) {
		product.options.push({name: "Unknown", amount: 0, out_stock: false, id: "", weight: 0});
	}
	
	const {form, components, template, server} = p;

	useEffect(() => {
		if(p.onUpdate) {
			p.onUpdate(product);
		}
	}, [form, components, template, server]);

	return <Component.Product product={product} onClick={openDemo} />;
}

