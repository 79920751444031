import {useEffect, useId, useState} from "react";
import {Api} from "../../../shared";
import {Button, Col, FormText} from "reactstrap";
import {Component} from "../../..";

function PickerCol(props: {
	name: string,
	update: (v: string) => any,
	options: Set<string>,
	showErrors: boolean,
	isValid: (v: string) => boolean,
	remove: () => any,
}) {

	const input_id = useId();
	let error = "";

	if(!props.showErrors) {
	}

	else if(props.name === "") {
		error = "Value Required";
	}

	else if(!props.isValid(props.name)) {
		error = "Invalid Value";
	}

	return (<>
		<div className="side-flex">
			<label htmlFor={input_id} className="visually-hidden">Template name</label>
			<Component.DataList
				value={props.name}
				onChange={props.update}
				options={props.options}
				innerProps={{placeholder: "Template name", id: input_id}}
			/>
			<Button onClick={props.remove}>X</Button>
		</div>
		<FormText color="danger">{error}</FormText>
	</>);
}

export function TemplatePicker(props: {
	value: string[],
	onChange: (v: (string | undefined)[]) => any,
	showErrors: boolean,
}) {
	const [templates, setTemplates] = useState<Set<string>>(() => new Set());
	const [curIndex, setCurIndex] = useState(props.value.length);
	const [values, setValues] = useState<Map<number, string>>(() =>
	{
		let map = new Map<number, string>();

		for(let i = 0; i < props.value.length; i++) {
			map.set(i, props.value[i]);
		}

		return map;
	});

	function sendIds(values: Map<number, string>)
	{
		let ids: (string | undefined)[] = [];

		for(let name of values.values()) {
			ids.push(templates.has(name) ? name : undefined);
		}

		props.onChange(ids);
	}

	function add() {
		values!.set(curIndex, "");
		setCurIndex(curIndex + 1);
		setValues(values);
		sendIds(values!);
	}

	function remove(index: number) {
		values!.delete(index);
		setValues(values);
		sendIds(values!);
	}

	function update(index: number, value: string) {
		values!.set(index, value);
		setValues(values);
		sendIds(values!);
	}

	useEffect(() => {(async () =>
	{
		let res = await Api.Practitioner.Component.Call.getTemplates({});
		let template_names = new Set<string>();

		if(!res.data) {
			console.error(res.error);
			return;
		}

		for(let item of res.data.templates) {
			template_names.add(item.name);
		}

		setTemplates(template_names);

	})()}, []);

	if(!values) {
		return <></>;
	}

	const namesUsed = new Set<string>();
	const namesFiltered = new Set<string>();
	const names = new Set<string>();

	for(let item of values.values()) {
		namesUsed.add(item);
	}

	for(let item of templates ?? []) {
		names.add(item);
		if(!namesUsed.has(item)) {
			namesFiltered.add(item);
		}
	}

	let items_e: JSX.Element[] = [];

	for(let [k, name] of values.entries()) {
		items_e.push(<PickerCol
			name={name}
			options={namesFiltered}
			update={(v) => update(k, v)}
			remove={() => remove(k)}
			showErrors={props.showErrors}
			isValid={(v) => names.has(v)}
			key={k}
		/>);
	}

	return (<>
		{items_e}
		<Col><Button onClick={add}>Add</Button></Col>
	</>);
}

