import {Practitioner} from ".";
import {SQLEnum} from "../SQLEnum";
import {GetType} from "./Api";
import * as CallType from "./CallType";

export interface ProductOptionType {
	id: string;
	name: string;
	out_stock: boolean;
	amount: number;
	weight: number;
	sizing?: number;
}

export interface ProductBaseType {
	id: string;
	name: string;
	template?: string | null;
	image_name: string;
	image_id: string;
	cart_max?: number | null;
	uses_max?: number | null;
	uses?: number | null;
	expiry?: number | null;
	brand: string;
	category: string;
	visibility: SQLEnum.ProductVisibility;
}

export interface ProductSearchedType extends ProductBaseType {
	search_terms?: string | null;
	amount: number;
}

export interface ProductType extends ProductBaseType {
	description: any;
	can_purchase: boolean;
	options: ProductOptionType[];
	components?: (Practitioner.Product.ComponentType & {
		id: string;
		group_name?: string | null;
		out_stock: boolean;
		quantity_type: SQLEnum.QuantityType;
	})[] | null;
	gst_aud: SQLEnum.TaxTypeGST;
	vat_gbp: SQLEnum.TaxTypeVAT;
	is_fav?: boolean;
}

export interface Search extends GetType
{
	Query: {
		q: string;
		after?: string;
		limit?: string;
		hidePersonal?: string;
	}
	Res: {
		products: ProductSearchedType[];
	}
}

export interface Categories extends GetType
{
	Res: {
		categories: string[];
	}
}

export interface Get extends GetType<"product not found">
{
	Query: {
		id: string;
	}
	Res: {
		product: ProductType;
	}
}

export const Call =
{
	search: CallType.Get<Search>("/product/search"),
	categories: CallType.Get<Categories>("/product/categories"),
	get: CallType.Get<Get>("/product/get"),
};
