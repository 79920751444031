
import {Page} from "..";
import {MakeRoute} from "./Routes";

export const GetRootRoute = () => MakeRoute(Page.Home, undefined, {
	admin: Page.AdminRoute(),
	cart: Page.CartRoute(),
	login: Page.LoginRoute(),
	order: Page.OrderRoute(),
	practitioner: Page.PractitionerRoute(),
	profile: Page.ProfileRoute(),
	register: Page.RegisterRoute(),
	staff: Page.StaffRoute(),
	store: Page.StoreRoute(),
});

