import { RefObject, useEffect, useRef } from "react";

export const TabTrap = (props: {children: any, autofocus?: boolean, hidden?: boolean}) =>
{
	const point_1 = useRef<HTMLDivElement>(null);
	const point_2 = useRef<HTMLDivElement>(null);

	const moveTo = (ref: RefObject<HTMLElement>) =>
	{
		ref.current?.focus();
	}

	useEffect(() => {
		if(props.autofocus)
		{
			moveTo(point_1);
		}
	}, []);

	return (
		<div hidden={props.hidden}>
			<div aria-hidden="true" tabIndex={0} onFocus={() => moveTo(point_1)} />
			<div aria-hidden="true" tabIndex={0} onFocus={() => moveTo(point_2)} />
			<div aria-hidden="true" tabIndex={-1} ref={point_1} className="visually-hidden" />
			{props.children}
			<div aria-hidden="true" tabIndex={-1} ref={point_2} className="visually-hidden" />
			<div aria-hidden="true" tabIndex={0} onFocus={() => moveTo(point_1)} />
			<div aria-hidden="true" tabIndex={0} onFocus={() => moveTo(point_2)} />
		</div>
	)
}
