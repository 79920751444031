import { PostType, GetType } from "./Api";
import * as CallType from "./CallType";

export type CheckType = "product not found" | "cart too large" | "product not prescribed" | "out of stock" | "bad cookie prefs" | "valid";

export interface Product extends PostType<"product not found">
{
	Body: {
		id: string;
		count: number;
	}
}

export interface ProductCartType
{
	name: string;
	count: number;
	amount: number;
	weight: number;
	product_id: string;
	option_id: string;
	image_name: string;
	image_id: string;
	cart_max?: number | null;
	uses?: number | null;
	uses_max?: number | null;
	is_custom: boolean;
}

export interface Get extends GetType
{
	Res: {
		cart: ProductCartType[];
	}
}

export interface Check extends GetType
{
	Query: {
		add: string;
		count: string;
		product: string;
	}
	Res: {
		code: CheckType;
	}
}

export const Call =
{
	changeProduct: CallType.Post<Product>("/cart/changeProduct"),
	addProduct: CallType.Post<Product>("/cart/addProduct"),
	get: CallType.Get<Get>("/cart/get"),
	check: CallType.Get<Check>("/cart/check"),
};
