import * as CallType from "../../CallType";
import {Api} from "../../..";
import {GetType, PostType} from "../../Api";

export interface Get extends GetType {
	Res: {
		products: Api.Product.ProductSearchedType[];
	}
};

export interface Add extends PostType<"bad product"> {
	Body: {
		id: string;
	}
}

export interface Remove extends PostType<"bad product"> {
	Body: {
		id: string;
	}
}

export const Call = {
	add: CallType.Post<Add>("/practitioner/product/favourites/add"),
	remove: CallType.Post<Remove>("/practitioner/product/favourites/remove"),
	get: CallType.Get<Get>("/practitioner/product/favourites/get"),
};

