import {createRef} from "react";
import {Button, Input} from "reactstrap";
import {GlobalVariables} from "../../../scripts/GlobalVariables";
import {App} from "../../App";
import {ReactComponent} from "../../../scripts/util";
import {Api, Helpers} from "../../../shared";
import {User} from ".";

interface IState
{
	session: Api.Auth.SessionType;
}

interface IProps
{
	page: User;
	user: Api.Staff.User.UserType;
}

export class Permissions extends ReactComponent<IProps, IState>
{
	in_ref = createRef<HTMLInputElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			session: GlobalVariables.Session.regSet(this, "session"),
		}
	}

	async click() {
		if(!this.in_ref.current) {
			return;
		}

		let val = this.in_ref.current.value;

		let res = await App.openDialogue({
			title: "Update User Permissions",
			prompt: true,
			content: () => (
				<p>
					Are you sure your want to update the permissions
					of {this.props.user.name} from {this.props.user.permission
					} to {val}?
				</p>
			),
		});

		if(res) {
			await Api.Staff.User.Call.perms({
				email: this.props.user.email,
				perm: val,
			});

			await this.props.page.update();
		}
	}

	render() {
		let user = this.props.user;
		let disabled = (user.permission_opts.length === 0);
		let input_opts: JSX.Element[] = [];
		let permission_opts = user.permission_opts;

		if(permission_opts.length === 0) {
			permission_opts = [user.permission];
		}

		for(let val of user.permission_opts) {
			input_opts.push(<option value={val} key={val}>{Helpers.String.CapitalizeFirst(val)}</option>);
		}

		return (
			<div className="spacer">
				<h5>Permission Level</h5>
				<div className="side-flex">
					<Input type="select" innerRef={this.in_ref} defaultValue={user.permission} disabled={disabled}>{input_opts}</Input>
					<Button disabled={disabled} onClick={() => this.click()}>Update</Button>
				</div>
			</div>
		);
	}
}
