import {Button, Col, Container, FormGroup, FormText} from "reactstrap";
import {App} from "../../App";
import {Api} from "../../../shared";
import {Delete} from "./Delete";
import {useState} from "react";
import {Form} from "./Form";
import {GlobalVariables} from "../../../scripts/GlobalVariables";
import {UserCapability, UserHasCapability} from "../../../shared/User";

export function Edit(props: {reload: () => any, template: Api.Staff.Template.Type})
{
	const [error, setError] = useState<string>();
	const [showError, setShowError] = useState(false);
	const [template, setTemplate] = useState<Api.Staff.Template.Type>(props.template);
	const [disabled, setDisabled] = useState(false);
	const [session, setSession] = useState(GlobalVariables.Session.value);

	GlobalVariables.Session.regUse(setSession);

	let can_delete = false;

	if(session.user && UserHasCapability(session.user.permission, UserCapability.ADMIN)) {
		can_delete = true;
	}

	async function doConfirm()
	{
		if(error) {
			setShowError(true);
			return;
		}

		setDisabled(true);

		let res = await Api.Staff.Template.Call.create({...template, name_old: props.template.name});

		if(res.error) {
			setError("Error: " + res.error);
			setShowError(true);
			setDisabled(false);
			return;
		}

		await props.reload();
		App.open(null);
	}

	function doDelete()
	{
		App.open(() => <Delete reload={props.reload} template={props.template} />, false);
	}

	return (
		<Container>
			<h4>Edit Template</h4>
			<Form showErrors={showError} template={template} onError={setError} onChange={setTemplate} />
			<FormText color="danger">{error && showError ? error : ``}</FormText>
			<FormGroup>
				<Button onClick={() => App.open(null)}>Back</Button>
				<Button onClick={doConfirm} disabled={disabled}>Confirm</Button>
				{can_delete ? <Button onClick={doDelete}>Delete</Button> : <></>}
			</FormGroup>
		</Container>
	);
}

