import React, { createRef, useId, useState } from "react";
import { Button, Col, Container, FormGroup, FormText, Input, Label } from "reactstrap";
import { FormHelperItem } from "../../scripts/formhelper";
import { GenID } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps
{
	form: FormHelperItem;
	type: "BRAND" | "CATEGORY";
}

interface IState
{
	attribs?: string[];
	attribs_value: string;
	showAddErrors: boolean;
}

export class AttribName extends React.Component<IProps, IState>
{
	input_ref = createRef<HTMLInputElement>();
	label_id = GenID();

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			showAddErrors: false,
			attribs_value: "",
		};
	}

	async componentDidMount()
	{
		let attribs = await Api.Staff.Product.Call.attribs({
			type: this.props.type,
		});

		if(!attribs.data)
		{
			throw attribs.error;
		}

		this.setState({
			attribs: attribs.data.attribs,
			attribs_value: this.props.form.value ?? "",
		});
	}

	get_title()
	{
		let title = "";

		if(this.props.type === "BRAND")
		{
			title = "Brand Name";
		}

		else if(this.props.type === "CATEGORY")
		{
			title = "Category";
		}

		return title;
	}

	errors(v: string)
	{
		if(!v)
		{
			return "Required";
		}

		else
		{
			return "";
		}
	}

	render()
	{
		if(!this.state.attribs)
		{
			return <></>;
		}

		return (
			<Col>
				<Label htmlFor={this.label_id}>{this.get_title()}</Label>
				<FormGroup>
					<Component.DataList
						innerProps={{id: this.label_id}}
						value={this.props.form.value ?? ""}
						options={this.state.attribs ?? []}
						onChange={v => {
							this.props.form.value = v;
							this.setState({attribs_value: v});
						}}
					/>
					<FormText color="danger">
						{this.props.form.addErrorField()}
					</FormText>
				</FormGroup>

			</Col>
		)
	}
}
