import {Container} from "reactstrap";
import {Page} from "../../..";
import {Api} from "../../../shared";
import {useEffect, useState} from "react";
import {App} from "../../App";
import {Create} from "./Create";
import {Edit} from "./Edit";
import {Find} from "./Find";

export function Templates()
{
	const [templates, setTemplates] = useState<Api.Staff.Template.Type[]>();

	async function reload()
	{
		let res = await Api.Practitioner.Component.Call.getTemplates({});
		
		if(res.data) {
			res.data.templates.sort((a, b) => a.name.localeCompare(b.name));
			setTemplates(res.data.templates);
		}
	}

	useEffect(() => {reload()}, []);

	function create()
	{
		App.open(() => <Create reload={reload} />);
	}

	function edit(template: Api.Staff.Template.Type)
	{
		App.open(() => <Edit reload={reload} template={template} />);
	}

	return (
		<Page.Staff>
			<Container>
				<h4>All Templates</h4>
				<Find templates={templates} onClick={edit} onAdd={create} />
			</Container>
		</Page.Staff>
	);
}

