import React from "react";

interface IProps {
	height?: number | string;
	type?: "spacer" | "bottom";
}

export class Padding extends React.Component<IProps>
{
	render()
	{
		let height: number | string = "60px";
		if(this.props.height) {
			height = this.props.height;
		}

		let type = this.props.type;
		if(type) {
			if(type === "spacer") {
				height = "40px";
			}
			if(type === "bottom") {
				height = "60px";
			}
		}

		return <div style={{height: height}}></div>
	}
}
