
import {MakeRoute} from "../Routes"
import {Totals} from "./Totals"
import {UserSearch} from "./UserSearch"
import {AddProductPage} from "./add-product"
import {OutOfStock} from "./OutOfStock"
import {Orders} from "./Orders"
import {OrderLookup} from "./OrderLookup"
import {Attribs} from "./Attribs"
import {UserCapability} from "../../shared/User"
import {EditProduct} from "./EditProduct"
import {Component} from "../.."
import {User} from "./user"
import {Templates} from "./templates"
import {Components} from "./components"
import {Groups} from "./groups"
import {MyProducts} from "./MyProducts"

export function Staff(props: {children?: any, render_callback?: () => JSX.Element}){
	return (
		<div className="page-staff">
			<Component.SideBarWindow
				name="Staff Tools"
				render_callback={props.render_callback}
				items={[
					{to: "/staff/search", display: "User search"},
					{display: "Products", items: [
						{to: "/staff/my-products", display: "My Products"},
						{to: "/staff/add-product", display: "Add Product"},
						{to: "/staff/all-brands", display: "Brands"},
						{to: "/staff/all-categories", display: "Categories"},
						{to: "/staff/out-of-stock", display: "Out of Stock"},
						{to: "/staff/all-components", display: "Components"},
						{to: "/staff/all-templates", display: "Templates"},
						{to: "/staff/all-groups", display: "Groups"},
					]},
					{display: "Orders", items: [
						{to: "/staff/orders-paid", display: "New Orders"},
						{to: "/staff/orders-unpaid-bank", display: "Unpaid Bank"},
						{to: "/staff/orders-unpaid-phone", display: "Unpaid Phone"},
						{to: "/staff/orders-ship", display: "Ready to Ship"},
						{to: "/staff/orders-pickup", display: "Ready for Pickup"},
						{to: "/staff/orders-hidden", display: "Hidden Orders"},
						{to: "/staff/orders-old", display: "Old Orders"},
						{to: "/staff/order-lookup", display: "Order Lookup"},
					]},
					{to: "/staff/totals", display: "Webstore Totals"},
				]}
			>
				{props.children}
			</Component.SideBarWindow>
		</div>
	)
}

export const StaffRoute = () => MakeRoute(Staff, UserCapability.STAFF, {
	"search": MakeRoute(UserSearch),
	"user": MakeRoute(User),
	"add-product": MakeRoute(AddProductPage),
	"edit-product": MakeRoute(EditProduct),
	"out-of-stock": MakeRoute(OutOfStock),
	"orders-paid": MakeRoute(Orders("PAID")),
	"orders-unpaid-bank": MakeRoute(Orders("BANK")),
	"orders-unpaid-phone": MakeRoute(Orders("PHONE")),
	"orders-ship": MakeRoute(Orders("SHIP")),
	"orders-pickup": MakeRoute(Orders("PICKUP")),
	"orders-hidden": MakeRoute(Orders("HIDDEN")),
	"orders-old": MakeRoute(Orders("OLD")),
	"order-lookup": MakeRoute(OrderLookup),
	"all-brands": MakeRoute(Attribs("BRAND")),
	"all-categories": MakeRoute(Attribs("CATEGORY")),
	"all-components": MakeRoute(Components),
	"all-templates": MakeRoute(Templates),
	"all-groups": MakeRoute(Groups),
	"my-products": MakeRoute(MyProducts),
	"totals": MakeRoute(Totals),
});

