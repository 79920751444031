import React from "react";
import { Container } from "reactstrap";
import {Component} from "../..";

interface IProps {
	type?: string;
}

export class Error extends React.Component<IProps>
{
	render() {
		return (
			<div className="page-error">
				<Container>

					<h4>Error {this.props.type ? this.props.type : "404"}</h4>

				</Container>
				<Component.Padding type="bottom" />
			</div>
		)
	}
}
