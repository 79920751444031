
import {createRef} from "react";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {Container, Col, Button} from "reactstrap";
import {App} from "../App";
import {SessionReload} from "../AppHelpers";
import {AddressToString} from "../../shared/helpers/DataHelpers";
import {GenID, OnActive, ReactComponent} from "../../scripts/util";
import {MakeRoute} from "../Routes";
import {UserCapability} from "../../shared/User";
import {AddPractitioner} from "./AddPractitioner";
import {AddMFA} from "./AddMFA";
import {ChangeEmail} from "./ChangeEmail";
import {ChangeName} from "./ChangeName";
import {ChangePhone} from "./ChangePhone";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";
import {FavouriteProducts} from "./FavouriteProducts";

interface IState
{
	profile?: Api.Auth.Profile["Res"]["profile"];
	session: Api.Auth.SessionType;
}

export class Profile extends ReactComponent<{}, IState>
{
	constructor(props: {})
	{
		super(props);

		this.state = {
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	async onMount()
	{
		let profile = await Api.Auth.Call.profile({});

		if(!profile.data)
		{
			throw profile.error;
		}

		this.setState({
			profile: profile.data.profile,
		});
	}

	async logout(user: Api.Auth.UserType)
	{
		let email = user.email;
		let res = await Api.Auth.Call.logout({});
		await SessionReload();

		if(res.data && res.data.wasProxy) {
			App.routeToUrl("/staff/user?" + Helpers.Data.QueryString({
				email: email,
			}));
		} else {
			App.routeToUrl("/login");
		}
	}

	async change_name(user: Api.Auth.UserType)
	{
		App.open(() => <ChangeName user={user} />);
	}

	async change_email(user: Api.Auth.UserType)
	{
		App.open(() => <ChangeEmail user={user} />);
	}

	async change_phone(user: Api.Auth.UserType)
	{
		App.open(() => <ChangePhone user={user} />);
	}

	change_password()
	{
		let form_ref = createRef<Component.ChangePassword>();
		let ok_ref = createRef<HTMLButtonElement>();

		const click = async () =>
		{
			if(form_ref.current && ok_ref.current)
			{
				ok_ref.current.disabled = true;
				await form_ref.current.changePassword();
				ok_ref.current.disabled = false;
			}
		}

		const close = () =>
		{
			App.open(null);
		}

		const id1 = GenID();

		App.open(() => (
			<Container role="dialogue" aria-labelledby={id1}>
				<Col><h4 id={id1}>Change Password</h4></Col>
				<Component.ChangePassword ref={form_ref} callback={close} />
				<Col>
					<Button onClick={click} innerRef={ok_ref}>Ok</Button>
					<Button onClick={close}>Cancel</Button>
				</Col>
			</Container>
		));
	}

	async download_my_data()
	{
		if(!await App.openDialogue({
			title: "Download your data",
			prompt: true,
			content: () => (
				<p>
					Are you sure you want to download a copy of your data? 
					Ensure you store it safely, as it contains all data 
					that we have linked to you stored on our servers.
				</p>
			),
		}))
		{
			return;
		}

		let res = await Api.Auth.Call.exportData({});

		if(!res.data)
		{
			throw res.error;
		}

		let data = JSON.stringify(res.data.user, null, 2);
		let e = document.createElement("a");
		e.href = URL.createObjectURL(new Blob([data], {type: "application/json"}));
		e.download = `userdata-${new Date().toLocaleDateString()}-goulds-natural-medicine.json`;
		document.body.appendChild(e);
		e.click();
		setImmediate(() => {
			document.body.removeChild(e);
		});
	}

	async delete_my_account()
	{
		if(!await App.openDialogue({
			title: "Delete your account",
			prompt: true,
			content: () => (
				<p>
					Are you sure you want to delete your account? 
					We will give you 7 days in which you can change 
					your decision. After 7 days, all of the data we 
					have linked to this account (with the exception 
					of invoices and orders) will be permanently deleted 
					from our databases and cannot be recovered. 
				</p>
			),
		}))
		{
			return;
		}

		await Api.Auth.Call.deleteAccount({});
		await SessionReload();
	}

	async remove_mfa(user: Api.Auth.UserType)
	{
		if(!await App.openDialogue({
			title: "Remove MFA",
			prompt: true,
			content: () => (
				<Col>
					<p>
						Do you really want to remove multi-factor authentication
						from your account? 
					</p>
					<p>
						For security purposes it is recommended
						that you keep this. 
					</p>
				</Col>
			)
		}))
		{
			return;
		}

		await Api.Auth.Call.removeMFA({});
		await SessionReload();
	}

	render_cards(user: Api.Auth.UserType)
	{
		let elements: JSX.Element[] = [];
		let it = 0;

		let fav_txt = "";
		let fav_colour = "#006400";
		
		if(user.cards.length > 1)
		{
			fav_txt = "favourite";
		}

		for(const card of user.cards)
		{
			const click = async () =>
			{
				if(!await App.openDialogue({
					title: "Remove card",
					prompt: true,
					content: () => {
						return (
							<Col><p>Are you sure you want to remove XXXX-XXXX-XXXX-{card.last4}?</p></Col>
						);
					},
				})) {
					return;
				}

				await Api.Post.Call.removeCard({
					id: card.id
				});

				await SessionReload();
			}

			let fav_click: () => any = () => {};

			if(it > 0)
			{
				fav_click = async () =>
				{
					await Api.Post.Call.favCard({
						id: card.id
					});

					await SessionReload();
				}
			}

			elements.push(
				<Component.Card key={it++}>
					
					<button
						style={{
							borderStyle: "none",
							background: "none",
							margin: 0,
							padding: 0,
							marginLeft: "10px",
							float: "right",
							color: "#640000",
						}}
						onClick={click}
					>X</button>
					{fav_txt ? (
						<button
							style={{
								borderStyle: "none",
								background: "none",
								marginLeft: "10px",
								color: fav_colour,
								float: "right",
							}}
							onClick={fav_click}
						>{fav_txt}</button>
					) : <></>}
					{card.name} <br/>
					XXXX-XXXX-XXXX-{card.last4}
				</Component.Card>
			);

			fav_txt = "mark favourite";
			fav_colour = "#646400";
		}

		return elements;
	}

	render_addresses(user: Api.Auth.UserType)
	{
		let elements: JSX.Element[] = [];
		let it = 0;

		let fav_txt = "";
		let fav_colour = "#006400";
		
		if(user.addresses.length > 1)
		{
			fav_txt = "favourite";
		}

		for(const address of user.addresses)
		{
			const click = async () =>
			{
				if(!await App.openDialogue({
					title: "Remove address",
					prompt: true,
					content: () => {
						return (
							<Col><p>Are you sure you want to remove your address at {AddressToString(address)}?</p></Col>
						);
					},
				})) {
					return;
				}

				await Api.Post.Call.removeAddress({
					addressId: address.id
				});

				await SessionReload();
			}

			let fav_click: () => any = () => {};

			if(it > 0)
			{
				fav_click = async () =>
				{
					await Api.Post.Call.favAddress({
						addressId: address.id
					});

					await SessionReload();
				}
			}

			elements.push(
				<Component.Card key={it++}>
					
					<button
						style={{
							borderStyle: "none",
							background: "none",
							marginLeft: "10px",
							color: "#640000",
							float: "right",
						}}
						onClick={click}
					>X</button>
					{fav_txt ? (
						<button
							style={{
								borderStyle: "none",
								background: "none",
								marginLeft: "10px",
								color: fav_colour,
								float: "right",
							}}
							onClick={fav_click}
						>{fav_txt}</button>
					) : <></>}
					
					{address.name} <br/>
					{address.address1} <br/>
					{address.address2 ? (<>{address.address2} <br /></>) : <></>}
					{address.city}, {address.state}, {address.postcode} {address.country}
				</Component.Card>
			);

			fav_txt = "mark favourite";
			fav_colour = "#646400";
		}

		return elements;
	}

	render_orders(orders: Api.Staff.Order.OrderBasicType[])
	{
		let elements: JSX.Element[] = [];
		let it = 0;

		for(let order of orders)
		{
			elements.push(
				<Component.OrderCard setHidden={false} order={order} key={it++} />
			);
		}

		return elements;
	}

	render_products(products: Api.Product.ProductSearchedType[])
	{
		let elements: JSX.Element[] = [];
		let it = 0;

		for(let product of products)
		{
			const click = () =>
			{
				App.routeToUrl("/store/item?" + Helpers.Data.QueryString({id: product.id}));
			}

			elements.push(
				<Component.Product
					product={product}
					onClick={click}
					key={it++}
				/>
			);
		}

		return elements;
	}

	async remove_practitioner(user: Api.Practitioner.Client.PractitionerType)
	{
		let state = await App.openDialogue({
			title: "Remove Practitioner",
			content: () => (<>
				<Col><p>
					Are you sure you want to remove this practitioner?
				</p></Col>
				<Col><Component.UserCard user={user} /></Col>
			</>),
			prompt: true,
			type: "yesno",
		});

		if(!state)
		{
			return;
		}

		let res = await Api.Practitioner.Client.Call.removePractitioner({
			id: user.id,
		});

		if(res.data)
		{
			GlobalVariables.Session.value.user!.practitioners = res.data.practitioners;
			GlobalVariables.Session.update(GlobalVariables.Session.value);
		}
	}

	render_practitioners(practitioners: Api.Practitioner.Client.PractitionerType[])
	{
		let elements: JSX.Element[] = [];

		for(let user of practitioners)
		{
			elements.push(
				<Component.UserCard user={user} key={user.id}>
					<button className="right-x-button" onClick={() => this.remove_practitioner(user)}>X</button>
				</Component.UserCard>
			);
		}

		return elements;
	}

	render()
	{
		if(!this.state.session.user) {
			return <></>;
		}

		let user = this.state.session.user;
		let permission: string | null = this.state.session.user.permission;
		permission = (permission !== "CUSTOMER" ? Helpers.String.CapitalizeFirst(permission) : null);

		return (
			<div className="page-profile">

				<Container>

					<Col><h4>{user.name}'s Profile</h4></Col>

					<Col className="spacer">
						<h5>Name</h5>
						<p>{user.name}</p>
						<Button onClick={() => {this.change_name(user)}}>Edit</Button>
					</Col>

					<Col className="spacer">
						<h5>Email</h5>
						<p>{user.email}</p>
						<Button onClick={() => {this.change_email(user)}}>Edit</Button>
					</Col>

					<Col className="spacer">
						<h5>Phone Number</h5>
						<p>{user.phone}</p>
						<Button onClick={() => {this.change_phone(user)}}>Edit</Button>
					</Col>

					{permission ? (
						<Col className="spacer">
							<h5>Permission Level</h5>
							<p>{permission}</p>
						</Col>
					) : <></>}

					{user.discount ? (
						<Col>
							<h5>Active Discount</h5>
							<p>{user.discount.name} ({
								Helpers.String.RemoveLeading((user.discount.amount * 100).toFixed(6))
							}%)</p>
							{user.discount.expiry ? (
								<p>Expires: {new Date(user.discount.expiry).toLocaleString()}</p>
							) : <></>}
						</Col>
					) : <></>}

					{user.cards && user.cards.length > 0 ? (
						<Col className="spacer scroll-window">
							<h5>Saved Cards</h5>
							<Component.Skipper>
								{this.render_cards(user)}
							</Component.Skipper>
						</Col>
					) : <></>}

					{user.addresses && user.addresses.length > 0 ? (
						<Col className="spacer scroll-window">
							<h5>Saved Addresses</h5>
							<Component.Skipper>
								{this.render_addresses(user)}
							</Component.Skipper>
						</Col>
					) : <></>}

					<Col className="spacer">
						<h5>Privacy</h5>
						<Component.Skipper>
							<ul>
								<li><p><span className="link" {...OnActive(() => this.change_password())}>Change my password</span></p></li>
								<li><p><span className="link" {...OnActive(Component.Notice_GetCookiePreferences)}>Change my cookie preferences</span></p></li>
								<li><p><span className="link" {...OnActive(() => this.download_my_data())}>Download my data</span></p></li>
								<li><p><span className="link" {...OnActive(() => this.delete_my_account())}>Delete my account</span></p></li>
							</ul>
						</Component.Skipper>
					</Col>

					<Col className="spacer">
						<h5>Multi-factor Authentication</h5>
						{user.has_mfa ? (
							<>
								<p>Currently linked</p>
								<Button onClick={() => this.remove_mfa(user)}>Remove</Button>
							</>
						) : (
							<>
								<p>Not currently linked</p>
								<Button onClick={() => App.open(() => <AddMFA />)}>Add</Button>
							</>
						)}
					</Col>

					{this.state.profile && this.state.profile.orders.length > 0 ? (
						<Col className="spacer">
							<h5>My Orders</h5>
							<Component.Skipper>
								<div className="scroll-window">
									{this.render_orders(this.state.profile.orders)}
								</div>
							</Component.Skipper>
						</Col>
					) : <></>}

					{this.state.profile && this.state.profile.products.length > 0 ? (
						<Col className="spacer">
							<h5>My Practitioner Recommended Products</h5>
							<Component.Skipper>
								<div className="scroll-window">
									{this.render_products(this.state.profile.products)}
								</div>
							</Component.Skipper>
						</Col>
					) : <></>}

					{this.state.session.user && this.state.session.user.practitioners.length > 0 ? (
						<Col className="spacer">
							<h5>My Practitioners</h5>
							<Component.Skipper>
								<div className="scroll-window">
									{this.render_practitioners(this.state.session.user.practitioners)}
								</div>
							</Component.Skipper>
						</Col>
					) : <></>}

					<Col className="spacer">
						<Button onClick={() => this.logout(user)}>Logout</Button>
					</Col>

				</Container>
				<Component.Padding type="bottom" />
			</div>
		);
	}
}

export const ProfileRoute = () => MakeRoute(Profile, UserCapability.LOGGED_IN, {
	"add-practitioner": MakeRoute(AddPractitioner),
});

