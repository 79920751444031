import React from "react";
import { ReactComponent } from "./util";

type SetterType<T> = (v: T) => unknown;

export class GlobVar<T>
{
	value: T;
	
	private watching_setstate: {
		index: string;
		ref: WeakRef<ReactComponent>;
	}[] = [];

	private watching_usestate: WeakRef<SetterType<T>>[] = [];

	constructor(value: T)
	{
		this.value = value;
	}

	private filter()
	{
		this.watching_setstate = this.watching_setstate.filter((v) =>
		{
			let ref = v.ref.deref();

			return (ref && ref.mounted);
		});

		this.watching_usestate = this.watching_usestate.filter((v) => v.deref() != undefined);
	}

	regSet<T>(ref: ReactComponent<any, T>, key: keyof T)
	{
		ref.glob_queue.push([this, key as string]);

		return this.value;
	}

	_regSet<T>(ref: ReactComponent<any, T>, key: string)
	{
		for(let o of this.watching_setstate)
		{
			if(o.index === key && o.ref.deref() === ref)
			{
				return;
			}
		}

		this.filter();
		this.watching_setstate.push({
			index: key,
			ref: new WeakRef(ref),
		});
	}

	regUse(setVal: SetterType<T>)
	{
		for(let o of this.watching_usestate)
		{
			if(o.deref() === setVal)
			{
				return;
			}
		}

		this.filter();
		this.watching_usestate.push(new WeakRef(setVal));
	}

	use([value, setValue]: [T, SetterType<T>])
	{
		this.regUse(setValue);
		return value;
	}

	update(value: T)
	{
		this.value = value;
		this.filter();

		for(let o of this.watching_setstate)
		{
			const ref = o.ref.deref();

			if(ref && ref.mounted)
			{
				ref.setState({
					[o.index]: value,
				});
			}
		}

		for(let o of this.watching_usestate)
		{
			const setVal = o.deref();

			if(setVal)
			{
				setVal(value);
			}
		}

		console.log(`updated globvar on ${this.watching_setstate.length} + ${this.watching_usestate.length} hooks`);
	}
}
