
import React from "react";
import {Container, Col} from "reactstrap";
import {Chart} from "react-google-charts";
import {Api} from "../../shared";
import {Page} from "../..";

interface IProps {

}

interface IState {
	hits: {
		date: number;
		count: number;
	}[];
}

export class Hits extends React.Component<IProps, IState>
{
	alive = true;

	constructor(props: IProps) {
		super(props);

		this.state = {
			hits: [],
		};
	}

	componentDidMount() {
		this.update();
	}

	componentWillUnmount() {
		this.alive = false;
	}

	async update()
	{
		let res = await Api.Admin.Call.hits({});

		if(!res.data)
		{
			throw res.error;
		}

		this.setState({
			hits: res.data.hits,
		});
	}

	render() {

		let data: (string | number)[][] = [["Date", "Hits"]];
		let sample: number[] = [];
		let average = 0;

		for(let hit of this.state.hits)
		{
			average += hit.count;
			sample.unshift(hit.count);

			if(sample.length > 7)
			{
				average -= sample.pop() ?? 0;
				data.push([new Date(hit.date).toLocaleDateString(), average]);
			}
		}

		if(data.length === 1)
		{
			return <Page.Admin />;
		}

		return (
			<Page.Admin>
				<Container>

					<Col><h4>Page API Hits (per 7 days)</h4></Col>

					<Col><Chart
						chartType="LineChart"
						data={data}
					/></Col>

				</Container>
			</Page.Admin>
		);
	}
}
