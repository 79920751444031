import React, {createRef, useId, useState} from "react";
import {Button, Col, Container, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import {App} from "../../App";
import {Api, Helpers} from "../../../shared";
import {Component} from "../../..";
import {User} from ".";
import {ProductFinder} from "../../practitioner/ProductFinder";

interface IState
{
	render_allback?: () => JSX.Element;
}

interface IProps
{
	page: User;
	user: Api.Staff.User.UserType;
}

export const ProductPrescribe = ({callback, p, onEdit}: {
	callback: (expiry?: number, limit?: number) => void,
	p: Api.Product.ProductSearchedType
	onEdit?: () => void,
}) => {
	const [expiryDisabled, setExpiryDisabled] = useState(p.expiry == null);
	const [limitDisabled, setLimitDisabled] = useState(p.uses_max == null);
	const [expiryErrors, setExpiryErrors] = useState("");
	const [limitErrors, setLimitErrors] = useState("");
	const [expiry, setExpiry] = useState(p.expiry);
	const [limit, setLimit] = useState(p.uses_max);

	let has_errors = false;
	let items_txt = "";
		
	if(p.uses === 1)
	{
		items_txt = `User purchased 1 item`;
	}

	else
	{
		items_txt = `User purchased ${p.uses ?? 0} items`;
	}

	if(expiryErrors || limitErrors)
	{
		has_errors = true;
	}

	let expiry_def_str: string | undefined;

	if(p.expiry)
	{
		let d = new Date();
		let expiry = new Date(p.expiry - d.getTimezoneOffset() * 60000);
		expiry_def_str = expiry.toJSON().slice(0, 10);
	}

	const updateExpiry = (v: string) =>
	{
		let d = new Date();
		let expiry = Date.parse(v) + d.getTimezoneOffset() * 60000;

		if(expiry < Date.now())
		{
			setExpiryErrors("Value must be in the future");
		}

		else
		{
			setExpiryErrors("");
		}

		setExpiry(expiry);
	}

	const updateLimit = (v: string) =>
	{
		let uses_max = parseFloat(v);

		if(isNaN(uses_max))
		{
			setLimitErrors("Value must be a number");
		}

		else if(uses_max < 1)
		{
			setLimitErrors("Value must be positive");
		}

		else if(Math.floor(uses_max) !== uses_max)
		{
			setLimitErrors("Value must be a whole number");
		}

		else if(uses_max > Number.MAX_SAFE_INTEGER)
		{
			setLimitErrors("Value is too big");
		}

		else
		{
			setLimitErrors("");
		}

		setLimit(uses_max);
	}

	const click = () =>
	{
		callback(expiryDisabled ? undefined : (expiry ?? undefined), limitDisabled ? undefined : (limit ?? undefined));
	}

	const id1 = useId();
	const id2 = useId();
	const id3 = useId();
	const id4 = useId();
	const id5 = useId();

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<Row>
				<Col>
					<h4 id={id1}>Product Restrictions</h4>
				</Col>
				<Col>

				</Col>
			</Row>
			<Row>
				<Col>
					<FormGroup>
						<Label htmlFor={id2}>Product disable date</Label>
						<Input
							id={id2}
							type="date"
							defaultValue={expiryDisabled ? undefined : expiry_def_str}
							disabled={expiryDisabled}
							onChange={(v) => updateExpiry(v.target.value)} />
						<FormText color="danger">
							{expiryErrors}
						</FormText>
					</FormGroup>
					<FormGroup>
						<Input
							id={id3}
							type="checkbox"
							defaultChecked={!expiryDisabled}
							onChange={(v) => setExpiryDisabled(!v.target.checked)}
						/> <Label htmlFor={id3}>Enable product auto disable</Label>
					</FormGroup>
					<FormGroup>
						<Label htmlFor={id4}>Total item limit</Label>
						<Input
							id={id4}
							type="number"
							defaultValue={limitDisabled ? undefined : (p.uses_max ?? undefined)}
							disabled={limitDisabled}
							onChange={(v) => updateLimit(v.target.value)} />
						<FormText color="danger">
							{limitErrors}
						</FormText>
					</FormGroup>
					<FormGroup>
						<Input
							id={id5}
							type="checkbox"
							defaultChecked={!limitDisabled}
							onChange={(v) => setLimitDisabled(!v.target.checked)}
						/> <Label htmlFor={id5}>Enable limit for total purchases</Label>
					</FormGroup>
					<FormGroup>
						<FormText>
							{items_txt}
						</FormText>
					</FormGroup>
					<FormGroup>
						<Button onClick={() => click()} disabled={has_errors}>Submit</Button>
						<Button onClick={() => App.open(null)}>Cancel</Button>
					</FormGroup>
				</Col>
				<Col>
					<div style={{float: "right"}}>
						<Component.Product product={p} onClick={() => {
							window.open("/store/item?" + Helpers.Data.QueryString({id: p.id}), "_blank")?.focus();
						}} >
							{onEdit ? <Button style={{float: "right"}} onClick={(e) => {
								e.stopPropagation();
								onEdit();
							}}>Edit</Button> : <></>}
						</Component.Product>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

enum mode_t {
	TEMPLATE,
	CUSTOM,
	EXISTING,
};

export const ProductCreate = ({callback}: {callback: (p: Api.Product.ProductSearchedType, expiry?: number, limit?: number) => void}) =>
{
	const id1 = useId();
	const [mode, setMode] = useState(mode_t.TEMPLATE);
	const [demo_product, setDemoProduct] = useState<Api.Product.ProductType>();

	let form_e: JSX.Element;

	async function templateCallback(p: Api.Practitioner.Product.TemplateType) {
		let res = await Api.Practitioner.Product.Call.create({
			product: p
		});
		
		if(!res.data) {
			throw res.error;
		}

		let amount = Number.MAX_SAFE_INTEGER;
		
		for(let option of demo_product?.options ?? []) {
			if(option.amount < amount) {
				amount = option.amount;
			}
		}

		let p_searched: Api.Product.ProductSearchedType = {
			id: res.data.id,
			amount: amount,
			image_id: p.image_id,
			image_name: p.image_name,
			name: p.name,
			brand: p.brand,
			category: p.category,
			visibility: p.visibility,
		};

		function onEdit()
		{
			
		}
		
		App.open(() => <ProductPrescribe p={p_searched} callback={(expiry, searched) => callback(p_searched, expiry, searched)} onEdit={onEdit} />);
	}

	switch(mode)
	{
	case mode_t.TEMPLATE:
		form_e = (
			<Component.AddTemplateProduct showStaffOpts={true} showPublicOpts={false} onDemoUpdate={setDemoProduct} onSubmit={templateCallback} />
		);
		break;
	case mode_t.EXISTING:
		form_e = (
			<ProductFinder showStaffOpts={true} onDemoUpdate={setDemoProduct} callback={templateCallback} />
		);
		break;
	case mode_t.CUSTOM:
		form_e = (
			<Component.AddProduct isCustom={true} onSubmit={async p =>
			{
				let res = await Api.Staff.Product.Call.create({
					product: p
				});
		
				if(!res.data) {
					throw res.error;
				}
		
				let amount = Number.MAX_SAFE_INTEGER;
		
				for(let option of p.options) {
					if(option.amount < amount) {
						amount = option.amount;
					}
				}
		
				let p_searched: Api.Product.ProductSearchedType = {
					id: res.data.id,
					amount: amount,
					image_id: p.image_id,
					image_name: p.image_name,
					name: p.name,
					brand: p.brand,
					category: p.category,
					visibility: p.visibility,
				};
				
				App.open(() => <ProductPrescribe p={p_searched} callback={(expiry, searched) => callback(p_searched, expiry, searched)} />);

			}}/>
		);
		break;
	}

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<div style={{height: "38px", width: "100%"}}>
				<Button style={{float: "right"}} onClick={() => App.open(null)}>Close</Button>
			</div>
			<h4 id={id1}>Create Product</h4>
			<FormGroup>
				<Input type="select" defaultValue={mode} onChange={v => setMode(parseInt(v.target.value))}>
					<option value={mode_t.TEMPLATE}>From Template</option>
					<option value={mode_t.EXISTING}>From My Products</option>
					<option value={mode_t.CUSTOM}>Full Custom</option>
				</Input>
			</FormGroup>
			{form_e}
		</Container>
	);
}

export const ProductAdd = ({callback}: {callback: (p: Api.Product.ProductSearchedType, expiry?: number, limit?: number) => void}) =>
{
	const id1 = useId();

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<Col>
				<div style={{height: "38px", width: "100%"}}>
					<Button style={{float: "right"}} onClick={() => App.open(null)}>Close</Button>
				</div>
				<h4 id={id1}>Add Product</h4>
			</Col>
			<Col>
				<Component.Store
					hidePersonalProducts={true}
					onClick={(p) => App.open(() => <ProductPrescribe p={p} callback={(expiry, searched) => callback(p, expiry, searched)} />)}
				/>
			</Col>
		</Container>
	)
}

export class Products extends React.Component<IProps, IState>
{
	in_ref = createRef<HTMLInputElement>();

	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}

	async prescribe_callback(p: Api.Product.ProductSearchedType, expiry?: number, limit?: number)
	{
		let res = await Api.Practitioner.Product.Call.prescribe({
			email: this.props.user.email,
			product_id: p.id,
			expiry: expiry ?? undefined,
			uses_max: limit ?? undefined,
		});

		if(res.error)
		{
			throw res.error;
		}

		App.open(null);

		await this.props.page.update();
	}

	prescribe(p: Api.Product.ProductSearchedType)
	{
		App.open(() => <ProductPrescribe p={p} callback={(expiry, limit) => this.prescribe_callback(p, expiry, limit)} />);
	}

	async unprescribe(product: Api.Product.ProductSearchedType)
	{
		let res = await App.openDialogue({
			title: "Remove Practitioner Recommended Product",
			content: () => (
				<>
					<Col>
						<Component.Product product={product} />
					</Col>
					<Col>
						<p>Are you sure you want to unprescribe this product?</p>
					</Col>
				</>
			),
			prompt: true,
		});

		if(!res)
		{
			return;
		}

		await Api.Practitioner.Product.Call.prescribe({
			email: this.props.user.email,
			product_id: product.id,
			remove: true,
		});

		await this.props.page.update();
	}

	create()
	{
		App.open(() => <ProductCreate callback={(p, expiry, limit) => this.prescribe_callback(p, expiry, limit)} />);
	}

	add()
	{
		App.open(() => <ProductAdd callback={(p, expiry, limit) => this.prescribe_callback(p, expiry, limit)} />);
	}

	render()
	{
		let products = this.props.user.products;
		let elements_p: JSX.Element[] = [];
		let elements_c: JSX.Element[] = [];
		let it = 0;

		for(let product of products)
		{
			let e = (
				<Component.Product
					key={it++}
					product={product}
					onClick={() => this.prescribe(product)}
					onRemove={() => this.unprescribe(product)}
				/>
			);

			if(product.visibility === "PRIVATE")
			{
				elements_c.push(e);
			}

			else
			{
				elements_p.push(e);
			}
		}

		return (
			<>
				<Col className="spacer">
					<h5>Practitioner Recommended Products</h5>
					<Component.Skipper>
						<div className="scroll-window">
							{elements_p}
						</div>
					</Component.Skipper>
					<Button onClick={() => this.add()}>Add</Button>
				</Col>
				<Col className="spacer">
					<h5>Custom Products</h5>
					<Component.Skipper>
						<div className="scroll-window">
							{elements_c}
						</div>
					</Component.Skipper>
					<Button onClick={() => this.create()}>Add</Button>
				</Col>
			</>
		);
	}
}
