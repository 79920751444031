import {FormGroup, Input, Label} from "reactstrap";
import {Api, SQLEnum} from "../../../shared";
import {useEffect, useId, useState} from "react";
import {TemplatePicker} from "./TemplatePicker";
import {Component} from "../../..";

interface FormProps {
	component: Api.Staff.Component.Type;
	onChange: (v: Api.Staff.Component.Type) => any;
	onError: (v: string | undefined) => any;
	showErrors: boolean;
}

const SIZES = {"G": "g", "ML": "mL"};

export function Form(props: FormProps)
{
	const [name, setName] = useState(props.component.name);
	const [quantity_type, setQuantityType] = useState(props.component.quantity_type);
	const [surcharge, setSurcharge] = useState(props.component.surcharge >= 0 ? (props.component.surcharge / 100).toString() : "");
	const [weight, setWeight] = useState(props.component.weight >= 0 ? props.component.weight.toString() : "");
	const [out_stock, setOutStock] = useState(props.component.out_stock ? "1" : "");
	const [templates, setTemplates] = useState<(string | undefined)[]>(props.component.templates);
	const [group, setGroup] = useState(props.component.group ?? "");
	const [groups, setGroups] = useState(() => new Map<string, Api.Staff.Group.Type>());

	const name_id = useId();
	const quantity_type_id = useId();
	const surcharge_id = useId();
	const weight_id = useId();
	const out_stock_id = useId();
	const group_id = useId();

	useEffect(() => {(async () => {
		let res = await Api.Staff.Group.Call.getAll({});
		let groups = new Map<string, Api.Staff.Group.Type>();
		if(!res.data) throw res.error;
		for(let item of res.data.groups) {
			groups.set(item.name, item);
		}
		setGroups(groups);
	})()}, []);

	useEffect(() =>
	{
		if(name.length === 0) {
			props.onError("Name is required");
			return;
		}

		for(let item of templates) {
			if(item === undefined) {
				props.onError("Invalid template");
				return;
			}
		}

		let component: Api.Staff.Component.Type = {
			name: name,
			surcharge: parseFloat(surcharge ?? 0) * 100,
			weight: 1,
			out_stock: out_stock === "1",
			quantity_type: quantity_type,
			templates: templates as string[],
			group: group || undefined,
		}

		if(quantity_type === "ML") {
			component.weight = parseFloat(weight);
		}

		if(isNaN(component.surcharge) || component.surcharge < 0) {
			props.onError("Invalid surcharge");
			return;
		}

		if(isNaN(component.weight) || component.weight < 0) {
			props.onError("Invalid weight");
			return;
		}

		props.onChange(component);
		props.onError(undefined);
	
	}, [name, surcharge, weight, out_stock, quantity_type, templates, group]);

	let group_templates_e: JSX.Element[] = [];

	for(let item of groups?.get(group)?.templates ?? []) {
		group_templates_e.push(
			<li key={item}>{item}</li>
		);
	}

	return (<>
		<FormGroup>
			<Label htmlFor={name_id}>Name</Label>
			<Input id={name_id} defaultValue={name} onChange={(e) => setName(e.target.value)} />
		</FormGroup>
		<FormGroup>
			<Label htmlFor={group_id}>Group</Label>
			<Component.DataList options={[...groups?.keys()] ?? []} value={group} onChange={setGroup} innerProps={{id: group_id}} />
		</FormGroup>
		{group_templates_e.length > 0 ? 
			<FormGroup>
				<Label>Templates (from group)</Label>
				<ul>{group_templates_e}</ul>
			</FormGroup>
		: <></>}
		<FormGroup>
			<Label>Templates (by component)</Label>
			<TemplatePicker value={props.component.templates} onChange={setTemplates} showErrors={props.showErrors} />
		</FormGroup>
		<FormGroup>
			<Label htmlFor={quantity_type_id}>Quantity Type</Label>
			<Input id={quantity_type_id} type="select" defaultValue={quantity_type} onChange={(e) => setQuantityType(e.target.value as SQLEnum.QuantityType)}>
				<option value="G">Grams</option>
				<option value="ML">Milliliters</option>
			</Input>
		</FormGroup>
		<FormGroup>
			<Label htmlFor={surcharge_id}>Surcharge (AUD/{SIZES[quantity_type]})</Label>
			<Input id={surcharge_id} type="number" defaultValue={surcharge} onChange={(e) => setSurcharge(e.target.value)} />
		</FormGroup>
		{quantity_type === "ML" ? <>
			<FormGroup>
				<Label htmlFor={weight_id}>Weight (g/{SIZES[quantity_type]})</Label>
				<Input id={weight_id} type="number" defaultValue={weight} onChange={(e) => setWeight(e.target.value)} />
			</FormGroup>
		</> : <></>}
		<FormGroup>
			<Label htmlFor={out_stock_id}>Stock Status</Label>
			<Input id={out_stock_id} type="select" defaultValue={out_stock} onChange={(e) => setOutStock(e.target.value)}>
				<option value="">In Stock</option>
				<option value="1">Out of Stock</option>
			</Input>
		</FormGroup>
	</>);
}

