import {Product} from "..";
import {SQLEnum} from "../../SQLEnum";
import {GetType, PostType} from "../Api";
import * as CallType from "../CallType";

export interface Attribs extends GetType {
	Query: {
		type: SQLEnum.AttribType;
	}
	Res: {
		attribs: string[];
	}
}

export interface ItemOptType
{
	name: string;
	out_stock: boolean;
	amount: number;
	weight: number;
}

export interface ItemType
{
	name: string;
	description: any;
	category: string;
	brand: string;
	gst_aud: SQLEnum.TaxTypeGST;
	vat_gbp: SQLEnum.TaxTypeVAT;
	visibility: SQLEnum.ProductVisibility;
	cart_max?: number | null;
	image_id: string;
	image_name: string;
	options: ItemOptType[];
	is_fav?: boolean;
}

export interface Create extends PostType
{
	Body: {
		id?: string;
		product: ItemType;
	}
	Res: {
		id: string;
	}
}

export interface Stats extends GetType<"product not found">
{
	Query: {
		id: string
	}
	Res: {
		scripts: number;
	}
}

export interface Delete extends PostType
{
	Body: {
		mergeIntoId?: string;
		id: string;
	}
}

export interface RenameAttrib extends PostType
{
	Body: {
		old: string;
		new: string;
		type: string;
	}
}

export interface GetOutOfStock extends GetType
{
	Res: {
		products: Product.ProductSearchedType[];
	}
}

export const Call =
{
	attribs: CallType.Get<Attribs>("/staff/product/attribs"),
	create: CallType.Post<Create>("/staff/product/create"),
	delete: CallType.Post<Delete>("/staff/product/delete"),
	renameAttrib: CallType.Post<RenameAttrib>("/staff/product/renameAttrib"),
	getOutOfStock: CallType.Get<GetOutOfStock>("/staff/product/getOutOfStock"),
	stats: CallType.Get<Stats>("/staff/product/stats"),
};
