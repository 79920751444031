import { RawDraftContentState } from "draft-js";
import { PostType, GetType } from "./Api";
import * as CallType from "./CallType";

export interface Home extends GetType
{
	Res: {
		home?: RawDraftContentState;
	}
}

export const Call =
{
	home: CallType.Get<Home>("/info/home"),
};

