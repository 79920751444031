import {createRef} from "react";
import {createBrowserHistory} from "history";
import {GlobalVariables} from "../scripts/GlobalVariables";
import {Container, Col, Button} from "reactstrap";
import {SessionReload} from "./AppHelpers";
import {UserHasCapability} from "../shared/User";
import {GenID, ReactComponent} from "../scripts/util";
import {Route} from "./Routes";
import {Api, Helpers} from "../shared";
import {GetRootRoute} from "./RootRoute";
import {Component, Page} from "..";

export const AppHistory = createBrowserHistory();

export interface AppDialogue {
	title: string;
	prompt?: boolean;
	type?: "yesno" | "okcancel";
	content: () => JSX.Element;
	canContinue?: () => boolean;
}

export type AppElement = JSX.Element | JSX.Element[] | string;

interface IProps {

}

interface IState {
	loaded: boolean;
	session: Api.Auth.SessionType;
	render_callbacks: (() => JSX.Element)[];
	root_route: Route;
	path: string;
}

export class App extends ReactComponent<IProps, IState>
{
	static open: (renderer: (() => JSX.Element) | null, replace?: boolean) => void;
	static openDialogue: (dialogue: AppDialogue) => Promise<boolean>;
	static routeToUrl: (url: string, query?: Parameters<typeof Helpers.Data.QueryString>[0]) => void;

	dialogueCallback?: (v: boolean) => void;

	id1 = GenID();
	open_heap: (() => JSX.Element)[] = [];

	constructor(props: Readonly<IProps>) {
		super(props);

		this.state = {
			loaded: false,
			session: GlobalVariables.Session.regSet(this, "session"),
			root_route: GetRootRoute(),
			path: window.location.href,
			render_callbacks: [],
		};

		App.routeToUrl = (url, query) => {
			if(query) {
				AppHistory.push(url + "?" + Helpers.Data.QueryString(query));
			} else {
				AppHistory.push(url);
			}
			SessionReload();
		};

		App.open = (renderer, replace=true) =>
		{
			let render_callbacks = this.state.render_callbacks;

			if(renderer)
			{
				if(!replace || render_callbacks.length === 0)
				{
					render_callbacks.push(renderer);
				}

				else
				{
					render_callbacks[render_callbacks.length - 1] = renderer;
				}
			}

			else
			{
				render_callbacks.pop();
			}

			this.setState({
				render_callbacks: render_callbacks,
			});
		}

		App.openDialogue = (dialogue) =>
		{
			let id = GenID();

			App.open(() => (
				<Container role="alertdialogue" aria-labelledby={id}>
					<Col><h4 id={id}>{dialogue.title}</h4></Col>
					{dialogue.content()}
					<Col className="spacer">
						<Button onClick={() =>
						{
							if(!dialogue) {
								return;
							}

							if(dialogue.canContinue) {
								if(!dialogue.canContinue()) {
									return;
								}
							}

							App.open(null);
							
							if(this.dialogueCallback) {
								this.dialogueCallback(true);
							}
						}}>
							{(dialogue.prompt && dialogue.type === "yesno") ? "Yes" : "Ok"}
						</Button>
						{dialogue.prompt ? (
							<Button onClick={() =>
							{
								App.open(null);

								if(this.dialogueCallback) {
									this.dialogueCallback(false);
								}
							}}>
								{(dialogue.type === "yesno") ? "No" : "Cancel"}
							</Button>
						) : <></>}
					</Col>
				</Container>
			), false);

			return new Promise<boolean>((resolve) => {
				this.dialogueCallback = resolve;
			});
		}
	}

	async updateSession() {
		return new Promise<void>(async (resolve) =>
		{
			if(!this.mounted) {
				resolve();
				return;
			}

			try
			{
				await SessionReload();
				
				this.setState({
					loaded: true,
				});

				// Refresh in 1 hour
				setTimeout(() => this.updateSession(), 3600000);
				resolve();
			}

			catch(e)
			{
				console.error(e);

				// Try again in 2 seconds
				setTimeout(async () => {
					await this.updateSession();
					resolve();
				}, 2000);
			}
		})
	}
	
	async onMount()
	{
		AppHistory.listen(() =>
		{
			this.setState({
				path: window.location.href,
			});
		});

		await this.updateSession();
	}

	resendConfirmEmail() {
		Api.Auth.Call.resend({});
	}

	async logoutUser()
	{
		await Api.Auth.Call.logout({});
		await SessionReload();
	}

	to_div = createRef<HTMLDivElement>();

	clearFocus()
	{
		if(this.to_div.current) {
			this.to_div.current.focus();
		}
	}

	getRoute()
	{
		if(
			!this.state.session.confirmed &&
			!window.location.pathname.startsWith("/register/confirm") &&
			!window.location.pathname.startsWith("/login")
		) {
			return (
				<>
					<Container role="alertdialogue" aria-labelledby={this.id1}>
						<Col><h5 id={this.id1}>Account confirmation email sent</h5></Col>

						<Col><p>
							Please check your email. A link to confirm
							your account will be sent there.
						</p></Col>

						<Col>
							<Button
								onClick={() => this.resendConfirmEmail()}
							>Resend Email</Button>
							<Button
								onClick={() => this.logoutUser()}
							>Logout</Button>
						</Col>
					</Container>
					<Component.Padding type="bottom" />
				</>
			);
		}

		let path_str = window.location.pathname;
		let path = path_str.toLowerCase().split("/");
		let cur: Route | undefined = this.state.root_route;

		for(let item of path)
		{
			if(item.length === 0)
			{
				continue;
			}

			cur = cur.routes[item];

			if(!cur)
			{
				return <Page.Error type="404" />;
			}

			if(!UserHasCapability(this.state.session.user?.permission, cur.cap))
			{
				if(this.state.session.user)
				{
					return <Page.Error type="403" />
				}

				else
				{
					return (<>
						<Container><Component.Login /></Container>
						<Component.Padding type="bottom" />
					</>);
				}
			}
		}

		return cur.component ? <cur.component /> : <Page.Error type="403" />;

	}

	render()
	{
		if(!this.state.loaded) {
			return <></>;
		}

		let popup_elements: JSX.Element[] = [];

		for(let i = 0; i < this.state.render_callbacks.length; i++)
		{
			popup_elements.push(
				<Component.TabTrap hidden={i < this.state.render_callbacks.length - 1} autofocus={true} key={i}>
					<div className="confirm-box-bg" />
					<div className="confirm-box-fg">{this.state.render_callbacks[i]()}</div>
				</Component.TabTrap>
			);
		}

		return (
			<Component.TabTrap>
				{popup_elements}
				<div aria-hidden={this.state.render_callbacks.length > 0}>
					<Component.Navbar />
					<Component.NoticeList />
					<div id="top">
						{this.getRoute()}
					</div>
				</div>
			</Component.TabTrap>
		);
	}
}
