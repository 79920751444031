import {Button, Container} from "reactstrap";
import {Component} from "../..";
import {App} from "../App";
import {Api, Helpers} from "../../shared";
import {useEffect, useState} from "react";

export function EditProduct(props: {
	productId: string;
	onSubmit: () => any;
	showStaffOpts: boolean;
}) {

	const [product, setProduct] = useState<Api.Practitioner.Product.TemplateType>();

	useEffect(() => {(async () => {
		let res = await Api.Product.Call.get({id: props.productId});
		if(!res.data) {
			throw res.error;
		}
		setProduct(Helpers.Data.MakeProductTemplate(res.data.product));
	})()}, []);

	async function submit(product: Api.Practitioner.Product.TemplateType) {
		let res = await Api.Practitioner.Product.Call.create({product, id: props.productId});
		if(!res.data) {
			throw res.error;
		}
		await props.onSubmit();
	}

	if(!product) {
		return <></>;
	}

	return (
		<Container>
			<h4>Edit custom product</h4>
			<Component.AddTemplateProduct showPublicOpts={false} showStaffOpts={props.showStaffOpts} onSubmit={submit} product={product}>
				<Button onClick={() => App.open(null)}>Close</Button>
			</Component.AddTemplateProduct>
		</Container>
	);
}
