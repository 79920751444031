import {Button, Col, Container, FormGroup, FormText} from "reactstrap";
import {App} from "../../App";
import {useState} from "react";
import {Api} from "../../../shared";
import {Form} from "./Form";

export function Create(props: {reload: () => any})
{
	const [error, setError] = useState<string>();
	const [showError, setShowError] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [template, setTemplate] = useState<Api.Staff.Template.Type>({
		name: "",
		quantity_type: "G",
		sizings: [{amount: -1, base_cost: -1, weight: -1}],
		image_name: "",
		image_id: "",
	});

	async function onSubmit()
	{
		if(error) {
			setShowError(true);
			return;
		}

		setDisabled(true);

		let res = await Api.Staff.Template.Call.create(template);
		
		if(res.error) {
			setError("Error: " + res.error);
			setShowError(true);
			setDisabled(false);
			return;
		}

		await props.reload();
		App.open(null);
	}

	return (
		<Container>
			<Col><h4>Create Template</h4></Col>
			<Form showErrors={showError} template={template} onChange={setTemplate} onError={setError} />
			<Col><FormText color="danger">{error && showError ? error : ``}</FormText></Col>
			<Col><FormGroup>
				<Button onClick={() => App.open(null)}>Close</Button>
				<Button disabled={disabled} onClick={onSubmit}>Submit</Button>
			</FormGroup></Col>
		</Container>
	);
}
