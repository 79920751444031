import React, { useId } from "react";
import { Button, Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { FormHelperItem } from "../../scripts/formhelper";
import { GenID } from "../../scripts/util";

interface IProps
{
	form: FormHelperItem;
	type: "GST_AUD" | "VAT_GBP";
}

interface IState
{
	
}

export class TaxName extends React.Component<IProps, IState>
{
	label_id = GenID();

	render()
	{
		let title = "";
		let options: JSX.Element = <></>;

		if(this.props.type === "GST_AUD")
		{
			title = "GST";
			options = (
				<>
					<option value={"FULL"}>Standard</option>
					<option value={"NONE"}>None</option>
				</>
			);
		}

		else if(this.props.type === "VAT_GBP")
		{
			title = "VAT";
			options = (
				<>
					<option value={"FULL"}>Standard</option>
					<option value={"SEMI"}>Reduced</option>
					<option value={"NONE"}>None</option>
				</>
			);
		}

		return (
			<Col><FormGroup>

				<Label htmlFor={this.label_id}>{title}</Label>
				<Input id={this.label_id} type="select" {...this.props.form.addInput()}>
					{options}
				</Input>

			</FormGroup></Col>
		)
	}
}
