import {GetType, PostType} from "../Api";
import * as CallType from "../CallType";

export interface PractitionerType {
	name: string;
	id: string;
	is_self?: boolean;
}

export interface AddPractitioner extends PostType<"invalid token" | "not logged in">
{
	Body: {
		request_id: string,
	},
	Res: {
		practitioners: PractitionerType[],
	},
}

export interface RemovePractitioner extends PostType<"not logged in" | "invalid user">
{
	Body: {
		id: string,
	},
	Res: {
		practitioners: PractitionerType[],
	},
}

export interface GetPractitioners extends GetType<"not logged in">
{
	Res: {
		practitioners: PractitionerType[],
	},
}

export interface GetPractitionerInfo extends GetType<"invalid token">
{
	Query: {
		request_id: string,
	},
	Res: PractitionerType,
}

export const Call = 
{
	addPractitioner: CallType.Post<AddPractitioner>("/practitioner/client/addPractitioner"),
	removePractitioner: CallType.Post<RemovePractitioner>("/practitioner/client/removePractitioner"),
	getPractitioners: CallType.Get<GetPractitioners>("/practitioner/client/getPractitioners"),
	getPractitionerInfo: CallType.Get<GetPractitionerInfo>("/practitioner/client/getPractitionerInfo"),
};

