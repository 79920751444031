
import {ReactElement} from "react";

import "./style.scss";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {Component} from "../..";
import {SideList} from "./SideList";

export interface SideBarItem {
	display: string;
	to?: string;
	items?: SideBarItem[];
}

export function SideBarWindow(props: {
	render_callback?: () => JSX.Element;
	children: ReactElement;
	items: SideBarItem[];
	name: string;
}) {
	let render_callback_e = <></>;

	if(props.render_callback) {
		render_callback_e = (<>
			{props.render_callback()}
			<Component.Padding />
		</>);
	}

	let elements = [
		<div id="side-bar-content" className={"csbw-left" + (GlobalVariables.IsPhone ? " phone" : "")}>
			{props.children}
			<Component.Padding type="bottom" />
		</div>,
		<div className={"csbw-right" + (GlobalVariables.IsPhone ? " phone" : "")} >
			<div className={"component-side-bar" + (GlobalVariables.IsPhone ? " phone" : "")}>
				<h5>{props.name}</h5>
				<Component.Skipper>
					<SideList items={props.items} />
				</Component.Skipper>
				<Component.Padding />
				{render_callback_e}
			</div>
		</div>
	];

	if(GlobalVariables.IsPhone) {
		elements.reverse();
	}

	return (
		<div className="component-side-bar-window">
			{elements[0]}
			{elements[1]}
		</div>
	)
}

