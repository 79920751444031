import {Button, Container, Input} from "reactstrap";
import {Practitioner} from ".";
import {useEffect, useId, useState} from "react";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";
import {App} from "../App";
import {AddProduct} from "./AddProduct";
import {EditProduct} from "./EditProduct";

export function Products(props: {
	onClick?: (p: Api.Product.ProductSearchedType, reload: () => any) => void;
	onRemove?: (p: Api.Product.ProductSearchedType, reload: () => any) => void;
	onDemoUpdate?: (p: Api.Product.ProductType) => any;
	showStaffOpts: boolean;
	showPublicOpts?: boolean;
}) {
	const [filter, setFilter] = useState("");
	const [products, setProducts] = useState<Api.Product.ProductSearchedType[]>([]);
	const search_id = useId();
		
	async function reload() {
		let res = await Api.Practitioner.Product.Favourites.Call.get({});
		if(!res.data) throw res.error;
		setProducts(res.data.products);
	}

	async function closeAndReload() {
		await reload();
		App.open(null);
	}

	useEffect(() => {reload()}, []);

	function addProduct() {
		App.open(() => (
			<Container>
				<h4>Add custom product</h4>
				<AddProduct preset={{is_fav: true}} callback={closeAndReload} showStaffOpts={props.showStaffOpts} showPublicOpts={props.showPublicOpts} onDemoUpdate={props.onDemoUpdate} />
			</Container>
		), false);
	}

	let e_products: JSX.Element[] = [];

	for(let item of products) {
		if(Helpers.String.HasTerms([item.name, item.search_terms ?? ""].join(" "), filter)) {
			e_products.push(<Component.Product
				product={item}
				key={item.id}
				onClick={props.onClick?.bind(null, item, closeAndReload)}
				onRemove={props.onRemove?.bind(null, item, closeAndReload)}
			/>);
		}
	}

	return (<>
		<div className="side-flex">
			<label htmlFor={search_id} className="visually-hidden">Filter products</label> 
			<Input
				id={search_id}
				type="search"
				placeholder="Filter"
				onChange={(e) => setFilter(e.target.value)}
			/>
			<Button onClick={addProduct}>Add</Button>
		</div>
		{e_products}
	</>);
}

export namespace ProductsPageCallbacks {
	export function Click(showStaffOpts: boolean) {
		return (p: Api.Product.ProductSearchedType, reload: () => any) => {
			App.open(() => <EditProduct productId={p.id} onSubmit={reload} showStaffOpts={showStaffOpts} />);
		}
	}
	export async function Remove(p: Api.Product.ProductSearchedType, reload: () => any) {
		if(await App.openDialogue({
			title: "Delete custom product",
			content: () => (<>
				<div><p>Are you sure you want to delete this custom product? This action cannot be undone.</p></div>
				<div><Component.Product product={p} /></div>
			</>),
			type: "yesno",
			prompt: true,
		})) {
			await Api.Practitioner.Product.Favourites.Call.remove({id: p.id});
		}
		await reload();
	}
};

export function ProductsPage()
{
	return (
		<Practitioner>
			<Container>
				<h4>My Products</h4>
				<Products onClick={ProductsPageCallbacks.Click(false)} onRemove={ProductsPageCallbacks.Remove} showStaffOpts={false} />
			</Container>
		</Practitioner>
	);
}

