import {Button, Container} from "reactstrap";
import {Api} from "../../../shared";
import {App} from "../../App";

export function ConfirmUser(props: {
	update: () => any;
	user: Api.Staff.User.UserType;
}) {

	async function ok() {
		await Api.Staff.User.Call.confirm({email: props.user.email});
		await props.update();
		App.open(null);
	}

	function cancel() {
		App.open(null);
	}

	return (
		<Container>
			<h4>Confirm user {props.user.name}</h4>
			<p>Are you sure you want to confirm this user?</p>
			<p>
				If this person is unable to receive confirmation emails,
				they will also be unable to receive anything else.
			</p>
			<Button onClick={ok}>Ok</Button>
			<Button onClick={cancel}>Cancel</Button>
		</Container>
	);
}

