import {GetType, PostType} from "../Api";
import * as CallType from "../CallType";

export * as Group from "./Group";
export * as Component from "./Component";
export * as Template from "./Template";
export * as Order from "./Order";
export * as Product from "./Product";
export * as User from "./User";

export interface TotalsType {
	method: string;
	total: number;
	refund: number;
	gross: number;
	gst_aud: number;
	vat_gbp: number;
	total_gst_inc: number;
	total_gst_exc: number;
}

export interface UploadImage extends PostType<"no file to upload" | "invalid filename" | "unsupported filetype" | "file too big"> {
	Res: {
		image_id: string;
		image_name: string;
	}
}

export interface Totals extends GetType<"min higher than max"> {
	Query: {
		min: string;
		max: string;
	};
	Res: {
		totals: TotalsType[];
	};
}

export const Call = {
	totals: CallType.Get<Totals>("/staff/totals"),
};

