import {Container} from "reactstrap";
import {Staff} from ".";
import {Products, ProductsPageCallbacks} from "../practitioner/Products";

export function MyProducts()
{
	return (
		<Staff>
			<Container>
				<h4>My Products</h4>
				<Products onClick={ProductsPageCallbacks.Click(true)} onRemove={ProductsPageCallbacks.Remove} showStaffOpts={true} />
			</Container>
		</Staff>
	);
}

