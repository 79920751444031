import {useEffect, useId, useState} from "react";
import {Api, Helpers, SQLEnum} from "../../shared";
import {Button, FormGroup, FormText, Input, Label} from "reactstrap";
import {Component} from "../..";
import {App} from "../../app/App";
import {Sizes} from "./Sizes";
import {ComponentType, Components} from "./Components";
import {EditorState, convertFromRaw, convertToRaw} from "draft-js";
import {Demo} from "./Demo";
import {FormProc} from "./FormProc";
import {MakeImage} from "../../shared/helpers/DataHelpers";
import {PrescriptionsList} from "../add-product/ProductVisibility";

export type FormType = {
	name: string;
	template_name: string;
	brand: string;
	category: string;
	description: EditorState;
	base_size: string;
	components: ComponentType[];
	sizes: number[];
	image: Api.Files.ImageType | undefined;
	vat_gbp: SQLEnum.TaxTypeVAT;
	gst_aud: SQLEnum.TaxTypeGST;
	visibility: SQLEnum.ProductVisibility;
	cart_limit: string;
	cart_limit_enabled: boolean;
};

export type ErrorsType = Partial<{
	name: string;
	template_name: string;
	brand: string;
	category: string;
	description: string;
	components: string;
	base_size: string;
	sizes: string;
	image: string;
	cart_limit: string;
	main: string;
}>;

export type ServerOpts = {
	brands: string[],
	categories: string[],
	components: Api.Staff.Component.TypeID[],
	templates: Api.Staff.Template.Type[],
};

async function getAttribs(type: SQLEnum.AttribType, ua: boolean): Promise<string[]>
{
	if(!ua) {
		return [];
	}

	let res = await Api.Staff.Product.Call.attribs({type});

	if(!res.data) {
		throw res.error;
	}

	res.data.attribs.sort();
	return res.data.attribs;
}

async function getServerOpts(ua: boolean): Promise<ServerOpts | undefined>
{
	let brands = await getAttribs("BRAND", ua);
	let categories = await getAttribs("CATEGORY", ua);
	let templates = await Api.Practitioner.Component.Call.getTemplates({});
	let components = await Api.Practitioner.Component.Call.getAll({});

	if(!templates.data) {
		throw templates.error;
	}

	if(!components.data) {
		throw components.error;
	}

	templates.data.templates.sort((a, b) => a.name.localeCompare(b.name));
	components.data.components.sort((a, b) => a.name.localeCompare(b.name));

	return {
		brands,
		categories,
		templates: templates.data.templates,
		components: components.data.components,
	};
}

export function AddTemplateProduct(props: {
	product?: Partial<Api.Practitioner.Product.TemplateType & {id: string}>,
	onChange?: (v: Api.Practitioner.Product.TemplateType | undefined) => any,
	onSubmit?: (v: Api.Practitioner.Product.TemplateType) => any,
	onDemoUpdate?: (v: Api.Product.ProductType) => any,
	showStaffOpts: boolean,
	showPublicOpts: boolean,
	children?: any,
}) {

	const [show_errors, setShowErrors] = useState(false);
	const [product, setProduct] = useState<Api.Practitioner.Product.TemplateType>();

	const [product_base] = useState((): Api.Practitioner.Product.TemplateType => ({
		name: "",
		description: convertToRaw(EditorState.createEmpty().getCurrentContent()),
		template: "",
		visibility: "PRIVATE",
		image_id: "",
		image_name: "",
		gst_aud: "FULL",
		vat_gbp: "FULL",
		brand: "",
		category: "",
		components: [],
		sizes: [],
		...props.product,
	}));

	const ids = {
		name: useId(),
		template: useId(),
		brand: useId(),
		category: useId(),
		base_size: useId(),
		gst: useId(),
		vat: useId(),
		visibility: useId(),
		cart_limit: useId(),
		cart_limit_checkbox: useId(),
	};
	
	const [form, setForm] = useState((): FormType =>
	{
		let components: ComponentType[] = [];
		let base_size = product_base.sizes.length > 0 ? Math.max(...product_base.sizes) : 0;

		for(let item of product_base.components ?? []) {
			let v = item.amount_per_q * base_size;
			components.push({name: item.name, value: v > 0 ? v.toString() : ""});
		}

		let image: Api.Files.ImageType | undefined = MakeImage(product_base);

		if(!image.file_id || !image.file_name) {
			image = undefined;
		}

		return {
			name: product_base.name,
			template_name: product_base.template,
			brand: product_base.brand,
			category: product_base.category,
			description: EditorState.createWithContent(convertFromRaw(product_base.description)),
			base_size: base_size > 0 ? base_size.toString() : "",
			sizes: product_base.sizes,
			vat_gbp: product_base.vat_gbp,
			gst_aud: product_base.gst_aud,
			visibility: product_base.visibility,
			cart_limit: product_base.cart_max ? product_base.cart_max.toString() : "",
			cart_limit_enabled: !!product_base.cart_max,
			components,
			image,
		}
	});


	const [server, setServer] = useState<ServerOpts>();
	const [errors, setErrors] = useState<ErrorsType>({});

	const visible_errors = show_errors ? errors : {};
	const has_errors = Object.keys(errors).length > 0;
	const template = server?.templates.find((v) => v.name === form.template_name);
	const [components, setComponents] = useState(() => new Map<string, Api.Staff.Component.Type>());

	useEffect(() => {
		if(server) {
			for(let component of server.components) {
				if(component.templates.includes(form.template_name) || component.group_templates?.includes(form.template_name)) {
					components.set(component.name, component);
				}
			}
		}
		setComponents(components);
	}, [form, server]);

	function updateForm(v: Partial<FormType>) {
		setForm({...form, ...v});
	}

	function formatSizeValue(v: number) {
		switch(template?.quantity_type)
		{
		case "G":
			return `${v} g`;
		case "ML":
			return `${v} mL`;
		default:
			return `${v}`;
		}
	}

	function pickImage() {
		App.open(() => <Component.ImageSelector closeCallback={(image) => {
			if(image) {
				updateForm({image});
			}
			App.open(null);
		}} />, false);
	}

	function getComponentAmountName(name: string)
	{
		const component = components.get(name);

		switch(component?.quantity_type)
		{
		case "G":
			return "Amount (Grams)";
		case "ML":
			return "Volume (Milliliters)";
		default:
			return "Amount";
		}
	}

	async function submit()
	{
		if(!props.onSubmit || !product) {
			setShowErrors(true);
			return;
		}

		if(!await props.onSubmit(product)) {
			let o = await getServerOpts(props.showPublicOpts);
			if(o) {
				setServer(o);
			}
		}
	}
	
	function seePrescriptions()
	{
		App.open(() => <PrescriptionsList product_id={props.product!.id!} />);
	}

	const sizing_nums: number[] = [];
	const template_names: string[] = [];
	const component_names = [...components.keys()];
	let base_size_placeholder = "";
	

	switch(template?.quantity_type)
	{
	case "G":
		base_size_placeholder = "Amount (Grams)";
		break;
	case "ML":
		base_size_placeholder = "Volume (Milliliters)";
		break;
	}

	for(let item of template?.sizings ?? []) {
		sizing_nums.push(item.amount);
	}

	for(let item of server?.templates ?? []) {
		template_names.push(item.name);
	}

	useEffect(() =>
	{
		let {errors, template_product} = FormProc({form, server, showPublicOpts: props.showPublicOpts, product_base});

		setErrors(errors);
		setProduct(template_product);

		if(props.onChange) {
			props.onChange(template_product);
		}

	}, [form, server]);

	useEffect(() => {(async () =>
	{
		let server = await getServerOpts(props.showPublicOpts);

		if(server) {
			setServer(server);
		}

	})()}, []);
	
	console.log(form.base_size, sizing_nums);

	return (<>
		<FormGroup>
			<Label htmlFor={ids.name}>Product Name</Label>
			<Input id={ids.name} defaultValue={form.name} onChange={(e) => updateForm({name: e.target.value})} />
			<FormText color="danger">{visible_errors.name}</FormText>
		</FormGroup>
		<FormGroup>
			<Label htmlFor={ids.template}>Product Template</Label>
			<Component.DataList innerProps={{id: ids.template}} value={form.template_name} onChange={v => updateForm({template_name: v})} options={template_names} />
			<FormText color="danger">{visible_errors.template_name}</FormText>
		</FormGroup>
		{props.showPublicOpts ? <>
			<FormGroup>
				<Label htmlFor={ids.brand}>Brand Name</Label>
				<Component.DataList innerProps={{id: ids.brand}} value={form.brand} onChange={brand => updateForm({brand})} options={server?.brands ?? []} />
				<FormText color="danger">{visible_errors.brand}</FormText>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={ids.category}>Category</Label>
				<Component.DataList innerProps={{id: ids.category}} value={form.category} onChange={category => updateForm({category})} options={server?.categories ?? []} />
				<FormText color="danger">{visible_errors.category}</FormText>
			</FormGroup>
		</> : <></>}
		<FormGroup>
			<Label>Description</Label>
			<Component.TextEditorContainer editorState={form.description} onChange={description => updateForm({description})} />
			<FormText color="danger">{visible_errors.description}</FormText>
		</FormGroup>
		{sizing_nums.length > 0 ? <>
			<FormGroup>
				<Label htmlFor={ids.base_size}>Base Size</Label>
				<Input id={ids.base_size} type="select" defaultValue={form.base_size} onChange={e => updateForm({base_size: e.target.value})} placeholder={base_size_placeholder} >
					<option disabled value="">None</option>
					<Component.OptionList opts={sizing_nums} getDisplayName={formatSizeValue} />
				</Input>
				<FormText color="danger">{visible_errors.base_size}</FormText>
			</FormGroup>
			<FormGroup>
				<Label>Enabled Sizes</Label>
				<Sizes names={sizing_nums} checked={form.sizes} onChange={sizes => updateForm({sizes})} formatValue={formatSizeValue}  />
				<FormText color="danger">{visible_errors.sizes}</FormText>
			</FormGroup>
		</>: <></>}
		<FormGroup>
			<Label>Components</Label>
			<Components disabled={!template} items={form.components} options={component_names} onChange={components => updateForm({components})} getAmountName={getComponentAmountName} />
			<FormText color="danger">{visible_errors.components}</FormText>
		</FormGroup>
		{props.showStaffOpts ? <>
			<FormGroup>
				<Label htmlFor={ids.gst}>GST</Label>
				<Input id={ids.gst} type="select" defaultValue={form.gst_aud} onChange={e => updateForm({gst_aud: e.target.value as SQLEnum.TaxTypeGST})} >
					<Component.OptionList getDisplayName={Helpers.String.CapitalizeFirst} opts={SQLEnum.A.TaxTypeGST} />
				</Input>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={ids.vat}>VAT</Label>
				<Input id={ids.vat} type="select" defaultValue={form.vat_gbp} onChange={(e) => updateForm({vat_gbp: e.target.value as SQLEnum.TaxTypeVAT})} >
					<Component.OptionList getDisplayName={Helpers.String.CapitalizeFirst} opts={SQLEnum.A.TaxTypeVAT} />
				</Input>
			</FormGroup>
			<FormGroup>
				<Button onClick={pickImage}>Pick Image</Button>
				<FormText color="danger">{visible_errors.image}</FormText>
			</FormGroup>
		</> : <></>}
		<Demo form={form} components={components} template={template} server={server} onUpdate={props.onDemoUpdate} />
		{props.showPublicOpts ? <>
			<FormGroup>
				<Label htmlFor={ids.visibility}>Prescription Only</Label>
				<Input id={ids.visibility} type="select" defaultValue={form.visibility} onChange={(e) => updateForm({visibility: e.target.value as SQLEnum.ProductVisibility})} >
					<option value="INVISIBLE">Yes (Invisible)</option>
					<option value="VISIBLE">Yes (Visible)</option>
					<option value="PUBLIC">No</option>
				</Input>
			</FormGroup>
			{props.product?.id ? 
				<FormGroup>
					<Button onClick={seePrescriptions}>See Prescriptions</Button>
				</FormGroup>
			: <></>}
			<FormGroup>
				<Label htmlFor={ids.cart_limit}>Total cart limit</Label>
				<Input id={ids.cart_limit} type="number" onChange={(e) => updateForm({cart_limit: e.target.value})} defaultValue={form.cart_limit} disabled={!form.cart_limit_enabled} />
				<Input
					id={ids.cart_limit_checkbox}
					type="checkbox"
					onChange={(e) => updateForm({cart_limit_enabled: e.target.checked})}
					defaultChecked={form.cart_limit_enabled}
				/> <Label htmlFor={ids.cart_limit_checkbox}>Enable limit for items in cart</Label>
				<FormText color="danger">{visible_errors.cart_limit}</FormText>
			</FormGroup>
		</> : <></>}
		<div><FormText color="danger">{show_errors && has_errors ? "Errors exist" : ""}</FormText></div>
		<FormGroup>
			<Button onClick={submit}>Submit</Button>
			{props.children}
		</FormGroup>
	</>);
}

