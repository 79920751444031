
import React, { createRef, useId, useState } from "react";
import { Col, Input, Button, FormText, Container } from "reactstrap";
import { SQLEnum } from "../../shared/SQLEnum";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { UserHasCapability, UserCapability } from "../../shared/User";
import { App } from "../../app/App";
import { GenID, ReactComponent } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IState {
	products: Api.Product.ProductSearchedType[];
	grouping: SQLEnum.AttribType;
	session: Api.Auth.SessionType;
}

interface IProps {
	onClick?: (product: Api.Product.ProductSearchedType) => void;
	hidePersonalProducts?: boolean;
	onSearch?: (terms: string) => void;
	getDefaultSearch?: () => Promise<string> | string;
	shouldDisplay?: (product: Api.Product.ProductSearchedType) => boolean;
}

const RenameCategory = (props: {name: string, grouping: SQLEnum.AttribType, callback: (name: string) => void}) =>
{
	const [name, setName] = useState(props.name);
	const id1 = useId();
	const id2 = useId();

	const click = async () =>
	{
		let res = await Api.Staff.Product.Call.renameAttrib({
			old: props.name,
			new: name,
			type: props.grouping,
		});

		if(res.error)
		{
			throw res.error;
		}

		App.open(null);
		props.callback(name);
	}

	return (
		<Container aria-labelledby={id1} role="dialogue">
			<Col><h4 id={id1}>Rename Category</h4></Col>
			<Col>
				<label htmlFor={id2} className="visually-hidden">Category name</label>
				<Input id={id2} defaultValue={props.name} maxLength={100} onChange={(v) => setName(v.target.value)} />
			</Col>
			<Col className="spacer">
				<Button disabled={name.length === 0 || name === props.name} onClick={click}>Update</Button>
				<Button onClick={() => App.open(null)}>Cancel</Button>
			</Col>
		</Container>
	);
}

export class Store extends ReactComponent<IProps, IState>
{
	terms = "";
	terms_a = "";
	lock = false;

	id_search = GenID();
	id_skips = GenID();

	constructor(props: IProps) {
		super(props);

		this.state = {
			grouping: "CATEGORY",
			products: [],
			session: GlobalVariables.Session.value,
		};
	}

	async onMount()
	{
		GlobalVariables.Session.regSet(this, "session");

		if(this.props.getDefaultSearch)
		{
			this.terms = await this.props.getDefaultSearch();
		}

		this.search(this.terms);
	}

	async search(terms: string)
	{
		if(this.lock)
		{
			return;
		}

		this.lock = true;

		try
		{
			if(this.props.onSearch) {
				this.props.onSearch(terms);
			}
		}

		catch(e)
		{
			this.lock = false;
			throw e;
		}

		let response = await Api.Product.Call.search({
			q: terms,
			hidePersonal: this.props.hidePersonalProducts ? "true" : undefined,
		});

		this.lock = false;
		this.terms_a = this.terms;

		if(!response.data) {
			throw "no response";
		}

		let products = response.data.products;

		if(this.props.shouldDisplay) {
			products = products.filter(this.props.shouldDisplay);
		}

		this.setState({products});
	}

	async rename_category(name: string)
	{
		App.open(() => <RenameCategory name={name} grouping={this.state.grouping} callback={(name_new) =>
		{
			for(let item of this.state.products)
			{
				if(item.category === name)
				{
					item.category = name_new;
				}
			}

			this.setState({
				products: this.state.products,
			});
			
		}} />);
	}

	render()
	{
		let found = 0;
		let products: {
			[key: string]: JSX.Element[]
		} = {};

		let it = 0;

		for(let product of this.state.products)
		{
			if(!products[product.category])
			{
				products[product.category] = [];
			}

			products[product.category].push(
				<Component.Product
					key={it++}
					product={product}
					disabled={false}
					onClick={() => {
						if(this.props.onClick) {
							this.props.onClick(product);
						}
					}}
				/>
			);

			found += 1;
		}

		let products_l = Object.entries(products);
		let elements: JSX.Element[] = [];

		if(this.terms_a.length > 0)
		{
			products_l.sort((a, b) => {
				return b[1].length - a[1].length;
			});
		}

		it = 0;

		for(let category of products_l)
		{
			let e_button = <></>;
			let id = `${this.id_skips}_${it++}`;

			if(this.state.session.user && UserHasCapability(this.state.session.user.permission, UserCapability.STAFF))
			{
				e_button = (
					<Button onClick={() => this.rename_category(category[0])} >Rename</Button>
				);
			}

			elements.push(
				<Col className="spacer" key={it++}>
					<h5>{category[0]} {e_button}</h5>
					<Component.Skipper>{category[1]}</Component.Skipper>
				</Col>
			);
		}

		let search_txt = "";

		if(this.terms_a.length > 0)
		{
			if(found === 1) {
				search_txt = "Found 1 result";
			}

			else {
				search_txt = "Found " + found + " results";
			}
		}

		return (
			<div className="component-store">
				<div className="side-flex">
					<label className="visually-hidden" htmlFor={this.id_search}>Search our webstore</label>
					<Input
						type="search"
						placeholder="Search"
						defaultValue={this.terms}
						id={this.id_search}
						onKeyDown={(e) => {
							if(e.key === 'Enter') {
								this.search(this.terms);
							}
						}}
						onChange={(e) => {
							this.terms = e.target.value;
						}}
					/>
					<Button onClick={() => this.search(this.terms)}>Search</Button>
				</div>
				<FormText>{search_txt}</FormText>
				{elements}
			</div>
		);
	}
}
