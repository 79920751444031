import React, { createRef, useId } from "react";
import { Input, FormText, FormGroup, Label } from "reactstrap";
import "./style.scss";
import { Environment } from "../../environment";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { Stripe, PaymentMethodResult, StripeCardNumberElement, StripeCardCvcElement, StripeCardExpiryElement } from '@stripe/stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import { GenID, OnActive, ReactComponent } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

export interface StripePaymentParams {
	onPurchase: (stripe: Stripe, state: PaymentMethodResult, saveCard: boolean) => Promise<void>,
	onChange?: (name: string) => void;
}

export class StripePayment extends ReactComponent<StripePaymentParams, {session: Api.Auth.SessionType}>
{
	card1Element = createRef<HTMLDivElement>();
	card2Element = createRef<HTMLDivElement>();
	card3Element = createRef<HTMLDivElement>();
	saveCardElement = createRef<HTMLInputElement>();
	//card!: StripeCardElement;
	cardNumber!: StripeCardNumberElement;
	cardCcv!: StripeCardCvcElement;
	cardExpiry!: StripeCardExpiryElement;
	stripe!: Stripe;
	fullName = "";

	showErrors = false;

	constructor(props: StripePaymentParams)
	{
		super(props);

		this.state = {
			session: GlobalVariables.Session.regSet(this, "session"),
		}
	}

	hasFormErrors() {
		if(this.fullName === "") {
			return true;
		} else {
			return false;
		}
	}

	async load_stripe()
	{
		if(!this.state.session.cookies.includes("STRIPE"))
		{
			return;
		}

		let stripe = await loadStripe(Environment.stripe_public);

		if(!stripe)
		{
			return;
		}

		this.stripe = stripe;
		
		var elements = stripe.elements();

		let options = {
			style: {
				base: {
					color: "#495057",
					fontFamily: `
						system,
						BlinkMacSystemFont,
						"Segoe UI",
						Roboto,
						"Helvetica Neue",
						Arial,
						"Noto Sans",
						sans-serif,
						"Apple Color Emoji",
						"Segoe UI Emoji",
						"Segoe UI Symbol",
						"Noto Color Emoji"
					`,
					fontWeight: "400",
					fontSize: "1.25rem",
					"::placeholder": {
						color: "#6c757d",
					},
				}
			},
		}

		if(this.card1Element.current && this.card2Element.current && this.card3Element.current)
		{
			if(Environment.disable_purchase) {
				this.card1Element.current.innerHTML = "<p>Sorry, payments are disabled at this time.</p>";
			} else {
				this.cardNumber = elements.create("cardNumber", options);
				this.cardCcv = elements.create("cardCvc", options);
				this.cardExpiry = elements.create("cardExpiry", options);
				this.cardNumber.mount(this.card1Element.current);
				this.cardCcv.mount(this.card2Element.current);
				this.cardExpiry.mount(this.card3Element.current);
			}
		}
	}

	onMount()
	{
		this.load_stripe();
	}

	async purchase()
	{
		if(Environment.disable_purchase || !this.stripe) {
			return;
		}

		this.showErrors = true;
		this.forceUpdate();

		if(this.hasFormErrors()) {
			return;
		}

		let result = await this.stripe.createPaymentMethod({
			type: "card",
			card: this.cardNumber,
			billing_details: {
				name: this.fullName,
			},
		});

		if(result.error) {
			return;
		}

		if(!result.paymentMethod) {
			return;
		}

		await this.props.onPurchase(this.stripe, result, this.saveCardElement.current?.checked ?? false);

		this.forceUpdate();
	}

	aria1 = GenID();
	aria2 = GenID();

	render()
	{
		let cardsave_e: JSX.Element = <></>;

		if(this.state.session.user)
		{
			cardsave_e = (
				<div>
					<div style={{width: 20, display: "inline-block"}}></div>
					<Input id={this.aria2} type="checkbox" innerRef={this.saveCardElement} /> <Label
						htmlFor={this.aria2} style={{display: "inline-block"}}>Save my card</Label>
				</div>
			);
		}

		const fullname_change = (v: string) =>
		{
			this.fullName = v;
			this.forceUpdate();
			if(this.props.onChange) {
				this.props.onChange(this.fullName);
			}
		}

		return (
			<div className="component-stripe-payment">

				<FormGroup>
					<div className="firstlast-name">
						<label className="visually-hidden" htmlFor={this.aria1}>Your full name</label>
						<Input
							id={this.aria1}
							type="text"
							placeholder="Full Name"
							onChange={(e) => fullname_change(e.target.value)} />
					</div>
					<div className="stripeInput">
						<div ref={this.card1Element}>
							{!this.state.session.cookies.includes("STRIPE") ? (
								<FormText color="danger">
									Sorry, Stripe is disabled by your cookie preferences. You may change
									this <span className="link" {...OnActive(async () => {
										await Component.Notice_GetCookiePreferences();
										if(this.state.session.cookies.includes("STRIPE"))
										{
											this.load_stripe();
										}
									})}>here. </span>
								</FormText>
							) : <></>}
						</div>
					</div>
					<div className="stripeInput">
						<div ref={this.card2Element}></div>
					</div>
					<div className="stripeInput">
						<div ref={this.card3Element}></div>
					</div>
					{cardsave_e}
				</FormGroup>

			</div>
		);
	}
}
