import {Button, Container, FormGroup, Input} from "reactstrap";
import {Api} from "../../shared";
import {useId, useState} from "react";
import {AddProduct} from "./AddProduct";
import {App} from "../App";
import {ProductFinder} from "./ProductFinder";

enum mode_t {
	FROM_BLANK,
	FROM_EXISTING,
};

export function AddProductClient(props: {
	callback: (product: Api.Practitioner.Product.TemplateType, demo_product: Api.Product.ProductType, id: string) => any;
}) {
	const [mode, setMode] = useState(mode_t.FROM_BLANK);
	const mode_id = useId();
	
	let form_e: JSX.Element;

	switch(mode) {
		default:
			form_e = <></>;
			break;
		case mode_t.FROM_BLANK:
			form_e = <AddProduct callback={props.callback} showStaffOpts={false} />;
			break;
		case mode_t.FROM_EXISTING:
			form_e = <ProductFinder callback={props.callback} showStaffOpts={false} />;
			break;
	}

	return (
		<Container>
			<h4>Add custom product</h4>
			<FormGroup>
				<div className="side-flex">
					<label htmlFor={mode_id} className="visually-hidden">Mode</label>
					<Input type="select" id={mode_id} defaultValue={mode} onChange={e => setMode(parseInt(e.target.value))}>
						<option value={mode_t.FROM_BLANK}>From Template</option>
						<option value={mode_t.FROM_EXISTING}>From My Products</option>
					</Input>
					<Button onClick={App.open.bind(null, null, false)}>Close</Button>
				</div>
			</FormGroup>
			{form_e}
		</Container>
	);
}

