import React, { useEffect, useId, useState } from "react";
import { Button, Col, Container, FormGroup, FormText, Input, Label } from "reactstrap";
import { FormHelperItem } from "../../scripts/formhelper";
import { GenID } from "../../scripts/util";
import { App } from "../../app/App";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps
{
	form: FormHelperItem<string>;
	product_id: string | null;
}

export const PrescriptionsList = (props: {product_id: string}) =>
{
	const [users, setUsers] = useState<Api.Staff.User.UserBasicType[]>([]);
	const [visibleUsers, setVisibleUsers] = useState<Api.Staff.User.UserBasicType[]>([]);
	const [loading, setLoading] = useState(true);

	const Close = () => {
		App.open(null);
	}

	const Get = async () => {
		let users = await Api.Staff.User.Call.getScriptUsers({
			product_id: props.product_id
		});
		if(users.data) {
			setUsers(users.data.users);
			setVisibleUsers(users.data.users);
		}
		setLoading(false);
	}

	const UserClick = (user: Api.Staff.User.UserBasicType) => {
		let url = new URLSearchParams();
		url.set("email", user.email);
		window.open("/staff/user?" + url, "_blank");
	}

	const FilterCheck = (user: Api.Staff.User.UserBasicType, terms: string[]) => {
		for(let item of terms) {
			if(user.email.toLowerCase().includes(item) || user.name.toLowerCase().includes(item)) return true;
		}
		return false;
	}

	const Filter = (e: React.ChangeEvent<HTMLInputElement>) => {
		let terms = e.target.value.toLowerCase().split(" ");
		let users_filtered: Api.Staff.User.UserBasicType[] = [];
		for(let user of users) {
			if(!FilterCheck(user, terms)) continue;
			users_filtered.push(user);
		}
		setVisibleUsers(users_filtered);
	}

	useEffect(() => {
		Get();
	}, []);

	let user_elements: JSX.Element[] = [];

	for(let user of visibleUsers) {
		user_elements.push(<Component.UserCard user={user} key={user.email} onClick={() => UserClick(user)} />);
	}

	if(!loading && users.length === 0) {
		user_elements.push(<p key="empty notice">List is empty</p>);
	}

	return (
		<Container>
			<h4>All Prescriptions</h4>
			<Input placeholder="Filter" aria-label="Filter users" onChange={(e) => Filter(e)} />
			<div className="scroll-window"><Component.Skipper>{user_elements}</Component.Skipper></div>
			<div className="spacer"><Button onClick={Close}>Close</Button></div>
		</Container>
	);
}

export class ProductVisibility extends React.Component<IProps>
{
	errors(v: string)
	{
		return v ? "" : "Required";
	}

	label_id = GenID();

	seePrescriptions()
	{
		if(this.props.product_id)
		{
			let product_id = this.props.product_id;

			App.open(() => <PrescriptionsList product_id={product_id} />);
		}
	}

	render()
	{
		return (
			<>
				<FormGroup>
					<Label htmlFor={this.label_id}>Prescription Only</Label>
					<Input id={this.label_id} type="select" {...this.props.form.addInput((v) => this.errors(v))}>
						<option value="INVISIBLE">Yes (Invisible)</option>
						<option value="VISIBLE">Yes (Visible)</option>
						<option value="PUBLIC">No</option>
					</Input>
				</FormGroup>
				{this.props.product_id ?
					<FormGroup>
						<Button onClick={() => this.seePrescriptions()}>See Prescriptions</Button>
					</FormGroup>
				: <></>}
			</>
		)
	}
}
