import {Api} from "..";
import { SQLEnum } from "../SQLEnum";
import { PostType, GetType } from "./Api";
import * as CallType from "./CallType";
import {PractitionerType} from "./practitioner/Client";

export type CookiePrefs = "SET" | "SESSION" | "CART" | "STRIPE" | "GOOGLE" | "REMEMBER_OTP" | "ALL";

export interface Login extends PostType<"auth error" | "invalid otp"> {
	Body: {
		email: string;
		password: string;
		otp?: number;
		remember?: boolean;
	}
	Res: {
		missing?: "totp" | "email otp";
	}
}

export interface Salt extends GetType {
	Query: {
		email: string;
	}
	Res: {
		header: string;
	}
}

export interface Register extends PostType<"user exists"> {
	Body: {
		email: string;
		password: string;
		name: string;
		phone: string;
	}
}

export interface Confirm extends PostType<"user not found" | "already confirmed" | "invalid token"> {
	Body: {
		email: string;
		confirm: string;
	}
}

export interface Resend extends PostType<"not logged in" | "already confirmed"> {

}

export interface DiscountType {
	expiry?: number | null;
	name: string;
	amount: number;
}

export interface CardType {
	id: string;
	name: string;
	last4: number;
	stripe_id: string;
}

export interface AddressType {
	id: string;
	name: string;
	country: string;
	postcode: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
}

export interface OrderBasicType {
	name: string;
	email: string;
	created: number;
	ref: number;
	step: SQLEnum.OrderStep;
	total: number;
	id: string;
}

export interface UserBasicType {
	name: string;
	email: string;
}

export interface UserType {
	created: number;
	name: string;
	email: string;
	phone: string;
	discount?: DiscountType;
	permission: SQLEnum.UserPermission;
	confirmed: boolean;
	cards: CardType[];
	addresses: AddressType[];
	orders: OrderBasicType[];
	practitioners: PractitionerType[];
	has_mfa: boolean;
	deletion_date: number | null;
}

export interface SessionType {
	cart_count: number;
	confirmed: boolean;
	cookies: CookiePrefs[];
	user?: UserType;
	is_proxy?: boolean;
}

export interface Session extends GetType {
	Res: {
		session: SessionType;
	}
}

export interface Logout extends PostType {
	Res: {
		wasProxy: boolean;
	}
}

export interface Forgot extends PostType<"invalid recaptcha" | "user not found"> {
	Body: {
		email: string;
		recaptcha: string;
	}
}

export interface ChangePassword extends PostType<"invalid token" | "not logged in" | "auth error"> {
	Body: {
		confirmId?: string;
		passwordC?: string;
		passwordN: string;
	}
}

export interface RecoverEmail extends PostType<"invalid token"> {
	Body: {
		code: string;
	}
}

export interface AddMFA extends PostType<"not logged in"> {
	Body: {
		token: string;
	}
}

export interface SendMFA extends PostType<"auth error"> {
	Body: {
		email: string;
		password: string;
	}
}

export interface Profile extends GetType {
	Res: {
		profile: {
			orders: {
				id: string;
				name: string;
				email: string;
				created: number;
				ref: number;
				step: SQLEnum.OrderStep;
				total: number;
				ship: boolean;
			}[];
			products: Api.Product.ProductSearchedType[];
		}
	}
}

export interface ExportData extends GetType<"not logged in">
{
	Res: {
		user: any;
	}
}

export interface CookieSettings extends PostType
{
	Body: {
		prefs: CookiePrefs[];
	}
}

export type RemoveMFA = PostType<"not logged in">;
export interface DeleteAccount extends PostType<"not logged in"> {Body: {undo?: boolean}};
export interface ChangeName extends PostType<"not logged in"> {Body: {name: string}};
export interface ChangePhone extends PostType<"not logged in"> {Body: {phone: string}};
export interface ChangeEmail extends PostType<"not logged in" | "email in use"> {Body: {email: string}};
export interface ChangeDob extends PostType<"not logged in"> {Body: {dob: number}};

export const Call =
{
	login: CallType.Post<Login>("/auth/login"),
	salt: CallType.Get<Salt>("/auth/salt"),
	register: CallType.Post<Register>("/auth/register"),
	confirm: CallType.Post<Confirm>("/auth/confirm"),
	resend: CallType.Post<Resend>("/auth/resend"),
	session: CallType.Get<Session>("/auth/session"),
	logout: CallType.Post<Logout>("/auth/logout"),
	forgot: CallType.Post<Forgot>("/auth/forgot"),
	changePassword: CallType.Post<ChangePassword>("/auth/changePassword"),
	changePhone: CallType.Post<ChangePhone>("/auth/changePhone"),
	changeEmail: CallType.Post<ChangeEmail>("/auth/changeEmail"),
	changeName: CallType.Post<ChangeName>("/auth/changeName"),
	recoverEmail: CallType.Post<RecoverEmail>("/auth/recoverEmail"),
	removeMFA: CallType.Post<RemoveMFA>("/auth/removeMFA"),
	sendMFA: CallType.Post<SendMFA>("/auth/sendMFA"),
	addMFA: CallType.Post<AddMFA>("/auth/addMFA"),
	profile: CallType.Get<Profile>("/auth/profile"),
	cookieSettings: CallType.Post<CookieSettings>("/auth/cookieSettings"),
	exportData: CallType.Get<ExportData>("/auth/exportData"),
	deleteAccount: CallType.Post<DeleteAccount>("/auth/deleteAccount"),
};

