
import {Container, Col, FormGroup, Input} from "reactstrap";
import {App} from "../../App";
import {Page} from "../../..";
import {AddProduct} from "./AddProduct";

export function AddProductPage()
{
	return (
		<Page.Staff>
			<Container>
				<Col><h4>Add product</h4></Col>
				<AddProduct onSubmit={(id) => {
					App.routeToUrl("/store/item?id=" + id);
				}} />
			</Container>
		</Page.Staff>
	)
}
