import {Api} from ".";
import { SQLEnum } from "./SQLEnum";
import {TestShared} from "./Test";

export interface OrderField
{
	name: string;
	value: number;
	bold?: boolean;
	start?: string;
	end?: string;
	id?: string;
}

export const GetOrderFields = (order: Api.Post.OrderType) =>
{
	let fields: OrderField[] = [];

	for(let product of order.cart)
	{
		let p_name = product.name + (product.count > 1 ? ` x${product.count}` : ``);

		if(product.uses_max != null && (product.uses ?? 0) + product.count > product.uses_max)
		{
			p_name += " (cannot dispense)";
		}

		fields.push({
			name: p_name,
			value: product.amount,
			bold: false,
			id: product.item_id,
		});
	}

	fields.push({
		name: "Subtotal",
		value: order.subtotal,
		bold: true
	});

	fields.push({
		name: ((order.shipping && order.shipping.country !== "AU") || order.created < 1693579552778) ? "Shipping and handling" : "Shipping",
		value: order.shipping ? order.shipping.amount : 0,
		bold: true
	});

	switch(order.tax_type)
	{
		case "GST_AUD":
			fields.push({
				name: "GST",
				value: order.tax ? order.tax : 0,
				bold: true,
			});
			break;
		case "VAT_GBP":
			fields.push({
				name: "VAT",
				value: order.tax ? order.tax : 0,
				bold: true,
				start: "£",
				end: " GBP",
			});
			break;
	}

	if(order.discount && order.discount.amount && order.discount.name)
	{
		fields.push({
			name: "Discount",
			value: -Math.round(order.subtotal * order.discount.amount / (1 - order.discount.amount)),
			bold: true,
		});
	}

	fields.push({
		name: "Grand Total",
		value: order.total,
	});

	return fields;
}

export const GetOrderNotes = (order: Api.Post.OrderType) =>
{
	let notes: string[] = [];

	if((order.shipping && order.shipping.country === "GB") || order.tax_type === "VAT_GBP")
	{
		if(order.tax_type === "VAT_GBP") {
			notes.push("VAT has been added to this order, as VAT is applied to orders to the United Kingdom.")
		}

		else if(order.tax_type === "NONE") {
			notes.push("VAT has not been added to this order, as VAT is applied to orders under £135 GBP.");
		}
	}

	else if(order.tax_type === "NONE") {
		notes.push("GST has been removed from this order, as GST is not applied to orders delivered outside of Australia.");
	}

	if(order.discount && order.discount.name && order.discount.amount)
	{
		let first: string;

		if(order.discount.name.toLowerCase() === "staff discount")
		{
			first = "A Staff Discount";
		}

		else
		{
			first = "The discount " + order.discount.name;
		}

		notes.push(first + " with a value of " + (order.discount.amount * 100) + "% has been applied to this order.");
	}

	return notes;
}

export const IsOrderSameday = (shipping_method?: string) =>
{
	return (shipping_method !== "Express" && shipping_method !== "Standard");
}

TestShared(() => !IsOrderSameday("Express"));
TestShared(() => !IsOrderSameday("Standard"));
TestShared(() => IsOrderSameday("Idek"));

export const OrderStatusToString = (step: SQLEnum.OrderStep, shipping: any) =>
{
	switch(step)
	{
		case "CREATED":   return "Unpaid";
		case "PAID":	  return "Paid";
		case "CANCELLED": return "Cancelled";
		case "PROCESSED": return shipping ? "Processed" : "Ready for collection";
		case "SHIPPED":   return shipping ? "Shipped" : "Picked up";
		default:		  return "Unknown";
	}
}

TestShared(() => OrderStatusToString("PAID", null) == "Paid");
TestShared(() => OrderStatusToString("PROCESSED", null) == "Ready for collection");

