import React, { createRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './app/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';
import { workerInit } from './scripts/worker';
import { GlobalVariables } from './scripts/GlobalVariables';
import CacheBuster from "react-cache-buster";
import Meta from './shared/Meta';
import { EnvironmentInit } from './environment';

export * as Page from "./app";
export * as Component from "./component";

workerInit();

GlobalVariables.IsPhone = (window.innerWidth <= 900);

const init = async () =>
{
	const root = ReactDOM.createRoot(
		document.getElementById('root') as HTMLElement
	);

	await EnvironmentInit();
	
	root.render(
		<React.StrictMode>
			<CacheBuster
				isEnabled={true}
				currentVersion={Meta.version}
				isVerboseMode={true}
			>
				<App />
			</CacheBuster>
		</React.StrictMode>
	);
}

init();
