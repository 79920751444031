import {SQLEnum} from "./SQLEnum";
import {TestShared} from "./Test";

export enum UserCapability
{
	NONE       = 0x00,
	ALL        = 0xff,

	CUSTOMER   = 0x00,
	LOGGED_IN  = 0x01,
	STAFF      = 0x02,
	ADMIN      = 0x04,

	PRACTITIONER              = 0x08,
	PRACTITIONER_PRODUCT_API  = 0x10,
	PRODUCT_USE_HIDDEN        = 0x20,
};

const CAPABILITIES: {[key: string]: UserCapability} = {
	"STAFF":
		UserCapability.STAFF |
		UserCapability.PRACTITIONER_PRODUCT_API |
		UserCapability.PRODUCT_USE_HIDDEN,
	"ADMIN":
		UserCapability.ADMIN |
		UserCapability.STAFF |
		UserCapability.PRACTITIONER_PRODUCT_API |
		UserCapability.PRODUCT_USE_HIDDEN, 
	"PRACTITIONER":
		UserCapability.PRACTITIONER |
		UserCapability.PRACTITIONER_PRODUCT_API |
		UserCapability.PRODUCT_USE_HIDDEN,
	"CUSTOMER":
		UserCapability.CUSTOMER,
};

export const UserHasCapability = (user?: SQLEnum.UserPermission, cap?: UserCapability) =>
{
	if(!cap)
		return true;
	if(!user)
		return false;
	return ((CAPABILITIES[user] | UserCapability.LOGGED_IN) & cap) === cap;
}

TestShared(() => UserHasCapability("ADMIN", UserCapability.STAFF));
TestShared(() => !UserHasCapability("PRACTITIONER", UserCapability.STAFF));
TestShared(() => !UserHasCapability("PRACTITIONER", UserCapability.ADMIN));
TestShared(() => UserHasCapability("CUSTOMER", UserCapability.LOGGED_IN));
TestShared(() => !UserHasCapability(undefined, UserCapability.LOGGED_IN));
TestShared(() => UserHasCapability(undefined, UserCapability.CUSTOMER));
TestShared(() => !UserHasCapability(undefined, UserCapability.STAFF));
TestShared(() => !UserHasCapability("STAFF", UserCapability.STAFF | UserCapability.ADMIN | UserCapability.LOGGED_IN));
TestShared(() => UserHasCapability("STAFF", UserCapability.STAFF | UserCapability.LOGGED_IN));

