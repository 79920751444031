import React, { createRef, useId } from "react";
import { Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { FormHelperItem } from "../../scripts/formhelper";
import { GenID } from "../../scripts/util";

interface IProps
{
	form: FormHelperItem<[boolean, string]>;
}

export class ProductCartLimit extends React.Component<IProps>
{
	errors(v?: [boolean, string])
	{
		if(!v || !v[0])
		{
			return "";
		}

		let n = parseInt(v[1]);

		if(!v)
		{
			return "Required";
		}

		else if(isNaN(n))
		{
			return "Invalid Number";
		}

		else if(n < 1)
		{
			return "Cart limit too small";
		}

		else if(n > 9999)
		{
			return "Cart limit too large";
		}

		else
		{
			return "";
		}
	}

	componentDidMount()
	{
		this.props.form.errors = (v) => this.errors(v);
	}

	id1 = GenID();
	id2 = GenID();

	render()
	{
		let value = this.props.form.value;
		let i_ref = createRef<HTMLInputElement>();
		let c_ref = createRef<HTMLInputElement>();

		if(!value)
		{
			value = [false, ""];
		}

		this.props.form.onModify = (v) =>
		{
			if(c_ref.current && i_ref.current)
			{
				if(v)
				{
					c_ref.current.checked = v[0];
					i_ref.current.value = v[1];
				}

				else
				{
					c_ref.current.checked = false;
					i_ref.current.value = "";
				}
			}
		}

		return (
			<Col><FormGroup>

				<Label htmlFor={this.id1}>Total cart limit</Label>
				<Input
					id={this.id1}
					type="number"
					disabled={!value[0]}
					defaultValue={value[0] ? value[1] : ""}
					innerRef={i_ref}
					onChange={(e) =>
					{
						this.props.form.modify([true, e.target.value]);
					}}
				/>

				<div><FormText color="danger">
					{this.props.form.addErrorField()}
				</FormText></div>

				<Input
					id={this.id2}
					type="checkbox"
					defaultChecked={value[0]}
					innerRef={c_ref}
					onChange={(e) =>
				{
					this.props.form.modify([e.target.checked, ""]);

					if(i_ref.current)
					{
						i_ref.current.value = "";
					}

				}} /> <Label htmlFor={this.id2}>Enable limit for items in cart</Label>

			</FormGroup></Col>
		)
	}
}
