import {useId, useState} from "react";
import {Input, Label} from "reactstrap";

function SizeRow(props: {
	value: number,
	checked: boolean,
	onChange: (v: boolean) => any,
	formatValue: (v: number) => string,
}) {

	const id = useId();

	return (
		<li style={{listStyleType: "none"}}>
			<Input
				id={id}
				type="checkbox"
				defaultChecked={props.checked}
				onChange={(e) => props.onChange(e.target.checked)}
			/> <Label htmlFor={id}>{props.formatValue(props.value)}</Label>
		</li>
	);
}

export function Sizes(props: {
	names: number[],
	checked: number[],
	onChange: (v: number[]) => any,
	formatValue: (v: number) => string,
}) {
	const [checked] = useState(new Set(props.checked));

	function change(item: number, v: boolean)
	{
		if(v) {
			checked.add(item);
		} else {
			checked.delete(item);
		}

		props.onChange([...checked]);
	}

	let elements: JSX.Element[] = [];

	for(let item of props.names) {
		elements.push(<SizeRow
			key={item}
			value={item}
			checked={checked.has(item)}
			onChange={(v) => change(item, v)}
			formatValue={props.formatValue}
		/>);
	}

	return <ul>{elements}</ul>;
}

