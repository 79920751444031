
import {Container, Col} from "reactstrap";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {ReactComponent} from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

enum ErrorType {
	NONE,
	PRODUCT_NOT_FOUND,
	UNAUTHORISED,
}

interface IProps {

}

interface IState {
	loaded: boolean;
	error: ErrorType;
	product?: Api.Product.ProductType;
	session: Api.Auth.SessionType;
}

export class Item extends ReactComponent<IProps, IState>
{
	constructor(props: IProps) {
		super(props);

		this.state = {
			loaded: false,
			error: ErrorType.NONE,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	onMount() {
		this.update();
	}

	async update()
	{
		let url = new URL(window.location.href);
		let id = url.searchParams.get("id");

		if(!id) {
			this.setState({
				error: ErrorType.PRODUCT_NOT_FOUND,
				loaded: true,
			});
			return;
		}

		let product_res = await Api.Product.Call.get({
			id: id,
		});

		if(!product_res.data) {
			if(product_res.error === "product not found") {
				this.setState({
					loaded: true,
					error: ErrorType.PRODUCT_NOT_FOUND,
				});
				return;
			}
			if(product_res.error === "unauthorised") {
				this.setState({
					loaded: true,
					error: ErrorType.UNAUTHORISED,
				});
				return;
			}
			throw product_res.error;
		}

		this.setState({
			loaded: true,
			product: product_res.data.product,
			error: ErrorType.NONE,
		});
	}

	render()
	{
		if(!this.state.loaded) {
			return <></>;
		}

		if(this.state.error === ErrorType.PRODUCT_NOT_FOUND) {
			return (
				<Container>
					<Col><h4>Product not found</h4></Col>
					<Col><p>
						The product you entered doesn't exist.
						Please make sure to check the spelling
						of the URL.
					</p></Col>
					<Component.Padding type="bottom" />
				</Container>
			);
		}

		if(this.state.error === ErrorType.UNAUTHORISED) {

			if(!this.state.session.user) {
				return (
					<Container>
						<Component.Login loginCallback={() => {
							this.update();
						}} />
						<Component.Padding type="bottom" />
					</Container>
				);
			}

			else
			{
				return (
					<Container>
						<Col><h4>Product unauthorised</h4></Col>
						<Col><p>You are not allowed to view this item.</p></Col>
						<Component.Padding type="bottom" />
					</Container>
				);
			}
		}

		let product = this.state.product;

		if(!product) {
			return <></>;
		}

		return (
			<div className="page-store-item">

				<Container>

					<Component.ProductInfo product={product}/>

				</Container>

				<Component.Padding type="bottom" />

			</div>
		)
	}
}
