import {useEffect, useState} from "react";
import {Button, Col, Container, FormText} from "reactstrap";
import {App} from "../App";
import {Api} from "../../shared";
import {Component} from "../..";

export const AddPractitioner = () =>
{
	let url = new URL(window.location.href);
	let request_id = url.searchParams.get("id");

	const [info, setInfo] = useState<Api.Practitioner.Client.PractitionerType>();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [error, setError] = useState("");

	const getInfo = async () =>
	{
		if(!request_id || request_id.length !== 64) {
			setError("invalid parameters");
			return;
		}
		
		let res = await Api.Practitioner.Client.Call.getPractitionerInfo({request_id: request_id});

		if(res.error) {
			console.error(res.error);
			setError(res.error);
			return;
		}

		else if(!res.data) {
			setError("unknown");
			return;
		}
		
		setInfo(res.data);

		if(res.data.is_self) {
			setError("Info: You cannot add yourself as a client. The above information is for demonstration purposes.");
			setButtonDisabled(true);
			return;
		}
	}

	const confirm = async () =>
	{
		setButtonDisabled(true);

		let res = await Api.Practitioner.Client.Call.addPractitioner({request_id: request_id!});

		if(res.error)
		{
			setButtonDisabled(false);
			setError(`Error: failed to add your practitioner`);
		}

		App.routeToUrl("/profile");
	}

	useEffect(() => {getInfo()}, []);

	if(error && !info)
	{
		return (
			<Container>
				<Col><h4>Add Practitioner</h4></Col>
				<Col><p>Error: {error}</p></Col>
				<Col><p>
					Please check that the link from your practitioner is correct and try again.
				</p></Col>
			</Container>
		);
	}

	if(!info)
	{
		return (<></>);
	}

	return (
		<Container>
			<Col><h4>Add Practitioner</h4></Col>
			<Col><p>
				Please verify that this information is correct. If it is,
				go ahead and add them to your profile. 
			</p></Col>
			<Col>
				<Component.UserCard user={info} />
				<FormText>{error}</FormText>
			</Col>
			<Col className="spacer">
				<Button disabled={buttonDisabled} onClick={() => confirm()}>Confirm</Button>
			</Col>
		</Container>
	);
}

