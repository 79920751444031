
import React, { useId, useState } from "react";
import { Container, Col, Button, FormGroup } from "reactstrap";
import { RawDraftContentState } from "draft-js";
import { ProductName } from "./ProductName";
import { AttribName } from "./AttribName";
import { ProductOptions } from "./ProductOptions";
import { TaxName } from "./TaxName";
import { ImagePicker } from "./ImagePicker";
import { FormHelper } from "../../scripts/formhelper";
import { ProductDescription } from "./ProductDescription";
import { SQLEnum } from "../../shared/SQLEnum";
import { ProductDemo } from "./ProductDemo";
import { ProductVisibility } from "./ProductVisibility";
import { ProductCartLimit } from "./ProductCartLimit";
import { App } from "../../app/App";
import {Api} from "../../shared";
import {Product} from "../product";
import {Component} from "../..";

export type ItemType = Api.Staff.Product.ItemType;

interface IProps {
	productId?: string;
	isCustom?: boolean;
	isEdit?: boolean;
	onSubmit?: (p: ItemType) => void;
	getProductId?: () => string;
	preset?: ItemType;
	onDelete?: (mergeInto?: Api.Product.ProductSearchedType) => void;
	onClose?: () => void;
}

interface IState {
	ready: boolean;
}

export const ProductDelete = ({onDelete, scripts}: {onDelete?: (mergeInto?: Api.Product.ProductSearchedType) => void, scripts: number}) =>
{
	const [product, setProduct] = useState<Api.Product.ProductSearchedType>();
	const [disabled, setDisabled] = useState(false);
	const [merging, setMerging] = useState(false);
	const id1 = useId();

	const click = async () =>
	{
		App.open(null, false);
		
		if(onDelete)
		{
			onDelete(product);
		}
	}

	if(merging)
	{
		return (
			<Container role="alertdialogue" aria-labelledby={id1}>
				<Col><h4 id={id1}>Product Merge Scripts and Deletion</h4></Col>
				<Col><p>
					This will clear {scripts} {scripts === 1 ? "script" : "scripts"} for other users.
					Before you delete, find an alternative product to convert these scripts into. 
				</p></Col>
				<Col>
					{product ? (
						<Product
							product={product}
							onRemove={() => {
								setProduct(undefined);
								setDisabled(true);
							}}
						/>
					) : (
						<Component.Store
							hidePersonalProducts={true}
							onClick={(p) => {
								setProduct(p);
								setDisabled(false);
							}}
						/>
					)}
				</Col>
				<Col><FormGroup>
					<Button disabled={disabled} onClick={click}>Ok</Button>
					<Button onClick={() => App.open(null, false)}>Cancel</Button>
				</FormGroup></Col>
			</Container>
		);
	}
	
	else
	{
		return (
			<Container role="alertdialogue" aria-labelledby={id1}>
				<Col><h4 id={id1}>Product Deletion</h4></Col>
				<Col><p>
					Are you sure you want to delete this
					product? 
				</p></Col>
				{scripts != null ? (
					<Col><p>
						This will clear {scripts} {scripts === 1 ? "script" : "scripts"} for other users. You may merge these
						scripts with an alternative product <span className="link" onClick={() => setMerging(true)}>here.</span>
					</p></Col>
				) : <></>}
				<Col><FormGroup>
					<Button disabled={disabled} onClick={click}>Yes</Button>
					<Button onClick={() => App.open(null, false)}>No</Button>
				</FormGroup></Col>
			</Container>
		);
	}
}

export class AddProduct extends React.Component<IProps, IState>
{
	form = new FormHelper(() => this.forceUpdate());
	form_name = this.form.createRef();
	form_brand_name = this.form.createRef();
	form_category_name = this.form.createRef();
	form_options = this.form.createRef<Api.Staff.Product.ItemOptType[]>();
	form_gst_aud = this.form.createRef();
	form_vat_gbp = this.form.createRef();
	form_image = this.form.createRef<Api.Files.ImageType>();
	form_desc = this.form.createRef<RawDraftContentState>();
	form_cart_limit = this.form.createRef<[boolean, string]>();
	form_visibility = this.form.createRef();

	constructor(props: IProps) {
		super(props);

		this.state = {
			ready: false,
		};
	}

	isCustom()
	{
		return this.props.isCustom || this.form_visibility.value === "PRIVATE";
	}

	submit()
	{
		let r = this.form.check();

		if(!r) {
			return;
		}

		let product = this.get_product();

		if(this.props.onSubmit) {
			this.props.onSubmit(product);
		}
	}

	async delete()
	{
		let scripts = 0;

		if(this.props.productId)
		{
			let stats = await Api.Staff.Product.Call.stats({
				id: this.props.productId
			});

			if(stats.data)
			{
				scripts = stats.data.scripts;
			}
		}

		App.open(() => <ProductDelete onDelete={this.props.onDelete} scripts={scripts ?? 0} />, false);
	}

	async componentDidMount()
	{
		if(this.props.preset)
		{
			let preset = this.props.preset;
			
			this.form_name.modify(preset.name);
			this.form_brand_name.modify(preset.brand);
			this.form_category_name.modify(preset.category);
			this.form_desc.modify(preset.description);
			this.form_options.modify(preset.options);
			this.form_gst_aud.modify(preset.gst_aud);
			this.form_vat_gbp.modify(preset.vat_gbp);
			this.form_visibility.modify(preset.visibility);
			this.form_cart_limit.modify([!!preset.cart_max, (preset.cart_max ?? 0).toString()]);
			this.form_image.modify({file_id: preset.image_id, file_name: preset.image_name});
		}

		else
		{
			this.form_visibility.modify("PUBLIC");
			this.form_gst_aud.modify("FULL");
			this.form_vat_gbp.modify("FULL");
			this.form_options.modify([
				{
					amount: 0,
					name: "",
					out_stock: false,
					weight: 0,
				},
			]);
		}

		this.setState({
			ready: true,
		});
	}

	get_product()
	{
		let cart_max = this.form_cart_limit.value;
		let visibility = this.form_visibility.value as SQLEnum.ProductVisibility;

		if(!visibility || this.isCustom())
		{
			visibility = "PRIVATE";
		}

		let item: ItemType = {
			brand: this.form_brand_name.value ?? "",
			category: this.form_category_name.value ?? "",
			description: this.form_desc.value,
			gst_aud: (this.form_gst_aud.value as SQLEnum.TaxTypeGST) ?? "FULL",
			vat_gbp: (this.form_vat_gbp.value as SQLEnum.TaxTypeVAT) ?? "FULL",
			image_id: this.form_image.value?.file_id ?? "",
			image_name: this.form_image.value?.file_name ?? "",
			name: this.form_name.value ?? "",
			options: this.form_options.value ?? [],
			cart_max: ((cart_max && cart_max[0] && cart_max[1]) ? parseInt(cart_max[1]) : 0) ?? 0,
			visibility: visibility,
		};

		return item;
	}

	render()
	{
		if(!this.state.ready) {
			return <></>;
		}

		return (
			<div className="component-add-product">

				<ProductName form={this.form_name}/>

				{!this.isCustom() ? (
					<>
						<AttribName form={this.form_brand_name} type="BRAND"/>
						<AttribName form={this.form_category_name} type="CATEGORY"/>
					</>
				) : <></>}

				<ProductDescription form={this.form_desc} />
				<ProductOptions form={this.form_options} />
				<TaxName form={this.form_gst_aud} type="GST_AUD" />
				<TaxName form={this.form_vat_gbp} type="VAT_GBP" />
				<ImagePicker form={this.form_image} />
				<ProductDemo product={this.get_product()} />

				{!this.isCustom() ? (
					<>
						<ProductVisibility form={this.form_visibility} product_id={this.props.getProductId ? this.props.getProductId() : null} />
						<ProductCartLimit form={this.form_cart_limit} />
					</>
				) : <></>}

				<Col className="spacer">
					<Button onClick={() => this.submit()}>
						{this.props.isEdit ? "Update" : "Submit"}
					</Button>
					{this.props.onDelete ? (
						<Button onClick={() => this.delete()}>
							Delete
						</Button>
					) : <></>}
					{this.props.onClose ? (
						<Button onClick={() => {
							if(this.props.onClose) {
								this.props.onClose();
							}
						}}>Close</Button>
					) : <></>}
				</Col>

			</div>
		)
	}
}
