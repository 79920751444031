import { App } from "./App"
import { GlobalVariables } from "../scripts/GlobalVariables";
import {Api} from "../shared";

export const SessionReload = async () =>
{
	let res = await Api.Auth.Call.session({});

	if(res.data)
	{
		GlobalVariables.Session.update(res.data.session);
	}
}

export const AtBottomOfPage = (e: HTMLElement = document.body) =>
{
	return ((window.innerHeight + window.scrollY) >= e.offsetHeight);
}
