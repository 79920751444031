import {useId, useState} from "react";
import {Button, Col, Container} from "reactstrap";
import {OnActive} from "../../../scripts/util";
import {UserHasCapability, UserCapability} from "../../../shared/User";
import {App} from "../../App";
import {SessionReload} from "../../AppHelpers";
import {Api, Helpers} from "../../../shared";
import {Component} from "../../..";
import {User} from ".";

const MergeUsers = (props: {user: Api.Staff.User.UserType, update_callback: (user: Api.Staff.User.UserBasicType) => unknown}) =>
{
	const [user2, setUser2] = useState<Api.Staff.User.UserBasicType>();
	const [terms, setTerms] = useState("");
	const id1 = useId();

	const clicks_ok = async () =>
	{
		if(!user2)
		{
			return;
		}

		let res = await Api.Staff.User.Call.merge({
			from: props.user.email,
			to: user2.email,
		});

		if(res.error)
		{
			throw res.error;
		}

		App.routeToUrl("", {email: user2.email});
		App.open(null);

		props.update_callback(user2);
	}

	if(user2)
	{
		return (
			<Container role="alertdialogue" aria-labelledby={id1}>
				<Col><h4 id={id1}>Merge and Delete User</h4></Col>
				<Col>
					<p>
						Merge user <a target="_blank" href={"?"+Helpers.Data.QueryString({email: props.user.email})}>{props.user.email}</a> into
						user <a target="_blank" href={"?"+Helpers.Data.QueryString({email: user2.email})}>{user2.email}</a>?
					</p>
					<p>
						This will delete <a target="_blank" href={"?"+Helpers.Data.QueryString({email: props.user.email})}>{props.user.email}</a> in
						the process. Do you want to do this? 
					</p>
				</Col>
				<Col className="spacer">
					<Button onClick={clicks_ok}>Yes</Button>
					<Button onClick={() => setUser2(undefined)}>Back</Button>
					<Button onClick={() => App.open(null)}>Cancel</Button>
				</Col>
			</Container>
		);
	}

	const click = (user: Api.Staff.User.UserBasicType) =>
	{
		if(user.email !== props.user.email && !UserHasCapability(user.permission, UserCapability.STAFF))
		{
			setUser2(user);
		}
	}

	return (
		<Container>
			<Col><h4>Merge and Delete User</h4></Col>
			<Col><Component.UserSearch terms={terms} setTerms={setTerms} onClick={click} /></Col>
			<Col className="spacer">
				<Button onClick={() => App.open(null)}>Cancel</Button>
			</Col>
		</Container>
	);
}

export const RenderUserManagement = (ref: User, user: Api.Staff.User.UserType) =>
{
	if(UserHasCapability(user.permission, UserCapability.STAFF))
	{
		return <></>;
	}

	const login = async () =>
	{
		let res = await Api.Staff.User.Call.forceLogin({
			email: user.email,
		});

		if(res.error)
		{
			console.error(res.error);
		}

		else
		{
			await SessionReload();
			App.routeToUrl("/profile");
		}
	}

	const merge = async () =>
	{
		App.open(() => <MergeUsers user={user} update_callback={(u) => ref.update(u.email)} />);
	}

	return (
		<Col>
			<h5>User management</h5>
			<ul>
				<li><p><span className="link" {...OnActive(login)}>Log in as this user</span></p></li>
				<li><p><span className="link" {...OnActive(merge)}>Merge and delete</span></p></li>
			</ul>
		</Col>
	)
}
