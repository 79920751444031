import {Button, Col, FormText, Input, Label} from "reactstrap";
import {Api, SQLEnum} from "../../../shared";
import {useId, useState} from "react";
import {Component} from "../../..";

interface ColType {
	base_cost: string;
	amount: string;
	weight: string;
};

const SIZES = {"G": "Grams", "ML": "Milliliters"};

function PickerCol(props: {
	quantityType: SQLEnum.QuantityType, 
	showErrors: boolean,
	value: ColType, 
	update: (v: Partial<ColType>) => any,
	remove: () => any,
}) {

	const amount_id = useId();
	const base_cost_id = useId();
	const weight_id = useId();

	let base_cost_v = parseFloat(props.value.base_cost ?? 0) / 100;
	let amount_v = parseFloat(props.value.amount ?? 0);
	let weight_v = parseFloat(props.value.weight ?? 1);
	let error = "";

	if(!props.showErrors) {
	}

	else if(isNaN(base_cost_v) || isNaN(amount_v) || isNaN(weight_v) || base_cost_v < 0 || amount_v <= 0 || weight_v < 0) {
		error = "Invalid Value";
	}

	return (<>
		<tr>
			<td>
				<label htmlFor={amount_id} className="visually-hidden">Quantity Type in {SIZES[props.quantityType]}</label>
				<Input
					id={amount_id}
					type="number"
					defaultValue={props.value.amount}
					onChange={(e) => props.update({amount: e.target.value})}
				/>
			</td>
			<td>
				<label htmlFor={base_cost_id} className="visually-hidden">Base Price in AUD</label>
				<Input
					id={base_cost_id}
					type="number"
					defaultValue={props.value.base_cost}
					onChange={(e) => props.update({base_cost: e.target.value})}
				/>
			</td>
			<td>
				<label htmlFor={weight_id} className="visually-hidden">Base Weight in Grams</label>
				<Input
					id={weight_id}
					type="number"
					defaultValue={props.value.weight}
					onChange={(e) => props.update({weight: e.target.value})}
				/>
			</td>
			<td>
				<Button onClick={() => props.remove()}>X</Button>
			</td>
		</tr>
		{error ? <FormText color="danger">{error}</FormText> : <></>}
	</>);
}

export function SizingsPicker(props: {
	value: Api.Staff.Template.SizingType[],
	onChange: (v: Api.Staff.Template.SizingType[]) => any,
	quantityType: SQLEnum.QuantityType,
	showErrors: boolean,
}) {

	const [lookup, setLookup] = useState(() =>
	{
		let map = new Map<number, ColType>();

		for(let i = 0; i < props.value.length; i++)
		{
			let item = props.value[i];

			map.set(i, {
				amount: item.amount >= 0 ? item.amount.toString() : "",
				base_cost: item.base_cost >= 0 ? (item.base_cost / 100).toString() : "",
				weight: item.weight >= 0 ? item.weight.toString() : "",
			});
		}

		return map;
	});

	const [indexAt, setIndexAt] = useState(props.value.length);

	function calcSizings(lookup: Map<number, ColType>)
	{
		let sizings: Api.Staff.Template.SizingType[] = [];

		for(let item of lookup.values()) {
			sizings.push({
				amount: parseFloat(item.amount ?? 0),
				base_cost: parseFloat(item.base_cost ?? 0) * 100,
				weight: parseFloat(item.weight ?? 1),
			});
		}

		props.onChange(sizings);
	}

	function remove(index: number)
	{
		if(lookup.size <= 1) {
			return;
		}

		lookup.delete(index);

		setLookup(lookup);
		calcSizings(lookup);
	}

	function update(index: number, v: Partial<ColType>)
	{
		let item = lookup.get(index)!;
		lookup.set(index, {...item, ...v});

		setLookup(lookup);
		calcSizings(lookup);
	}

	function add()
	{
		lookup.set(indexAt, {
			amount: "",
			base_cost: "",
			weight: "",
		});

		setLookup(lookup);
		setIndexAt(indexAt + 1);
		calcSizings(lookup);
	}

	let e_fields: JSX.Element[] = [];

	for(let [k, item] of lookup.entries())
	{
		e_fields.push(<PickerCol
			quantityType={props.quantityType}
			showErrors={props.showErrors}
			remove={() => remove(k)}
			update={(v) => update(k, v)}
			value={item}
			key={k}
		/>);
	}

	return (<>
		<Component.Skipper>
			<table>
				<thead><tr>
					<td><Label>Quantity ({SIZES[props.quantityType]})</Label></td>
					<td><Label>Base Price (AUD)</Label></td>
					<td><Label>Base Weight (Grams)</Label></td>
				</tr></thead>
				<tbody>{e_fields}</tbody>
			</table>
		</Component.Skipper>
		<Col><Button onClick={add}>Add</Button></Col>
	</>);
}

