import {Component} from "../../..";
import {Api} from "../../../shared";
import {App} from "../../App";

export function List(props: {
	title: string;
	items: Api.Staff.User.UserBasicType[];
	update: (email: string) => unknown;
}) {
	if(props.items.length === 0) {
		return <></>;
	}

	let elements: JSX.Element[] = [];

	for(let item of props.items) {
		elements.push(<Component.UserCard key={item.email} user={item} onClick={() => {
			App.routeToUrl("/staff/user?" + (new URLSearchParams({email: item.email})).toString());
			props.update(item.email);
		}} />);
	}

	return (
		<div className="spacer">
			<h5>{props.title}</h5>
			<Component.Skipper>
				{elements}
			</Component.Skipper>
		</div>
	);
}

