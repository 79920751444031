
import React from "react";
import {Container, Col} from "reactstrap";
import {App} from "../App";
import {Api, Helpers} from "../../shared";
import {Component, Page} from "../..";

interface IProps {

}

interface IState {
	users: Api.Staff.User.UserBasicType[];
}

export class CurrentStaff extends React.Component<IProps, IState>
{
	alive = true;

	constructor(props: IProps) {
		super(props);

		this.state = {
			users: []
		};
	}

	async componentDidMount() {
		await this.update();
	}

	componentWillUnmount() {
		this.alive = false;
	}

	async update()
	{
		let users = await Api.Admin.Call.getStaff({});

		if(!users.data)
		{
			throw users.error;
		}

		this.setState({
			users: users.data.users
		});
	}

	render()
	{
		let it = 0;
		let elements: {
			[permission: string]: JSX.Element[],
		} = {};

		for(let user of this.state.users)
		{
			let perm = user.permission ?? "CUSTOMER";
			let child = (
				<Component.UserCard user={user} key={it++} onClick={() => {
					App.routeToUrl("/staff/user?"+Helpers.Data.QueryString({
						email: user.email,
					}));
				}} />
			);

			if(perm in elements)
			{
				elements[perm].push(child)
			}

			else
			{
				elements[perm] = [child];
			}
		}

		return (
			<Page.Admin>
				<Container>

					<Col><h4>Current Staff</h4></Col>

					<Col className="spacer">
						<h5>Admin</h5>
						<Component.Skipper>
							{elements.ADMIN ?? <></>}
						</Component.Skipper>
					</Col>

					<Col className="spacer">
						<h5>Staff</h5>
						<Component.Skipper>
							{elements.STAFF ?? <></>}
						</Component.Skipper>
					</Col>

				</Container>
			</Page.Admin>
		);
	}
}
