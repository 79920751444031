import React from "react";
import { Container } from "reactstrap";
import { App } from "../App";
import {MakeRoute, Route} from "../Routes";
import {ForgotConfirm} from "./ForgotConfirm";
import {Forgot} from "./Forgot";
import {Recover} from "./Recover";
import {Component} from "../..";

class Login extends React.Component
{
	render() {
		return (
			<div className="page-login">
				<Container>
					<Component.Login loginCallback={() => {
						App.routeToUrl("/profile");
					}} />
				</Container>
				<Component.Padding type="bottom" />
			</div>
		);
	}
}

export const LoginRoute = () => MakeRoute(Login, undefined, {
	"forgot": MakeRoute(Forgot, undefined, {
		"confirm": MakeRoute(ForgotConfirm),
	}),
	"recover": MakeRoute(Recover),
});

