import {Component} from "../../..";
import {OnActive} from "../../../scripts/util";
import {Api} from "../../../shared";

export function ViewCard({template, click, children}: {template?: Api.Staff.Template.Type, click?: () => unknown, children?: any})
{
	let contents: JSX.Element = <></>;

	if(template) {
		contents = (<>
			{children}
			<p>{template.name}</p>
		</>);
	}

	return (
		<Component.Card {...OnActive(click)}>
			{contents}
		</Component.Card>
	);
}

