
import { Container, Col, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { Environment } from "../../environment";
import { EmailValidator } from "../../shared/Validators";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { GenID, ReactComponent } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {

}

interface IState {
	email: string;
	recaptcha: string;
	showErrors: boolean;
	email_error: string;
	button_disabled: boolean;
	done: boolean;
	session: Api.Auth.SessionType;
}

export class Forgot extends ReactComponent<IProps, IState>
{
	emailElement?: HTMLInputElement | HTMLTextAreaElement;
	passwordElement?: HTMLInputElement | HTMLTextAreaElement;
	passwordCElement?: HTMLInputElement | HTMLTextAreaElement;

	constructor(props: IProps) {
		super(props);

		this.state = {
			email: "",
			recaptcha: "",
			showErrors: false,
			email_error: "",
			button_disabled: false,
			done: false,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	emailError() {
		if(this.state.email_error !== "") return this.state.email_error;
		switch(EmailValidator(this.emailElement ? this.emailElement.value : "")) {
			case "Required": return "Required";
			case "Valid": return "";
			default: return "Invalid user";
		}
	}

	humanError() {
		if(this.state.recaptcha) {
			return "";
		} else {
			return "Required";
		}
	}

	updateValues() {
		this.setState({
			email: this.emailElement ? this.emailElement.value : "",
		});
	}

	async changePassword()
	{
		this.setState({
			showErrors: true,
		});

		// Don't continue if there are any errors
		if(
			this.humanError().length > 0 ||
			this.emailError().length > 0 ||
			!this.emailElement
		) {
			return;
		}

		this.setState({
			button_disabled: true,
		});

		let email = this.emailElement.value;

		let request = await Api.Auth.Call.forgot({
			email: email,
			recaptcha: this.state.recaptcha,
		});

		if(request.error) {
			this.setState({
				button_disabled: false,
			});
			if(request.error === "user not found") {
				this.setState({
					email_error: "Invalid user",
				});
				return;
			}
			if(request.error === "invalid recaptcha") {
				this.setState({
					recaptcha: "",
				});
				return;
			}
			throw request.error;
		}

		this.setState({
			done: true,
		});
	}

	id = GenID();

	render() {

		if(this.state.done) {
			return (
				<div className="page-forgot">

					<Container>

						<Col><h4>Forgot Password</h4></Col>

						<Col><p>Password change request sent</p></Col>

						<Col><p>
							Please check your email. A link to change
							your password will be sent there.
						</p></Col>

					</Container>

					<Component.Padding type="bottom" />
					
				</div>
			);
		}

		return (
			<div className="page-forgot" role="form">

				<Container>

					<Col><h4>Forgot Password</h4></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id}>Email</Label>
						<Input
							id={this.id}
							type="text" 
							innerRef={(e) => {if(e) this.emailElement = e}} 
							onChange={() => {
								this.setState({
									email_error: "",
								});
								this.updateValues();
							}}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.emailError() : ""}
						</FormText>
					</FormGroup></Col>

					<Col><FormGroup>
						<Label>Human Test</Label>
						<Component.Recaptcha
							sitekey={Environment.recaptcha_public}
							onChange={(token) => {
								this.setState({
									recaptcha: token ? token : "",
								});
							}}
							onErrored={() => {
								this.setState({
									recaptcha: "",
								});
							}}
							onExpired={() => {
								this.setState({
									recaptcha: "",
								});
							}}
						/>
						<FormText color="danger">
							{this.state.showErrors && this.state.session.cookies.includes("GOOGLE") ? this.humanError() : ""}
						</FormText>
						
					</FormGroup></Col>

					<Col>
						<Button
							onClick={() => this.changePassword()}
							disabled={this.state.button_disabled}
						>Continue</Button>
					</Col>

				</Container>

				<Component.Padding type="bottom" />

			</div>
		)
	}
}
