import {useId, useState} from "react";
import {Button, Col, Input} from "reactstrap";
import {Component} from "../..";

export interface ComponentType {
	name: string;
	value: string;
}

function ComponentRow(props: {
	item: ComponentType;
	options: Set<string>;
	disabled: boolean;
	getAmountName: (name: string) => string;
	onChange: (v: ComponentType) => any;
	onRemove: () => any;
}) {

	const name_id = useId();
	const value_id = useId();
	const amount_name = props.getAmountName(props.item.name);

	return (
		<div className="side-flex">
			<label className="visually-hidden" htmlFor={name_id}>Name</label>
			<Component.DataList
				value={props.item.name}
				onChange={v => props.onChange({...props.item, name: v})}
				innerProps={{placeholder: "Name", id: name_id, disabled: props.disabled}}
				options={props.options}
			/>
			<label className="visually-hidden" htmlFor={value_id}>{amount_name}</label>
			<Input
				id={value_id}
				autoComplete="off"
				disabled={props.disabled}
				defaultValue={props.item.value}
				onChange={e => props.onChange({...props.item, value: e.target.value})}
				placeholder={amount_name}
			/>
			<Button onClick={props.onRemove} disabled={props.disabled}>X</Button>
		</div>
	);
}

export function Components(props: {
	items: ComponentType[];
	options: string[];
	disabled: boolean;
	getAmountName: (name: string) => string;
	onChange: (v: ComponentType[]) => any;
}) {
	const [index_at, setIndexAt] = useState(Math.max(props.items.length, 1));
	const [map] = useState(() =>
	{
		const map = new Map<number, ComponentType>();

		for(let i = 0; i < props.items.length; i++) {
			map.set(i, props.items[i]);
		}

		if(props.items.length === 0) {
			map.set(0, {name: "", value: ""});
		}

		return map;
	});

	function remove(k: number) {
		if(map.size <= 1) {
			return;
		}
		map.delete(k);
		props.onChange([...map.values()]);
	}

	function change(k: number, item: ComponentType) {
		map.set(k, item);
		props.onChange([...map.values()]);
	}

	function add() {
		map.set(index_at, {name: "", value: ""});
		setIndexAt(index_at + 1);
		props.onChange([...map.values()]);
	}

	const options_unused = new Set(props.options);

	for(let val of map.values()) {
		if(options_unused.has(val.name)) {
			options_unused.delete(val.name);
		}
	}

	const elements: JSX.Element[] = [];

	for(let [k, item] of map.entries()) {
		elements.push(<ComponentRow
			key={k}
			item={item}
			disabled={props.disabled}
			options={options_unused}
			getAmountName={props.getAmountName}
			onChange={(v) => change(k, v)}
			onRemove={() => remove(k)}
		/>);
	}

	return (
		<>
			{elements}
			<Col><Button onClick={add} disabled={props.disabled}>Add</Button></Col>
		</>
	);
}

