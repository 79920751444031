import {SQLEnum} from "../../SQLEnum";
import {PostType} from "../Api";
import * as CallType from "../CallType";

export type TypeBasic = {
	id?: string;
	name: string;
	group?: string | null;
	quantity_type: SQLEnum.QuantityType;
	surcharge: number;
	weight: number;
	out_stock: boolean;
}

export type TypeBasicID = TypeBasic & {
	id: string;
};

export type Type = TypeBasic & {
	templates: string[];
	group_templates?: string[];
};

export type TypeID = Type & {
	id: string;
}

export interface Create extends PostType<"duplicate entry" | "component not found"> {
	Body: Type;
};

export interface Delete extends PostType<"component not found"> {
	Body: {
		id: string;
		force?: boolean;
		replace_id?: string;
	};
	Res: {
		found: number;
		done: boolean;
	};
};

export interface Assign extends PostType<"component not found" | "template not found"> {
	Body: {
		component_id: string;
		template_name: string;
		unassign: boolean;
	};
};

export const Call = {
	create: CallType.Post<Create>("/staff/component/create"),
	delete: CallType.Post<Delete>("/staff/component/delete"),
	assign: CallType.Post<Assign>("/staff/component/assign"),
};

