
import "./style.scss";
import React from "react";
import { OnActive } from "../../scripts/util";

interface IProps {
	onClick?: () => void;
	children?: any;
}

export class Card extends React.Component<IProps>
{
	render() {
		return (
			<div
				className="component-card"
				{...(this.props.onClick ? OnActive(() => {
					if(this.props.onClick) {
						this.props.onClick();
					}
				}) : [])}
			>
				{this.props.children}
			</div>
		);
	}
}
