import {UserCapability} from "../shared/User";

export interface Route {
	component?: any,
	cap?: UserCapability;
	routes: {[key: string]: Route};
};

export const MakeRoute = (component: any, cap?: UserCapability, routes: Route["routes"] = {}): Route =>
{
	return {
		component: component,
		routes: routes,
		cap: cap,
	};
}

