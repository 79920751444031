
import React, { createRef } from "react";
import { Col, Container } from "reactstrap";
import {Api} from "../../shared";
import {Component, Page} from "../..";

interface IProps {

}

interface IState {
	orders: Api.Staff.Order.OrderBasicType[];
}

export const Orders = (orders_mode: Api.Staff.Order.OrderStatusType) =>
{
	return class extends React.Component<IProps, IState>
	{
		lock = false;

		constructor(props: IProps)
		{
			super(props);

			this.state = {
				orders: [],
			};
		}

		async componentDidMount()
		{
			await this.update();
		}

		async changeBefore(before: Date)
		{
			await this.update(before, []);
		}

		async update(older?: Date, orders = this.state.orders)
		{
			let last: string | undefined = undefined;

			if(orders.length > 0) {
				last = orders[orders.length - 1].id;
			}

			if(this.lock) {
				return;
			}

			this.lock = true;
			let response = await Api.Staff.Order.Call.getAll({
				older: older ? older.getTime().toString() : undefined,
				type: orders_mode,
				after: last,
			});

			this.lock = false;

			if(!response.data) {
				return;
			}

			for(let item of response.data.orders) {
				orders.push(item);
			}

			this.setState({
				orders: orders,
			});

		}

		render()
		{
			let it = 0;
			let order_e: {
				element: JSX.Element,
				date: Date,
			}[] = [];

			for(let item of this.state.orders)
			{
				order_e.push({
					date: new Date(item.created),
					element: (<Component.OrderCard setHidden={orders_mode !== "OLD"} order={item} key={it++} />),
				});
			}

			let button_more = <></>;

			if(this.state.orders.length > 0)
			{
				let ref = createRef<Component.BigButton>();

				const click = async () =>
				{
					await this.update();
				}

				button_more = (
					<Component.BigButton ref={ref} bigOnClick={click}>Load More</Component.BigButton>
				);
			}

			return (
				<Page.Staff render_callback={() => (<Component.HistoryListButtons callback={(d) => this.changeBefore(d)} />)}>
	
					<Container>

						<Col><h4>{
							orders_mode === "PAID" ? "New orders" :
							orders_mode === "SHIP" ? "Ready to ship" :
							orders_mode === "PICKUP" ? "Ready for pickup" :
							orders_mode === "OLD" ? "Old orders" : 
							orders_mode === "HIDDEN" ? "Hidden orders" : 
							orders_mode === "BANK" ? "Unpaid bank orders" :
							orders_mode === "PHONE" ? "Unpaid phone orders" : ""
						}</h4></Col>

						<Component.HistoryList items={order_e} />

						{button_more}

					</Container>
	
				</Page.Staff>
			)
		}
	}
}
