
import "./style.scss";
import React from "react";
import Axios from "axios";
import { Container, Col, Button, FormText, Input } from "reactstrap";
import { SessionReload } from "../../app/AppHelpers";
import { GenID, OnActive } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {
	closeCallback: (image?: Api.Files.ImageType) => void;
}

interface IState {
	filter: string[];
	images: Api.Files.ImageType[];
	visible: boolean;
	everyone: boolean;
	errors: string;
}

export class ImageSelector extends React.Component<IProps, IState>
{
	containerElement?: HTMLDivElement;
	uploadElement?: HTMLInputElement;

	containerHeight = 0;
	alive = true;

	constructor(props: IProps) {
		super(props);

		this.state = {
			visible: true,
			everyone: true,
			filter: [],
			images: [],
			errors: "",
		};
	}

	componentDidMount()
	{
		this.updateWindow();
		this.updateImages();
	}

	updateWindow()
	{
		if(!this.alive) {
			return;
		}

		if(this.containerElement) {
			let h = window.innerHeight;
			if(this.containerHeight !== h) {
				this.containerHeight = h;
				this.forceUpdate();
			}
		}

		setTimeout(() => this.updateWindow(), 10);
	}

	async updateImages()
	{
		let response = await Api.Files.Call.images({});

		if(response.data)
		{
			this.setState({
				images: response.data.images,
			});
		}
	}

	upload()
	{
		// Don't execute if the upload form isn't loaded
		if(!this.uploadElement) {
			return;
		}

		this.setState({
			errors: "",
		});

		// Trigger the upload form
		this.uploadElement.click();
		let done = false;
		
		// Async wait until the upload form is set
		this.uploadElement.onchange = async () =>
		{
			// Don't execute if the upload form has already been
			// triggered or the upload form isn't loaded
			if(!this.uploadElement || done) {
				return;
			}

			// Make sure there actually is a file
			if(!this.uploadElement.files || this.uploadElement.files.length === 0) {
				return;
			}

			// Get the file
			let file = this.uploadElement.files[0];

			// Make sure the file is an actual image
			if(!(
				file.name.endsWith(".png") ||
				file.name.endsWith(".jpg") ||
				file.name.endsWith(".jpeg") ||
				file.name.endsWith(".gif") ||
				file.name.endsWith(".bmp") ||
				file.name.endsWith(".svg")
			)) {
				this.setState({
					errors: "File must be an image",
				});
				return;
			}

			// Don't upload images > 2 MB
			if(file.size > 8388608) {
				this.setState({
					errors: "File must be less than 8 MB",
				});
				return;
			}

			// Pack the file into a form object
			let formData = new FormData();
			formData.append("image", this.uploadElement.files[0]);

			// Send the upload request to the server
			let response = await Axios.post("/api/staff/upload", formData, {
				headers: {'Content-Type': 'multipart/form-data'},
			});

			// Load the updated session object
			await SessionReload();

			// Check for errors
			if(response.data.error) {
				if(response.data.error === "not logged in") {
					return;
				}
				throw response.data.error;
			}

			// Update the image selection
			this.updateImages();
		}
	}

	id1 = GenID();
	id2 = GenID();

	render() {

		if(!this.state.visible) {
			return <></>;
		}

		return (
			<div ref={(e) => {if (e) this.containerElement = e}}>
				<Container className="component-image-selector">
					<Col><h4>Select an image</h4></Col>
					<Col>
						<label htmlFor={this.id1} className="visually-hidden">Filter keywords</label>
						<Input
							id={this.id1}
							type="search"
							placeholder="Filter keywords"
							style={{width: "calc(100% - 10em)", display: "inline-block"}}
							onChange={(event) =>
							{
								let keywords = event.target.value.toLowerCase().split(" ");

								this.setState({
									filter: keywords,
								});
							}}
						/>
						<label htmlFor={this.id2} className="visually-hidden">Filter by owner</label>
						<Input
							id={this.id2}
							style={{width: "10em", display: "inline-block"}}
							type="select"
							onChange={(event) =>
							{
								this.setState({
									everyone: event.target.value === "0"
								});
							}}
						>
							<option value={0}>Everyone</option>
							<option value={1}>Myself</option>
						</Input>
					</Col>
					<Col className="images" style={{
						height: this.containerHeight - 358
					}}>
						<Component.Skipper label="Images">
							{(() =>
							{
								let elements: JSX.Element[] = [];
								let it = 0;

								for(let image of this.state.images)
								{
									if(!image.is_owner && !this.state.everyone)
									{
										continue;
									}

									let image_name_lower = image.file_name.toLowerCase();
									let showImage = 0;

									for(let word of this.state.filter)
									{
										if(image_name_lower.includes(word))
										{
											showImage += 1;
										}
									}

									if(showImage === this.state.filter.length)
									{
										elements.push(
											<div
												key={it++}
												className="image" 
											>
												<img
													loading="lazy"
													src={Api.Files.Image(image.file_id, image.file_name) + "?w=400"}
													alt={image.file_name}
													style={{
														width: "200px",
														height: "200px",
													}}
													{...OnActive(() => {
														this.props.closeCallback(image);
														this.setState({
															visible: false,
														});
													})}
												/>
												<p aria-hidden="true" style={{
													fontSize: "10px",
												}}>{image.file_name}</p>
											</div>
										);
									}
								}

								return elements;
							})()}
						</Component.Skipper>
					</Col>
					<Col className="buttons">
						<Button
							onClick={() => {
								this.props.closeCallback();
								this.setState({
									visible: false,
								});
							}}
						>Close</Button>
						<Button
							onClick={() => this.upload()}
						>Upload</Button>
						<FormText color="danger">
							{this.state.errors}
						</FormText>
						<input
							type="file"
							accept="image/*"
							className="file-upload"
							ref={(e) => {
								if(e) {
									this.uploadElement = e;
								}
							}}
						/>
					</Col>
				</Container>
			</div>
		)
	}
}
