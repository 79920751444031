
import { Col, Label, Input, FormText, FormGroup } from "reactstrap";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { PasswordValidator } from "../../shared/Validators";
import { secureRandom } from "../../shared/Security";
import { workerCalculatePassword } from "../../scripts/worker";
import { GenID, ReactComponent } from "../../scripts/util";
import {Api} from "../../shared";

interface IProps {
	callback?: () => void;
}

interface IState {
	oldPassword: string;
	newPassword: string;
	newPasswordC: string;
	showErrors: boolean;
	oldPasswordErrors: string;
	session: Api.Auth.SessionType;
}

export class ChangePassword extends ReactComponent<IProps, IState>
{
	oldPasswordElement?: HTMLInputElement | HTMLTextAreaElement;
	newPasswordElement?: HTMLInputElement | HTMLTextAreaElement;
	newPasswordCElement?: HTMLInputElement | HTMLTextAreaElement;

	newPasswordErrors()
	{
		if(!this.newPasswordElement || !this.newPasswordCElement) {
			return "Required";
		}

		switch(PasswordValidator(this.newPasswordElement.value)) {
			case "Required": return "Required";
			case "TooShort": return "Too short";
		}

		if(this.newPasswordElement.value !== this.newPasswordCElement.value) {
			return "Passwords do not match";
		}

		return "";
	}

	update() {
		this.setState({
			oldPassword: this.oldPasswordElement ? this.oldPasswordElement.value : "",
			newPassword: this.newPasswordElement ? this.newPasswordElement.value : "",
			newPasswordC: this.newPasswordCElement ? this.newPasswordCElement.value : "",
		});
	}

	async changePassword()
	{
		this.setState({
			showErrors: true,
		});

		if(
			!this.state.session.user ||
			!this.newPasswordElement ||
			!this.oldPasswordElement ||
			!this.newPasswordCElement ||
			(this.state.oldPassword === "" && !this.state.session.is_proxy) ||
			this.state.newPassword === "" ||
			this.state.newPasswordC === "" ||
			this.state.oldPasswordErrors !== "" ||
			this.newPasswordErrors() !== ""
		){
			return;
		}

		let user = this.state.session.user;

		if(!this.state.session.is_proxy)
		{
			switch(PasswordValidator(this.state.oldPassword)) {
				case "Required": {
					this.setState({
						oldPasswordErrors: "Required",
					});
					return;
				}
				case "TooShort": {
					this.oldPasswordElement.value = "";
					this.update();
					this.setState({
						oldPasswordErrors: "Invalid Password",
					});
					return;
				}
			}
		}

		let response_saltC = await Api.Auth.Call.salt({
			email: user.email
		})

		if(!response_saltC.data) {
			throw response_saltC.error;
		}

		let headerC = response_saltC.data.header;
		let headerN = secureRandom(32).toString("hex");
		
		let passwordC = await workerCalculatePassword(this.state.oldPassword, headerC);
		let passwordN = await workerCalculatePassword(this.state.newPassword, headerN);

		let changePassword_response = await Api.Auth.Call.changePassword({
			passwordC: passwordC,
			passwordN: passwordN,
		});

		if(changePassword_response.error) {
			if(changePassword_response.error === "auth error") {
				this.oldPasswordElement.value = "";
				this.update();
				this.setState({
					oldPasswordErrors: "Invalid Password",
				});
				return;
			}
			console.error(changePassword_response.error);
			return;
		}

		this.newPasswordElement.value = "";
		this.newPasswordCElement.value = "";
		this.oldPasswordElement.value = "";
		this.update();

		this.setState({
			showErrors: false,
		});

		if(this.props.callback)
		{
			this.props.callback();
		}
	}

	constructor(props: IProps) {
		super(props);

		this.state = {
			showErrors: false,
			newPassword: "",
			newPasswordC: "",
			oldPassword: "",
			oldPasswordErrors: "",
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	id1 = GenID();
	id2 = GenID();
	id3 = GenID();

	render() {
		return (
			<div className="component-change-password">

				<Col>
					<FormGroup>
						<Label htmlFor={this.id1}>Old Password</Label>
						<Input
							id={this.id1}
							type="password"
							onChange={() => {
								this.update();
								this.setState({
									oldPasswordErrors: "",
								});
							}}
							innerRef={(e) => {if(e) this.oldPasswordElement = e}}
							disabled={this.state.session.is_proxy ?? false}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.state.oldPasswordErrors : ""}
						</FormText>
					</FormGroup>
				</Col>

				<Col>
					<FormGroup>
						<Label htmlFor={this.id2}>New Password</Label>
						<Input
							id={this.id2}
							onChange={() => this.update()}
							innerRef={(e) => {if(e) this.newPasswordElement = e}}
							type="password"
						/>
						<FormText color="danger"></FormText>
					</FormGroup>
				</Col>

				<Col>
					<FormGroup>
						<Label htmlFor={this.id3}>Confirm New Password</Label>
						<Input
							id={this.id3}
							onChange={() => this.update()}
							innerRef={(e) => {if(e) this.newPasswordCElement = e}}
							type="password"
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.newPasswordErrors() : ""}
						</FormText>
					</FormGroup>
				</Col>

			</div>
		)
	}
}
