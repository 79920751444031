import {Api} from "../../shared";
import {UserCapability} from "../../shared/User";

export interface NavItem {
	cap?: UserCapability;
	display: string;
	visual_to?: string;
	to: string;
}

export const NavItems = (session: Api.Auth.SessionType): NavItem[] => {
	return [
		{
			display: "Home",
			to: "/",
		},
		{
			display: "Store",
			to: "/store",
		},
		{
			display: "Practitioner",
			visual_to: "/practitioner",
			to: "/practitioner/clients",
			cap: UserCapability.PRACTITIONER,
		},
		{
			display: "Staff",
			to: "/staff",
			cap: UserCapability.STAFF,
		},
		{
			display: "Admin",
			to: "/admin",
			cap: UserCapability.ADMIN,
		},
		{
			display: `Cart (${session.cart_count})`,
			to: "/cart",
		},
	];
}
