import {useEffect, useState} from "react";
import {Button, FormGroup, FormText, Label} from "reactstrap";
import {FormHelperItem} from "../../scripts/formhelper";
import {Api} from "../../shared";
import {ProductType} from "./ProductType";

type ItemOptType = Api.Staff.Product.ItemOptType & {key?: number};

export function ProductOptions(props: {
	form: FormHelperItem<Api.Staff.Product.ItemOptType[]>;
}) {
	const [types, setTypes] = useState<ItemOptType[]>([]);
	const [key_at, setKeyAt] = useState(0);

	props.form.onModify = (types?: ItemOptType[]) => {
		if(!types) {
			setTypes([]);
			return;
		}
		let i = key_at;
		for(let type of types) {
			if(!type.key) {
				type.key = ++i;
			}
		}
		if(i !== key_at) {
			setKeyAt(i);
		}
		setTypes(types);
	}
	props.form.errors = (types) => {
		if(!types || types.length === 0) {
			return "Invalid";
		}

		for(let type of types) {
			if(type.amount === 0) {
				return "Incomplete";
			}
			if(type.amount < 0 || type.weight < 0 || type.name.length > 100) {
				return "Invalid";
			}
			if(type.name.length === 0 && types.length > 1) {
				return "Incomplete";
			}
		}

		return "";
	}

	useEffect(props.form.update, []);

	let elements: JSX.Element[] = [];

	for(let i = 0; i < types.length; i++) {
		let type = types[i];

		elements.push(
			<tr key={type.key}>
				<ProductType
					onDelete={() => {
						types.splice(i, 1);
						props.form.modify(types);
					}}
					onChange={(c) => {
						types[i] = {key: types[i].key, ...c};
						props.form.modify(types);
					}}
					defaultValue={type}
					single={types.length === 1}
				/>
			</tr>
		)
	}

	return (<>
		<FormGroup>
			<Label>Product Pricing/Options</Label>
		</FormGroup>
		<FormGroup>
			<table>
				<thead><tr>
					{types.length > 1 ? 
						<td><Label>Name</Label></td>
					: <></>}
					<td><Label>Weight (Grams)</Label></td>
					<td><Label>Cost (AUD $)</Label></td>
					<td><Label>Stock Status</Label></td>
				</tr></thead>
				<tbody>
					{elements}
				</tbody>
			</table>
			<div>
				<Button
					onClick={() => {
						types.push({
							amount: 0,
							name: "",
							weight: 0,
							out_stock: false,
						});
						props.form.modify(types);
					}}
				>Add</Button>
				<div><FormText color="danger">
					{props.form.addErrorField()}
				</FormText></div>
			</div>
		</FormGroup>
	</>);
}

