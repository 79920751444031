
import React, { useId } from "react";
import { Container, Col, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { PasswordValidator } from "../../shared/Validators";
import { App } from "../App";
import { SessionReload } from "../../app/AppHelpers";
import { workerCalculatePassword } from "../../scripts/worker";
import { GenID } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {

}

interface IState {
	confirm: string;
	email: string;
	valid: boolean;
	loaded: boolean;
	error_reason?: string;
	button_disabled: boolean;
	change_error: string;
	showErrors: boolean;
	password: string;
	passwordC: string;
}

export class ForgotConfirm extends React.Component<IProps, IState>
{
	passwordElement?: HTMLInputElement | HTMLTextAreaElement;
	passwordCElement?: HTMLInputElement | HTMLTextAreaElement;

	constructor(props: IProps) {
		super(props);

		this.state = {
			confirm: "",
			email: "",
			valid: false,
			loaded: false,
			button_disabled: false,
			change_error: "",
			showErrors: false,
			password: "",
			passwordC: "",
		};
	}

	componentDidMount()
	{
		let query = new URL(window.location.href);
		let confirm = query.searchParams.get("id");
		let email = query.searchParams.get("email");

		if(typeof(confirm) !== "string" || typeof(email) !== "string" || confirm.length !== 64) {
			this.setState({
				valid: false,
				loaded: true,
				error_reason: "Invalid query parameters",
			});
			return;
		}

		for(let i = 0; i < confirm.length; i++) {
			if(!"0123456789abcdef".includes(confirm[i])) {
				this.setState({
					valid: false,
					loaded: true,
					error_reason: "Invalid query parameters",
				});
				return;
			}
		}

		this.setState({
			valid: true,
			confirm: confirm,
			email: email,
			loaded: true,
		});
	}

	updateValues()
	{
		this.setState({
			password: this.passwordElement ? this.passwordElement.value : "",
			passwordC: this.passwordCElement ? this.passwordCElement.value : "",
		});
	}

	passwordError()
	{
		if(!this.passwordElement || !this.passwordCElement) {
			return "Required";
		}

		switch(PasswordValidator(this.passwordElement.value)) {
			case "Required": return "Required";
			case "TooShort": return "Too short";
		}

		if(this.passwordElement.value !== this.passwordCElement.value) {
			return "Passwords do not match";
		}

		return "";
	}

	async changePassword()
	{
		this.setState({
			showErrors: true,
		});

		if(
			!this.passwordElement ||
			!this.passwordCElement ||
			this.passwordError()
		) {
			return;
		}

		this.setState({
			button_disabled: true,
		});
		
		// Get the user salt
		let salt_response = await Api.Auth.Call.salt({
			email: this.state.email,
		});

		// Check if there were any salt errors
		if(!salt_response.data) {
			throw salt_response.error;
		}

		// Salt the password
		let password = await workerCalculatePassword(this.state.password);

		let request = await Api.Auth.Call.changePassword({
			confirmId: this.state.confirm,
			passwordN: password,
		});

		if(request.error) {
			if(request.error === "invalid token") {
				this.setState({
					change_error: "Invalid token",
				});
				return;
			}
			throw request.error;
		}

		await SessionReload();
		App.routeToUrl("/");
	}

	id1 = GenID();
	id2 = GenID();

	render() {

		if(!this.state.loaded) {
			return <></>;
		}

		if(!this.state.valid) {
			return (
				<div className="page-forgot-confirm">
					<Container>

					<Col><h4>Invalid Request</h4></Col>

					<Col><p>

						We encountered an issue while
						processing your password change
						request.

					</p></Col>

					<Col><p>Reason: { this.state.error_reason ? this.state.error_reason : "" }</p></Col>

					</Container>
					<Component.Padding type="bottom" />
				</div>
			)
		}

		return (
			<div className="page-forgot-confirm">
				<Container role="form">

					<Col><h4>Change password</h4></Col>
					
					<Col><p>
						You requested to change the password
						for the account at {
							this.state.email
						}.
					</p></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id1}>New Password</Label>
						<Input
							id={this.id1}
							type="password" 
							innerRef={(e) => {if(e) this.passwordElement = e}} 
							onChange={() => this.updateValues()}
						/>
						<FormText color="danger" />
					</FormGroup></Col>

					<Col><FormGroup>
						<Label htmlFor={this.id2}>Confirm Password</Label>
						<Input
							id={this.id2}
							type="password" 
							innerRef={(e) => {if(e) this.passwordCElement = e}} 
							onChange={() => this.updateValues()}
						/>
						<FormText color="danger">
							{this.state.showErrors ? this.passwordError() : ""}
						</FormText>
					</FormGroup></Col>

					<Col>
						<Button
							onClick={() => this.changePassword()}
							disabled={this.state.button_disabled || this.state.change_error !== ""}
						>Change</Button>
						<FormText color="danger">
							{this.state.change_error}
						</FormText>
					</Col>

				</Container>
				<Component.Padding type="bottom" />
			</div>
		);
	}
}
