import { useId, useState } from "react";
import { Button, Col, Container, FormGroup, Label } from "reactstrap";
import { App } from "../../app/App";
import { ConvertStaffProduct } from "../../shared/Product";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps
{
	product: Api.Staff.Product.ItemType;
}

const ProductDemoShow = (props: IProps) =>
{
	const id1 = useId();

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<Component.ProductInfo product={ConvertStaffProduct(props.product)} viewOnly={true} />
			<Col className="spacer"><Button onClick={() => App.open(null, false)}>Close</Button></Col>
		</Container>
	)
}

export const ProductDemo = (props: IProps) =>
{
	return (
		<Col><FormGroup>

			<Label>Preview</Label>

			<div>
				<Component.Product
					product={ConvertStaffProduct(props.product)}
					onClick={() => App.open(() => <ProductDemoShow product={props.product} />, false)}
				/>
			</div>

		</FormGroup></Col>
	)
}
