
import Axios from "axios";
import { ResType, PostType, GetType } from "./Api";

let SessionReload: () => Promise<void>;
let CsrfToken = "";

try
{
	let m = require("../../app/AppHelpers");
	SessionReload = m.SessionReload;
}

catch(e)
{
	SessionReload = async () => {};
}

export async function Do<T extends PostType<any> | GetType<any>>(data: ResType<T>)
{
	if(data.error)
	{
		if(data.error === "unauthorised")
		{
			await SessionReload();
		}
	}

	return data;
}

export function Get<T extends GetType<any>>(url: string)
{
	return async (params: T["Query"]) =>
	{
		let res = await Axios.get<ResType<T>>("/api" + url, {
			params: params,
		});

		return await Do(res.data);
	}
}

export function Post<T extends PostType<any>>(url: string)
{
	return async (body: T["Body"]) =>
	{
		const go = () =>
		{
			return Axios.post<ResType<T>>("/api" + url, body, {
				headers: {
					Csrf: CsrfToken,
				},
			});
		}

		let res = await go();

		if(res.data.csrf)
		{
			CsrfToken = res.data.csrf;
			res = await go();
		}

		return await Do(res.data);
	}
}

