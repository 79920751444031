import { PaymentMethodResult, Stripe } from "@stripe/stripe-js";
import { useId, useState } from "react";
import { Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { GlobalVariables } from "../../../scripts/GlobalVariables";
import { EmailValidator, PhoneValidator } from "../../../shared/Validators";
import { App } from "../../App";
import { SessionReload } from "../../AppHelpers";
import {Api} from "../../../shared";
import {CartRefProps} from "..";
import {Component} from "../../..";

const Step1 = (ref: CartRefProps): JSX.Element =>
{
	const change_e = (v: string) =>
	{
		if(EmailValidator(v) === "Valid") {
			ref.setState({
				email: v,
			});
		} else {
			ref.setState({
				email: undefined,
			});
		}
	}

	const change_p = (v: string) =>
	{
		if(PhoneValidator(v) === "Valid") {
			ref.setState({
				phone: v,
			});
		} else {
			ref.setState({
				phone: undefined,
			});
		}
	}

	let id1 = useId();
	let id2 = useId();

	return (
		<Col>
			<div className="cart-section" role="form">
				<h4>Contact</h4>
				<FormGroup>
					<Label htmlFor={id1}>Email</Label>
					<Input
						id={id1}
						type="text"
						disabled={ref.state.progress !== 1}
						onChange={(e) => change_e(e.target.value)}
					/>
				</FormGroup>
				<FormGroup>
					<Label htmlFor={id2}>Phone Number</Label>
					<Input
						id={id2}
						type="text"
						disabled={ref.state.progress !== 1}
						onChange={(e) => change_p(e.target.value)}
					/>
				</FormGroup>
			</div>
		</Col>
	)
}

const Step2 = (ref: CartRefProps): JSX.Element =>
{
	let ship_window: JSX.Element = <></>;
	let id1 = useId();
	let id2 = useId();
	let id3 = useId();

	if(ref.state.shippingMode === 1)
	{
		ship_window = (
			<FormGroup>
				<Label htmlFor={id1}>Full Name *</Label>
				<Input
					id={id1}
					disabled={ref.state.progress !== 2}
					onChange={(e) => {
						ref.setState({
							name: e.target.value,
						});
					}}
				/>
			</FormGroup>
		);
	}

	else if(ref.state.shippingMode === 0)
	{
		let ship_service_window: JSX.Element = <></>;

		if(ref.state.shippingService &&
			ref.state.shippingServices &&
			Object.keys(ref.state.shippingServices).length > 0)
		{
			const shipping_service_onchange = (v: string) =>
			{
				if(!ref.state.shippingServices) {
					return;
				}

				let service = ref.state.shippingServices[v];

				if(!service) {
					return;
				}

				ref.setState({
					shippingService: v,
					postageCost: (
						service.cost
					),
				});
			}

			let elements: JSX.Element[] = [];
			let it = 0;
			
			if(ref.state.shippingService)
			{
				for(let [k, v] of Object.entries(ref.state.shippingServices))
				{
					if(!v) {
						continue;
					}

					it += 1;
					elements.push(
						<option key={it} value={k}>
							{v.display}
						</option>
					);
				}
			}

			let shipping_service_msg = "";

			if(ref.state.shippingService && ref.state.shippingServices)
			{
				let service = ref.state.shippingServices[ref.state.shippingService];

				if(service) {
					shipping_service_msg = service.message;
				}
			}

			ship_service_window = (
				<FormGroup>
					<Label htmlFor={id2}>Shipping Service *</Label>
					<Input
						id={id2}
						type="select"
						defaultValue={ref.state.shippingService}
						disabled={ref.state.progress !== 2}
						onChange={(e) => shipping_service_onchange(e.target.value)}
					>
						{elements}
					</Input>
					<FormText>
						{shipping_service_msg}
					</FormText>
				</FormGroup>
			);
		}

		let msg = "";

		if(ref.state.auspostDown)
		{
			msg = `
				Sorry, we can't reach Australia Post at the moment.
				Please try again later or contact us to see if we
				can arrange an order to your location.
				Give us a call at +61 3 6234 4223.
			`;
		}

		else if(ref.state.postageCost === undefined && ref.state.showPostageErrors)
		{
			msg = `
				Sorry, we don't ship to this location.
				Please contact us to see if we can arrange
				an order to your location. Give us a call
				at +61 3 6234 4223.
			`;
		}

		ship_window = (
			<>
				<Component.ShippingInput
					disabled={ref.state.progress !== 2}
					onChange={(state) => ref.calculateShipping(state)}
					onError={(error) =>
					{
						if(error === "") {
							ref.setState({
								auspostDown: true,
							});
						}
					}}
				/>
				{ship_service_window}
				<FormText color="danger">
					{msg}
				</FormText>
			</>
		)
	}

	const onchange = (v_i: string) =>
	{
		let v = parseInt(v_i);

		ref.setState({
			shippingMode: v,
		});
		if(v === 0) {
			ref.calculateShipping({
				country: "AU",
				postcode: "",
				address1: "",
				address2: "",
				city: "",
				state: "",
				name: "",
				save: false,
			});
		}
		if(v === 1) {
			ref.setState({
				postageCost: 0,
				shipping: undefined,
				shippingService: undefined,
				shippingServices: undefined,
			});
		}
	}

	return (
		<Col>
			<div className="cart-section" role="form">
				<h4>Shipping</h4>
				<FormGroup>
					<Label htmlFor={id3}>Method *</Label>
					<Input
						id={id3}
						type="select"
						defaultValue={0}
						disabled={ref.state.progress !== 2}
						onChange={(e) => onchange(e.target.value)}
					>
						<option value={1}>Pickup from store (Free)</option>
						<option value={0}>Australia Post / Courier (Worldwide)</option>
					</Input>
				</FormGroup>
				{ship_window}
			</div>
		</Col>
	);
}

export const Step3 = (ref: CartRefProps): JSX.Element =>
{
	const paymode_change = (v: string) =>
	{
		ref.setState({
			payMode: v,
		});
	}

	const [session, setSession] = useState(GlobalVariables.Session.value);
	GlobalVariables.Session.regUse(setSession);

	let user = session.user;
	let card_elements: JSX.Element[] = [];
	
	if(user)
	{
		let it = 0;

		for(const card of user.cards) {
			card_elements.push(
				<option key={it++} value={"saved_"+card.stripe_id}>Card XXXX-XXXX-XXXX-{card.last4} ({card.name})</option>
			);
		}
	}

	let paywindow_e: JSX.Element = <></>;

	if(ref.state.payMode === "card")
	{
		let onpurchase = async (stripe: Stripe, state: PaymentMethodResult, saveCard: boolean) =>
		{
			if(!ref.state.order || !state.paymentMethod) {
				return;
			}

			let response = await Api.Post.Call.payOrder({
				paymentMethod: state.paymentMethod.id,
				id: ref.state.order.id,
				saveCard: saveCard,
			});

			if(await ref.handlePurchase(response, saveCard, stripe)) {
				App.routeToUrl("/order?id=" + ref.state.order.id);
			}
		}

		paywindow_e = (
			<FormGroup>
				<h5>Card details</h5>
				<Component.StripePayment
					onPurchase={onpurchase}
					onChange={() => ref.setState({
						stripeElement: ref.state.stripeElement,
					})}
					ref={ref.state.stripeElement}
				/>
			</FormGroup>
		)
	}

	else if(ref.state.payMode === "bank")
	{
		paywindow_e = (
			<p>
				Please quote your surname and invoice
				number as the payment reference.
				Please forward remittance advice to <a
					href="mailto:postal.orders@gouldsnaturalmedicine.com.au"
				>postal.orders@gouldsnaturalmedicine.com.au</a>.<br/>
				<br/>
				Account Name: Althaea Pty Ltd<br/>
				BSB: 633 000<br/>
				Account number: 127 228 815<br/>
			</p>
		)
	}

	else if(ref.state.payMode === "phone")
	{
		paywindow_e = (
			<p>
				Please call +61 3 6234 4223 during business hours
				(9am to 5pm weekdays, 10.00am to 1.30pm Saturdays, GMT+10)
				and select “postal orders” at the main menu to
				speak to our postal order team.
			</p>
		);
	}

	const big_click = async () =>
	{
		if(!ref.state.payMode) {
			return;
		}

		ref.setState({
			buttonDisabled: true,
		});

		if(ref.state.payMode.startsWith("saved_"))
		{
			const paymentMethod = ref.state.payMode.substring(6);

			if(!ref.state.order) {
				return;
			}

			let response = await Api.Post.Call.payOrder({
				paymentMethod: paymentMethod,
				id: ref.state.order.id,
				saveCard: false,
			});

			if(await ref.handlePurchase(response, false)) {
				App.routeToUrl("/order?id=" + ref.state.order.id);
			}

			ref.setState({
				buttonDisabled: false,
			});
		}

		else if(ref.state.payMode !== "card")
		{
			if(ref.state.order)
			{
				let response = await Api.Post.Call.confirmOrder({
					id: ref.state.order.id,
					type: (
						ref.state.payMode === "phone" ? "PHONE" :
						ref.state.payMode === "bank" ? "BANK" :
						"CARD"
					),
				});

				await SessionReload();
				
				if(response.error)
				{
					ref.setState({
						buttonDisabled: false,
					});

					throw response.error;
				}

				App.routeToUrl("/order?id=" + ref.state.order.id);
			}

			ref.setState({
				buttonDisabled: false,
			});

			return;
		}

		else if(ref.state.stripeElement.current)
		{
			await ref.state.stripeElement.current.purchase();

			ref.setState({
				buttonDisabled: false,
			});
		}
	}

	let id = useId();

	return (
		<Col>
			<div className="cart-section" role="form">
				<h4>Payment</h4>
				<FormGroup>
					<label htmlFor={id} className="visually-hidden">Payment mode</label>
					<Input
						id={id}
						type="select"
						defaultValue={ref.state.payMode}
						onChange={(e) => paymode_change(e.target.value)}
					>
						{card_elements}
						<option value="card">Pay with card</option>
						<option value="bank">Pay via direct deposit</option>
						<option value="phone">Pay via phone</option>
					</Input>
				</FormGroup>
				{paywindow_e}
				<Component.Invoice order={ref.state.order} />
				<p>
					For support/questions about
					your purchase, please contact us
					at <a href="mailto:postal.orders@gouldsnaturalmedicine.com.au"
					>postal.orders@gouldsnaturalmedicine.com.au</a> or call +61 3 6234 4223
					(9am to 5pm weekdays, 10.00am to 1.30pm Saturdays, GMT+10).
				</p>
				<Component.BigButton
					bigOnClick={big_click}
					disabled={
						(
							ref.state.stripeElement.current &&
							ref.state.stripeElement.current.hasFormErrors() &&
							ref.state.payMode === "card"
						) ||
						ref.state.buttonDisabled
					}
				>
					Confirm Order
				</Component.BigButton>
			</div>
		</Col>
	);
}

export const Checkout = (ref: CartRefProps): JSX.Element =>
{
	let state = ref.state;
	const [session, setSession] = useState(GlobalVariables.Session.value);
	GlobalVariables.Session.regUse(setSession);

	if(!state.cart) {
		return <></>;
	}

	let cart: Api.Cart.ProductCartType[] = [];
	let user = session.user;

	for(let item of state.cart) {
		if(item.count > 0) {
			cart.push(item);
		}
	}

	let elements: JSX.Element[] = [];

	if(state.progress > 0 && !user) {
		elements.push(<Step1 key={1} {...ref} />);
	}

	if(state.progress > 1) {
		elements.push(<Step2 key={2} {...ref} />);
	}

	if(state.progress > 2) {
		elements.push(<Step3 key={3} {...ref} />);
	}

	return <>{elements}</>;
}
