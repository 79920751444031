
import React, { createRef, useId } from "react";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import { GenID, ReactComponent } from "../../scripts/util";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { AddressToString } from "../../shared/helpers/DataHelpers";
import { ShippingState } from "../../shared/Shipping";
import {Api} from "../../shared";

interface IProps {
	onChange: (state: ShippingState) => void;
	onError: (error: string) => void;
	disabled?: boolean;
}

interface CountryProps {
	code: string;
	name: string;
	description: string;
	postalServiceName?: string | null;
	postalServiceUrl?: string | null;
}

const STATE_EMPTY: ShippingState = {
	address1: "",
	address2: "",
	country: "AU",
	postcode: "",
	state: "",
	city: "",
	name: "",
	save: false,
};

export class ShippingInput extends ReactComponent<IProps, ShippingState & {session: Api.Auth.SessionType}>
{
	static countries: CountryProps[] = [];

	ref_name = createRef<HTMLInputElement>();
	ref_country = createRef<HTMLInputElement>();
	ref_postcode = createRef<HTMLInputElement>();
	ref_address1 = createRef<HTMLInputElement>();
	ref_address2 = createRef<HTMLInputElement>();
	ref_city = createRef<HTMLInputElement>();
	ref_state = createRef<HTMLInputElement>();
	ref_save = createRef<HTMLInputElement>();
	ref_preset = createRef<HTMLInputElement>();

	constructor(props: IProps) {
		super(props);
		this.state = {
			address1: STATE_EMPTY.address1,
			address2: STATE_EMPTY.address2,
			city: STATE_EMPTY.city,
			country: STATE_EMPTY.country,
			name: STATE_EMPTY.name,
			postcode: STATE_EMPTY.postcode,
			state: STATE_EMPTY.state,
			save: STATE_EMPTY.save,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	update()
	{
		if(!this.ref_name.current ||
			!this.ref_country.current ||
			!this.ref_postcode.current ||
			!this.ref_address1.current ||
			!this.ref_address2.current ||
			!this.ref_city.current ||
			!this.ref_state.current)
		{
			return;
		}

		let state: ShippingState = {
			name: this.ref_name.current.value,
			country: this.ref_country.current.value,
			postcode: this.ref_postcode.current.value,
			address1: this.ref_address1.current.value,
			address2: this.ref_address2.current.value,
			city: this.ref_city.current.value,
			state: this.ref_state.current.value,
			save: this.ref_save.current?.checked ?? false,
		};

		if(this.ref_preset.current)
		{
			this.ref_preset.current.value = "";
		}

		this.props.onChange(state);
		this.setState(state);
	}

	use_preset(id: string)
	{
		let preset: ShippingState | undefined;
		let user = this.state.session.user;

		if(!this.ref_name.current ||
			!this.ref_country.current ||
			!this.ref_postcode.current ||
			!this.ref_address1.current ||
			!this.ref_address2.current ||
			!this.ref_city.current ||
			!this.ref_state.current ||
			!this.ref_save.current ||
			!user)
		{
			return;
		}

		if(id === "")
		{
			preset = STATE_EMPTY;
		}

		else
		{
			for(let option of user.addresses)
			{
				if(option.id === id)
				{
					preset = option;
					break;
				}
			}

			if(!preset)
			{
				return;
			}
		}

		this.ref_name.current.value = preset.name;
		this.ref_country.current.value = preset.country;
		this.ref_postcode.current.value = preset.postcode;
		this.ref_address1.current.value = preset.address1;
		this.ref_address2.current.value = preset.address2;
		this.ref_city.current.value = preset.city;
		this.ref_state.current.value = preset.state;
		this.ref_save.current.checked = false;

		this.props.onChange(preset);
		this.setState(preset);
	}

	async onMount()
	{
		if(ShippingInput.countries.length === 0)
		{
			let countries = await Api.Post.Call.countries({});

			if(countries.error)
			{
				this.props.onError(countries.error);
				return;
			}

			if(countries.data)
			{
				ShippingInput.countries = countries.data.countries;
				this.forceUpdate();
			}
		}

		let user = this.state.session.user;

		if(user && user.addresses.length > 0)
		{
			this.use_preset(user.addresses[0].id);
		}
	}

	render_country_opts()
	{
		let it = 0;
		let elements: JSX.Element[] = [
			<option key={it++} value="AU">Australia</option>
		];

		for(let country of ShippingInput.countries)
		{
			elements.push(
				<option key={it++} value={country.code}>
					{country.description}
				</option>
			);
		}

		return elements;
	}

	id1 = GenID();
	id2 = GenID();
	id3 = GenID();
	id4 = GenID();
	id5 = GenID();
	id6 = GenID();
	id7 = GenID();
	id8 = GenID();
	id9 = GenID();

	render_saves()
	{
		let user = this.state.session.user;

		if(!user || user.addresses.length === 0)
		{
			return <></>;
		}

		let it = 0;
		let address_opts: JSX.Element[] = [];

		address_opts.push(
			<option value="">None</option>
		)

		for(let address of user.addresses)
		{
			address_opts.push(
				<option key={it++} value={address.id}>{AddressToString(address)}</option>
			);
		}

		return (
			<FormGroup>
				<Label htmlFor={this.id1}>Saved Addresses</Label>
				<Input
					id={this.id1}
					type="select"
					innerRef={this.ref_preset}
					disabled={this.props.disabled}
					defaultValue={user.addresses[0].id}
					onChange={(e) => this.use_preset(e.target.value)}>
					{address_opts}
				</Input>
			</FormGroup>
		);
	}

	render = () =>
	(
		<div className="component-shipping-input">
			{this.render_saves()}
			<FormGroup>
				<Label htmlFor={this.id2}>Full Name *</Label>
				<Input
					id={this.id2}
					disabled={this.props.disabled}
					innerRef={this.ref_name}
					onChange={() => this.update()}
				/>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={this.id3}>Country *</Label>
				<div>
					<Input
						id={this.id3}
						type="select"
						disabled={this.props.disabled}
						innerRef={this.ref_country}
						style={{
							width: "calc(50% - 4px)",
							display: "inline-block",
						}}
						onChange={() => this.update()}
					>
						{this.render_country_opts()}
					</Input>
					<label htmlFor={this.id4} className="visually-hidden">{this.state.country === "US" ? "Zip Code *" : "Postcode *"}</label>
					<Input
						id={this.id4}
						placeholder={this.state.country === "US" ? "Zip Code *" : "Postcode *"}
						disabled={this.props.disabled}
						innerRef={this.ref_postcode}
						onChange={() => this.update()}
						style={{
							width: "calc(50% - 4px)",
							marginLeft: "8px",
							display: "inline-block",
						}}
					/>
				</div>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={this.id5}>Address 1 *</Label>
				<Input
					id={this.id5}
					disabled={this.props.disabled}
					innerRef={this.ref_address1}
					onChange={() => this.update()}
				/>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={this.id6}>Address 2</Label>
				<Input
					id={this.id6}
					disabled={this.props.disabled}
					innerRef={this.ref_address2}
					onChange={() => this.update()}
				/>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={this.id7}>City *</Label>
				<Input
					id={this.id7}
					disabled={this.props.disabled}
					innerRef={this.ref_city}
					onChange={() => this.update()}
				/>
			</FormGroup>
			<FormGroup>
				<Label htmlFor={this.id8}>State *</Label>
				<Input
					id={this.id8}
					disabled={this.props.disabled}
					innerRef={this.ref_state}
					onChange={() => this.update()}
				/>
			</FormGroup>
			{this.state.session.user ? (
				<FormGroup>
					<Input type="checkbox"
						id={this.id9}
						disabled={this.props.disabled}
						innerRef={this.ref_save}
						onChange={() => this.update()}
					/> <Label htmlFor={this.id9} style={{display: "inline-block"}}>Save my shipping details</Label>
				</FormGroup>
			) : <></>}
		</div>
	)
}
