import { RawDraftContentState, RawDraftContentBlock } from "draft-js";
import {TestShared} from "../Test";
import {Api} from "..";

export const IntParser = (str?: string | null) =>
{
	if(str === null || str === undefined)
	{
		return NaN;
	}

	else
	{
		return parseInt(str);
	}
}

TestShared(() => isNaN(IntParser(null)));
TestShared(() => IntParser("42") === 42);
TestShared(() => IntParser("-53") === -53);

export function MakeImage(v: {image_id: string, image_name: string}): Api.Files.ImageType {
	return {file_id: v.image_id, file_name: v.image_name};
}

export const HexParser = (hex?: string | null) =>
{
	if(hex === null || hex === undefined)
	{
		throw "buffer undefined";
	}

	else
	{
		return Buffer.from(hex, "hex");
	}
}

export function SwapToUD<T>(v: T)
{
	if(v === null)
	{
		return undefined;
	}

	else
	{
		return v as Exclude<T, null>;
	}
}

export function SwapToNull<T>(v: T)
{
	if(v === undefined)
	{
		return null;
	}

	else
	{
		return v as Exclude<T, undefined>;
	}
}

export const StripPathname = (pathname: string) => {
	let len = pathname.length;
	while(pathname[len - 1] === "/") {
		len--;
	}
	return pathname.substring(0, len);
}

TestShared(() => StripPathname("/hello/world//") === "/hello/world");
TestShared(() => StripPathname("/hello/world") === "/hello/world");

export const RemoveDraftJSWhitespaces = (state: RawDraftContentState) =>
{
	let blocks: RawDraftContentBlock[] = [];

	let found = false;

	for(let block of state.blocks)
	{
		if(!found) {
			if(block.text !== "") {
				found = true;
				blocks.push(block);
			}
		}

		else {
			blocks.push(block);
		}
	}

	let blocks_flipped = blocks.reverse();
	blocks = [];
	found = false;

	for(let block of blocks_flipped)
	{
		if(!found) {
			if(block.text !== "") {
				found = true;
				blocks.push(block);
			}
		}

		else {
			blocks.push(block);
		}
	}

	return <RawDraftContentState> {
		blocks: blocks.reverse(),
		entityMap: state.entityMap,
	}
}

export const GetDay = (now: number | Date = Date.now()) =>
{
	if(now instanceof Date)
	{
		now = now.getTime();
	}

	return Math.floor(now / (1000 * 60 * 60 * 24));
}

export const AddressToString = (address: Api.Post.AddressType) =>
{
	let str = `${address.name}: ${address.address1}`;

	if(address.address2)
	{
		str += `, ${address.address2}`;
	}

	return `${str}, ${address.city}, ${address.state}, ${address.postcode} ${address.country}`;
}

export function QueryString(v: ConstructorParameters<typeof URLSearchParams>[0]) {
	return (new URLSearchParams(v)).toString();
}

export const MONTHS = [
	"January", "February", "March", "April",
	"May", "June", "July", "August",
	"September", "October", "November", "December",
];

export class AsyncLock<T>
{
	callback?: (v: T) => void;
	queue: T[] = [];

	wait()
	{
		return new Promise<T>((res) =>
		{
			let v = this.queue.pop();

			if(v)
			{
				res(v);
			}

			else
			{
				this.callback = res;
			}
		});
	}

	free(v: T)
	{
		if(this.callback !== undefined)
		{
			let f = this.callback;
			this.callback = undefined;
			f(v);
		}

		else
		{
			this.queue.unshift(v);
		}
	}
}

export const TextRawifyToArray = (data: any) => {
	let text = []; 
	for(let block of data?.blocks ?? []) {
		if(block?.text) {
			text.push(block.text)
		}
	}
	return text;
}

export const TextRawify = (data: any) => {
	return TextRawifyToArray(data).join(" ");
}

export const RenderCurrency = (amount: number, start="$", end=" AUD") =>
{
	if(amount < 0)
	{
		return `-${start}${(-amount / 100).toFixed(2)}${end}`;
	}

	else
	{
		return `${start}${(amount / 100).toFixed(2)}${end}`;
	}
}

TestShared(() => RenderCurrency(420) === "$4.20 AUD");

export function MakeProductTemplate(p: Api.Product.ProductType) {
	if(!p.template || !p.components) return undefined;
	let product: Api.Practitioner.Product.TemplateType = {
		...p,
		template: p.template,
		components: p.components,
		sizes: [],
	};
	for(let item of p.options) {
		product.sizes.push(item.sizing!);
	}
	return product;
}

