
import {Button, Col, Container, Input} from "reactstrap";
import {useEffect, useId, useState} from "react";
import {App} from "../App";
import qrcode from "qrcode";
import {Api} from "../../shared";
import {Component} from "../..";
import {Practitioner} from ".";

export const GenAddRequest = () =>
{
	const [url, setUrl] = useState<{base: string, qr: string}>();

	const GenURL = async () =>
	{
		let res = await Api.Practitioner.User.Call.genAddRequest({});

		if(!res.data)
		{
			App.open(null);
			console.error(res.error);
			return;
		}

		let url = window.location.origin + "/profile/add-practitioner?" + new URLSearchParams({
			id: res.data.request_id,
		}).toString();

		setUrl({
			base: url,
			qr: await qrcode.toDataURL(url),
		});
	}

	useEffect(() => {GenURL()}, []);

	if(!url) return <></>;

	return (<>
		<Container>
			<Col><h4>Add Client</h4></Col>
			<Col>
				<p>
					To add your client, please share this link with them so you can be added as their practitioner.
					If they are unable to do this, Goulds staff can do this for you. 
				</p>
				<p>
					Please note that this link will expire in 30 days or until it is used by a client, whichever
					event happens first. 
				</p>
				<p><a href={url.base} target="_blank">{url.base}</a></p>
			</Col>
			<Col><img src={url.qr} alt="QR Code" /></Col>
			<Col className="spacer"><Button onClick={() => App.open(null)}>Ok</Button></Col>
		</Container>
	</>);
}

export const Clients = () =>
{
	const [clients, setClients] = useState<Api.Auth.UserBasicType[]>([]);
	const [filter, setFilter] = useState("");

	async function getClients()
	{
		let res = await Api.Practitioner.User.Call.getClients({});
	
		if(res.data)
		{
			setClients(res.data.clients);
		}
	}

	async function removeClient(user: Api.Auth.UserBasicType)
	{
		let state = await App.openDialogue({
			title: "Remove Client",
			content: () => (<>
				<Col><p>
					Are you sure you want to remove this client?
				</p></Col>
				<Col><Component.UserCard user={user} /></Col>
			</>),
			prompt: true,
			type: "yesno",
		});

		if(!state)
		{
			return;
		}

		let res = await Api.Practitioner.User.Call.removeClient({email: user.email});

		if(res.data)
		{
			setClients(res.data.clients);
		}
	}

	function addClient() {
		App.open(() => <GenAddRequest />);
	}

	function goToClient(user: Api.Auth.UserBasicType) {
		App.routeToUrl("/practitioner/client?" + new URLSearchParams({email: user.email}).toString());
	}

	useEffect(() => {getClients()}, []);

	const search_id = useId();

	let e_clients: JSX.Element[] = [];
	let filter_terms = filter.toLowerCase().split(" ");

	for(let found of clients)
	{
		if(filter.length > 0)
		{
			let l_email = found.email.toLowerCase();
			let l_name = found.name.toLowerCase();
			let valid = true;

			for(let term of filter_terms)
			{
				if(!(l_email.includes(term) || l_name.includes(term)))
				{
					valid = false;
					break;
				}
			}

			if(!valid)
			{
				continue;
			}
		}

		e_clients.push(
			<Component.UserCard key={found.email} user={found} onClick={() => goToClient(found)}>
				<button className="right-x-button" onClick={(e) => {
					e.stopPropagation();
					removeClient(found);
				}}>X</button>
			</Component.UserCard>
		);
	}

	return (
		<Practitioner>
			<Container>
				<h4>My Clients</h4>
				<div className="side-flex">
					<label htmlFor={search_id} className="visually-hidden">Filter clients</label> 
					<Input
						id={search_id}
						type="search"
						placeholder="Filter"
						onChange={(e) => setFilter(e.target.value)}
					/>
					<Button onClick={addClient}>Add</Button>
				</div>
				{e_clients}
			</Container>
		</Practitioner>
	);
};

