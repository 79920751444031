import React from "react";
import { Button, Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { App } from "../../app/App";
import { SessionReload } from "../../app/AppHelpers";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { UserHasCapability, UserCapability } from "../../shared/User";
import "./style.scss";
import { GenID, ReactComponent } from "../../scripts/util";
import {AddMFA} from "../../app/profile/AddMFA";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";

export const Notice_GetCookiePreferences = async () =>
{
	let prefs = new Set<Api.Auth.CookiePrefs>(GlobalVariables.Session.value.cookies);

	if(!prefs.has("SET"))
	{
		prefs.clear();
	}

	const toggle = (type: Api.Auth.CookiePrefs) =>
	{
		return (e: React.ChangeEvent<HTMLInputElement>) =>
		{
			let v = e.target.checked;

			if(v)
			{
				prefs.add(type);
			}

			else
			{
				prefs.delete(type)
			}
		}
	}

	const id1 = GenID();
	const id2 = GenID();
	const id3 = GenID();
	const id4 = GenID();
	const id5 = GenID();

	await App.openDialogue({
		title: "Your Cookie Preferences",
		content: () => (
			<>
				<Col className="spacer">
					<h5>Strictly Necessary Cookies</h5>
					<p>
						These cookies are non-tracking. They are used to remember your
						cookie preferences. Your consent on this browser will be
						remembered on this browser for up to 1 year. 
					</p>
				</Col>
				<Col className="spacer">
					<h5>Functional Cookies</h5>
					<p>
						These cookies are tracking. They are used by our databases to
						provide functionality. They remember what user account you are
						logged in to, and the items you have stored in your cart. 
						These cookies are stored on your device for up to 1 year.
					</p>
					<p>
						For user accounts, you may at any time request to collect or
						delete all of the data we have collected and linked to you. 
						Once you have made the request, the data will be deleted after 7 days.
					</p>
					<FormGroup>
						<Input id={id1} type="checkbox" defaultChecked={prefs.has("SESSION")} onChange={toggle("SESSION")}
						/> <Label htmlFor={id1}>Enable user session cookies</Label>
					</FormGroup>
					<FormGroup>
						<Input id={id2} type="checkbox" defaultChecked={prefs.has("CART")} onChange={toggle("CART")}
						/> <Label htmlFor={id2}>Enable cart cookies</Label>
					</FormGroup>
					<FormGroup>
						<Input id={id5} type="checkbox" defaultChecked={prefs.has("REMEMBER_OTP")} onChange={toggle("REMEMBER_OTP")}
						/> <Label htmlFor={id5}>Enable multi-factor authentication cookies</Label>
					</FormGroup>
				</Col>
				<Col className="spacer">
					<h5>Stripe Services and Cookies</h5>
					<p>
						These services and cookies are maintained by Stripe. We use Stripe for our
						payment processing, so Stripe is required for using your card
						at the checkout. Other payment options such as saved cards,
						phone, and bank transfer will all be functional without Stripe. 
					</p>
					<FormGroup>
						<Input id={id3} type="checkbox" defaultChecked={prefs.has("STRIPE")} onChange={toggle("STRIPE")}
						/> <Label htmlFor={id3}>Enable Stripe</Label>
					</FormGroup>
				</Col>
				<Col className="spacer">
					<h5>Google Services and Cookies</h5>
					<p>
						These services and cookies are maintained by Google. We use
						Google ReCAPTCHA to help protect our servers against spam. 
					</p>
					<FormGroup>
						<Input id={id4} type="checkbox" defaultChecked={prefs.has("GOOGLE")} onChange={toggle("GOOGLE")}
						/> <Label htmlFor={id4}>Enable Google</Label>
					</FormGroup>
				</Col>
			</>
		)
	});

	await Api.Auth.Call.cookieSettings({
		prefs: Array.from(prefs)
	});

	await SessionReload();
}

export interface NoticeCard
{
	title: string;
	content: () => JSX.Element;
	click?: () => any;
}

interface IProps
{

}

interface IState
{
	notices: NoticeCard[];
	session: Api.Auth.SessionType;
}

export class NoticeList extends ReactComponent<IProps, IState>
{
	loaded = false;
	mfa_later = false;
	asking_mfa = false;
	asking_undo_deletion = false;

	constructor(props: IProps)
	{
		super(props);

		this.state = {
			notices: [],
			session: GlobalVariables.Session.regSet(this, "session"),
		}
	}

	ask_for_cookies()
	{
		let notice = this.addNotice({
			title: "Cookie Notice",
			content: () => (
				<>
					<p>
						This website uses cookies.
					</p>
					<Button onClick={async () =>
					{
						notice.remove();
						await Api.Auth.Call.cookieSettings({
							prefs: ["ALL"]
						});
						await SessionReload();
	
					}}>Enable all</Button>
					<Button onClick={async () =>
					{
						notice.remove();
						await Notice_GetCookiePreferences();
	
					}}>Set my preferences</Button>
				</>
			),
		});
	}

	async ask_for_mfa()
	{
		if(this.asking_mfa)
		{
			return;
		}

		let lock = new Helpers.Data.AsyncLock();
		this.asking_mfa = true;

		const go = () =>
		{
			let user = this.state.session.user;

			if(!user)
			{
				return;
			}

			App.open(() => <AddMFA callback={(v) =>
			{
				user = this.state.session.user;

				if(!user || user.has_mfa || !UserHasCapability(user.permission, UserCapability.STAFF))
				{
					lock.free(0);
				}
			}} />);
		}

		let notice = this.addNotice({
			title: "Multi-Factor Authentication",
			content: () => (
				<>
					<p>
						Please enable multi-factor authentication.
					</p>
					<Button onClick={go}>Go</Button>
					<Button onClick={async () =>
					{
						lock.free(0);
						this.mfa_later = true;
	
					}}>Later</Button>
				</>
			),
		});

		await lock.wait();
		notice.remove();
		this.asking_mfa = false;
	}

	async ask_for_undo_deletion()
	{
		let user = this.state.session.user;

		if(this.asking_undo_deletion || !user || !user.deletion_date)
		{
			return;
		}

		let lock = new Helpers.Data.AsyncLock();
		let date = new Date(user.deletion_date);

		this.asking_undo_deletion = true;

		let notice = this.addNotice({
			title: "Scheduled Account Deletion",
			content: () => (
				<>
					<p>
						Your account is scheduled for deletion on {date.toLocaleString()}.
						After this date your account will be <b>permenantly deleted</b>.
						If you've changed your mind, feel free to unschedule. 
					</p>
					<Button onClick={async () =>
					{
						await Api.Auth.Call.deleteAccount({
							undo: true,
						});
						await SessionReload();
						lock.free(0);

					}}>Undo</Button>
				</>
			),
		});

		await lock.wait();
		notice.remove();
		this.asking_undo_deletion = false;
	}

	async onMount()
	{
		if(this.loaded)
		{
			return;
		}

		this.loaded = true;

		if(!this.state.session.cookies.includes("SET"))
		{
			await Helpers.Time.Sleep(2000);

			this.ask_for_cookies();
		}

		while(this.mounted)
		{
			await Helpers.Time.Sleep(5000);

			let user = this.state.session.user;

			if(user)
			{
				if(!user.has_mfa && !this.mfa_later && UserHasCapability(user.permission, UserCapability.STAFF))
				{
					this.ask_for_mfa();
				}
				
				if(user.deletion_date !== null)
				{
					this.ask_for_undo_deletion();
				}
			}
		}
	}

	addNotice(card: NoticeCard)
	{
		let index = this.state.notices.length;

		this.state.notices.push(card);
		this.setState({
			notices: this.state.notices,
		});

		return {
			remove: () =>
			{
				this.state.notices.splice(index, 1);
				this.setState({
					notices: this.state.notices,
				});
			}
		}
	}

	id1 = GenID();

	render()
	{
		let elements: JSX.Element[] = [];
		let it = 0;

		for(let card of this.state.notices)
		{
			let id = `${this.id1}_${it++}`;

			elements.push(
				<div aria-labelledby={id} aria-modal="false" role="dialogue" className="component-notice-card" key={card.title}>
					<h5 id={id}>{card.title}</h5>
					{card.content()}
				</div>
			);
		}

		if(elements.length === 0)
		{
			return <></>;
		}

		return (
			<>
				<div className="component-notice">
					<div className="component-notice-list">
						<Component.Skipper label="Notices">
							{elements}
						</Component.Skipper>
					</div>
				</div>
			</>
		);
	}
}
