type TestCallback = () => boolean | Promise<boolean>;

export type TestData<CB> = {
	cb: CB,
	if_fail: Error,
	pri: number;
};

let tests: TestData<TestCallback>[] = [];
let doSharedTests = false;

export const SetSharedTestsEnabled = () =>
{
	doSharedTests = true;
}

export const TestShared = (cb: TestCallback, pri: number | number[] = [0]) =>
{
	if(doSharedTests)
	{
		let e = new Error();

		if(!Array.isArray(pri)) {
			pri = [pri];
		}

		for(let p of pri) {
			tests.push({
				cb: cb,
				if_fail: e,
				pri: p,
			});
		}
	}
}

export const GetSharedTests = () =>
{
	return tests;
}

