import {GetType, PostType} from "..";
import {SQLEnum} from "../../SQLEnum";
import * as CallType from "../CallType";
import {OrderBasicType} from "./Order";

export interface UserProductType
{
	uses: number;
	uses_max: number;
	expiry: number;
	name: string;
	amount: number;
	image_name: string;
	image_id: string;
	visibility: SQLEnum.ProductVisibility;
	brand: string;
	category: string;
	id: string;
}

export interface UserType
{
	name: string;
	email: string;
	phone: string;
	permission: SQLEnum.UserPermission;
	permission_opts: SQLEnum.UserPermission[];
	products: UserProductType[];
	created: number;
	email_history: {
		email: string;
		when: number;
	}[];
	discount?: {
		name: string;
		amount: number;
		expiry?: number | null;
	};
	mod_log?: {
		created: number;
		content: string;
	}[];
	practitioners: UserBasicType[];
	clients: UserBasicType[];
	has_mfa: boolean;
	is_confirmed: boolean;
	orders: OrderBasicType[];
}

export interface UserBasicType {
	name: string;
	email: string;
	permission: SQLEnum.UserPermission;
}

export interface Search extends PostType {
	Query: {
		q: string;
		after?: string;
	}

	Res: {
		users: UserBasicType[];
	}
}

export interface Get extends GetType<"user not found"> {
	Query: {
		email: string;
	}

	Res: {
		user: UserType;
	}
}

export interface Confirm extends PostType<"user not found"> {
	Body: {
		email: string;
	}
}

export interface GetScriptUsers extends GetType {
	Query: {
		product_id: string;
	}

	Res: {
		users: UserBasicType[];
	}
}

export interface SetDiscount extends PostType<"user not found" | "discount not found" | "user is staff"> {
	Body: {
		name: string;
		email: string;
		value: number;
		expires?: number;
	}
}

export interface ForceLogin extends PostType<"user not found"> {
	Body: {
		email: string;
	}
}

export interface Merge extends PostType<"user not found"> {
	Body: {
		from: string;
		to: string;
	}
}

export interface Perms extends PostType<"user not found" | "same target and host" | "unauthorised"> {
	Body: {
		email: string;
		perm: string;
	};
};

export const Call = {
	search: CallType.Get<Search>("/staff/user/search"),
	confirm: CallType.Post<Confirm>("/staff/user/confirm"),
	get: CallType.Get<Get>("/staff/user/get"),
	setDiscount: CallType.Post<SetDiscount>("/staff/user/setDiscount"),
	forceLogin: CallType.Post<ForceLogin>("/staff/user/forceLogin"),
	merge: CallType.Post<Merge>("/staff/user/merge"),
	getScriptUsers: CallType.Get<GetScriptUsers>("/staff/user/getScriptUsers"),
	perms: CallType.Post<Perms>("/staff/user/perms"),
};

