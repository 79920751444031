import {Button, Container, FormGroup, FormText, Label} from "reactstrap";
import {Api} from "../../../shared";
import {TemplatePicker} from "../components/TemplatePicker";
import {useState} from "react";
import {App} from "../../App";

export function Edit(props: {group: Api.Staff.Group.Type, reload: () => unknown})
{
	const [templates, setTemplates] = useState<(string | undefined)[]>(props.group.templates);
	const [show_errors, setShowErrors] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [errors, setErrors] = useState("");

	async function update() {
		setShowErrors(true);
		for(let item of templates) {
			if(!item) {
				setErrors("Invalid templates");
				return;
			}
		}
		setDisabled(true);
		let res = await Api.Staff.Group.Call.set({
			name: props.group.name,
			templates: templates as string[],
		});
		setDisabled(false);
		if(res.error) {
			throw res.error;
		}
		await props.reload();
		App.open(null);
	}

	return (
		<Container>
			<h4>Edit Group {props.group.name}</h4>
			<FormGroup>
				<Label>Templates</Label>
				<TemplatePicker showErrors={show_errors} value={props.group.templates} onChange={setTemplates} />
			</FormGroup>
			<div><FormText color="danger">{show_errors ? errors : ""}</FormText></div>
			<FormGroup>
				<Button onClick={() => App.open(null)}>Back</Button>
				<Button onClick={update} disabled={disabled}>Save</Button>
			</FormGroup>
		</Container>
	);
}

