import React from "react";
import { Container, Col } from "reactstrap";
import { App, AppHistory } from "../App";
import {MakeRoute, Route} from "../Routes";
import {Item} from "./Item";
import {Api} from "../../shared";
import {Component} from "../..";

class Store extends React.Component
{
	render()
	{
		return (
			<div className="page-store">

				<Container>

					<Col><h4>Store</h4></Col>

					<Component.Store
						onClick={(product) =>
						{
							App.routeToUrl("/store/item?id=" + product.id);
						}}
						getDefaultSearch={() =>
						{
							let url = new URL(window.location.href);
							let terms = url.searchParams.get("q");
							
							return terms ? terms : "";
						}}
						onSearch={(terms) =>
						{
							let query = new URLSearchParams();

							if(terms.length > 0)
							{
								query.set("q", terms);
							}

							AppHistory.push("?" + query.toString());
						}}
					/>

				</Container>

				<Component.Padding type="bottom" />

			</div>
		);
	}
}

export const StoreRoute = () => MakeRoute(Store, undefined, {
	"item": MakeRoute(Item),
});

