import {SQLEnum} from "../SQLEnum";

export function Equal(a: string, b: string, ignoreCase = false)
{
	if(ignoreCase) {
		a = a.toLowerCase();
		b = b.toLowerCase();
	}

	return a === b;
}

export function CapitalizeFirst(item: string)
{
	return item.length > 0 ? item[0].toUpperCase() + item.substring(1).toLowerCase() : "";
}


export function FormatAmount(q: SQLEnum.QuantityType | undefined, v: number) {
	switch(q) {
	case "G":
		return `${v} g`;
	case "ML":
		return `${v} mL`;
	default:
		return `${v}`;
	}
}

export function RemoveLeading(item: string, find: string = '0')
{
	let len = item.length;

	while(item[len - 1] === find)
	{
		len--;
	}

	return item.substring(0, len);
}

export function HasTerms(name: string, terms: string)
{
	name = name.toLowerCase();

	for(let term of terms.toLowerCase().split(" ")) {
		if(!name.includes(term)) {
			return false;
		}
	}

	return true;
}


