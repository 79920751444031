
import {Button, Container} from "reactstrap";
import {GlobalVariables} from "../../../scripts/GlobalVariables";
import {Permissions} from "./Permissions";
import {RenderOrders} from "./Orders";
import {Discounts} from "./Discounts";
import {Products} from "./Products";
import {RenderModLog} from "./ModLog";
import {RenderEmailHistory} from "./EmailHistory";
import {RenderUserManagement} from "./UserManagement";
import {ReactComponent} from "../../../scripts/util";
import {Api} from "../../../shared";
import {Page} from "../../..";
import {List} from "./List";
import {App} from "../../App";
import {ConfirmUser} from "./ConfirmUser";

interface IProps {

}

interface IState {
	email?: string;
	loaded: boolean;
	user?: Api.Staff.User.UserType;
	session: Api.Auth.SessionType;
}

export class User extends ReactComponent<IProps, IState>
{
	discountElement!: HTMLInputElement | HTMLTextAreaElement;

	constructor(props: IProps) {
		super(props);

		this.state = {
			loaded: false,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	async onMount()
	{
		let query = new URL(window.location.href);
		let email = query.searchParams.get("email");

		if(!email) {
			this.setState({
				loaded: true,
			});
			return;
		}

		await this.update(email);
	}

	async update(email = this.state.email)
	{
		if(!email)
		{
			return;
		}

		let request = await Api.Staff.User.Call.get({
			email: email,
		});

		if(!request.data) {
			this.setState({
				loaded: true,
			});
			throw request.error;
		}

		this.setState({
			loaded: true,
			email: email,
			user: request.data.user,
		});
	}

	render() {

		if(!this.state.loaded) {
			return <></>;
		}

		if(!this.state.session.user || !this.state.user) {
			return <></>;
		}

		let user = this.state.user;
		const update = this.update.bind(this);

		return (
			<Page.Staff>
				<Container>
					{(this.state.user && user) ? (
						<>
							<h4>{this.state.user.name}'s account</h4>
							<div className="spacer">
								<h5>Email</h5>
								<p>{user.email}</p>
							</div>
							<div className="spacer">
								<h5>Phone Number</h5>
								<p>{user.phone}</p>
							</div>
							<div className="spacer">
								<h5>Verification Status</h5>
								<p>Status: {this.state.user.is_confirmed ? "Confirmed" : "Unconfirmed"}</p>
								{this.state.user.is_confirmed ? <></> :
									<Button onClick={App.open.bind(null, () => <ConfirmUser update={update} user={this.state.user!} />, false)}>Confirm</Button>
								}
							</div>
							<div className="spacer">
								<h5>Multi-Factor Authentication</h5>
								<p>Status: {this.state.user.has_mfa ? "Linked" : "Not Linked"}</p>
							</div>
							<Permissions page={this} user={user} />
							<Discounts page={this} user={user} />
							<Products page={this} user={user} />
							{RenderUserManagement(this, user)}
							{RenderEmailHistory(this)}
							{RenderOrders(this)}
							<List update={update} title="Clients" items={user.clients} />
							<List update={update} title="Practitioners" items={user.practitioners} />
							{RenderModLog(this)}
						</>
					) : (
						<>
							<h4>Invalid User</h4>
							<p>
								The user {
									this.state.email ?
									(
										"with the email " +
										this.state.email
									) : "you specified"
								} was not found on this server.
							</p>
						</>
					)}
				</Container>
			</Page.Staff>
		);
	}
}
