
import "./style.scss";
import IconImage from "./icon-image.svg";
import React, { useId } from "react";
import { RichUtils, EditorState } from "draft-js";
import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import * as Buttons from '@draft-js-plugins/buttons';
import djsCreateImagePlugin from '@draft-js-plugins/image';
import djsCreateFocusPlugin from '@draft-js-plugins/focus';
import djsCreateResizeablePlugin from '@draft-js-plugins/resizeable';
import djsCreateDragnDropPlugin from '@draft-js-plugins/drag-n-drop';
import djsCreateLinkifyPlugin from '@draft-js-plugins/linkify';
import djsStaticToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import { GenID } from "../../scripts/util";
import {Api} from "../../shared";
import {ImageSelector} from "../image-selector";

let DragnDropPlugin = djsCreateDragnDropPlugin();
let FocusPlugin = djsCreateFocusPlugin();
let ResizeablePlugin = djsCreateResizeablePlugin();
let LinkifyPlugin = djsCreateLinkifyPlugin();
let StaticToolbarPlugin = djsStaticToolbarPlugin();

const { Toolbar } = StaticToolbarPlugin;

const decorator = composeDecorators(
	ResizeablePlugin.decorator,
	FocusPlugin.decorator,
	DragnDropPlugin.decorator,
);

let ImagePlugin = djsCreateImagePlugin({decorator});

export const TextEditorPlugins = [
	ResizeablePlugin,
	DragnDropPlugin,
	FocusPlugin,
	ImagePlugin,
	LinkifyPlugin,
	StaticToolbarPlugin,
];

interface IProps {
	onChange?: (state: EditorState) => void;
	editorState: EditorState;
}

interface IState {
	editorState: EditorState;
	imageDialogueOpen: boolean;
}

export class TextEditor extends React.Component<IProps, IState>
{
	aria_textarea = GenID();

	constructor(props: IProps) {
		super(props);

		this.state = {
			editorState: props.editorState ? props.editorState : EditorState.createEmpty(),
			imageDialogueOpen: false,
		};
	}

	onChange(state: EditorState) {
		if(this.props.onChange) {
			this.props.onChange(state);
		}
	}

	handleState(state: EditorState | null)
	{
		if(state)
		{
			if(this.props.onChange) {
				this.props.onChange(state);
			}

			this.setState({
				editorState: state,
			});

			return true;
		}

		return false;
	}

	render()
	{
		let toolbar = (p: Buttons.DraftJsStyleButtonProps) =>
		{
			return (
				<div>
					<Buttons.BoldButton {...p} />
					<Buttons.ItalicButton {...p} />
					<Buttons.UnderlineButton {...p} />

					<Separator />

					<div className={p.theme.buttonWrapper}>
						<button
							className={p.theme.button}
							onClick={() => {
								this.handleState(
									RichUtils.toggleBlockType(this.state.editorState, "header-four")
								);
							}}
						>H4</button>
					</div>
					<div className={p.theme.buttonWrapper}>
						<button
							className={p.theme.button}
							onClick={() => {
								this.handleState(
									RichUtils.toggleBlockType(this.state.editorState, "header-five")
								);
							}}
						>H5</button>
					</div>

					<Separator />

					<Buttons.BlockquoteButton {...p} />
					<Buttons.CodeButton {...p} />

					<Separator />

					<Buttons.OrderedListButton {...p} />
					<Buttons.UnorderedListButton {...p} />
					
					<Separator />

					<div className={p.theme.buttonWrapper}>
						<button
							className={p.theme.button}
							onClick={() => {
								this.setState({
									imageDialogueOpen: true,
								});
							}}
						>
							<img
								src={IconImage}
								style={{
									height: "24px",
									opacity: 0.5,
								}}

							/>
						</button>
					</div>
				</div>
			)
		}

		return (
			<>
				{this.state.imageDialogueOpen ? (
					<ImageSelector
						closeCallback={(url) =>
						{
							this.setState({
								imageDialogueOpen: false,
							});

							if(!url) {
								return;
							}

							this.handleState(ImagePlugin.addImage(this.state.editorState, Api.Files.Image(url.file_id, url.file_name), {}));
						}}
					/>
				) : <></>}

				<div aria-hidden="true">
					<Toolbar>{toolbar}</Toolbar>
				</div>

				<label className="visually-hidden" id={this.aria_textarea}>Product description</label>

				<Editor

					ariaLabelledBy={this.aria_textarea}
					spellCheck={true}
					editorState={this.state.editorState}
					tabIndex={0}

					onChange={((editorState) =>
					{
						this.setState({
							editorState: editorState,
						});

						this.onChange(editorState);

						setImmediate(() => {
							this.forceUpdate();
						});
					})}

					handleKeyCommand={(command, state) =>
					{
						if(this.handleState(RichUtils.handleKeyCommand(state, command))) {
							return "handled"
						}

						return "not-handled";
					}}

					plugins={TextEditorPlugins}

				/>
			</>
		)
	}
}

export class TextEditorContainer extends React.Component<IProps>
{
	render() {
		return (
			<div className="component-text-editor">

				<TextEditor {...this.props} />

			</div>
		)
	}
}
