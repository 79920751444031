
export function OptionList<T extends string | number>(props: {
	getDisplayName?: (v: T) => string,
	opts: T[],
}) {
	let items: JSX.Element[] = [];

	for(let item of props.opts) {
		items.push(<option value={item} key={item}>{props.getDisplayName ? props.getDisplayName(item) : item}</option>);
	}

	return <>{items}</>;
}

