import {Button, Input} from "reactstrap";
import {Component} from "../../..";
import {Api, Helpers} from "../../../shared";
import {useId, useState} from "react";
import {ViewCard} from "./ViewCard";

export function Find(props: {
	templates?: Api.Staff.Template.Type[],
	onClick: (v: Api.Staff.Template.Type) => any,
	shouldDisplay?: (v: Api.Staff.Template.Type) => boolean,
	onAdd?: () => any,
}) {
	const [filter, setFilter] = useState("");
	const filterId = useId();
	
	let templates_e: JSX.Element[] = [];

	for(let template of props.templates ?? [])
	{
		if(!Helpers.String.HasTerms(template.name, filter)) {
			continue;
		}

		if(props.shouldDisplay && !props.shouldDisplay(template)) {
			continue;
		}

		templates_e.push(<ViewCard template={template} key={template.name} click={() => props.onClick(template)} />);
	}

	return (<>
		<div className="side-flex">
			<label htmlFor={filterId} className="visually-hidden">Filter templates by name</label>
			<Input id={filterId} placeholder="Filter" onChange={(e) => setFilter(e.target.value)} />
			{props.onAdd ? <Button onClick={props.onAdd}>
				Add
			</Button> : <></>}
		</div>
		<Component.Skipper>
			{templates_e}
		</Component.Skipper>
	</>);
}

