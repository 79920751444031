
import "./style.scss";
import React, { createRef } from "react";
import { Container, Col } from "reactstrap";
import { SessionReload } from "../AppHelpers";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { App } from "../App";
import { ShippingState } from "../../shared/Shipping";
import { Stripe } from "@stripe/stripe-js";
import { CartList } from "./items/CartList";
import { Checkout } from "./items/Checkout";
import { Summary } from "./items/Summary";
import { ReactComponent } from "../../scripts/util";
import {MakeRoute} from "../Routes";
import {Api} from "../../shared";
import {Component, Page} from "../..";

interface IProps {

}

export interface IState {
	order?: Api.Post.OrderType;
	cart?: Api.Cart.ProductCartType[];
	progress: number;
	buttonDisabled: boolean;
	shipping?: ShippingState;
	shippingMode: number;
	shippingService?: string;
	shippingServices?: Api.Post.CalcPostageReturn;
	auspostDown: boolean;
	postageCost?: number;
	showPostageErrors: boolean;
	payMode?: string;
	email?: string;
	phone?: string;
	name?: string;
	stripeElement: React.RefObject<Component.StripePayment>;
	session: Api.Auth.SessionType;
	hasCustomProducts?: boolean;
}

export interface CartRefProps
{
	state: Page.Cart["state"];
	setState: Page.Cart["setState"];
	calculateShipping: Page.Cart["calculateShipping"];
	handlePurchase: Page.Cart["handlePurchase"];
}

export class Cart extends ReactComponent<IProps, IState>
{
	shippingLastPostCode = "";
	shippingLastCountry = "AU";

	constructor(props: IProps) {
		super(props);

		this.state = {
			progress: 0,
			shippingMode: 0,
			showPostageErrors: true,
			buttonDisabled: false,
			auspostDown: false,
			stripeElement: createRef<Component.StripePayment>(),
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	async onMount()
	{
		let response = await Api.Cart.Call.get({});
		await SessionReload();

		if(!response.data) {
			throw "cannot get cart";
		}

		let hasCustomProducts = false;

		for(let item of response.data.cart)
		{
			if(item.is_custom)
			{
				hasCustomProducts = true;
				break;
			}
		}

		this.setState({
			cart: response.data.cart,
			hasCustomProducts: hasCustomProducts,
		});
	}

	async handlePurchase(response: Awaited<ReturnType<typeof Api.Post.Call.payOrder>>, saveCard: boolean, stripe?: Stripe): Promise<boolean>
	{
		const error_cb = async (fn: () => JSX.Element) => {
			await App.openDialogue({
				title: "Order Error",
				content: fn,
			});
			return true;
		}

		if(!this.state.order) {
			return false;
		}

		if(response.data) {
			if(response.error === "order already paid") {
				await error_cb(() => <p>This order has already been paid.</p>);
				return true;
			}

			if(response.error === "order not found") {
				await error_cb(() => <p>This order could not be found.</p>);
				return true;
			}

			if(response.error === "payment error") {
				await error_cb(() => <p>{response.data?.error_message ?? "An unknown error was encountered while processing your payment."}</p>);
				return false;
			}

			if(response.data.status === "requires_action" && response.data.secret && stripe) {
				let handleCardAction = await stripe.handleCardAction(response.data.secret);

				if(!handleCardAction.paymentIntent) {
					await error_cb(() => <p>An unknown error was encountered while processing your payment.</p>);
					return false;
				}

				let newResponse = await Api.Post.Call.payOrder({
					paymentIntent: handleCardAction.paymentIntent.id,
					id: this.state.order.id,
					saveCard: saveCard,
				});

				return await this.handlePurchase(newResponse, saveCard);
			}

			else if(response.data.status) {
				const status = response.data.status;
				await error_cb(() => <>
					<p>An unexpected error was encountered while processing your payment.</p>
					<p>Error: {status}</p>
				</>);
				return false;
			}
		}

		await App.openDialogue({
			title: "Order Status",
			content: () => {
				return (
					<p>
						Thank you for your purchase
						with Goulds Natural Medicine.
						A receipt has been sent to your
						inbox at <a href={`mailto:${this.state.email}`}>{this.state.email}</a>.
					</p>
				);
			},
		});

		return true;
	}

	async calculateShipping(state: ShippingState) {
		this.setState({
			shipping: state,
		})

		if(!this.state.cart) {
			return;
		}

		if(
			state.country === this.shippingLastCountry &&
			(state.country !== "AU" || state.postcode === this.shippingLastPostCode)
		) {
			return;
		}

		this.shippingLastCountry = state.country;
		this.shippingLastPostCode = state.postcode;
		let weight = 0;

		for(let item of this.state.cart) {
			if(item.count > 0) {
				weight += item.weight * item.count;
			}
		}

		this.setState({
			postageCost: undefined,
			showPostageErrors: false,
		});

		if(state.country === "AU") {
			if(state.postcode.length !== 4) {
				return;
			}
	
			for(let digit of state.postcode) {
				if(!"1234567890".includes(digit)) {
					return;
				}
			}
		}

		let response = await Api.Post.Call.calc({
			country_code: state.country,
			post_code: state.postcode,
			weight: weight,
		});

		if(!response.data) {
			return;
		}

		let shippingServiceKeys = Object.keys(response.data.calc);
		let shippingService = (
			(
				this.state.shippingService !== undefined &&
				response.data.calc[this.state.shippingService]
			) ? this.state.shippingService : (
				shippingServiceKeys.length > 0
			) ? shippingServiceKeys[0] : undefined
		);
		
		let shippingServiceObj = shippingService ? response.data.calc[shippingService] : undefined;

		this.setState({
			shippingService: shippingService,
			shippingServices: response.data.calc,
			showPostageErrors: true,
			postageCost: (
				shippingServiceObj ?
				shippingServiceObj.cost : (
					response.data.calc.express ? (
						response.data.calc.express.cost
					) :
					response.data.calc.standard ? (
						response.data.calc.standard.cost
					) :
					undefined
				)
			),
		});
	}

	render()
	{
		if(!this.state.cart) {
			return <></>;
		}

		let name = "Guest";
		let user = this.state.session.user;

		if(user) {
			name = user.name;
		}

		const props: CartRefProps = {
			state: this.state,
			setState: (a) => this.setState(a),
			calculateShipping: (a) => this.calculateShipping(a),
			handlePurchase: (a, b, c) => this.handlePurchase(a, b, c),
		};

		return (
			<div className="page-cart">
				<Container>

					<h4>{name}'s Cart</h4>

					<CartList {...props} />
					<Checkout {...props} />
					<Summary {...props} />

				</Container>
				<Component.Padding />
			</div>
		)
	}
}

export const CartRoute = () => MakeRoute(Cart);

