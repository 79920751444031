
import React, { ReactElement } from "react";
import {MakeRoute} from "../Routes";
import {UserCapability} from "../../shared/User";
import {Logs} from "./Logs";
import {CurrentStaff} from "./CurrentStaff";
import {Hits} from "./Hits";
import {Component} from "../..";

export class Admin extends React.Component<{children?: any, render_callback?: () => JSX.Element}>
{
	render() {
		return (
			<div className="page-admin">
				<Component.SideBarWindow
					name="Admin Tools"
					render_callback={this.props.render_callback}
					items={[
						{to: "/admin/logs", display: "Action Logs"},
						{to: "/admin/hits", display: "Page Hits"},
						{to: "/admin/current-staff", display: "Current Staff"},
					]}
				>
					{this.props.children}
				</Component.SideBarWindow>
			</div>
		)
	}
}

export const AdminRoute = () => MakeRoute(Admin, UserCapability.ADMIN, {
	"logs": MakeRoute(Logs),
	"hits": MakeRoute(Hits),
	"current-staff": MakeRoute(CurrentStaff),
});

