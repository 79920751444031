import {Button, Container, Input} from "reactstrap";
import {Component, Page} from "../../..";
import {useEffect, useId, useState} from "react";
import {Api, Helpers} from "../../../shared";
import {App} from "../../App";
import {Create} from "./Create";
import {Edit} from "./Edit";
import {ViewCard} from "./ViewCard";

export function Components()
{
	const [components, setComponents] = useState<Api.Staff.Component.TypeID[]>();
	const [terms, setTerms] = useState("");

	async function reload()
	{
		let res = await Api.Practitioner.Component.Call.getAll({});
		
		if(res.data) {
			setComponents(res.data.components);
		}
	}

	useEffect(() => {reload()}, []);

	function create()
	{
		App.open(() => <Create reload={reload} />);
	}

	function edit(component: Api.Staff.Component.TypeID)
	{
		App.open(() => <Edit component={component} components={components ?? []} reload={reload} />);
	}

	let components_e: JSX.Element[] = [];

	for(let item of components ?? [])
	{
		if(!Helpers.String.HasTerms(item.name, terms)) {
			continue;
		}

		components_e.push(<ViewCard component={item} key={item.name} click={() => edit(item)} />);
	}

	const searchId = useId();

	return (
		<Page.Staff>
			<Container>
				<h4>All Components</h4>
				<div className="side-flex">
					<label htmlFor={searchId} className="visually-hidden">Filter components by name</label>
					<Input id={searchId} placeholder="Filter" onChange={(e) => setTerms(e.target.value)} />
					<Button onClick={create}>
						Add
					</Button>
				</div>
				<Component.Skipper>
					{components_e}
				</Component.Skipper>
			</Container>
		</Page.Staff>
	);
}

