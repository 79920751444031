import {TestShared} from "./Test";
export function IsAlphebetic(email: string, include = '')
{
	for(let i of email) {
		let found = false;
		for(let a of 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890' + include) {
			if(i === a) {
				found = true;
			}
		}
		if(!found) {
			return false;
		}
	}

	return true;
}

TestShared(() => IsAlphebetic("HelloWorld123"));
TestShared(() => !IsAlphebetic("Hello World 123"));

export function EmailValidator(email: string)
{
	if(email.length === 0) {
		return "Required";
	}

	if(email.length > 254) {
		return "TooLong";
	}

	if(!IsAlphebetic(email, '@._-')) {
		return "InvalidCharacters";
	}

	if(!email.includes('.')) {
		return "InvalidSyntax";
	}

	if(email.split('@').length !== 2) {
		return "InvalidSyntax";
	}

	const [sender, domain] = email.split('@');

	if(sender.length === 0) {
		return "InvalidSyntax";
	}

	for(const c of domain.split('.')) {
		if(c.length === 0) {
			return "InvalidSyntax";
		}
	}

	return "Valid";
}

TestShared(() => EmailValidator("qwerty@hotmail.com") === "Valid");
TestShared(() => EmailValidator("qw@rty@hotmail.com") !== "Valid");
TestShared(() => EmailValidator("") !== "Valid");

export function PasswordValidator(password: string)
{
	if(password.length === 0) {
		return 'Required';
	}

	if(password.length < 8) {
		return 'TooShort';
	}

	return 'Valid';
}

TestShared(() => PasswordValidator("") !== "Valid");
TestShared(() => PasswordValidator("pass") !== "Valid");
TestShared(() => PasswordValidator("password123") === "Valid");

export function NameValidator(name: string)
{
	if(name.length === 0) {
		return "Required";
	}

	if(name.length > 64) {
		return "TooBig";
	}

	if(name.includes("\n")) {
		return "IllegalCharacters";
	}

	if(name.includes("\t")) {
		return "IllegalCharacters";
	}

	return "Valid";
}

TestShared(() => NameValidator("Bad\n\tName") !== "Valid");
TestShared(() => NameValidator("") !== "Valid");

export function FutureDateValidator(date: string | number)
{
	if(typeof(date) === "string") {
		date = Date.parse(date);
	}

	if(isNaN(date)) {
		return "InvalidFormat";
	}

	let date_o = new Date(date);
	let now = new Date();

	// The date can't be in the past
	if(date_o <= now) {
		return "InThePast";
	}

	return "Valid";
}

TestShared(() => FutureDateValidator(Date.now()) !== "Valid");
TestShared(() => FutureDateValidator(0) !== "Valid");
TestShared(() => FutureDateValidator(NaN) !== "Valid");
TestShared(() => FutureDateValidator(new Date().toString()) !== "Valid");
TestShared(() => FutureDateValidator(Date.now() + 1000) === "Valid");
TestShared(() => FutureDateValidator(new Date(Date.now() + 1000).toString()) === "Valid");

export function PhoneValidator(v: string)
{
	if(v.length === 0) {
		return "Required";
	}

	if(v.length > 64) {
		return "TooLong";
	}

	if(v.startsWith("+")) {
		v = v.substring(1);
	}

	for(let c of v) {
		if(!"0123456789".includes(c)) {
			return "InvalidCharacters";
		}
	}

	return "Valid";
}

TestShared(() => PhoneValidator("") !== "Valid");
TestShared(() => PhoneValidator("0123456789") === "Valid");
TestShared(() => PhoneValidator("+61234567") === "Valid");
TestShared(() => PhoneValidator("+6123O542") !== "Valid");

export function BirthdayValidator(date: string | number)
{
	if(typeof(date) === "string") {
		let date_n = Date.parse(date);
		if(!date_n) {
			return "InvalidFormat";
		}
		date = date_n;
	}

	let date_o = new Date(date);
	let now = new Date();

	// It's safe to assume no one is > 200 years old
	let too_old = new Date(now.getTime() - 6311520000000);

	// Time travel probably isn't possible yet
	if(date_o > now) {
		return "TimeTraveller";
	}

	// No one has lived this long
	if(date_o < too_old) {
		return "TooOld";
	}

	return "Valid";
}

TestShared(() => BirthdayValidator(Date.now() + 1000*60*60*24*365*10) !== "Valid");
TestShared(() => BirthdayValidator(Date.now() - 1000*60*60*24*365*10) === "Valid");
TestShared(() => BirthdayValidator(new Date(Date.now() + 1000*60*60*24*365*10).toString()) !== "Valid");
TestShared(() => BirthdayValidator(new Date(Date.now() - 1000*60*60*24*365*10).toString()) === "Valid");

