import {ShippingState} from "../Shipping";
import {SQLEnum} from "../SQLEnum";
import {PostType, GetType} from "./Api";
import * as CallType from "./CallType";

export interface PostSizeReqOpts {
	sizes: {
		size: PostSizeOpts[] | PostSizeOpts;
	}
}

export interface CountryProps {
	code: string;
	name: string;
	description: string;
	postalServiceName?: string | null;
	postalServiceUrl?: string | null;
}

export interface PostSizeOpts {
	code: string;
	name: string;
	value?: string | null;
}

export interface PostSizeBestOpts {
	dx: number;
	dy: number;
	dz: number;
	size: PostSizeOpts;
	volume: number;
}

export interface PostOptionsOpts {
	code: string;
	name: string;
}

export interface PostServiceOpts {
	code: string;
	name: string;
	price: string;
	max_extra_cover: number;
	options: {
		option: PostOptionsOpts[] | PostOptionsOpts;
	};
}

export interface PostServiceReqOpts {
	services: {
		service: PostServiceOpts[] | PostServiceOpts;
	}
}

export interface PostCostProps {
	item: string;
	cost: string;
}

export interface PostCalculateProps {
	postage_result: {
		service: string;
		delivery_time?: string | null;
		total_cost: string;
		costs: {
			cost: PostCostProps;
		};
	};
}

export interface CalcPostageOpts {
	country_code: string;
	post_code: string;
	weight: number;
}

export interface CalcPostageType {
	cost: number;
	message: string;
	display: string;
	//tracking: boolean;
	//signature_on_delivery: boolean;
	//sms_track_advice: boolean;
	//extra_cover: boolean;
}

export interface CalcPostageReturn {
	[key: string]: CalcPostageType | undefined | null;
	standard?: CalcPostageType | null;
	express?: CalcPostageType | null;
}

export interface AddressType
{
	address1: string;
	address2: string;
	city: string;
	country: string;
	postcode: string;
	state: string;
	name: string;
}

export interface CourierType
{
	code: string;
	name: string;
	description: string;
	postalServiceName?: string | null;
	postalServiceUrl?: string | null;
}

export interface OrderType {
	id: string;
	created: number;
	name: string;
	email: string;
	phone: string;
	ref: number;
	step: SQLEnum.OrderStep;
	subtotal: number;
	payment_method?: SQLEnum.PaymentMethod | null;
	total: number;
	tax?: number;
	is_hidden?: SQLEnum.Bool;
	tax_type: SQLEnum.TaxType;
	shipping?: ShippingState & {
		method?: string;
		tracking?: string;
		name: string;
		country: string;
		postcode: string;
		address1: string;
		address2: string;
		city: string;
		state: string;
		amount: number;
	}
	cart: {
		item_id: string;
		product_id?: string;
		option_id?: string;
		name: string;
		option: string;
		description: any;
		components: string[];
		count: number;
		amount: number;
		image_name: string;
		image_id: string;
		uses?: number;
		uses_max?: number;
		s_id_bin?: string;
	}[]
	discount?: {
		name: string;
		amount: number;
	}
}

export interface Calc extends PostType<"auspost offline">
{
	Body: {
		country_code: string;
		post_code: string;
		weight: number;
	}

	Res: {
		calc: CalcPostageReturn;
	}
}

export interface Countries extends GetType<"auspost offline">
{
	Res: {
		countries: CourierType[];
	}
}

export interface CreateAddress extends PostType
{
	Body: AddressType & {
		link: boolean;
	}

	Res: {
		addressId: string;
	}
}

export interface GetAddresses extends GetType
{
	Res: {
		addresses: (AddressType & {
			id_bin: string;
		})[]
	}
}

export interface RemoveAddress extends PostType
{
	Body: {
		addressId: string;
	}
}

export interface CreateOrder extends PostType<"cart empty" | "no post service">
{
	Body: {
		name: string;
		email: string;
		phone: string;
		shipping_id?: string;
		service?: string;
	}
	Res: {
		orderId: string;
	}
}

export interface ConfirmOrder extends PostType<"order not found" | "pay type already set">
{
	Body: {
		id: string;
		type: SQLEnum.PaymentMethod;
	}
}

export interface Couriers extends GetType
{
	Res: {
		couriers: {
			[key: string]: {
				regions: {[key: string]: number};
				display: string;
				message: string;
				limit: number;
			}
		}
	}
}

export interface PayOrder extends PostType<"order not found" | "order already paid" | "payment error" | "cannot dispense item">
{
	Body: {
		id: string;
		paymentIntent?: string;
		paymentMethod?: string;
		saveCard?: boolean;
	}
	Res: {
		status?: "requires_payment_method" | "requires_confirmation" | "requires_action" | "processing" | "requires_capture" | "canceled";
		error_message?: string;
		secret?: string;
	}
}

export interface GetOrder extends GetType<"order not found">
{
	Query: {
		id: string;
	}
	Res: {
		order: OrderType;
	}
}

export interface RemoveCard extends PostType<"card not found">
{
	Body: {
		id: string;
	}
}

export interface FavCard extends PostType<"card not found">
{
	Body: {
		id: string;
	}
}

export interface FavAddress extends PostType<"address not found">
{
	Body: {
		addressId: string;
	}
}

export const Call =
{
	couriers: CallType.Get<Couriers>("/post/couriers"),
	countries: CallType.Get<Countries>("/post/countries"),
	calc: CallType.Post<Calc>("/post/calc"),
	createOrder: CallType.Post<CreateOrder>("/post/createOrder"),
	payOrder: CallType.Post<PayOrder>("/post/payOrder"),
	getOrder: CallType.Get<GetOrder>("/post/getOrder"),
	confirmOrder: CallType.Post<ConfirmOrder>("/post/confirmOrder"),
	removeCard: CallType.Post<RemoveCard>("/post/removeCard"),
	createAddress: CallType.Post<CreateAddress>("/post/createAddress"),
	getAddresses: CallType.Get<GetAddresses>("/post/getAddresses"),
	removeAddress: CallType.Post<RemoveAddress>("/post/removeAddress"),
	favCard: CallType.Post<FavCard>("/post/favCard"),
	favAddress: CallType.Post<FavAddress>("/post/favAddress"),
};

