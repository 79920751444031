
import {Container, Col} from "reactstrap";
import {App, AppHistory} from "../App";
import {Component, Page} from "../..";
import {Helpers} from "../../shared";

export const UserSearch = () =>
{
	const get_terms = () =>
	{
		let query = new URL(window.location.href);

		return query.searchParams.get("q") ?? undefined;
	}

	const set_terms = (terms: string) =>
	{
		if(terms)
		{
			AppHistory.push("?" + Helpers.Data.QueryString({
				q: terms,
			}));
		}

		else
		{
			AppHistory.push("");
		}
	}

	return (
		<Page.Staff>
			<Container>
				<Col><h4>User Search</h4></Col>
				<Component.UserSearch
					terms={get_terms()}
					setTerms={(terms) => set_terms(terms)}
					onClick={(user) => {
						App.routeToUrl("/staff/user?" + Helpers.Data.QueryString({
							email: user.email,
						}));
					}}
				/>
			</Container>
		</Page.Staff>
	);
}
