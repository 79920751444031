import { Col, Table } from "reactstrap";
import {Component} from "../../..";
import {User} from ".";

export const RenderEmailHistory = (ref: User) =>
{
	if(!ref.state.user)
	{
		return <></>;
	}

	let user = ref.state.user;
	let elements: JSX.Element[] = [];
	let it = 0;

	let when = user.created;

	for(let email of user.email_history)
	{
		elements.push(
			<tr key={it++}>
				<td><p>{email.email}</p></td>
				<td><p>{new Date(when).toLocaleString()}</p></td>
			</tr>
		);

		when = email.when;
	}

	elements.push(
		<tr key={it++}>
			<td><p>{user.email}</p></td>
			<td><p>{new Date(when).toLocaleString()}</p></td>
		</tr>
	);

	return (
		<Col className="spacer">
			<Component.Skipper label="Email history">
				<Table>
					<thead>
						<tr>
							<th><p>Email</p></th>
							<th><p>Changed</p></th>
						</tr>
					</thead>
					<tbody>
						{elements}
					</tbody>
				</Table>
			</Component.Skipper>
		</Col>
	);
}
