
import React from "react";
import { Container, Col, Button, FormText } from "reactstrap";
import { App } from "../App";
import { SessionReload } from "../AppHelpers";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {

}

interface IState {
	code: string;
	email: string;
	valid: boolean;
	loaded: boolean;
	invalid_reason?: string;
	confirm_error: string;
	button_disabled: boolean;
}

export class Confirm extends React.Component<IProps, IState>
{
	constructor(props: IProps) {
		super(props);

		this.state = {
			code: "",
			email: "",
			valid: false,
			loaded: false,
			confirm_error: "",
			button_disabled: false,
		}
	}

	async componentDidMount()
	{
		let query = new URL(window.location.href);
		let email = query.searchParams.get("email");
		let code = query.searchParams.get("code");

		if(!email || !code) {
			this.setState({
				loaded: true,
				invalid_reason: "Invalid query parameters",
			});
			return;
		}

		this.setState({
			code: code,
			email: email,
			valid: true,
			loaded: true,
		});
	}

	async confirm()
	{
		if(!this.state.loaded || !this.state.email || !this.state.valid) {
			return;
		}

		this.setState({
			button_disabled: true,
		});

		let res = await Api.Auth.Call.confirm({
			email: this.state.email,
			confirm: this.state.code,
		});

		await SessionReload();

		if(res.error) {
			if(res.error === "already confirmed") {
				this.setState({
					confirm_error: "User already confirmed",
				});
				return;
			}
			if(res.error === "invalid token") {
				this.setState({
					confirm_error: "Invalid token",
				});
				return;
			}
			if(res.error === "user not found") {
				this.setState({
					confirm_error: "User not found",
				});
				return;
			}
			this.setState({
				confirm_error: res.error,
			});
			throw res.error;
		}

		App.routeToUrl("/");
	}

	render() {

		if(!this.state.loaded || !this.state.email) {
			return <></>;
		}

		if(!this.state.valid) {
			return (
				<div className="page-confirm">
					<Container>

						<Col><h4>Invalid Request</h4></Col>

						<Col><p>

							We encountered an issue while
							processing your account confirmation
							request.

						</p></Col>

						<Col><p>
							
							Reason: {this.state.invalid_reason}

						</p></Col>

					</Container>
					<Component.Padding type="bottom" />
				</div>
			)
		}

		return (
			<div className="page-confirm">
				<Container>

					<Col><h4>Confirm Account</h4></Col>

					<Col><p>

						Welcome to the Goulds Natural Medicine webstore!
					
					</p></Col>
					<Col><p>
						
						Please click the button below to
						confirm your account at {
							this.state.email
						}.

					</p></Col>

					<Col>
						<Button
							onClick={() => this.confirm()}
							disabled={this.state.button_disabled || this.state.confirm_error !== ""}
						>Confirm</Button>
						<FormText color="danger">
							{this.state.confirm_error}
						</FormText>
					</Col>

				</Container>
				<Component.Padding type="bottom" />
			</div>
		)
	}
}
