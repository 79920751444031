import {ComponentProps, useRef, useState} from "react";
import {Input} from "reactstrap";
import {Helpers} from "../../shared";
import {OnActive} from "../../scripts/util";
import "./style.scss";

export function DataMappedList<T>(props: {
	innerProps?: ComponentProps<typeof Input>,
	value: string,
	onChange: (v?: T) => any,
	options: Map<string, T>,
}) {
	return <DataList
		innerProps={props.innerProps}
		value={props.value}
		options={props.options}
		onChange={v => props.onChange(props.options.get(v))}
	/>;
}

export function DataList(props: {
	innerProps?: ComponentProps<typeof Input>,
	value: string,
	onChange: (v: string) => any,
	options: string[] | Set<string> | Map<string, any>,
}) {
	const [value, setValue] = useState(props.value);
	const [visible, setVisible] = useState(false);
	const [state] = useState({caught_focus: false});
	const e_options: JSX.Element[] = [];
	const inputRef = useRef<HTMLInputElement>(null);

	function update(v: string) {
		setValue(v);
		props.onChange(v);
	}

	function click(v: string) {
		inputRef.current!.value = v;
		setVisible(false);
		update(v);
	}

	function focus() {
		state.caught_focus = true;
		if(!visible) {
			setVisible(true);
		}
	}

	function blur() {
		state.caught_focus = false;
		setImmediate(() => {
			if(!state.caught_focus) {
				setVisible(false);
			}
		});
	}

	let it: Iterable<string>;

	if(props.options instanceof Map) {
		it = props.options.keys();
	} else {
		it = props.options;
	}

	for(let item of it)
	{
		if(item === value) {
			continue;
		}

		if(!Helpers.String.HasTerms(item, value)) {
			continue;
		}

		const fn = () => click(item);
		e_options.push(<li key={item} {...OnActive(fn)}>{item}</li>);
	}

	return (
		<div className="dropdown-contents-base" onFocus={focus} onBlur={blur}>
			<Input {...props.innerProps} autoComplete="off" defaultValue={props.value} innerRef={inputRef} onChange={(e) => update(e.target.value)} />
			<div className="dropdown-contents-top" hidden={!visible || e_options.length === 0}>
				<div className="dropdown-contents">
					{e_options}
				</div>
			</div>
		</div>
	);
}

