import {Container, Input} from "reactstrap";
import {Component, Page} from "../../..";
import {useEffect, useId, useState} from "react";
import {Api, Helpers} from "../../../shared";
import {App} from "../../App";
import {Edit} from "./Edit";

export function Groups()
{
	const [groups, setGroups] = useState<Api.Staff.Group.Type[]>();
	const [terms, setTerms] = useState("");

	const searchId = useId();

	async function reload() {
		let res = await Api.Staff.Group.Call.getAll({});
		if(!res.data) throw res.error;
		setGroups(res.data.groups);
	}

	useEffect(() => {reload()}, []);

	let groups_e: JSX.Element[] = [];

	for(let item of groups ?? []) {
		if(!Helpers.String.HasTerms(item.name, terms)) {
			continue;
		}
		function click() {
			App.open(() => <Edit group={item} reload={reload} />);
		}
		groups_e.push(
			<Component.Card onClick={click}>
				<p>{item.name}</p>
			</Component.Card>
		);
	}
		
	return (
		<Page.Staff>
			<Container>
				<h4>All Groups</h4>
				<div className="side-flex">
					<label htmlFor={searchId} className="visually-hidden">Filter groups by name</label>
					<Input id={searchId} placeholder="Filter" onChange={(e) => setTerms(e.target.value)} />
				</div>
				<Component.Skipper>
					{groups_e}
				</Component.Skipper>
			</Container>
		</Page.Staff>
	);
}

