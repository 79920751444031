import {SQLEnum} from "../../SQLEnum";
import {PostType} from "../Api";
import * as CallType from "../CallType";

export interface SizingType {
	amount: number;
	weight: number;
	base_cost: number;
};

export interface Type {
	name: string;
	quantity_type: SQLEnum.QuantityType;
	sizings: SizingType[];
	image_id: string;
	image_name: string;
};

export interface Create extends PostType<"duplicate entry" | "template not found" | "image not found"> {
	Body: Type & {name_old?: string};
};

export interface Delete extends PostType<"template not found" | "rejected"> {
	Body: {
		name: string;
		force?: boolean;
	};
	Res: {
		found: number;
	};
};

export const Call = {
	create: CallType.Post<Create>("/staff/template/create"),
	delete: CallType.Post<Delete>("/staff/template/delete"),
};

