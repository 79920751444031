import {Button, Container, FormGroup, FormText, Label} from "reactstrap";
import {App} from "../../App";
import {Api} from "../../../shared";
import {useId, useState} from "react";
import {ViewCard} from "./ViewCard";
import {Component} from "../../..";

export function Delete({reload, component, components}: {reload: () => any, component: Api.Staff.Component.TypeID, components: Api.Staff.Component.TypeID[]})
{
	const [disabled, setDisabled] = useState(false);
	const [replace, setReplace] = useState<Api.Staff.Component.TypeID>();
	const [force, setForce] = useState(false);
	const [error, setError] = useState("");

	const components_map = new Map<string, Api.Staff.Component.TypeID>();
	const replace_id = useId();

	for(let item of components) {
		components_map.set(item.name, item);
	}

	async function doDelete()
	{
		if(!component.id) {
			return;
		}

		setDisabled(true);

		let res = await Api.Staff.Component.Call.delete({
			id: component.id,
			replace_id: replace?.id,
			force: force,
		});

		if(res.error) {
			setError(res.error);
			return;
		}

		if(res.data && !res.data.done && !force) {
			setError(`This component will be removed from ${res.data.found} products. Are you sure?`);
			setDisabled(false);
			setForce(true);
			return;
		}

		App.open(null);
		App.open(null);

		reload();
	}

	let replace_e = <></>;

	if(force) {
		replace_e = (
			<FormGroup>
				<Label htmlFor={replace_id}>Replace with</Label>
				<Component.DataMappedList innerProps={{id: replace_id}} value="" options={components_map} onChange={setReplace} />
			</FormGroup>
		);
	}

	return (
		<Container>
			<h4>Delete Component</h4>
			<p>
				Are you sure you want to delete this product component?
			</p>
			<FormGroup><ViewCard component={component} /></FormGroup>
			{replace_e}
			<FormGroup>
				<FormText color="danger">{error}</FormText>
			</FormGroup>
			<FormGroup>
				<Button disabled={disabled} onClick={doDelete}>Yes</Button>
				<Button disabled={disabled} onClick={() => App.open(null)}>No</Button>
			</FormGroup>
		</Container>
	);
}

