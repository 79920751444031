import {useEffect, useState} from "react";
import {Button, Col, Container} from "reactstrap";
import {Api} from "../../shared";
import {Component} from "../..";
import {App} from "../App";
import {ProductAdd, ProductPrescribe} from "../staff/user/Products";
import {EditProduct} from "./EditProduct";
import {Practitioner} from ".";
import {AddProductClient} from "./AddProductClient";

export const Client = () =>
{
	const [user, setUser] = useState<Api.Practitioner.User.ClientType>();
	const [error, setError] = useState("");

	async function getInfo()
	{
		let url = new URL(window.location.href);
		let email = url.searchParams.get("email");

		if(!email) {
			setError("missing email");
			return;
		}

		let res = await Api.Practitioner.User.Call.getClient({email: email});

		if(!res.data) {
			setError(res.error ?? "unknown error");
			return;
		}

		setUser(res.data.client);
	}

	async function prescribeCallback(product: Api.Product.ProductSearchedType, expiry?: number, limit?: number)
	{
		let res = await Api.Practitioner.Product.Call.prescribe({
			email: user!.email,
			product_id: product.id,
			expiry: expiry ?? undefined,
			uses_max: limit ?? undefined,
		});

		if(res.error) {
			console.error(res.error);
		}

		App.open(null);
		await getInfo();
	}

	async function addProduct()
	{
		App.open(() => <ProductAdd callback={(p, expiry, limit) => prescribeCallback(p, expiry, limit)} />);
	}

	async function createProduct()
	{
		if(!user) {
			return;
		}

		App.open(() => <AddProductClient callback={async (p_t, p_d, id) =>
		{
			let p: Api.Product.ProductSearchedType = {
				...p_t,
				id,				
				amount: 0,
			};

			p.amount = Number.MAX_VALUE;

			for(let item of p_d.options) {
				if(item.amount < p.amount) {
					p.amount = item.amount;
				}
			}

			await prescribe(p);

		}} />, false);
	}

	function edit(product: Api.Product.ProductSearchedType)
	{
		App.open(() => <EditProduct showStaffOpts={false} productId={product.id} onSubmit={async () => {
			await getInfo();
			App.open(null);
		}} />);
	}

	async function prescribe(product: Api.Product.ProductSearchedType)
	{
		let edit_cb: () => void | undefined;

		if(product.visibility === "PRIVATE") {
			edit_cb = () => edit(product);
		}

		App.open(() => <ProductPrescribe p={product} callback={(expiry, limit) => prescribeCallback(product, expiry, limit)} onEdit={edit_cb} />);
	}

	async function unprescribe(product: Api.Product.ProductSearchedType)
	{
		let res = await App.openDialogue({
			title: "Remove Practitioner Recommended Product",
			content: () => (
				<>
					<Col>
						<Component.Product product={product} />
					</Col>
					<Col>
						<p>Are you sure you want to unprescribe this product?</p>
					</Col>
				</>
			),
			prompt: true,
		});

		if(!res) {
			return;
		}

		await Api.Practitioner.Product.Call.prescribe({
			email: user!.email,
			product_id: product.id,
			remove: true,
		});

		await getInfo();
	}

	useEffect(() => {getInfo();}, []);

	if(!user && error) {
		return (
			<Container>
				<Col><h4>Your Client</h4></Col>
				<Col><p>Error: {error}</p></Col>
			</Container>
		);
	}

	else if(!user) {
		return <></>;
	}

	let elements_p: JSX.Element[] = [];
	let elements_c: JSX.Element[] = [];
	
	for(let product of user.products)
	{
		let e = (
			<Component.Product
				key={product.id}
				product={product}
				onClick={() => prescribe(product)}
				onRemove={() => unprescribe(product)}
			/>
		);

		if(product.visibility === "PRIVATE")
		{
			elements_c.push(e);
		}

		else
		{
			elements_p.push(e);
		}
	}

	return (
		<Practitioner>
			<Container>
				<Col><h4>{user.name}'s Account</h4></Col>
				<Col className="spacer">
					<h5>Email</h5>
					<p>{user.email}</p>
				</Col>
				<Col className="spacer">
					<h5>Phone Number</h5>
					<p>{user.phone}</p>
				</Col>
				<Col className="spacer">
					<h5>Practitioner Recommended Products</h5>
					<Component.Skipper>
						<div className="scroll-window">
							{elements_p}
						</div>
					</Component.Skipper>
					<Button onClick={() => addProduct()}>Add</Button>
				</Col>
				<Col className="spacer">
					<h5>Custom Products</h5>
					<Component.Skipper>
						<div className="scroll-window">
							{elements_c}
						</div>
					</Component.Skipper>
					<Button onClick={() => createProduct()}>Add</Button>
				</Col>
			</Container>
		</Practitioner>
	);
}

