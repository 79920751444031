import React, { useId } from "react";
import {Container, Col, Input} from "reactstrap";
import {GenID} from "../../scripts/util";
import {Api} from "../../shared";
import {Component, Page} from "../..";

interface IState {
	orders: Api.Staff.Order.OrderBasicType[];
}

interface IProps {

}

export class OrderLookup extends React.Component<IProps, IState>
{
	constructor(props: IProps) {
		super(props);

		this.state = {
			orders: [],
		};
	}

	async lookup(ref: string)
	{
		let res = await Api.Staff.Order.Call.lookup({
			ref: ref
		});

		if(!res.data) {
			return;
		}

		this.setState({
			orders: res.data.orders,
		});
	}

	id1 = GenID();

	render()
	{
		let orders_e: JSX.Element[] = [];
		let it = 0;

		for(let order of this.state.orders) {
			orders_e.push(
				<Component.OrderCard setHidden={false} order={order} key={it} />
			);
		}

		return (
			<Page.Staff>
				<Container>
					<Col><h4>Order lookup</h4></Col>
					<Col>
						<label className="visually-hidden" htmlFor={this.id1}>Lookup order by reference</label>
						<Input
							id={this.id1}
							placeholder="Order reference number here..."
							onChange={(e) => {
								if(e.target.value.length === 8) {
									this.lookup(e.target.value);
								} else {
									this.setState({
										orders: [],
									});
								}
							}}
						/>
					</Col>
					<Col>
						{orders_e}
					</Col>
				</Container>
			</Page.Staff>
		)
	}
}
