import React, { createRef } from "react";

export interface FormHelperItem<T = string>
{
	value?: T;
	onModify?: (v?: T) => unknown;
	errors?: (v?: T) => string;
	addInput: (errors?: (v: T) => string) => InputType;
	addErrorField: () => string;
	modify: (v: T) => void;
	update: () => void;
}

type Item<T> = FormHelperItem<T>;

interface InputType
{
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
	innerRef: React.Ref<HTMLInputElement>;
	defaultValue: string;
}

export class FormHelper
{
	forceUpdate: () => unknown;
	showErrors = false;
	items: Item<any>[] = [];

	constructor(forceUpdate: () => unknown)
	{
		this.forceUpdate = forceUpdate;
	}

	createRef<T = string>()
	{
		let item: Item<T> = {
			addInput: (errors) => this.addInput(item as unknown as Item<string>, errors),
			addErrorField: () => this.addErrorField(item),
			modify: (value) => this.modify(item, value),
			update: () =>
			{
				if(item.onModify && item.value)
				{
					item.onModify(item.value);
				}
			}
		};

		this.items.push(item);
		return item;
	}

	modify<T>(item: Item<T>, value: T)
	{
		item.value = value;

		if(item.onModify)
		{
			item.onModify(value);
		}

		this.forceUpdate();
	}

	addInput(item: Item<string>, errors?: (v: any) => string): InputType
	{
		let ref = createRef<HTMLInputElement>();

		item.errors = errors;
		item.onModify = (v?: string) =>
		{
			if(ref.current)
			{
				ref.current.value = v ?? "";
			}
		};

		return {
			onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
			{
				item.value = e.target.value;
				this.forceUpdate();
			},
			innerRef: ref,
			defaultValue: item.value ?? "",
		}
	}

	addErrorField<T>(item: Item<T>)
	{
		if(this.showErrors && item.errors)
		{
			return item.errors(item.value);
		}

		else
		{
			return "";
		}
	}

	check()
	{
		this.showErrors = true;

		for(let item of this.items)
		{
			if(item.errors && item.errors(item.value))
			{
				this.forceUpdate();

				return false;
			}
		}

		this.forceUpdate();

		return true;
	}
}
