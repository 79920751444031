import React from "react";
import { Button, Col, Container, FormText } from "reactstrap";
import { App } from "../../app/App";
import { GenID, OnActive } from "../../scripts/util";
import { GetOrderFields, GetOrderNotes } from "../../shared/Order";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {
	order?: Api.Post.OrderType;
}

interface IState {
}

export class Invoice extends React.Component<IProps, IState>
{
	constructor(props: Readonly<IProps>) {
		super(props);

		this.state = {};
	}

	view_product(product: Omit<Api.Product.ProductType, 'components'> & {components?: string[]})
	{
		const id1 = GenID();

		App.open(() => (
			<Container role="dialogue" aria-labelledby={id1}>
				<p className="visually-hidden" id={id1}>View {product.name}</p>
				<Component.ProductInfo
					viewOnly={true}
					hideTools={true}
					product={product}
				/>
				<Col className="spacer"><Button onClick={() => {
					App.open(null);
				}}>Close</Button></Col>
			</Container>
		));
	}

	render()
	{
		if(!this.props.order) {
			return <></>;
		}

		return (
			<Component.Skipper label="Invoice">
				<table className="product-confirm">
					<thead>
						<tr>
							<th><p>Product</p></th>
							<th><p>Cost</p></th>
						</tr>
					</thead>
					<tbody>
						{(() => {

							let elements: JSX.Element[] = [];
							let it = 0;

							const add = (text: string, cost: number, itemID?: string, symbolStart="$", symbolEnd=" AUD", bold=false) =>
							{
								const elementClickable = itemID ? <div className="item-clickable" {...OnActive(() => {

									if(!this.props.order) {
										return;
									}

									for(let item of this.props.order.cart)
									{
										if(item.item_id === itemID)
										{
											this.view_product({
												options: [{
													amount: item.amount,
													name: item.name,
													id: item.option_id ? item.option_id : "",
													out_stock: false,
													weight: 0,
												}],
												components: item.components,
												can_purchase: false,
												cart_max: 0,
												description: item.description,
												id: item.product_id ? item.product_id : "",
												image_id: item.image_id,
												image_name: item.image_name,
												name: item.name + (item.count > 1 ? ` x${item.count}` : ``),
												visibility: "PUBLIC",
												gst_aud: "FULL",
												vat_gbp: "FULL",
												brand: "",
												category: "",
											});

											break;
										}
									}

								})}><p>{text}</p></div> : <p>{text}</p>;

								elements.push(
									<tr key={it} className={bold ? "highlighted" : ""}>
										<td>{elementClickable}</td>
										<td><p>{(cost < 0 ? "-" : "")}{symbolStart}{
											(Math.abs(cost) / 100).toFixed(2)
										}{symbolEnd}</p></td>
									</tr>
								);
								it += 1;
							}

							for(let field of GetOrderFields(this.props.order)) {
								add(field.name, field.value, field.id, field.start, field.end, field.bold);
							}

							return elements;

						})()}
					</tbody>
				</table>
				<FormText>{GetOrderNotes(this.props.order).join(" ")}</FormText>
			</Component.Skipper>
		);
	}
}
