import {useEffect, useId, useState} from "react";
import {Container, Col, FormGroup, Input, Button, Row} from "reactstrap";
import {App, AppHistory} from "../App";
import {Api, Helpers} from "../../shared";
import {Component, Page} from "../..";

export const Attribs = (attrib_type: "BRAND" | "CATEGORY") =>
{
	const AddAttrib = ({callback}: {callback: (attrib: string) => void}) =>
	{
		let title = "";

		if(attrib_type === "BRAND")
		{
			title = "Add Brand";
		}

		else if(attrib_type === "CATEGORY")
		{
			title = "Add Category";
		}

		const [attrib, setAttrib] = useState("");
		const id1 = useId();
		const id2 = useId();

		return (
			<Container role="dialogue" aria-labelledby={id1}>
				<h4 id={id1}>{title}</h4>
				<FormGroup>
					<label htmlFor={id2} className="visually-hidden">Updated {attrib_type.toLowerCase()} name</label>
					<Input id={id2} onChange={(e) => setAttrib(e.target.value)} />
				</FormGroup>
				<FormGroup>
					<Button disabled={attrib.length === 0} onClick={() => {
						callback(attrib);
						App.open(null);
					}}>Ok</Button>
					<Button onClick={() => App.open(null)}>Cancel</Button>
				</FormGroup>
			</Container>
		)
	}

	const Ret = () =>
	{
		const [attribs, setAttribs] = useState<string[]>([]);
		const [migrating, setMigrating] = useState<string>();

		const [filter, setFilter] = useState(() =>
		{
			let url = new URL(window.location.href);

			return url.searchParams.get("q") ?? "";
		});

		useEffect(() =>
		{
			const effect = async () =>
			{
				let res = await Api.Staff.Product.Call.attribs({
					type: attrib_type
				});
	
				if(!res.data)
				{
					throw res.error;
				}

				setAttribs(res.data.attribs);
			}

			effect().catch(console.error);
		}, []);

		let terms: string[] = [];
		let title = "";
		let elements: JSX.Element[] = [];

		for(let term of filter.split(" "))
		{
			if(term)
			{
				terms.push(term.toLowerCase());
			}
		}

		for(let item of attribs)
		{
			let skip = false;
			let item_lower = item.toLowerCase();

			for(let term of terms)
			{
				if(!item_lower.includes(term))
				{
					skip = true;

					break;
				}
			}

			if(skip)
			{
				continue;
			}

			let button_text: string;
			let button_click: () => void;
			let button_colour: string | undefined;
			let button_disabled = false;

			if(attrib_type === "CATEGORY" && item_lower === "practitioner recommended products")
			{
				button_disabled = true;
			}

			if(migrating === undefined)
			{
				button_text = "Migrate";
				button_click = () => {
					setMigrating(item);
				}
			}

			else if(migrating === item)
			{
				button_text = "Cancel";
				button_click = () => {
					setMigrating(undefined);
				}
			}

			else
			{
				let old = migrating;

				button_colour = "danger";
				button_text = "Merge";
				button_click = async () =>
				{
					let res = await App.openDialogue({
						title: "Merge Categories",
						prompt: true,
						content: () => (
							<p>
								Are you sure you want to merge category from "{old}" to "{item}"?
							</p>
						)
					});

					setMigrating(undefined);

					if(!res)
					{
						return;
					}

					let rename_res = await Api.Staff.Product.Call.renameAttrib({
						type: attrib_type,
						old: old,
						new: item,
					});

					if(rename_res.error)
					{
						throw rename_res.error;
					}

					setAttribs(attribs.filter((v) => v !== old));
				}
			}

			elements.push(
				<Component.Card key={item}>
					<Row>
						<Col><p>{item}</p></Col>
						<Col>
							<Button
								style={{float: "right"}}
								color={button_colour}
								onClick={button_click}
								disabled={button_disabled}>
								{button_text}
							</Button>
						</Col>
					</Row>
				</Component.Card>
			);
		}

		if(attrib_type === "BRAND")
		{
			title = "All Brands";
		}

		else if(attrib_type === "CATEGORY")
		{
			title = "All Categories";
		}

		const add_attrib = () =>
		{
			App.open(() => <AddAttrib callback={(attrib) => {
				attribs.unshift(attrib);
				setAttribs(attribs);
			}} />);
		}

		const id1 = useId();

		return (
			<Page.Staff>
				<Container>
					<h4>{title}</h4>
					<div className="side-flex">
						<label className="visually-hidden" htmlFor={id1}>Filter {title} by name</label>
						<Input id={id1} placeholder="Filter" defaultValue={filter} onChange={(e) => {
							setFilter(e.target.value);
							if(e.target.value) {
								AppHistory.push("?"+Helpers.Data.QueryString({
									q: e.target.value
								}));
							} else {
								AppHistory.push("");
							}								
						}} />
						<Button onClick={add_attrib}>
							Add
						</Button>
					</div>
					<Component.Skipper label={"List of " + title}>
						{elements}
					</Component.Skipper>
				</Container>
			</Page.Staff>
		)
	}

	return Ret;
}
