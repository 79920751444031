import {useId, useState} from "react";
import {Input, Label} from "reactstrap";
import {GlobalVariables} from "../../../scripts/GlobalVariables";
import {SessionReload} from "../../AppHelpers";
import {Api} from "../../../shared";
import {CartRefProps} from "..";
import {Component} from "../../..";

export const Summary = (ref: CartRefProps): JSX.Element => {
	const [session, setSession] = useState(GlobalVariables.Session.value);
	const [checked, setChecked] = useState(false);
	const checkboxId = useId();

	GlobalVariables.Session.regUse(setSession);

	let user = session.user;
	let state = ref.state;

	if(!state.cart) {
		return <></>;
	}

	let count = 0;
	let total = 0;

	for(let item of state.cart) {
		if(item.count < 1) {
			continue;
		}

		count += 1;
		total += item.amount * item.count;
	}

	if(count === 0 || state.progress === 3) {
		return <></>;
	}

	const click = async () => {
		if(state.progress === 0) {
			if(!user) {
				ref.setState({
					progress: 1,
					postageCost: undefined,
					showPostageErrors: false,
				});
			} else {
				ref.setState({
					progress: 2,
					postageCost: 0,
					showPostageErrors: false,
					email: user.email,
					phone: user.phone
				});
			}
		}

		else if(state.progress === 1) {
			ref.setState({
				progress: 2,
				postageCost: 0,
				showPostageErrors: false,
			});
		}

		else if(state.progress === 2) {
			let payMode = "card";

			if(user && user.cards && user.cards.length > 0) {
				payMode = "saved_" + user.cards[0].stripe_id;
			}

			ref.setState({
				progress: 3,
				payMode: payMode,
			});

			let email = state.email;
			let phone = state.phone;
			let service = state.shippingService;
			let shipping = state.shipping;
			let shipping_id: string | undefined;

			if(!state.cart || !email || !phone) {
				return;
			}

			if(shipping) {
				let address_res = await Api.Post.Call.createAddress({
					address1: shipping.address1,
					address2: shipping.address2,
					city: shipping.city,
					country: shipping.country,
					name: shipping.name,
					postcode: shipping.postcode,
					state: shipping.state,
					link: !!shipping.save,
				});

				if(address_res.data) {
					shipping_id = address_res.data.addressId;
				}
			}

			let response = await Api.Post.Call.createOrder({
				email: email,
				phone: phone,
				shipping_id: shipping_id,
				service: service,
				name: (
					state.shippingMode === 1 ?
					(state.name ? state.name : "") :
					(state.shipping ? state.shipping.name : "")
				)
			});

			if(response.data) {
				let order_res = await Api.Post.Call.getOrder({
					id: response.data.orderId
				});

				if(order_res.data) {
					ref.setState({
						order: order_res.data.order
					});
				}
			}

			await SessionReload();
		}
	}

	let button_disabled = false;

	if(state.buttonDisabled) {
		button_disabled = true;
	}

	else if(state.progress === 0 && state.hasCustomProducts && !checked) {
		button_disabled = true;
	}

	else if(state.progress === 1 && (state.email === undefined || state.phone === undefined)) {
		button_disabled = true;
	}

	else if(state.progress === 2) {
		if(state.shippingMode === 1) {
			if(!state.name) {
				button_disabled = true;
			}
		}

		else if(state.shippingMode === 0) {
			if(
				state.postageCost === undefined ||
				!state.shipping ||
				!state.shipping.address1 ||
				!state.shipping.city ||
				!state.shipping.country ||
				!state.shipping.postcode ||
				!state.shipping.state ||
				!state.shipping.name)
			{
				button_disabled = true;
			}
		}
	}

	let button_text = "";

	if(state.progress < 2) {
		button_text = "Proceed To Checkout";
	}

	else if(state.progress === 2) {
		button_text = "Continue To Payment";
	}

	let shipping_and_handling: JSX.Element = <></>;
	let postage_cost = 0;
	
	if(state.postageCost !== undefined) {
		let v = (state.postageCost / 100).toFixed(2);

		postage_cost = state.postageCost;
		
		if(state.shipping && state.shipping.country === "AU") {
			shipping_and_handling = (
				<p>Shipping: ${v} AUD</p>
			);
		}

		else {
			shipping_and_handling = (
				<p>Shipping and handling: ${v} AUD</p>
			);
		}
	}

	let checkbox_e: JSX.Element | undefined = undefined;

	if(state.progress === 0 && state.hasCustomProducts) {
		checkbox_e = (
			<p>
				<Input type="checkbox" id={checkboxId} onChange={(e) => setChecked(e.target.checked)}
				/> <Label for={checkboxId} style={{display: "initial", lineHeight: "initial"}}>
					* Your order contains custom products that are unique and made specially for you. 
					Please be aware that returns of custom products will not be accepted in the event 
					of accidental orders, changes of mind, or unforeseeable individual responses (such 
					as sensitivities/intolerances or adverse reactions). It is our strong 
					recommendation that for unfamiliar preparations, smaller sizes be purchased first.
				</Label>
			</p>
		);
	}

	let subtotal = (total / 100).toFixed(2);
	let grand_total = ((total + postage_cost) / 100).toFixed(2);
	
	return (
		<div className="cart-section">
			<h4 className="visually-hidden">Summary</h4>
			<p>Subtotal: ${subtotal} AUD</p>
			{shipping_and_handling}
			<p>Grand Total: ${grand_total} AUD
			</p>
			{checkbox_e}
			<Component.BigButton bigOnClick={click} disabled={button_disabled}>
				{button_text}
			</Component.BigButton>
		</div>
	);
}

