import {Button, Container, FormGroup, FormText} from "reactstrap";
import {App} from "../../App";
import {Api} from "../../../shared";
import {Delete} from "./Delete";
import {Form} from "./Form";
import {useState} from "react";

export function Edit(props: {reload: () => any, component: Api.Staff.Component.TypeID, components: Api.Staff.Component.TypeID[]})
{
	const [error, setError] = useState<string>();
	const [showError, setShowError] = useState(false);
	const [component, setComponent] = useState<Api.Staff.Component.Type>(props.component);
	const [disabled, setDisabled] = useState(false);

	async function doConfirm()
	{
		if(error) {
			setShowError(true);
			return;
		}

		setDisabled(true);

		let res = await Api.Staff.Component.Call.create({...component, id: props.component.id});

		if(res.error) {
			setError("Error: " + res.error);
			setShowError(true);
			setDisabled(false);
			return;
		}

		await props.reload();
		App.open(null);
	}

	function doDelete()
	{
		App.open(() => <Delete reload={props.reload} component={props.component} components={props.components} />, false);
	}

	return (
		<Container>
			<h4>Edit Component</h4>
			<Form component={component} onError={setError} onChange={setComponent} showErrors={showError} />
			<div><FormText color="danger">{error && showError ? error : ``}</FormText></div>
			<FormGroup>
				<Button onClick={() => App.open(null)}>Back</Button>
				<Button onClick={doConfirm} disabled={disabled}>Save</Button>
				<Button onClick={doDelete}>Delete</Button>
			</FormGroup>
		</Container>
	);
}

