
import React from "react";
import { Container, Col, Button, FormText } from "reactstrap";
import { App } from "../App";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {

}

interface IState {
	code: string;
	valid: boolean;
	loaded: boolean;
	invalid_reason?: string;
	recover_error: string;
	button_disabled: boolean;
}

export class Recover extends React.Component<IProps, IState>
{
	constructor(props: IProps) {
		super(props);

		this.state = {
			code: "",
			valid: false,
			loaded: false,
			recover_error: "",
			button_disabled: false,
		}
	}

	async componentDidMount()
	{
		let query = new URL(window.location.href);
		let code = query.searchParams.get("code");

		if(!code) {
			this.setState({
				loaded: true,
				invalid_reason: "Invalid query parameters",
			});
			return;
		}

		this.setState({
			loaded: true,
			valid: true,
			code: code,
		});
	}

	async recover()
	{
		if(!this.state.loaded || !this.state.valid) {
			return;
		}

		this.setState({
			button_disabled: true,
		});

		let res = await Api.Auth.Call.recoverEmail({
			code: this.state.code,
		});

		if(res.error) {
			if(res.error === "invalid token") {
				this.setState({
					recover_error: "Invalid token",
				});
				return;
			}
			this.setState({
				recover_error: res.error,
			});
			throw res.error;
		}

		App.routeToUrl("/login");
	}

	render() {

		if(!this.state.loaded) {
			return <></>;
		}

		if(!this.state.valid) {
			return (
				<div className="page-confirm">
					<Container>

						<Col><h4>Invalid Request</h4></Col>

						<Col><p>

							We encountered an issue while
							processing your email recover
							request.

						</p></Col>

						<Col><p>
							
							Reason: {this.state.invalid_reason}

						</p></Col>

					</Container>
					<Component.Padding type="bottom" />
				</div>
			)
		}

		return (
			<div className="page-confirm">
				<Container>

					<Col><h4>Recover Account Email</h4></Col>

					<Col><p>
						
						Please click the button below to
						recover your account's email.

					</p></Col>

					<Col>
						<Button
							onClick={() => this.recover()}
							disabled={this.state.button_disabled || this.state.recover_error !== ""}
						>Recover</Button>
						<FormText color="danger">
							{this.state.recover_error}
						</FormText>
					</Col>

				</Container>
				<Component.Padding type="bottom" />
			</div>
		)
	}
}
