import { Container, Col, Button } from "reactstrap";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import { UserHasCapability, UserCapability } from "../../shared/User";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { SessionReload } from "../AppHelpers";
import { RemoveDraftJSWhitespaces } from "../../shared/helpers/DataHelpers";
import { OnActive, ReactComponent } from "../../scripts/util";
import { App } from "../App";
import {Api} from "../../shared";
import {Component} from "../..";

let homeData: EditorState | undefined = undefined;

interface IProps {

}

interface IState {
	editorState: EditorState;
	editMode: boolean;
	loaded: boolean;
	session: Api.Auth.SessionType;
}

export class Home extends ReactComponent<IProps, IState>
{
	constructor(props: IProps) {
		super(props);

		this.state = {
			editorState: EditorState.createEmpty(),
			editMode: false,
			loaded: false,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	onMount() {
		this.loadHomeData();
	}

	handleState(state?: EditorState) {
		if(state) {
			this.setState({
				editorState: state,
			});
		}
	}

	async loadHomeData()
	{
		let response = await Api.Info.Call.home({});

		if(response.data && response.data.home) {
			homeData = EditorState.createWithContent(convertFromRaw(response.data.home));
		}

		else {
			homeData = EditorState.createEmpty();
		}

		this.setState({
			editorState: homeData,
			loaded: true,
		});
	}

	async updateHomeData()
	{
		let res = await App.openDialogue({
			title: "Update the home page",
			prompt: true,
			content: () => (
				<p>Are you sure you want to update the home page?</p>
			),
		});

		if(!res)
		{
			return;
		}

		homeData = this.state.editorState;

		if(!homeData) {
			return;
		}

		this.setState({
			editMode: false,
		});

		await Api.Admin.Call.home({
			data: RemoveDraftJSWhitespaces(
				convertToRaw(homeData.getCurrentContent())
			),
		});

		await SessionReload();

		this.handleState(
			EditorState.createWithContent(
				convertFromRaw(
					RemoveDraftJSWhitespaces(
						convertToRaw(homeData.getCurrentContent())
					)
				)
			)
		);
	}

	render() {

		if(!this.state.loaded) {
			return <></>;
		}

		return (
			<div className="page-home">

				<Container>

					{this.state.editMode ? (
						<>
							<Col><Component.TextEditor
								editorState={this.state.editorState}
								onChange={(editorState) => {
									this.handleState(editorState);
								}}
							/></Col>

							<Col className="spacer">
								<Button onClick={() => this.updateHomeData()}>Update</Button>
							</Col>
							
						</>
					) : (
						<>
							<Col><Editor
								readOnly={true}
								plugins={Component.TextEditorPlugins}
								editorState={this.state.editorState}
								onChange={() => {}}
							/></Col>

							{this.state.session.user &&
							 UserHasCapability(this.state.session.user.permission, UserCapability.ADMIN) ? (
								<Col className="spacer">
									<Button onClick={() => {
										this.setState({
											editMode: true,
										});
									}}>Edit</Button>
								</Col>
							) : <></>}
						</>
					)}

					<Col>
						<p>
							You may modify your cookie preferences <span className="link" {...OnActive(Component.Notice_GetCookiePreferences)}>here.</span>
						</p>
					</Col>

				</Container>

				<Component.Padding type="bottom" />
			</div>
		);
	}
}
