import {useState} from "react";
import {SideBarItem} from ".";
import {StripPathname} from "../../shared/helpers/DataHelpers";
import {OnActive} from "../../scripts/util";
import {App} from "../../app/App";

function checkPaths(path: string, items: SideBarItem[]) {
	for(let item of items) {
		if(item.to && StripPathname(item.to) === path) {
			return true;
		}
		if(item.items && checkPaths(path, item.items)) {
			return true;
		}
	}
	return false;
}

export function SideList(props: {items: SideBarItem[]})
{
	let path = StripPathname(window.location.pathname);
	let items_e: JSX.Element[] = [];

	const [expanded, setExpanded] = useState(() => {
		let elements: boolean[] = [];
		for(let i = 0; i < props.items.length; i++) {
			let item = props.items[i];
			elements.push(item.items ? checkPaths(path, item.items) : false);
		}
		return elements;
	});

	for(let i = 0; i < props.items.length; i++) {
		let item = props.items[i];
		let is_expanded = expanded[i];
		let classes: string[] = ["side-bar-clickable"];
		let display: string[] = [item.display];
		if(item.to && StripPathname(item.to) === path || is_expanded) {
			classes.push("selected");
		}
		if(item.items) {
			display.push(is_expanded ? "↓" : "←");
		}
		function click() {
			if(item.to) {
				App.routeToUrl(item.to);
			}
			if(item.items) {
				expanded[i] = !is_expanded;
				setExpanded([...expanded]);
			}
		}
		items_e.push(
			<li key={i*2} className={classes.join(" ")} {...OnActive(click)}>{display.join(" ")}</li>
		);
		if(item.items && is_expanded) {
			items_e.push(
				<li key={i*2+1} className="side-bar-dropdown"><SideList items={item.items} /></li>
			);
		}
	}

	return <ul>{items_e}</ul>;
}

