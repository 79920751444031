import {Col} from "reactstrap";
import {App} from "../../App";
import {OnActive} from "../../../scripts/util";
import {Component} from "../../..";
import {User} from ".";
import {Helpers} from "../../../shared";

export const RenderModLog = (ref: User) =>
{
	if(!ref.state.user)
	{
		return <></>;
	}

	let user = ref.state.user;
	let elements: JSX.Element[] = [];
	let it = 0;

	if(!user.mod_log || user.mod_log.length === 0)
	{
		return <></>;
	}

	for(let item of user.mod_log)
	{
		elements.push(
			<Component.Card key={it++}>
				<p>
					{item.content}
				</p>
				<p>
					Executed by <span className="link" {...OnActive(() => App.routeToUrl("/staff/user?"+Helpers.Data.QueryString({
						email: user.email
					})))}>{user.email}</span> at {new Date(item.created).toLocaleString()}
				</p>
			</Component.Card>
		);
	}

	return (
		<Col className="spacer">
			<h5>Action Log</h5>
			<Component.Skipper>
				<div className="scroll-window">
					{elements}
				</div>
			</Component.Skipper>
		</Col>
	);
}
