import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormText } from "reactstrap";
import { OnActive } from "../../scripts/util";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import {Component} from "../..";

export const Recaptcha = (props: ReCAPTCHA["props"]) =>
{
	const [session, setSession] = useState(GlobalVariables.Session.value);
	GlobalVariables.Session.regUse(setSession);

	if(session.cookies.includes("GOOGLE"))
	{
		return <ReCAPTCHA {...props} />;
	}

	else
	{
		return (
			<div style={{padding: "16px 0"}}>
				<FormText color="danger">
					Cannot use Google ReCAPTCHA due to your cookie preferences. To use Google ReCAPTCHA, please allow
					Google <span className="link" {...OnActive(Component.Notice_GetCookiePreferences)}>here.</span>
				</FormText>
			</div>
		)
	}
}
