import qrcode from "qrcode";
import xssFilters from "xss-filters";
import {useId, useState, useEffect} from "react";
import {Container, Col, FormGroup, Input, FormText, Button} from "reactstrap";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {VerifyTOTP, secureRandom, EncodeBase32} from "../../shared/Security";
import {App} from "../App";
import {SessionReload} from "../AppHelpers";
import {Api, Helpers} from "../../shared";

export const AddMFA_Auth = (props: {secret: Buffer, callback?: (v: boolean) => void}) =>
{
	const id1 = useId();
	const id2 = useId();
	const [errors, setErrors] = useState("");
	const [code, setCode] = useState("");

	const close = (v: boolean) => {
		App.open(null);
		if(props.callback) {
			props.callback(v);
		}
	}

	const verify = async () =>
	{
		if(VerifyTOTP(props.secret, parseInt(code)))
		{
			await Api.Auth.Call.addMFA({
				token: props.secret.toString("hex"),
			});

			await SessionReload();
			close(true);
		}

		else
		{
			setErrors("Invalid Code");
		}
	}

	return (
		<Container role="dialogue" aria-aria-labelledby={id2}>
			<Col><h4 id={id2}>Setup Authenticator Code</h4></Col>
			<Col><FormGroup><p>
				We now must verify that your code app works correctly.
				Verify that it works by entering the number in the
				Authenticator app. 
			</p></FormGroup></Col>
			<Col>
				<FormGroup>
					<label htmlFor={id1} className="visually-hidden">Your authenticator code</label>
					<Input id={id1} onChange={(e) => setCode(e.target.value)} type="text" placeholder="Authenticator Code" autoComplete="off" />
					<FormText color="danger">{errors}</FormText>
				</FormGroup>
			</Col>
			<Col>
				<FormGroup>
					<Button onClick={verify}>Submit</Button>
					<Button onClick={() => close(false)}>Cancel</Button>
				</FormGroup>
			</Col>
		</Container>
	);
}



export const AddMFA = (props: {callback?: (v: boolean) => void}) =>
{
	const [session, setSession] = useState(GlobalVariables.Session.value);
	GlobalVariables.Session.regUse(setSession);
	let user = session.user;

	const [secret, _] = useState(() => secureRandom(20));
	const secret_b32 = EncodeBase32(secret);
	const email_s = xssFilters.uriPathInUnQuotedAttr(user?.email ?? "");
	const [url, setUrl] = useState<string>();
	const id1 = useId();

	useEffect(() =>
	{
		qrcode.toDataURL(`otpauth://totp/${email_s}?` + Helpers.Data.QueryString({
			secret: secret_b32,
			issuer: "Goulds Natural Medicine",
			algorithm: "SHA1",
			digits: "6",
			period: "30",
		}))
		
		.then((data) => {
			setUrl(data);
		});
	});

	if(!session.user)
	{
		return <></>;
	}

	user = session.user;

	const close = (v: boolean) => {
		App.open(null);
		if(props.callback) {
			props.callback(v);
		}
	}

	const add_email_mfa = async () =>
	{
		await Api.Auth.Call.addMFA({
			token: "00",
		});

		await SessionReload();
		close(true);
	}

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<p id={id1} className="visually-hidden">Add multi-factor authentication</p>
			<Col className="spacer">
				<h4>Setup Authenticator App</h4>
				<p>
					Using an Authenticator app (such as Google Authenticator),
					scan the QR code generated below or enter the setup key.
					Once imported into the app, press next. 
				</p>
				<img src={url} alt="Authenticator QR Code"/>
				<p>{secret_b32}</p>
				<p>
					<Button onClick={() => App.open(() => <AddMFA_Auth secret={secret} callback={props.callback} />)}>Next</Button>
					<Button onClick={() => close(false)}>Cancel</Button>
				</p>
			</Col>

			<Col className="spacer">
				<h4>Email-Based Multi-Factor Authentication</h4>
				<p>
					If you want, you can opt to set up just our
					email-based multi-factor authentication instead
					so you receive a code via email when you
					want to log in. 
				</p>
				<p>
					<Button onClick={() => add_email_mfa()}>Ok</Button>
					<Button onClick={() => close(false)}>Cancel</Button>
				</p>
			</Col>
		</Container>
	);
}

