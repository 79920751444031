import React from "react";
import {Container, Input} from "reactstrap";
import {GenID} from "../../scripts/util";
import {App} from "../App";
import {Api, Helpers} from "../../shared";
import {Component, Page} from "../..";

interface IState {
	products?: Api.Product.ProductSearchedType[];
}

interface IProps {

}

export class OutOfStock extends React.Component<IProps, IState>
{
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	async componentDidMount()
	{
		let response = await Api.Staff.Product.Call.getOutOfStock({});

		if(!response.data) {
			return;
		}

		this.setState({
			products: response.data.products,
		});
	}

	id_search = GenID();

	render()
	{
		return (
			<Page.Staff>
				{this.state.products ? (
					<Container>
						<h4>Out of stock</h4>
						<label htmlFor={this.id_search} className="visually-hidden">Filter out of stock products</label>
						<Input
							id={this.id_search}
							type="search"
							placeholder="Search"
							onChange={(e) => {
								if(!this.state.products) {
									return;
								}
								//TODO
							}}
						/>

						<Component.Skipper>{(() => {

							let elements: JSX.Element[] = [];

							if(!this.state.products) {
								return elements;
							}

							for(let i=0;i<this.state.products.length;i++)
							{
								let product = this.state.products[i];
								elements.push(
									<Component.Product
										key={i}
										product={product}
										onClick={() => {
											App.routeToUrl("/store/item?" + Helpers.Data.QueryString({
												id: product.id,
											}));
										}}
									/>
								);
							}

							return elements;

						})()}</Component.Skipper>

					</Container>
				) : <></>}
			</Page.Staff>
		)
	}
}
