
import "./style.scss";

import {Col, Input, Button, FormGroup} from "reactstrap";
import {EditorState, convertFromRaw} from "draft-js";
import Editor from "@draft-js-plugins/editor";
import {UserHasCapability, UserCapability} from "../../shared/User";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {App} from "../../app/App";
import {SessionReload} from "../../app/AppHelpers";
import {GenID, OnActive, ReactComponent} from "../../scripts/util";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";

interface IProps {
	product: Omit<Api.Product.ProductType, 'components'> & {components?: (string[] | Api.Product.ProductType["components"])};
	viewOnly?: boolean;
	hideTools?: boolean;
	selected?: string;
}

interface IState {
	selected: number;
	checkRes?: Api.Cart.CheckType;
	session: Api.Auth.SessionType;
}

export class ProductInfo extends ReactComponent<IProps, IState>
{
	amountElement?: HTMLInputElement;
	amount = 1;

	constructor(props: IProps) {
		super(props);

		let selected = 0;

		if(props.selected)
		{
			for(let i = 0; i < props.product.options.length; i++)
			{
				let option = props.product.options[i];

				if(option.id === props.selected)
				{
					selected = i;
					break;
				}
			}
		}

		this.state = {
			selected: selected,
			session: GlobalVariables.Session.regSet(this, "session"),
		}
	}

	async onMount()
	{
		await this.update();
	}

	async update(state = this.state)
	{
		if(this.amountElement) {
			this.amountElement.value = this.amount.toString();
		}

		let res = await Api.Cart.Call.check({
			add: "true",
			count: this.amount.toString(),
			product: this.props.product.options[state.selected].id,
		});

		if(!res.data)
		{
			this.forceUpdate();
			throw res.error;
		}

		this.setState({
			checkRes: res.data.code
		});
	}

	product_buy_error_msg(code?: Api.Cart.CheckType)
	{
		if(code === "cart too large")
		{
			return (
				`You have reached the the limit of how much you can buy of this product.`
			);
		}

		else if(code === "product not prescribed")
		{
			return (
				`You need to have a recommendation from a qualified health `+
				`professional to purchase this item. Please call our `+
				`apothecary (+61 3 6234 4223) during business hours `+
				`and ask to speak to one of our Naturopaths. `
			);
		}
		
		else if(code === "product not found")
		{
			return (
				`The product you asked for doesn't exist. `+
				`Please make sure to check the spelling of the URL. `
			);
		}

		else if(code === "out of stock")
		{
			return (
				`This product is currently out of stock. Please contact `+
				`our apothecary team on +61 3 6234 4223 during business `+
				`hours to suggest an alternative. `
			);
		}

		else if(code === "bad cookie prefs")
		{
			return (
				<>
					This product cannot be added to your cart due to your
					cookie preferences. To use the store, please enable
					cart cookies <span className="link" {...OnActive(async () => {
						await Component.Notice_GetCookiePreferences();
						await this.update();
					})}>here</span> or log in. 
				</>
			);
		}

		return "";
	}

	render_edit_button()
	{
		let user = this.state.session.user;

		if(this.props.viewOnly || !user || !UserHasCapability(user.permission, UserCapability.STAFF))
		{
			return <></>;
		}

		let style: React.CSSProperties = {float: "right"};

		return (
			<Button
				onClick={() => {
					App.routeToUrl("/staff/edit-product?" + Helpers.Data.QueryString({
						id: this.props.product.id,
					}));
				}}
				style={style}
			>Edit</Button>
		);
	}

	id1 = GenID();
	id2 = GenID();

	render_product_opts()
	{
		let product = this.props.product;

		if(product.options.length <= 1)
		{
			return <></>;
		}

		return (
			<div>
				<h5>Product Options</h5>
				<label className="visually-hidden" htmlFor={this.id1}>Pick a product option</label>
				<Input
					id={this.id1}
					type="select"
					onChange={(e) => {
						let selected = parseInt(e.target.value);
						this.setState({
							selected: selected,
						});
						this.update({
							selected: selected,
							session: this.state.session,
							checkRes: this.state.checkRes,
						});
					}}
				>
					{(()=>{

						let elements: JSX.Element[] = [];

						for(let i = 0; i < product.options.length; i++)
						{
							let option = product.options[i];
							elements.push(
								<option key={i} value={i}>
									{option.name}
								</option>
							);
						}

						return elements;

					})()}
				</Input>
			</div>
		);
	}

	render_tools()
	{
		if(this.props.hideTools)
		{
			return <></>;
		}

		let product = this.props.product;
		let selected_id = this.state.selected;
		let selected = this.props.product.options[selected_id];

		return (
			<div style={{
				display: "inline-block",
				width: GlobalVariables.IsPhone ? "100%" : "calc(100% - 320px)",
				padding: "20px",
				float: GlobalVariables.IsPhone ? undefined : "right",
			}}>
				{this.render_product_opts()}

				<div className={product.options.length > 1 ? "spacer" : ""}>
					<div className="product-amount-select">

						<label className="visually-hidden" htmlFor={this.id2}>Add to cart amount</label>
						<div className="adder">
							<button
								aria-label="Subtract"
								onClick={() => {
									if(this.amount > 1) {
										this.amount -= 1;
										this.update();
									}
								}}
							>-</button>
						</div>
						<div
							className="counter"
						>
							<input
								id={this.id2}
								ref={(e) => {
									if(e) {
										this.amountElement = e;
									}
								}}
								onChange={(e) => {

									if(!this.amountElement) {
										return;
									}

									let a = this.amountElement.value;
									let n = "";

									for(let c of a) {
										if(c === ".") {
											break;
										}
										if("1234567890e-".includes(c)) {
											n += c;
										}
									}

									this.amount = parseInt(n);

									if(!isFinite(this.amount) || this.amount < 1) {
										this.amount = 1;
									}

									if(this.amount > 1000) {
										this.amount = 1000;
									}

									this.update();

								}}
								onBlur={() =>
								{
									if(!this.amountElement) {
										return;
									}

									let a = this.amountElement.value;
									let n = "";

									for(let c of a) {
										if(c === ".") {
											break;
										}
										if("1234567890e-".includes(c)) {
											n += c;
										}
									}

									this.amount = parseInt(n);

									if(!isFinite(this.amount) || this.amount < 1) {
										this.amount = 1;
									}

									if(this.amount > 1000) {
										this.amount = 1000;
									}

									this.update();
								}}
								defaultValue={this.amount}
							/>
						</div>
						<div className="adder">
							<button
								aria-label="Add"
								onClick={() => {
									if(this.amount < 1000) {
										this.amount += 1;
										this.update();
									}
								}}
							>+</button>
						</div>

					</div>
				</div>

				<div className="spacer">
					<Component.BigButton
						disabled={
							this.props.viewOnly ||
							(this.state.checkRes && this.state.checkRes !== "valid")
						}
						bigOnClick={async () =>
						{
							await Api.Cart.Call.addProduct({
								count: this.amount,
								id: selected.id,
							});

							await SessionReload();

							App.routeToUrl("/cart");

						}}
					>Add To Cart</Component.BigButton>
					<p>{this.product_buy_error_msg(this.state.checkRes)}</p>
				</div>
			</div>
		)
	}

	render()
	{
		let product = this.props.product;
		let selected_id = this.state.selected;
		let selected = this.props.product.options[selected_id];
		let elements: JSX.Element[] = [];
		let now = Date.now();
		let it = 0;

		elements.push(<span key={it++}>Price: ${(selected.amount / 100).toFixed(2)} AUD</span>);

		if(product.expiry != null)
		{
			if(product.expiry < now)
			{
				elements.push(<br />);
				elements.push(<span style={{color: "red"}} key={it++}>Expired on {new Date(product.expiry).toLocaleString()}</span>);
			}

			else
			{
				elements.push(<br />);
				elements.push(<span key={it++}>Expires on {new Date(product.expiry).toLocaleString()}</span>);
			}
		}

		if(product.uses != null && product.uses_max != null && (product.expiry == null || product.expiry > now))
		{
			elements.push(<br />);
			
			if(product.uses === product.uses_max - 1)
			{
				elements.push(<span key={it++}>1 repeat left</span>);
			}

			else if(product.uses >= product.uses_max)
			{
				elements.push(<span key={it++} style={{color: "red"}}>0 repeats left</span>)
			}

			else
			{
				elements.push(<span key={it++}>{product.uses_max - product.uses} repeats left</span>);
			}
		}

		let product_info_e: JSX.Element;
		let editor_e = (
			<Editor
				readOnly={true}
				editorState={product.description ? EditorState.createWithContent(convertFromRaw(product.description)) : EditorState.createEmpty()}
				onChange={() => {}}
				plugins={Component.TextEditorPlugins}
			/>
		);

		if(product.template || product.components && product.components.length > 0)
		{
			let ingredients_e: JSX.Element[] = [];

			for(let item of product.components ?? [])
			{
				let line: string[] = [];

				if(typeof(item) === "string") {
					line.push(item);
				} else {
					line.push(item.name);
					line.push("(" + Helpers.String.FormatAmount(item.quantity_type, item.amount_per_q * selected.sizing!) + ")");
					if(item.out_stock) {
						line.push("(Out Of Stock)");
					}
				}

				ingredients_e.push(<li key={line[0]}>{line.join(" ")}</li>);
			}

			product_info_e = (
				<>
					<FormGroup>{editor_e}</FormGroup>
					<h5>Ingredients</h5>
					<ul>{ingredients_e}</ul>
				</>
			);
		}

		else
		{
			product_info_e = editor_e;
		}

		return (
			<div className="component-product-info">

				<Col>{product.name ? (<h4>{product.name}</h4>) : <></>}</Col>

				<Col>
					<div style={{
						display: "inline-block",
						padding: "20px",
						width: GlobalVariables.IsPhone ? "100%" : "320px",
					}}><>
						<img aria-hidden="true" className="product-image" src={Api.Files.Image(product.image_id, product.image_name)+"?w=560"} style={{
							width: "280px",
							height: "auto",
							maxWidth: "100%",
							display: "block",
						}} />
						
						<div style={{
							float: "left",
							paddingTop: "8px",
						}}>
							<p>{elements}</p>
						</div>

						{this.render_edit_button()}
					</></div>

					{this.render_tools()}
				</Col>

				<h5 className="visually-hidden">Product information</h5>
				{product_info_e}

			</div>
		)
	}
}
