import React, { useId } from "react";
import { Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { FormHelperItem } from "../../scripts/formhelper";
import { GenID } from "../../scripts/util";

interface IProps
{
	form: FormHelperItem;
}

export class ProductName extends React.Component<IProps>
{
	errors(v: string)
	{
		if(!v)
		{
			return "Required";
		}

		else
		{
			return "";
		}
	}

	id1 = GenID();

	render()
	{
		return (
			<Col><FormGroup>

				<Label htmlFor={this.id1}>Product Name</Label>
				<Input id={this.id1} maxLength={100} {...this.props.form.addInput((v) => this.errors(v))} />

				<FormText color="danger">
					{this.props.form.addErrorField()}
				</FormText>

			</FormGroup></Col>
		)
	}
}
