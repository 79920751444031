import {Auth, Staff} from "..";
import {GetType, PostType} from "../Api";
import * as CallType from "../CallType";

export interface ClientType
{
	name: string;
	phone: string;
	email: string;
	products: Staff.User.UserProductType[];
}

export interface GenAddRequest extends PostType
{
	Res: {
		request_id: string;
	};
}

export interface GetClients extends GetType
{
	Res: {
		clients: Auth.UserBasicType[];
	};
}

export interface RemoveClient extends PostType<"invalid user">
{
	Body: {
		email: string;
	};
	Res: {
		clients: Auth.UserBasicType[];
	};
}

export interface GetClient extends GetType<"invalid user">
{
	Query: {
		email: string;
	};
	Res: {
		client: ClientType;
	};
}

export const Call =
{
	genAddRequest: CallType.Post<GenAddRequest>("/practitioner/user/genAddRequest"),
	getClients: CallType.Get<GetClients>("/practitioner/user/getClients"),
	getClient: CallType.Get<GetClient>("/practitioner/user/getClient"),
	removeClient: CallType.Post<RemoveClient>("/practitioner/user/removeClient"),
};

