import {Button, Container, FormText} from "reactstrap";
import {App} from "../../App";
import {useState} from "react";
import {Api} from "../../../shared";
import {ViewCard} from "./ViewCard";

export function Delete({reload, template}: {reload: () => any, template: Api.Staff.Template.Type})
{
	const [disabled, setDisabled] = useState(false);
	const [force, setForce] = useState(false);
	const [error, setError] = useState("");

	async function doDelete()
	{
		setDisabled(true);

		let res = await Api.Staff.Template.Call.delete({
			name: template.name,
			force: force,
		});

		if(res.data && res.error === "rejected") {
			setError(`Removing this template will remove ${res.data.found} products. Click 'ok' again to confirm.`);
			setDisabled(false);
			setForce(true);
			return;
		}

		App.open(null);
		App.open(null);

		reload();
	}

	return (
		<Container>
			<h4>Delete Template</h4>
			<p>Are you sure you want to delete this product template?</p>
			<ViewCard template={template} />
			<div className="spacer">
				<div><FormText color="danger">{error}</FormText></div>
				<Button disabled={disabled} onClick={() => App.open(null)}>Back</Button>
				<Button disabled={disabled} onClick={doDelete}>Ok</Button>
			</div>
		</Container>
	);
}

