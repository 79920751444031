
import {Button} from "reactstrap";
import {Component} from "../..";
import {Api} from "../../shared";
import {App} from "../App";
import {useState} from "react";

export function AddProduct(props: {
	preset?: Partial<Api.Practitioner.Product.TemplateType>;
	callback: (product: Api.Practitioner.Product.TemplateType, demo_product: Api.Product.ProductType, id: string) => any;
	onDemoUpdate?: (p: Api.Product.ProductType) => any;
	showStaffOpts: boolean;
	showPublicOpts?: boolean;
}) {
	const [demo_product, setDemoProduct] = useState<Api.Product.ProductType>();

	async function submit(product: Api.Practitioner.Product.TemplateType)
	{
		let res = await Api.Practitioner.Product.Call.create({product: product});

		if(!res.data) {
			throw res.error;
		}

		await props.callback(product, demo_product!, res.data.id);
	}

	function demoUpdate(p: Api.Product.ProductType) {
		setDemoProduct(p);
		if(props.onDemoUpdate) {
			props.onDemoUpdate(p);
		}
	}

	return (
		<Component.AddTemplateProduct showPublicOpts={props.showPublicOpts ?? false} showStaffOpts={props.showStaffOpts} onSubmit={submit} onDemoUpdate={demoUpdate} product={props.preset} >
			<Button onClick={() => App.open(null)}>Close</Button>
		</Component.AddTemplateProduct>
	);
}

