
import "./style.scss";

import React from "react";
import { OrderStatusToString } from "../../shared/Order";
import { App } from "../../app/App";
import { Button, Col, Row } from "reactstrap";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {
	order: Api.Staff.Order.OrderBasicType;
	setHidden: boolean;
}

interface IState {
	order: Api.Staff.Order.OrderBasicType;
}

export class OrderCard extends React.Component<IProps, IState>
{
	constructor(props: IProps)
	{
		super(props);

		this.state = {
			order: props.order,
		};
	}

	async toggleHidden()
	{
		let res = await Api.Staff.Order.Call.setHidden({
			id: this.state.order.id,
			hidden: !this.state.order.is_hidden,
		});

		if(res.error)
		{
			throw res.error;
		}

		this.state.order.is_hidden = this.state.order.is_hidden ? 0 : 1;
		
		this.setState({
			order: this.state.order,
		});
	}

	render()
	{
		let hidden_b = <></>;

		if(this.props.setHidden)
		{
			hidden_b = (
				<div className="component-order-card-button">
					<Button onClick={(e) =>
					{
						e.stopPropagation();
						this.toggleHidden();
					}}>
						{this.state.order.is_hidden ? "Unhide" : "Hide"}
					</Button>
				</div>
			);
		}

		return (
			<div className="component-order-card">
				<Component.Card onClick={() => {
					App.routeToUrl("/order?id=" + this.state.order.id);
				}}>

					{hidden_b}

					<p>
						Customer: {this.state.order.name}<br/>
						Email: {this.state.order.email}<br/><br/>
						Created: {new Date(this.state.order.created).toLocaleString()}<br/>
						Reference number: {this.state.order.ref}<br/><br/>
						Status: {OrderStatusToString(this.state.order.step, this.state.order.ship)}{
							this.state.order.is_hidden ? <> (hidden)</> : <></>
						}<br/>
						Total: ${(this.state.order.total / 100).toFixed(2)} AUD
					</p>

				</Component.Card>
			</div>
		)
	}
}
