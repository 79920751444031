import React from "react";
import { Button, Col, FormGroup, FormText } from "reactstrap";
import { App } from "../../app/App";
import { FormHelperItem } from "../../scripts/formhelper";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps
{
	form: FormHelperItem<Api.Files.ImageType>;
}

interface IState
{
	
}

export class ImagePicker extends React.Component<IProps, IState>
{
	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}

	componentDidMount()
	{
		this.props.form.errors = (v) =>
		{
			if(!v)
			{
				return "Required";
			}

			else
			{
				return "";
			}
		}
	}

	select_image()
	{
		App.open(() =>
		(
			<Component.ImageSelector closeCallback={(image) =>
			{
				if(image)
				{
					this.props.form.modify(image);
				}

				App.open(null, false);

			}} />
		), false);
	}

	render()
	{
		return (
			<Col><FormGroup>

				<div><Button onClick={() => this.select_image()}>Pick Image</Button></div>

				<FormText color="danger">
					{this.props.form.addErrorField()}
				</FormText>

			</FormGroup></Col>
		)
	}
}
