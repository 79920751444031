import {Component} from "../..";
import {UserCapability} from "../../shared/User";
import {MakeRoute} from "../Routes";
import {Client} from "./Client";
import {Clients} from "./Clients";
import {ProductsPage} from "./Products";

export function Practitioner(props: {children?: any, render_callback?: () => JSX.Element}){
	return (
		<div className="page-staff">
			<Component.SideBarWindow
				name="Practitioner Tools"
				render_callback={props.render_callback}
				items={[
					{to: "/practitioner/clients", display: "My Clients"},
					{to: "/practitioner/products", display: "My Products"},
				]}
			>
				{props.children}
			</Component.SideBarWindow>
		</div>
	)
}

export const PractitionerRoute = () => MakeRoute(Practitioner, UserCapability.PRACTITIONER, {
	"products": MakeRoute(ProductsPage),
	"clients": MakeRoute(Clients),
	"client": MakeRoute(Client),
});

