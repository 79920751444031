
import "./style.scss";
import React, { createRef } from "react";
import { Button, FormText } from "reactstrap";
import { OnActive } from "../../scripts/util";
import {Api} from "../../shared";

interface IProps {
	product: Api.Product.ProductSearchedType | Api.Product.ProductType;
	onClick?: () => void;
	onRemove?: () => void;
	disabled?: boolean;
	children?: any;
}

const calc_min_amount = (product: Api.Product.ProductType) =>
{
	let min = Number.MAX_SAFE_INTEGER;

	for(let option of product.options)
	{
		if(option.amount < min)
		{
			min = option.amount;
		}
	}

	return min;
}

export class Product extends React.Component<IProps>
{
	render()
	{
		let product = this.props.product;
		let product_searched = product as Api.Product.ProductSearchedType;
		let product_full = product as Api.Product.ProductType;
		let ref = createRef<HTMLDivElement>();

		const click = () =>
		{
			if(this.props.disabled) {
				return;
			}
			if(this.props.onClick) {
				this.props.onClick();
			}
		}

		
		let repeats_left = "";
		let repeats_colour: string | undefined;

		if(product.uses != null && product.uses_max != null)
		{
			let repeats = product.uses_max - product.uses;

			if(repeats === 1)
			{
				repeats_left = "1 repeat left";
			}

			else if(repeats <= 0)
			{
				repeats_left = "0 repeats left";
				repeats_colour = "danger";
			}

			else
			{
				repeats_left = `${repeats} repeats left`;
			}
		}

		if(product.expiry != null && product.expiry < Date.now())
		{
			repeats_left = "Expired";
			repeats_colour = "danger";
		}

		return (
			<div
				className={"component-product" + (
					this.props.disabled ? " product-disabled" : " product-enabled"
				)} ref={ref} {...OnActive(click)} >
				<div className="image">
					<img aria-hidden="true" loading="lazy" className="product-image" src={
						Api.Files.Image(product.image_id, product.image_name) + "?w=400"
					} />
				</div>
				<div className="product-heading">
					{product.name ? (<h5>{product.name}</h5>) : <></>}
				</div>
				<div className="product-repeats">
					<FormText color={repeats_colour}>{repeats_left}</FormText>
				</div>
				<p>
					${((product_searched.amount !== undefined ? product_searched.amount : calc_min_amount(product_full)) / 100).toFixed(2)} AUD
					{this.props.onRemove ? (
						<Button
							onClick={(e) => {
								if(this.props.onRemove) {
									e.stopPropagation();
									this.props.onRemove();
								}
							}}
							style={{
								float: "right",
							}}
						>Remove</Button>
					) : <></>}
					{this.props.children ?? <></>}
				</p>
			</div>
		)
	}
}
