import {SQLEnum} from "../../SQLEnum";
import {GetType, PostType} from "../Api";
import * as CallType from "../CallType";

export interface OrderBasicType
{
	id: string;
	name: string;
	email: string;
	created: number;
	ref: number;
	step: SQLEnum.OrderStep;
	is_hidden?: SQLEnum.Bool;
	total: number;
	ship: boolean;
}

export interface CommentType
{
	name: string;
	email: string;
	created: number;
	content: string;
}

export type OrderStatusType = "PAID" | "SHIP" | "PICKUP" | "BANK" | "PHONE" | "HIDDEN" | "OLD";

export interface GetAll extends GetType
{
	Query: {
		type: OrderStatusType;
		older?: string;
		after?: string;
	}
	Res: {
		orders: OrderBasicType[];
	}
}

export interface Refund extends PostType<"order not found" | "order already cancelled">
{
	Body: {
		id: string;
	}
}

export interface SetStatus extends PostType<"order already processed" | "cannot dispense item">
{
	Body: {
		id: string;
		step: SQLEnum.OrderStep;
		payment_method?: SQLEnum.PaymentMethod;
	}
}

export interface GetComments extends GetType
{
	Query: {
		id: string;
	}
	Res: {
		comments: CommentType[];
	}
}

export interface AddComment extends PostType<"order not found">
{
	Body: {
		id: string;
		content: string;
	}
}

export interface SetTracking extends PostType
{
	Body: {
		id: string;
		tracking: string;
	}
}

export interface SetHidden extends PostType<"order not found">
{
	Body: {
		id: string;
		hidden: boolean;
	}
}

export interface Lookup extends GetType
{
	Query: {
		ref: string;
	}
	Res: {
		orders: OrderBasicType[];
	}
}

export const Call =
{
	getAll: CallType.Get<GetAll>("/staff/order/getAll"),
	refund: CallType.Post<Refund>("/staff/order/refund"),
	setStatus: CallType.Post<SetStatus>("/staff/order/setStatus"),
	getComments: CallType.Get<GetComments>("/staff/order/getComments"),
	addComment: CallType.Post<AddComment>("/staff/order/addComment"),
	setTracking: CallType.Post<SetTracking>("/staff/order/setTracking"),
	setHidden: CallType.Post<SetHidden>("/staff/order/setHidden"),
	lookup: CallType.Get<Lookup>("/staff/order/lookup"),
};
