import React from "react";
import { Col } from "reactstrap";
import { GenID, OnActive } from "../../scripts/util";
import { GetDay } from "../../shared/helpers/DataHelpers";
import {Helpers} from "../../shared";
import {Component} from "../..";

export interface HistoryListButtonsProps
{
	callback: (d: Date) => any;
}

export class HistoryListButtons extends React.Component<HistoryListButtonsProps>
{
	render()
	{
		let now = new Date();
		let months = now.getFullYear() * 12 + now.getMonth();
		let elements: JSX.Element[] = [];

		for(let i = months; i >= 24245; i--)
		{
			let month = i % 12;
			let year = Math.floor(i / 12);
			let date = new Date();
			date.setMonth(month);
			date.setFullYear(year);

			const click = async () =>
			{
				this.props.callback(date);
			}

			elements.push(
				<li key={i} className="side-bar-clickable" {...OnActive(click)}>{Helpers.Data.MONTHS[month]} {year}</li>
			);
		}

		return <>
			<h5>Before</h5>
			<Component.Skipper>
				<ul>{elements}</ul>
			</Component.Skipper>
		</>;
	}
}

export interface HistoryListProps
{
	items: {
		element: JSX.Element;
		date: Date;
	}[];
}

export class HistoryList extends React.Component<HistoryListProps>
{
	id1 = GenID();

	render()
	{
		let now = GetDay();
		let categories_e: {[key: string]: JSX.Element[]} = {};
		let items_e: JSX.Element[] = [];
		let it = 0;

		for(let item of this.props.items)
		{
			let days = now - GetDay(item.date);
			let heading: string;

			if(days <= 0) {
				heading = "Today";
			} else if(days <= 1) {
				heading = "Yesterday";
			} else if(days <= 7) {
				heading = "Past Week";
			} else {
				heading = `${Helpers.Data.MONTHS[item.date.getMonth()]} ${item.date.getFullYear()}`;
			}

			if(!categories_e[heading])
			{
				let l: JSX.Element[] = [];

				categories_e[heading] = l;

				items_e.push(
					<Col className="spacer" key={it++}>
						<h5>{heading}</h5>
						<Component.Skipper>
							{l}
						</Component.Skipper>
					</Col>
				);
			}

			categories_e[heading].push(item.element);
		}

		return <>{items_e}</>;
	}
}
