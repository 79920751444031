
import React from "react";
import {Container, Col} from "reactstrap";
import {OnActive} from "../../scripts/util";
import {App} from "../App";
import {Api, Helpers} from "../../shared";
import {Component, Page} from "../..";

interface IProps {

}

interface IState {
	loaded: boolean;
	items: Api.Admin.LogType[];
}

export class Logs extends React.Component<IProps, IState>
{
	lock = false;

	constructor(props: IProps)
	{
		super(props);

		this.state = {
			loaded: false,
			items: [],
		};
	}

	componentDidMount() {
		this.load_more();
	}

	async load_more(now: Date | undefined = undefined, items = this.state.items)
	{
		let last_id: number | undefined;

		if(items.length > 0)
		{
			last_id = items[items.length - 1].id;
		}

		if(this.lock)
		{
			return;
		}

		this.lock = true;
		let res = await Api.Admin.Call.logs({
			at: last_id?.toString(),
			before: now?.getTime().toString(),
		});

		this.lock = false;

		if(!res.data)
		{
			throw res.error;
		}

		for(let item of res.data.logs)
		{
			items.push(item);
		}

		this.setState({
			loaded: true,
			items: items
		});
	}

	render()
	{
		if(!this.state.loaded) {
			return <Page.Admin />;
		}

		let it = 0;
		let e_items: {
			element: JSX.Element;
			date: Date;
		}[] = [];

		for(let item of this.state.items)
		{
			e_items.push({
				date: new Date(item.created),
				element: (
					<Component.Card key={it++}>
						<p>
							{item.content}
						</p>
						<p>
							Executed by <span className="link" {...OnActive(() => App.routeToUrl("/staff/user?"+Helpers.Data.QueryString({
								email: item.user_email
							})))}>{item.user_email}</span> at {new Date(item.created).toLocaleString()}
						</p>
					</Component.Card>
				)
			})
		}

		return (
			<Page.Admin render_callback={() => (<Component.HistoryListButtons callback={(d) => this.load_more(d, [])} />)}>
				<Container>
					
					<Col><h4>Action Logs</h4></Col>
					<Col><Component.HistoryList items={e_items} /></Col>
					<Col><Component.BigButton bigOnClick={() => this.load_more()}>Load More</Component.BigButton></Col>

				</Container>
			</Page.Admin>
		)
	}
}
