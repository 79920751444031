import {useState, useId} from "react";
import {Container, Col, Input, FormText, Button} from "reactstrap";
import {NameValidator} from "../../shared/Validators";
import {App} from "../App";
import {SessionReload} from "../AppHelpers";
import {Api} from "../../shared";

export const ChangeName = ({user}: {user: Api.Auth.UserType}) =>
{
	const [name, setName] = useState(user.name);
	const [errors, setErrors] = useState("");

	const click = async () =>
	{
		let errors_now = "";

		switch(NameValidator(name))
		{
			case "IllegalCharacters":
				errors_now = "Illegal characters";
				break;
			case "Required":
				errors_now = "Value is required";
				break;
			case "TooBig":
				errors_now = "Value is too big";
				break;
			case "Valid":
				errors_now = "";
				break;
		}

		if(name === user.name)
		{
			errors_now = "Value unchanged";
		}

		if(errors_now === "")
		{
			let res = await Api.Auth.Call.changeName({
				name: name
			});

			if(res.error !== undefined)
			{
				switch(res.error)
				{
					case "not logged in":
						errors_now = "Not logged in";
						break;
					case "unauthorised":
						errors_now = "Unauthorised";
						break;
					default:
						throw res.error;
				}
			}
		}

		setErrors(errors_now);

		if(errors_now === "")
		{
			await SessionReload();

			App.open(null);
		}
	}

	const id1 = useId();
	const id2 = useId();

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<Col><h4 id={id1}>Change Name</h4></Col>
			<Col>
				<label htmlFor={id2} className="visually-hidden">New name</label>
				<Input id={id2} placeholder="Your Name" defaultValue={name} onChange={(e) => {
					setName(e.currentTarget.value);
				}} />
				<FormText color="danger">{errors}</FormText>
			</Col>
			<Col className="spacer">
				<Button onClick={click}>Ok</Button>
				<Button onClick={() => App.open(null)}>Cancel</Button>
			</Col>
		</Container>
	);
}

