import {SQLEnum} from "../../../SQLEnum";
import {PostType} from "../../Api";
import * as CallType from "../../CallType";

export * as Favourites from "./Favourites";

export interface ComponentType {
	id?: string;
	name: string;
	amount_per_q: number;
};

export interface TemplateType {
	name: string;
	description: any;
	visibility: SQLEnum.ProductVisibility;
	cart_max?: number | null;
	image_id: string;
	image_name: string;
	gst_aud: SQLEnum.TaxTypeGST;
	vat_gbp: SQLEnum.TaxTypeVAT;
	uses?: number | null;
	brand: string;
	category: string;
	template: string;
	components: (ComponentType & {id: string})[];
	sizes: number[];
	is_fav?: boolean;
};

export interface Create extends PostType<"template not found" | "product not found" | "image not found"> {
	Body: {
		id?: string;
		product: TemplateType;
	};
	Res: {
		id: string;
	}
};

export interface Prescribe extends PostType<"product not found" | "user not found"> {
	Body: {
		email: string;
		product_id: string;
		uses_max?: number;
		expiry?: number;
		remove?: boolean;
	}
}

export const Call = {
	create: CallType.Post<Create>("/practitioner/product/create"),
	prescribe: CallType.Post<Prescribe>("/practitioner/product/prescribe"),
}

