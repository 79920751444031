
import "./style.scss";
import {useId, useState} from "react";
import {Container, Col, Button, Input, Label} from "reactstrap";
import {OrderStatusToString} from "../../shared/Order";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {UserHasCapability, UserCapability} from "../../shared/User";
import {App} from "../App";
import {SQLEnum} from "../../shared/SQLEnum";
import {GenID, OnActive, ReactComponent} from "../../scripts/util";
import {MakeRoute} from "../Routes";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";


interface IProps {

}

interface IState {
	order?: Api.Post.OrderType;
	comments?: Api.Staff.Order.CommentType[];
	error?: 403 | 404;
	logIn: boolean;
	session: Api.Auth.SessionType;
}

const ShipOrder = (props: {callback: (ship: boolean, tracking?: string) => void}) =>
{
	const [hasTracking, setHasTracking] = useState(false);
	const [tracking, setTracking] = useState("");
	const id1 = useId();
	const id2 = useId();
	const id3 = useId();

	return (
		<Container role="alertdialogue" aria-labelledby={id3}>
			<Col><h4 id={id3}>Order Status</h4></Col>
			<Col><p>
				Are you sure you want to ship this order?
			</p></Col>
			<Col>
				<Label htmlFor={id1}>Tracking number</Label>
				<Input
					id={id1}
					disabled={!hasTracking}
					onChange={(e) => {
						let v = e.target.value.replace(/[ \n\t]/g, "").toUpperCase();
						let v2 = "";
						for(let c of v) {
							if("1234567890QWERTYUIOPASDFGHJKLZXCVBNM".includes(c)) {
								v2 += c;
							}
						}
						setTracking(v2);
					}}
					onBlur={(e) => {
						let v = e.target.value.replace(/[ \n\t]/g, "").toUpperCase();
						let v2 = "";
						for(let c of v) {
							if("1234567890QWERTYUIOPASDFGHJKLZXCVBNM".includes(c)) {
								v2 += c;
							}
						}
						e.target.value = v2;
					}}
				/>
				<Input
					id={id2}
					type="checkbox"
					onChange={(e) => setHasTracking(e.target.checked)}
				/> <Label htmlFor={id2}>Use tracking number</Label>
			</Col>
			<Col>
				<Button onClick={() => props.callback(true, hasTracking ? tracking : undefined)}>Yes</Button>
				<Button onClick={() => props.callback(false)}>No</Button>
			</Col>
		</Container>
	);
}

export class Order extends ReactComponent<IProps, IState>
{
	aria_write_comment = GenID();

	commentElement?: HTMLInputElement | HTMLTextAreaElement;
	notesElement?: HTMLDivElement;

	constructor(props: IProps) {
		super(props);

		this.state = {
			logIn: false,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	async refund()
	{
		if(!this.state.order) {
			return;
		}

		let confirmation = await App.openDialogue({
			prompt: true,
			title: "Refund Order",
			content: () =>
			{
				let e: JSX.Element = <></>;

				if(this.state.order?.payment_method !== "CARD")
				{
					e = (
						<p>
							Since this payment was made outside of the Stripe
							payment system, you'll need to be sure that this payment
							has been manually refunded too. 
						</p>
					);
				}

				return (
					<Col>
						<p>
							Are you sure you want to refund this order?
							This action cannot be undone.
						</p>
						{e}
					</Col>
				);
			},
		});

		if(!confirmation || !this.state.order) {
			return;
		}

		let response = await Api.Staff.Order.Call.refund({
			id: this.state.order.id
		});

		if(response.error) {
			throw response.error;
		}

		this.state.order.step = "CANCELLED";
		this.state.order.is_hidden = 0;

		this.setState({
			order: this.state.order,
		});
	}

	async markAsPaid()
	{
		if(!this.state.order) {
			return;
		}

		let id1 = GenID();
		let method = this.state.order.payment_method ?? "BANK";

		let confirmation = await App.openDialogue({
			prompt: true,
			title: "Order Status",
			type: "okcancel",
			content: () => {

				if(!this.state.order) {
					return <></>;
				}

				return (
					<Col>
						<p>
							Are you sure you want to update
							this orders status from {
								OrderStatusToString(this.state.order.step, !!this.state.order.shipping)
							} to {
								OrderStatusToString("PAID", !!this.state.order.shipping)
							}?
						</p>
						<p>
							<Label for={id1}>Payment Method</Label>
							<Input
								id={id1}
								type="select"
								defaultValue={this.state.order.payment_method ?? ""}
								onChange={(e) => {
									method = e.target.value as SQLEnum.PaymentMethod;
								}}
							>
								<option value="BANK">Paid via direct deposit</option>
								<option value="PHONE">Paid via phone</option>
							</Input>
						</p>
					</Col>
				);
			},
		});

		if(!confirmation || !this.state.order)
		{
			return;
		}

		let response = await Api.Staff.Order.Call.setStatus({
			id: this.state.order.id,
			payment_method: method,
			step: "PAID",
		});

		if(response.error) {
			throw response.error;
		}

		this.state.order.step = "PAID";
		this.state.order.payment_method = method;
		this.state.order.is_hidden = 0;

		this.setState({
			order: this.state.order,
		});
	}

	async changeStatus(status: SQLEnum.OrderStep)
	{
		if(!this.state.order) {
			return;
		}

		let confirmation = await App.openDialogue({
			prompt: true,
			title: "Order Status",
			type: "okcancel",
			content: () => {

				if(!this.state.order) {
					return <></>;
				}

				return (
					<Col>
						<p>
							Are you sure you want to update
							this orders status from {
								OrderStatusToString(this.state.order.step, this.state.order.shipping ? true : false)
							} to {
								OrderStatusToString(status, this.state.order.shipping ? true : false)
							}?
						</p>
					</Col>
				);
			},
		});

		if(!confirmation || !this.state.order) {
			return;
		}

		let response = await Api.Staff.Order.Call.setStatus({
			id: this.state.order.id,
			step: status,
		});

		if(response.error) {
			throw response.error;
		}

		this.state.order.step = status;
		this.state.order.is_hidden = 0;

		this.setState({
			order: this.state.order,
		});
	}

	countryCodeToName(code: string)
	{
		if(code === "AU") {
			return "Australia";
		}

		for(let country of Component.ShippingInput.countries)
		{
			if(country.code === code) {
				return country.description;
			}
		}

		return "Unknown";
	}

	shipOrder()
	{
		const callback = async (ship: boolean, tracking?: string) =>
		{
			App.open(null);

			if(!ship || !this.state.order)
			{
				return;
			}

			if(tracking)
			{
				await Api.Staff.Order.Call.setTracking({
					id: this.state.order.id,
					tracking: tracking,
				});
			}

			let res = await Api.Staff.Order.Call.setStatus({
				id: this.state.order.id,
				step: "SHIPPED",
			});

			if(res.error)
			{
				throw res.error;
			}

			this.state.order.step = "SHIPPED";
			this.state.order.is_hidden = 0;
		
			if(this.state.order.shipping)
			{
				this.state.order.shipping.tracking = tracking;
			}

			this.setState({
				order: this.state.order,
			});
		}

		App.open(() => <ShipOrder callback={callback} />);
	}

	onMount() {
		this.update();

		if(Component.ShippingInput.countries.length === 0) {
			Api.Post.Call.countries({}).then((res) => {
				if(res.data) {
					Component.ShippingInput.countries = res.data.countries;
					this.forceUpdate();
				}
			})
		}
	}

	async update()
	{
		let query = new URL(window.location.href);
		let id = query.searchParams.get("id");

		if(!id || id.length !== 64) {
			this.setState({
				error: 404,
			});
			return;
		}

		for(let c of id) {
			if(!"0123456789ABCDEFabcdef".includes(c)) {
				this.setState({
					error: 404,
				});
				return;
			}
		}

		let response = await Api.Post.Call.getOrder({
			id: id,
		});

		if(!response.data)
		{
			if(response.error === "unauthorised") {
				if(!this.state.session.user) {
					this.setState({
						logIn: true,
					});
				} else {
					this.setState({
						error: 403,
					});
				}
				return;
			}
	
			if(response.error === "order not found") {
				this.setState({
					error: 404,
				});
				return;
			}
	
			else {
				throw response.error;
			}
		}

		let user = this.state.session.user;

		if(user && UserHasCapability(user.permission, UserCapability.STAFF)) {
			await this.getComments(response.data.order);
		}

		this.setState({
			error: undefined,
			logIn: false,
			order: response.data.order,
		});
	}

	async getComments(order?: Api.Post.OrderType)
	{
		if(!order) {
			return;
		}

		let response = await Api.Staff.Order.Call.getComments({
			id: order.id,
		});

		if(!response.data) {
			throw response.error;
		}

		this.setState({
			comments: response.data.comments,
		});

		setImmediate(() => {
			if(!this.notesElement) return;
			this.notesElement.scrollTop = this.notesElement.scrollHeight;
		});
	}

	render()
	{
		if(this.state.logIn) {
			return (
				<Container>
					<Component.Login
						loginCallback={() => {
							this.update();
						}}
					/>
					<Component.Padding type="bottom" />
				</Container>
			);
		}

		if(this.state.error === 404) {
			return (
				<Container>
					<Col><h4>Order not found</h4></Col>
					<Col><p>
						The order at the url specified was
						not found. Please check the spelling
						of the order ID.
					</p></Col>
					<Component.Padding type="bottom" />
				</Container>
			);
		}

		if(this.state.error === 403) {
			return (
				<Container>
					<Col><h4>Unauthorised</h4></Col>
					<Col><p>
						You don't have permission to view
						this order. Make sure you are signed
						in with the same account that the order
						was placed by.
					</p></Col>
					<Component.Padding type="bottom" />
				</Container>
			);
		}

		let user = this.state.session.user;

		if(!this.state.order) {
			return <></>;
		}

		return (
			<div className="page-order">

				<Container>
					<h4>{this.state.order.name}'s order</h4>
					<p>
							For support/questions about
							your purchase, please contact us at <a
								href="mailto:postal.orders@gouldsnaturalmedicine.com.au"
							>postal.orders@gouldsnaturalmedicine.com.au</a
							> or call +61 3 6234 4223
							(9am to 5pm weekdays, 10.00am to 1.30pm Saturdays, GMT+10).
						</p>
					<div><Component.Invoice order={this.state.order} /></div>
					<div className="spacer">
						<h5>Shipping details</h5>
						<Component.Skipper>
							{
								this.state.order.shipping ? (
									<table>
										<tbody>
											<tr>
												<td><p>Name</p></td>
												<td><p>{this.state.order.shipping.name}</p></td>
											</tr>
											<tr>
												<td><p>Country</p></td>
												<td><p>{this.countryCodeToName(
													this.state.order.shipping.country
												)}</p></td>
											</tr>
											<tr>
												<td><p>Address 1</p></td>
												<td><p>{this.state.order.shipping.address1}</p></td>
											</tr>
											{this.state.order.shipping.address2 !== "" ? (
												<tr>
													<td><p>Address 2</p></td>
													<td><p>{this.state.order.shipping.address2}</p></td>
												</tr>
											) : <></>}
											<tr>
												<td><p>City</p></td>
												<td><p>{this.state.order.shipping.city}</p></td>
											</tr>
											<tr>
												<td><p>State</p></td>
												<td><p>{this.state.order.shipping.state}</p></td>
											</tr>
											<tr>
												<td><p>{(
													this.state.order.shipping.country === "US" ?
													"Zip Code" : "Postcode"
												)}</p></td>
												<td><p>{this.state.order.shipping.postcode}</p></td>
											</tr>
											<tr>
												<td><p>Service</p></td>
												<td><p>{this.state.order.shipping.method}</p></td>
											</tr>
										</tbody>
									</table>
								) : (
									<p>You have selected to pick up your order.</p>
								)
							}
						</Component.Skipper>
					</div>
					<div className="spacer">
						<h5>Customer name</h5>
						<p>{this.state.order.name}</p>
					</div>
					<div className="spacer">
						<h5>Email</h5>
						<p>{this.state.order.email}</p>
					</div>
					<div className="spacer">
						<h5>Phone number</h5>
						<p>{this.state.order.phone}</p>
					</div>
					<div className="spacer">
						<h5>Reference number</h5>
						<p>{this.state.order.ref}</p>
					</div>
					{this.state.order.shipping && this.state.order.shipping.tracking ? (
						<div className="spacer">
							<h5>Tracking number</h5>
							<p><a href={`http://auspost.com.au/track/track.html?${
								Helpers.Data.QueryString({id: this.state.order.shipping.tracking})
							}`} target="_blank">{this.state.order.shipping.tracking}</a></p>
						</div>
					) : <></>}
					{this.state.order.payment_method ? (
						<div className="spacer">
							<h5>Payment method</h5>
							<p>{
								this.state.order.payment_method === "CARD" ? "Card" :
								this.state.order.payment_method === "BANK" ? "Bank deposit" :
								this.state.order.payment_method === "PHONE" ? "Phone" : ""
							}</p>
						</div>
					) : <></>}
					<div className="spacer">
						<h5>Order status</h5>
						<p>
							{OrderStatusToString(this.state.order.step, this.state.order.shipping ? true : false)}
						</p>
					</div>
					<div className="spacer">
						<h5>Order creation date</h5>
						<p>{new Date(this.state.order.created).toLocaleString()}</p>
					</div>
					{this.state.comments ? (
						<div className="spacer">
							<h5>Notes</h5>
							<Component.Skipper enabled={this.state.comments.length > 0}>
								<div className="scroll-window" ref={(e) => {
									if(e) {
										this.notesElement = e;
									}
								}}>
									{(() => {

										let elements: JSX.Element[] = [];
										let it = 0;

										for(let comment of this.state.comments) {
											elements.push(
												<Component.Card>
													<p>
														<b style={{
															color: "#8d8d8d",
														}}>(<span className="link" {...OnActive(() => App.routeToUrl("/staff/user?" + Helpers.Data.QueryString({
															email: comment.email
														})))}>{comment.name}</span> at {
														new Date(comment.created).toLocaleString()
														})</b> {comment.content}
													</p>
												</Component.Card>
											);
											it += 1;
										}

										return elements;

									})()}
								</div>
							</Component.Skipper>
							<label className="visually-hidden" htmlFor={this.aria_write_comment}>Write a comment</label>
							<Input
								id={this.aria_write_comment}
								onKeyPress={async (e) =>
								{
									if(
										e.key !== "Enter" ||
										!this.state.comments ||
										!this.commentElement ||
										!this.state.order
									) {
										return;
									}

									let comment = this.commentElement.value;
									let user = this.state.session.user;

									if(comment.length === 0 || !user) {
										return;
									}

									this.state.comments.push({
										created: Date.now(),
										content: comment,
										email: user.email,
										name: user.name,
									});

									this.setState({
										comments: this.state.comments,
									});

									this.commentElement.value = "";

									setImmediate(() => {
										if(!this.notesElement) return;
										this.notesElement.scrollTop = this.notesElement.scrollHeight;
									});

									await Api.Staff.Order.Call.addComment({
										id: this.state.order.id,
										content: comment,
									});
								}}

								innerRef={(e) => {
									if(e) {
										this.commentElement = e;
									}
								}}
							/>
						</div>
					) : <></>}
					{(user && UserHasCapability(user.permission, UserCapability.STAFF) &&
					this.state.order.step !== "CANCELLED") ? (
						<div>
							{this.state.order.step === "CREATED" ? (
								<Button onClick={() => {
									this.refund();
								}}>Cancel</Button>
							) : (
								<Button onClick={() => {
									this.refund();
								}}>{this.state.order.payment_method === "CARD" ? "Refund" : "Mark as Refunded"}</Button>
							)}
							{this.state.order.step === "PAID" ? (
								<Button onClick={() => {
									this.changeStatus("PROCESSED");
								}}>Process Order</Button>
							) : this.state.order.step === "CREATED" ? (
								<Button onClick={() => {
									this.markAsPaid();
								}}>Mark as Paid</Button>
							) : this.state.order.step === "PROCESSED" ? (
								this.state.order.shipping ? (
									<Button onClick={() => {
										this.shipOrder();
									}}>Ship Order</Button>
								) : (
									<Button onClick={() => {
										this.changeStatus("SHIPPED");
									}}>Collect Order</Button>
								)
							) : <></>}
						</div>
					) : <></>}
					<Component.Padding type="bottom" />
				</Container>
			</div>
		)
	}
}

export const OrderRoute = () => MakeRoute(Order);

