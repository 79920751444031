import {useState, useId} from "react";
import {Container, Col, Input, FormText, Button} from "reactstrap";
import {EmailValidator} from "../../shared/Validators";
import {App} from "../App";
import {SessionReload} from "../AppHelpers";
import {Api} from "../../shared";

export const ChangeEmail = ({user}: {user: Api.Auth.UserType}) =>
{
	const [email, setEmail] = useState(user.email);
	const [errors, setErrors] = useState("");

	const click = async () =>
	{
		let errors_n = "";

		switch(EmailValidator(email))
		{
			case "InvalidCharacters":
			case "InvalidSyntax":
				errors_n = "Illegal characters";
				break;
			case "Required":
				errors_n = "Value is required";
				break;
			case "TooLong":
				errors_n = "Value is too big";
				break;
			case "Valid": {
				errors_n = "";
				break;
			}
		}

		if(email.toLowerCase() === user.email.toLowerCase())
		{
			errors_n = "Value unchanged";
		}

		if(errors_n === "")
		{
			let res = await Api.Auth.Call.changeEmail({
				email: email
			});

			if(res.error !== undefined)
			{
				switch(res.error)
				{
					case "not logged in":
						errors_n = "Not logged in";
						break;
					case "unauthorised":
						errors_n = "Unauthorised";
						break;
					case "email in use":
						errors_n = "Email already in use";
						break;
					default:
						throw res.error;
				}
			}
		}

		setErrors(errors_n);

		if(errors_n === "")
		{
			await SessionReload();

			App.open(null);
		}
	}

	const id1 = useId();
	const id2 = useId();

	return (
		<Container aria-labelledby={id1}>
			<Col><h4 id={id1}>Change Email</h4></Col>
			<Col><p>
				When you change your email you will need to confirm your account
				via a link. This is to verify that you are the owner. 
			</p></Col>
			<Col>
				<label htmlFor={id2} className="visually-hidden">New email</label>
				<Input id={id2} placeholder="Your Name" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
				<FormText color="danger">{errors}</FormText>
			</Col>
			<Col className="spacer">
				<Button onClick={click}>Ok</Button>
				<Button onClick={() => App.open(null)}>Cancel</Button>
			</Col>
		</Container>
	)
}
