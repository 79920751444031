import {Api} from "../shared";
import { GlobVar } from "./globvar";

export class GlobalVariables
{
	static IsServer = false;
	static Url = "";
	static RecaptchaSecret = "";
	static StripeSecret = "";
	static AuspostSecret = "";
	static IsPhone = false;
	static EmailApothecaryCallback = "";
	static EmailPostalCallback = "";
	static ABN = "";
	static FixerKey = "";
	static Session = new GlobVar<Api.Auth.SessionType>({
		confirmed: true,
		cart_count: 0,
		cookies: [],
	});
}
