import {useId, useState} from "react";
import {Col, Label, Input, FormText, Button, FormGroup, Form, Container} from "reactstrap";
import {App} from "../../app/App";
import {EmailValidator, PasswordValidator} from "../../shared/Validators";
import {SessionReload} from "../../app/AppHelpers";
import {GlobalVariables} from "../../scripts/GlobalVariables";
import {workerCalculatePassword} from "../../scripts/worker";
import {GenID, OnActive, ReactComponent} from "../../scripts/util";
import {Api, Helpers} from "../../shared";
import {Component} from "../..";
import {Notice_GetCookiePreferences} from "../notice";

interface IProps {
	loginCallback?: () => void;
}

interface IState {
	dangerText: string;
	buttonDisabled: boolean;
	session: Api.Auth.SessionType;
}

const GetTOTP = (props: {
	password: string, 
	email: string, 
	is_totp: boolean, 
	close: () => void, 
	callback: (code: string, remember: boolean) => Promise<boolean>
}) => {
	let code_name: string;

	const [session, set_session] = useState(GlobalVariables.Session.value);
	const [remember_me, set_remember_me] = useState(false);
	const [is_totp, set_is_totp] = useState(props.is_totp);
	const [errors, set_errors] = useState("");
	const [code, set_code] = useState("");
	const id1 = useId();
	const id2 = useId();
	const id3 = useId();

	GlobalVariables.Session.regUse(set_session);

	if(is_totp)
	{
		code_name = "Authenticator Code";
	}

	else
	{
		code_name = "One-Time Code";
	}

	const get_code = async () =>
	{
		await Api.Auth.Call.sendMFA({
			password: props.password,
			email: props.email,
		});

		set_is_totp(false);
	}

	const submit = async () =>
	{
		if(!await props.callback(code, remember_me))
		{
			set_errors("Invalid Code");
		}
	}

	let msg_e: JSX.Element;
	let remember_label_e: JSX.Element;

	if(is_totp) {
		msg_e = <>
			<p>
				Enter in the code from your Authenticator app to log in.
			</p>
			<p>
				If you cannot access your authenticator app,
				we can email you a temporary code. 
			</p>
		</>;
	} else {
		msg_e = (
			<p>
				We have sent a temporary one-time code to your email.
				Please enter this code. It expires in 1 hour. 
			</p>
		);
	}

	if(session.cookies.includes("REMEMBER_OTP")) {
		remember_label_e = <>Remember for 90 days</>;
	} else {
		remember_label_e = <>Remember for 90 days <span className="link" {...OnActive(Notice_GetCookiePreferences)}>(enable)</span></>;
	}

	return (
		<Container role="dialogue" aria-labelledby={id1}>
			<h4 id={id1}>Enter {code_name}</h4>
			{msg_e}
			<FormGroup>
				<label htmlFor={id2} className="visually-hidden">{code_name}</label>
				<Input onChange={(e) => set_code(e.target.value)} placeholder={code_name} maxLength={9} />
				<FormText color="danger">{errors}</FormText>
			</FormGroup>
			<FormGroup>
				<Input type="checkbox" id={id3} onChange={v=>set_remember_me(v.target.checked)} disabled={!session.cookies.includes("REMEMBER_OTP")}
				/> <Label htmlFor={id3}>{remember_label_e}</Label>
			</FormGroup>
			<FormGroup>
				<Button onClick={submit}>Submit</Button>
				<Button disabled={!is_totp} onClick={get_code}>Get Code</Button>
				<Button onClick={props.close}>Close</Button>
			</FormGroup>
		</Container>
	)
}

export class Login extends ReactComponent<IProps, IState>
{
	emailElement!: HTMLInputElement | HTMLTextAreaElement;
	passwordElement!: HTMLInputElement | HTMLTextAreaElement;
	formElement!: HTMLFormElement;

	constructor(props: IProps) {
		super(props);

		this.state = {
			dangerText: "",
			buttonDisabled: false,
			session: GlobalVariables.Session.regSet(this, "session"),
		};
	}

	id1 = GenID();
	id2 = GenID();

	render()
	{
		let cookie_error = !this.state.session.cookies.includes("SESSION");

		return (
			<div className="component-login" role="form">

				<Col><h4>Login</h4></Col>

				<Form onSubmit={(e) => {
					e.preventDefault();
				}} innerRef={(e) => {
					if(e) {
						this.formElement = e;
					}
				}}>
					<Col>
						<FormGroup>
							<Label htmlFor={this.id1}>Email</Label>
							<Input
								id={this.id1}
								disabled={cookie_error}
								onChange={() => this.clearDangerText()}
								type="text" innerRef={(e) => {
									if(e) this.emailElement = e;
								}}
							/>
						</FormGroup>
					</Col>

					<Col>
						<FormGroup>
							<Label htmlFor={this.id2}>Password</Label>
							<Input
								id={this.id2}
								disabled={cookie_error}
								onChange={() => this.clearDangerText()}
								type="password" innerRef={(e) => {
									if(e) this.passwordElement = e;
								}}
							/>
							<FormText color="danger">
								{this.state.dangerText}
								{cookie_error ? (
									<>
										Cannot login due to your cookie preferences. To login, please
										allow user session cookies <span className="link" {...OnActive(Component.Notice_GetCookiePreferences)}>here.</span>
									</>
								) : <></>}
							</FormText>
						</FormGroup>
					</Col>

					<Col>
						<FormGroup>
							<Button
								onClick={() => this.login()}
								disabled={this.state.buttonDisabled || cookie_error}
								type="submit"
							>Login</Button>
							<Button disabled={cookie_error} onClick={() => App.routeToUrl("/register")}>Register</Button>
							<Button disabled={cookie_error} onClick={() => App.routeToUrl("/login/forgot")}>Forgot</Button>
						</FormGroup>
					</Col>
				</Form>

			</div>
		)
	}

	clearDangerText() {
		this.setState({
			dangerText: "",
		});
	}

	async login()
	{
		let email = this.emailElement.value;
		let password = this.passwordElement.value;

		// Check if there is nothing in the email or password
		if(email === "" || password === "") {
			this.setState({
				dangerText: "Value required for email and password",
			});
			return;
		}

		this.setState({
			buttonDisabled: true,
		});

		// Check if the email and password format is actually valid first
		if(EmailValidator(email) !== "Valid" || PasswordValidator(password) !== "Valid") {
			this.setState({
				dangerText: "Incorrect username or password",
				buttonDisabled: false,
			});
			this.emailElement.value = "";
			this.passwordElement.value = "";
			return;
		}

		// Get the user salt
		let salt_response = await Api.Auth.Call.salt({
			email: email,
		});

		// Check if there were any salt errors
		if(!salt_response.data) {
			throw salt_response.error;
		}

		// Salt the password
		let hash = await workerCalculatePassword(password, salt_response.data.header);

		// attempt to log in now
		let login_response = await Api.Auth.Call.login({
			password: hash,
			email: email,
		});

		// we need to ask for one-time-passwords
		if(login_response.data && login_response.error === "invalid otp")
		{
			this.setState({
				buttonDisabled: false,
			});

			let lock = new Helpers.Data.AsyncLock();
			let is_totp = login_response.data.missing === "totp";

			const callback = async (code: string, remember: boolean) =>
			{
				let otp = parseFloat(code);

				if(isNaN(otp) || Math.floor(otp) !== otp || otp < 0)
				{
					return false;
				}

				login_response = await Api.Auth.Call.login({
					remember: remember,
					password: hash,
					email: email,
					otp: otp,
				});

				if(login_response.error === "invalid otp")
				{
					return false;
				}

				else
				{
					lock.free(0);

					return true;
				}
			}

			App.open(() => <GetTOTP is_totp={is_totp} email={email} password={hash} callback={callback} close={() => lock.free(0)} />)

			await lock.wait();

			App.open(null);
		}

		// Check if everything went ok
		if(login_response.error) {
			this.emailElement.value = "";
			this.passwordElement.value = "";
			if(login_response.error === "auth error") {
				this.setState({
					dangerText: "Incorrect username or password",
					buttonDisabled: false,
				});
				return;
			} else {
				throw login_response.error;
			}
		}

		// Update the session if it has been modified
		await SessionReload();

		this.emailElement.value = "";
		this.passwordElement.value = "";

		this.setState({
			buttonDisabled: false,
		});

		if(this.props.loginCallback) {
			this.props.loginCallback();
		}
	}
}
