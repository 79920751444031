import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from "draft-js";
import React from "react";
import { Col, FormGroup, FormText, Input, Label } from "reactstrap";
import { FormHelperItem } from "../../scripts/formhelper";
import { RemoveDraftJSWhitespaces } from "../../shared/helpers/DataHelpers";
import {Component} from "../..";

interface IProps
{
	form: FormHelperItem<RawDraftContentState>;
}

interface IState
{
	editorState?: EditorState;
}

export class ProductDescription extends React.Component<IProps, IState>
{
	constructor(props: IProps)
	{
		super(props);
		this.state = {};
	}

	errors(v: string)
	{
		if(v.length === 0)
		{
			return "Required";
		}

		else
		{
			return "";
		}
	}

	componentDidMount()
	{
		this.setState({
			editorState: EditorState.createEmpty(),
		});

		this.props.form.onModify = (state) =>
		{
			if(state)
			{
				this.setState({
					editorState: EditorState.createWithContent(convertFromRaw(state)),
				});
			}
		};

		this.props.form.errors = (v) =>
		{
			if(!v || v.blocks.length === 0)
			{
				return "Required";
			}

			else
			{
				return "";
			}
		};

		this.props.form.update();
	}

	render()
	{
		if(!this.state.editorState)
		{
			return <></>;
		}

		return (
			<Col><FormGroup>

				<Label>Description</Label>
				<Component.Skipper>
					<Component.TextEditorContainer
						onChange={(state) => this.props.form.modify(RemoveDraftJSWhitespaces(convertToRaw(state.getCurrentContent())))}
						editorState={this.state.editorState}
					/>
					<FormText color="danger">{this.props.form.addErrorField()}</FormText>
				</Component.Skipper>

			</FormGroup></Col>
		)
	}
}
