
import "./style.scss";
import React, { ReactElement } from "react";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import { OnActive } from "../../scripts/util";

interface IProps {
	bigOnClick?: () => void;
	disabled?: boolean;
	children: string;
}

interface IState {
	disabled: boolean;
}

export class BigButton extends React.Component<IProps, IState>
{
	setDisabled(status = true)
	{
		this.setState({
			disabled: status,
		});
	}

	isDisabled()
	{
		if(this.props.disabled === undefined)
		{
			return this.state.disabled;
		}

		else
		{
			return this.props.disabled;
		}
	}

	constructor(props: IProps)
	{
		super(props);

		this.state = {
			disabled: !!props.disabled,
		}
	}

	render()
	{
		let disabled = !this.props.bigOnClick || this.isDisabled();

		return (
			<div
				aria-disabled={disabled}
				className={"component-big-button" + (this.isDisabled() ? " disabled" : "")}
				{...OnActive(() =>
				{
					if(this.props.bigOnClick && !this.isDisabled())
					{
						this.props.bigOnClick();
					}
				})}
			>
				{GlobalVariables.IsPhone ? <p>{this.props.children}</p> : <h5>{this.props.children}</h5>}
			</div>
		)
	}
}
