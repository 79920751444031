
import React, { useId } from "react";
import { Button, Input } from "reactstrap";
import { GenID } from "../../scripts/util";
import { GlobalVariables } from "../../scripts/GlobalVariables";
import {Api} from "../../shared";

interface IProps {
	defaultValue?: Api.Staff.Product.ItemOptType;
	onChange?: (p: Api.Staff.Product.ItemOptType) => void;
	onDelete?: () => void;
	single?: boolean;
}

export class ProductType extends React.Component<IProps>
{
	labelElement?: HTMLInputElement | HTMLTextAreaElement;
	weightElement?: HTMLInputElement | HTMLTextAreaElement;
	instockElement?: HTMLInputElement | HTMLTextAreaElement;
	costElement?: HTMLInputElement | HTMLTextAreaElement;

	update()
	{
		let weight = 0;
		let cost = 0;

		if(this.costElement) {
			cost = Math.round(parseFloat(this.costElement.value) * 100);
		}

		if(this.weightElement) {
			weight = parseFloat(this.weightElement.value);
		}

		if(isNaN(cost)) {
			cost = 0;
		}

		if(isNaN(weight)) {
			weight = 0;
		}

		if(this.props.onChange) {
			this.props.onChange({
				out_stock: (this.instockElement && this.instockElement.value === "1") ? false : true,
				name: this.labelElement ? this.labelElement.value : "",
				weight: weight,
				amount: cost,
			});
		}
	}

	id1 = GenID();
	id2 = GenID();
	id3 = GenID();
	id4 = GenID();

	render() {
		return (
			<>
				{!this.props.single ? (
					<td>
						<label className="visually-hidden" htmlFor={this.id1}>Product option label</label>
						<Input
							id={this.id1}
							placeholder="Label"
							innerRef={(e) => {
								if(e) this.labelElement = e;
							}}
							onChange={() => {
								this.update();
							}}
							defaultValue={
								this.props.defaultValue ?
								this.props.defaultValue.name : ""
							}
						/>
					</td>
				) : <></>}
				<td>
					<label className="visually-hidden" htmlFor={this.id2}>Product option weight in grams</label>
					<Input
						id={this.id2}
						innerRef={(e) => {
							if(e) this.weightElement = e;
						}}
						onChange={() => {
							this.update();
						}}
						defaultValue={
							(this.props.defaultValue && this.props.defaultValue.weight !== 0) ?
							this.props.defaultValue.weight : ""
						}
						type="number"
						min="0"
					/>
				</td>
				<td>
					<label className="visually-hidden" htmlFor={this.id3}>Product option cost in AUD</label>
					<Input
						id={this.id3}
						innerRef={(e) => {
							if(e) this.costElement = e;
						}}
						onChange={() => {
							this.update();
						}}
						defaultValue={
							(this.props.defaultValue && this.props.defaultValue.amount !== 0) ?
							(this.props.defaultValue.amount/100).toFixed(2) : ""
						}
						type="number"
						step="0.01"
						min="0"
					/>
				</td>
				<td>
					<label className="visually-hidden" htmlFor={this.id4}>Product option stock status</label>
					<Input
						id={this.id4}
						type="select"
						defaultValue={this.props.defaultValue && this.props.defaultValue.out_stock ? 0 : 1}
						innerRef={(e) => {
							if(e) {
								this.instockElement = e;
							}
						}}
						onChange={() => {
							this.update();
						}}
					>
						<option value={1}>In stock</option>
						<option value={0}>Out of stock</option>
					</Input>
				</td>
				<td>
					<Button
						disabled={this.props.single}
						onClick={() => {
							if(this.props.onDelete) {
								this.props.onDelete();
							}
						}}
					>X</Button>
				</td>
			</>
		);
	}
}
