
import React, { createRef } from "react";
import {Col, Table, FormGroup, Label, Input, FormText, Button, Container} from "reactstrap";
import {GenID} from "../../scripts/util";
import {IntParser, RenderCurrency} from "../../shared/helpers/DataHelpers";
import {AppHistory} from "../App";
import {Api} from "../../shared";
import {Page} from "../..";

interface IProps {

}

interface IState {
	errors: string;
	button_disabled: boolean;
	totals?: Api.Staff.TotalsType[];
}

export class Totals extends React.Component<IProps, IState>
{
	from_ref = createRef<HTMLInputElement>();
	to_ref = createRef<HTMLInputElement>();
	lock = false;

	constructor(props: IProps)
	{
		super(props);
		this.state = {
			errors: "",
			button_disabled: false,
		};
	}

	async componentDidMount()
	{
		let url = new URL(window.location.href);
		let min = IntParser(url.searchParams.get("min"));
		let max = IntParser(url.searchParams.get("max"));

		if(this.from_ref.current && this.to_ref.current && !isNaN(min) && !isNaN(max) && min > 0 && max > 0 && max > min)
		{
			let min_d = new Date(min);
			let max_d = new Date(max);

			min -= min_d.getTimezoneOffset() * 60000;
			max -= max_d.getTimezoneOffset() * 60000;

			this.from_ref.current.valueAsDate = new Date(min);
			this.to_ref.current.valueAsDate = new Date(max);

			await this.click();
		}
	}

	update(): [number, number] | undefined
	{
		this.setState({
			totals: undefined,
		});

		if(!this.from_ref.current || !this.to_ref.current)
		{
			this.setState({
				errors: "",
			});
			return;
		}

		let from = Date.parse(this.from_ref.current.value);
		let to = Date.parse(this.to_ref.current.value);

		if(isNaN(from) || isNaN(to))
		{
			this.setState({
				errors: "invalid syntax",
			});
			return;
		}

		if(from > to)
		{
			[from, to] = [to, from];
		}

		this.setState({
			errors: "",
		});

		let q = new URLSearchParams();
		q.set("min", from.toString());
		q.set("max", to.toString());
		AppHistory.push("?" + q.toString());

		return [from, to];
	}

	async click()
	{
		let b = this.update();

		if(b === undefined)
		{
			return;
		}

		if(this.lock)
		{
			return;
		}

		this.lock = true;
		this.setState({
			button_disabled: true,
		});

		let res = await Api.Staff.Call.totals({
			min: b[0].toString(),
			max: b[1].toString()
		});

		this.lock = false;
		this.setState({
			totals: res.data?.totals,
			button_disabled: false,
		});
	}

	render_totals()
	{
		if(!this.state.totals)
		{
			return;
		}

		let totals = this.state.totals;
		let elements: JSX.Element[] = [];
		let it = 0;

		for(let total of totals)
		{
			elements.push(
				<tr key={it++}>
					<td><p>
						{total.method}
					</p></td>
					<td><p>
						{RenderCurrency(total.gross)}
					</p></td>
					<td><p>
						{RenderCurrency(total.total)}
					</p></td>
					<td><p>
						{RenderCurrency(total.refund)}
					</p></td>
					<td><p>
						{RenderCurrency(total.total_gst_inc)}
					</p></td>
					<td><p>
						{RenderCurrency(total.total_gst_exc)}
					</p></td>
					<td><p>
						{RenderCurrency(total.gst_aud)}
					</p></td>
					<td><p>
						{RenderCurrency(total.vat_gbp, "£", " GBP")}
					</p></td>
				</tr>
			);
		}

		return (
			<Col>
				<Table className="borders">
					<thead>
						<tr>
							<th><p>Method</p></th>
							<th><p>Total Sales</p></th>
							<th><p>Net Total</p></th>
							<th><p>Refunds</p></th>
							<th><p>Sales (GST inc)</p></th>
							<th><p>Sales (GST free)</p></th>
							<th><p>GST</p></th>
							<th><p>VAT</p></th>
						</tr>
					</thead>
					<tbody>
						{elements}
					</tbody>
				</Table>
			</Col>
		);
	}

	id1 = GenID();
	id2 = GenID();

	render()
	{
		return (
			<Page.Staff>
				<Container>
					<Col>
						<h4>Webstore Totals</h4></Col>
					<Col>
						<FormGroup>
							<Label htmlFor={this.id1}>From</Label>
							<Input innerRef={this.from_ref} onChange={() => this.update()} id={this.id1} type="datetime-local" />
						</FormGroup>
						<FormGroup>
							<Label htmlFor={this.id2}>To</Label>
							<Input innerRef={this.to_ref} onChange={() => this.update()} id={this.id2} type="datetime-local" />
							<FormText color="danger">{this.state.errors}</FormText>
						</FormGroup>
						<FormGroup>
							<Button onClick={() => this.click()}>Generate</Button>
						</FormGroup>
					</Col>
					{this.render_totals()}
				</Container>
			</Page.Staff>
		)
	}
}
