
import React, { useId } from "react";
import { Col, Input } from "reactstrap";
import { GenID } from "../../scripts/util";
import {Api} from "../../shared";
import {Component} from "../..";

interface IProps {
	terms?: string;
	setTerms?: (terms: string) => void;
	onClick: (user: Api.Staff.User.UserBasicType) => void;
}

interface IState {
	terms: string;
	users: Api.Staff.User.UserBasicType[];
}

export class UserSearch extends React.Component<IProps, IState>
{
	searchElement?: HTMLInputElement | HTMLTextAreaElement;

	constructor(props: IProps) {
		super(props);

		this.state = {
			terms: "",
			users: [],
		};
	}

	async componentDidMount()
	{
		let terms = this.props.terms;

		if(typeof(terms) === "string") {
			this.setState({
				terms: terms,
			});

			if(this.searchElement) {
				this.searchElement.value = terms;
			}
		}

		if(typeof(terms) === "string") {
			this.setState({
				users: await this.search(terms),
			});
		}
	}

	async search(terms?: string)
	{
		if(terms === undefined) {
			terms = this.state.terms;
		}

		if(this.props.setTerms)
		{
			this.props.setTerms(terms);
		}

		if(terms.length === 0) {
			return [];
		}

		let response = await Api.Staff.User.Call.search({
			q: terms,
		});

		if(!response.data) {
			if(response.error === "unauthorised") {
				return [];
			}
			throw response.error;
		}

		
		let users: Api.Staff.User.UserBasicType[] = response.data.users;
		return users;
	}

	getUsers()
	{
		let elements: JSX.Element[] = [];

		for(let i=0;i<this.state.users.length;i++)
		{
			let user = this.state.users[i];

			elements.push(<Component.UserCard
				key={i}
				user={user}
				onClick={() => this.props.onClick(user)}
			/>);
		}

		return elements;
	}

	id_search = GenID();

	render() {
		return (
			<>
				<Col>
					<label className="visually-hidden" htmlFor={this.id_search}>Search our users</label>
					<Input
						innerRef={(e) => {if(e) this.searchElement = e}}
						id={this.id_search}
						placeholder="Search"
						onChange={async () => {
							if(!this.searchElement) {
								return;
							}
							this.setState({
								users: await this.search(this.searchElement.value),
								terms: this.searchElement.value,
							});
						}}
					/>
				</Col>

				<Col>
					{this.getUsers()}
				</Col>
			</>
		)
	}
}
