
import React from "react";
import {Container, Col, Button} from "reactstrap";
import {App} from "../App";
import {Api} from "../../shared";
import {Component, Page} from "../..";
import {ProductDelete} from "../../component";

interface IProps {

}

interface IState {
	error: boolean;
	loaded: boolean;
	product?: Api.Product.ProductType;
}

export class EditProduct extends React.Component<IProps, IState>
{
	constructor(props: IProps) {
		super(props);

		this.state = {
			error: false,
			loaded: false,
		};
	}

	async componentDidMount()
	{
		let query = new URL(window.location.href);
		let id = query.searchParams.get("id");

		if(typeof(id) !== "string") {
			this.setState({
				error: true,
				loaded: true,
			});
			return;
		}

		let response = await Api.Product.Call.get({
			id: id
		});

		if(response.error === "product not found")
		{
			this.setState({
				loaded: true,
				error: true,
			});
			return;
		}

		if(!response.data) {
			return;
		}

		this.setState({
			loaded: true,
			product: response.data.product,
		});
	}

	render()
	{
		if(!this.state.loaded) {
			return <></>;
		}

		if(this.state.error) {
			return (
				<Container>
					<Col><h4>Product not found</h4></Col>
					<Col><p>
						The product you entered doesn't exist.
						Please make sure to check the spelling
						of the URL.
					</p></Col>
					<Component.Padding type="bottom" />
				</Container>
			);
		}

		if(!this.state.product) {
			return;
		}

		let product = this.state.product;
		let form_e: JSX.Element;

		async function onDelete(mergeInto?: Api.Product.ProductSearchedType)
		{
			if(!product) {
				return;
			}
			
			let response = await Api.Staff.Product.Call.delete({
				mergeIntoId: mergeInto?.id,
				id: product.id
			});

			if(response.error) {
				throw response.error;
			}
	
			App.routeToUrl("/staff");
		}

		if(!product.template)
		{
			form_e = (
				<Component.AddProduct
					productId={product.id}
					isEdit={true}
					preset={product}
					getProductId={() => product.id}
					onSubmit={async (p) =>
					{
						if(!product) {
							return;
						}

						let response = await Api.Staff.Product.Call.create({
							id: product.id,
							product: p,
						});
	
						if(!response.data) {
							throw response.error;
						}
	
						App.routeToUrl("/store/item?id=" + response.data.id);
	
					}}
					onDelete={onDelete}
				/>
			);
		}

		else
		{
			let preset: Api.Practitioner.Product.TemplateType = {
				...product,
				template: product.template!,
				cart_max: product.cart_max!,
				components: product.components!,
				sizes: [],
			}

			for(let item of product.options) {
				preset.sizes.push(item.sizing!);
			}

			async function onDeleteClick()
			{
				let res = await Api.Staff.User.Call.getScriptUsers({product_id: product.id});

				if(!res.data) {
					throw res.error;
				}

				App.open(() => <ProductDelete onDelete={onDelete} scripts={res.data?.users.length ?? 0} />, false);
			}

			form_e = (
				<Component.AddTemplateProduct showStaffOpts={true} showPublicOpts={product.visibility !== "PRIVATE"} product={preset} onSubmit={async p =>
				{
					if(!product) {
						return;
					}

					let res = await Api.Practitioner.Product.Call.create({id: product.id, product: p});

					if(!res.data) {
						throw res.error;
					}

					App.routeToUrl("/store/item?id=" + res.data.id);
				}}>
					<Button onClick={onDeleteClick}>Delete</Button>
				</Component.AddTemplateProduct>
			);
		}

		return (
			<Page.Staff>
				<Container>
					<h4>Edit product</h4>
					{form_e}
				</Container>
			</Page.Staff>
		)
	}
}

