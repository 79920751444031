
import { Buffer } from "buffer";
import * as asmcrypto from "asmcrypto.js";
import crypto from "crypto";
import {TestShared} from "./Test";

export const hashString = (value: string | Buffer) => {
	return crypto.createHash('sha256').update(value).digest();
}

TestShared(() => Buffer.compare(hashString("hello"), Buffer.from("2CF24DBA5FB0A30E26E83B2AC5B9E29E1B161E5C1FA7425E73043362938B9824", "hex")) === 0);

export const correctHashLength = (value: string) => {
	return value.length === 64;
}

TestShared(() => correctHashLength(hashString("hello").toString("hex")));

export const secureRandom = (size: number = 16) => {
	return crypto.randomBytes(size);
}

TestShared(() => secureRandom().length === 16);

export const exportPasswordSalt = (password: Buffer) =>
{
	if(password.length === 72)
	{
		return password.subarray(0, 40);
	}

	if(password.length === 96 || password.length === 64)
	{
		return password.subarray(0, 32);
	}

	if(password.length === 40)
	{
		return password;
	}

	if(password.length === 32)
	{
		return password;
	}
}

export const verifyPassword = (password1: Buffer, password2: Buffer) =>
{
	if(password1.length === password2.length)
	{
		return crypto.timingSafeEqual(password1, password2);
	}

	return false;
}

export const calculatePassword_int = (password: string, header?: Buffer): Buffer =>
{
	let password_bin: Buffer | Uint8Array = Buffer.from(password);
	let ctx = new asmcrypto.Sha256();

	if(header)
	{
		let repeat: number;
		let hash: Buffer;

		if(header.length === 64)
		{
			let ctx_c: crypto.Hash;

			// yeah the convert hash to string is
			// pointless but i did this first lol
			// also i am lazy thats why this isnt
			// with the faster asmcrypto lib
			ctx_c = crypto.createHash("sha256");
			password_bin = ctx_c.update((password_bin.toString() + header.subarray(0, 32).toString("hex"))).digest();
			ctx_c = crypto.createHash("sha256");
			password_bin = ctx_c.update(password_bin.toString("hex") + header.subarray(32, 64).toString("hex")).digest();

			for(let i = 0; i < 16384; i++)
			{
				password_bin = ctx.reset().process(password_bin).finish().result as Uint8Array;
			}

			return Buffer.concat([password_bin, header]);
		}

		else if(header.length === 40)
		{
			repeat = Number(header.readBigInt64BE(0));
			hash = header.subarray(8, 40);
		}

		else
		{
			return calculatePassword_int(password);
		}

		let i = 0;
		let max = repeat - 1024;
		let end = 10000 + Date.now();

		// less comparisons = better

		for(i = 0; i < max && Date.now() < end; i += 1024)
		{
			for(let j = 0; j < 1024; j++)
			{
				password_bin = ctx.reset().process(hash).process(password_bin).finish().result as Uint8Array;
			}
		}

		for(; i < repeat; i++)
		{
			password_bin = ctx.reset().process(hash).process(password_bin).finish().result as Uint8Array;
		}

		return Buffer.concat([header, password_bin]);
	}

	else
	{
		let repeat = 0;
		let hash = secureRandom(32);
		let end = 125 + Date.now();

		// only take up 1/8th of a second
		// this will be almost unnoticable
		// but it will make passwords secure
		while(Date.now() < end)
		{
			password_bin = ctx.reset().process(hash).process(password_bin).finish().result as Uint8Array;
			repeat++;
		}

		let repeat_bin = Buffer.alloc(8);
		repeat_bin.writeBigInt64BE(BigInt(repeat));
		return Buffer.concat([repeat_bin, hash, password_bin]);
	}
}

const BASE32 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";

export const EncodeBase32 = (buff: Buffer) =>
{
	let num = BigInt(0);
	let m256 = BigInt(256);
	let m32 = BigInt(32);

	for(let i = 0; i < buff.length; i++)
	{
		num = (num * m256) + BigInt(buff[i]);
	}

	let out = "";

	while(num > 0)
	{
		out = BASE32[Number(num % m32)] + out;
		num = num / m32;
	}

	return out;
}

export const GenerateTOTP = (secret: Buffer, at = 0) =>
{
	let counter = Math.floor(Date.now() / 30000) + at;
	let buff = Buffer.alloc(8);

	for(let i = 7; i >= 0; i--)
	{
		buff[i] = counter & 255;
		counter >>= 8;
	}

	let hmac = crypto.createHmac("sha1", secret).update(buff).digest();
	let offset = hmac[hmac.length - 1] & 0xf;
	let code = ((hmac[offset] & 0x7f) << 24) |
				((hmac[offset + 1] & 0xff) << 16) |
				((hmac[offset + 2] & 0xff) << 8) |
				(hmac[offset + 3] & 0xff);
	
	return code % (10 ** 6);
}

export const VerifyTOTP = (secret: Buffer, token: number, window = 1) =>
{
	if(secret.length !== 20)
	{
		return false;
	}

	for(let i = -window; i <= window; i++)
	{
		if(GenerateTOTP(secret, i) === token)
		{
			return true;
		}
	}

	return false;
}

