
import {FormGroup, Input} from "reactstrap";
import {Api} from "../../../shared";
import {Component} from "../../..";
import {useId, useState} from "react";
import {ProductFinder} from "../../practitioner/ProductFinder";

enum mode_t {TEMPLATE, EXISTING, CUSTOM};

export function AddProduct(props: {onSubmit: (id: string) => any})
{
	const [mode, setMode] = useState<mode_t>(mode_t.TEMPLATE);
	const mode_id = useId();

	let e = <></>;

	switch(mode)
	{
		case mode_t.CUSTOM:
		e = (
			<Component.AddProduct onSubmit={async (p) => {
				let res = await Api.Staff.Product.Call.create({
					product: p
				});
				if(!res.data) {
					throw res.error;
				}
				props.onSubmit(res.data.id);
			}} />
		);
		break;
		case mode_t.TEMPLATE:
		e = (<Component.AddTemplateProduct showStaffOpts={true} showPublicOpts={true} product={{visibility: "PUBLIC"}} onSubmit={async p => {
			let res = await Api.Practitioner.Product.Call.create({
				product: p,
			});
			if(!res.data) {
				return res.error;
			}
			props.onSubmit(res.data.id);
		}} />);
		break;
		case mode_t.EXISTING:
		e = (<ProductFinder showPublicOpts={true} showStaffOpts={true} overrides={{visibility: "PUBLIC"}} callback={(_p, _demo, id) => props.onSubmit(id)} />);
		break;
	}

	return (
		<>
			<FormGroup>
				<label className="visually-hidden" htmlFor={mode_id}>Edit Mode</label>
				<Input id={mode_id} type="select" defaultValue={mode} onChange={e => setMode(parseInt(e.target.value))}>
					<option value={mode_t.TEMPLATE}>From Template</option>
					<option value={mode_t.EXISTING}>From My Products</option>
					<option value={mode_t.CUSTOM}>Full Custom</option>
				</Input>
			</FormGroup>
			{e}
		</>
	)
}
